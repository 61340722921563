import Big from "big.js";
import { SwitchboardConfiguration } from "../subscription-configurator/components/subscription/switchboard/switchboard-types";
import { AddonPrice } from "./types";

export function calculateFeeFor(
  type: "oneTimeFee" | "recurringFee",
  collection: AddonPrice[]
): Big {
  return collection.reduce((acc, currentValue) => acc.plus(currentValue[type]), new Big(0));
}

export function switchboardPrices(switchboardConfiguration: SwitchboardConfiguration | undefined) {
  if (switchboardConfiguration) {
    return [
      {
        oneTimeFee: switchboardConfiguration.oneTimeFee || 0,
        recurringFee: switchboardConfiguration.recurringFee || 0,
      },
      ...switchboardConfiguration.addons.map((a) => ({
        oneTimeFee: a.oneTimeFee || 0,
        recurringFee: a.recurringFee || 0,
      })),
    ];
  }
  return [];
}
