<template>
  <div class="secondary-filter">
    <div t-id="secondary-filter__dropdown" class="secondary-filter__dropdown">
      <telia-p>
        <strong>{{ t("sortingAndFiltering.secondaryFilter.compatibleWith") }}</strong>
      </telia-p>
      <MultiselectDropdown :options="checkableOptions" @selected-option="onChange" />
    </div>
    <div t-id="secondary-filter__selections" class="secondary-filter__selected-container">
      <telia-chip-filter
        v-for="option of selected"
        :t-id="'secondary-filter-' + option"
        :key="option"
        :value="option"
        class="secondary-filter-button"
        :checked="selected.includes(option)"
        @change="onChange($event.target.value)"
      >
        {{ getName(option) }}
      </telia-chip-filter>
      <telia-button
        t-id="secondary-filter__clear-button"
        v-if="selected.length"
        type="button"
        variant="tertiary-purple"
        @click="onClearFilter"
      >
        {{ t("sortingAndFiltering.secondaryFilter.clearFilters") }}
      </telia-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { computed, onMounted, onUnmounted, ref } from "vue";
import MultiselectDropdown from "./MultiselectDropdown.vue";

interface Option {
  name: string;
  value: string;
}
interface Props {
  options: Option[];
  queryParameterName: string;
}
const props = defineProps<Props>();
const selected = ref<string[]>([]);
const emit = defineEmits<{ (e: "selected-options", value: string[]): void }>();

onMounted(() => {
  window.addEventListener("single-spa:before-routing-event", setActiveFilterByQueryParameter);
  setActiveFilterByQueryParameter();
});

onUnmounted(() => {
  window.removeEventListener("single-spa:before-routing-event", setActiveFilterByQueryParameter);
});

const checkableOptions = computed(() => {
  return props.options.map((option) => ({
    ...option,
    checked: selected.value.includes(option.value),
  }));
});

function getName(value: string) {
  return props.options.find((option) => option.value === value)?.name;
}
function setActiveFilterByQueryParameter() {
  if (props.queryParameterName) {
    const option = getDefaultSelectionFromQueryString();
    if (option) {
      setDefaultSelection(option);
    }
  }
}
function getDefaultSelectionFromQueryString() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(props.queryParameterName);
}
function setDefaultSelection(option: string) {
  if (props.options.some((o) => o.value === option)) {
    selected.value = [];
    onChange(option);
  } else {
    onClearFilter();
  }
}
function onChange(value: string) {
  const index = selected.value.indexOf(value);
  index < 0 ? selected.value.push(value) : selected.value.splice(index, 1);
  emit("selected-options", selected.value);
}
function onClearFilter() {
  selected.value = [];
  emit("selected-options", selected.value);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

@import "@teliads/components/foundations/breakpoints/variables";

.secondary-filter {
  display: flex;
  gap: $telia-spacing-24;
  flex-wrap: wrap;
  @media screen and (max-width: $telia-breakpoint-medium) {
    gap: $telia-spacing-8;
  }

  &__dropdown {
    display: flex;
    gap: $telia-spacing-12;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 0 auto;
    @media screen and (max-width: $telia-breakpoint-medium) {
      align-items: start;
      gap: 0;
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    & > div {
      flex: 1 1 auto;
    }
  }

  &__selected-container {
    margin-top: $telia-spacing-8;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex: 3 1 60%;
    gap: $telia-spacing-4;
  }

  &__selected-container > * {
    margin-bottom: $telia-spacing-8;
  }
}
</style>
