<template>
  <div>
    <telia-notification heading-tag="h3" :status="alertMessage.status" :t-id="alertMessage.tId">
      <span slot="heading">{{ t(alertTitle) }}</span>
      <span slot="content">
        <telia-p>
          {{ t(alertMessage.message) }}
        </telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script>
import { translateMixin } from "../../../locale";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessagePresenter",
  mixins: [translateMixin],
  props: {
    messageType: { type: String, required: true },
  },
  data: () => ({
    messages: {
      accessoriesFetch: {
        tId: "accessories-fetch-error",
        variant: "alert",
        status: "warning",
        message: "Notifications.ProductFetchError",
      },
      accessoriesFilterNoResult: {
        tId: "accessories-filterNoResult-alert",
        variant: "info",
        status: "information",
        message: "Notifications.ProductFilterNoResult",
        title: "Notifications.ProductFilterNoResultHeader",
      },
    },
  }),
  computed: {
    alertMessage() {
      return this.messages[this.messageType] || null;
    },
    alertTitle() {
      return this.alertMessage?.title || "Notifications.DefaultAlert";
    },
  },
});
</script>

<style scoped></style>
