import { corpInstalledbaseSearch, corpProductOrder } from "@telia/b2b-rest-client";
import { SubscriptionList } from "../../../hardware-configurator/subscription-selector/extend-existing-subscription/extendSubscriptionTypes";

export async function searchSubscriptionsInScope(
  scopeId: string,
  searchString: string
): Promise<SubscriptionList> {
  return corpInstalledbaseSearch.CorporateInstalledbaseSearchService.searchSubscriptions(
    scopeId,
    ["MOBILE_VOICE"],
    searchString
  ) as Promise<SubscriptionList>;
}

export async function validateForExtension(scopeId: string, msisdn: string) {
  return corpProductOrder.NumberValidationControllerService.validateMsisdnForExtension(
    scopeId,
    msisdn
  );
}
