<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="subscription-product-listing" t-id="b2b-subscription-product-listing">
    <telia-row v-if="stateStore.isReplicatedMfAgreement">
      <telia-col width="12">
        <div class="subscription-product-listing__legacy-link">
          <telia-link :href="`/foretag/mybusiness/${scopeId}/bestall/oppna-mobilt-abonnemang`">
            <telia-p> {{ t("legacyLink.link") }}</telia-p>
          </telia-link>
        </div>
      </telia-col>
    </telia-row>

    <telia-grid class="subscription-product-listing__grid-container">
      <telia-row
        v-if="
          stateStore.category === TabCategories.Subscription && filteredMobileAgreements.length > 1
        "
      >
        <telia-col width="6">
          <PrimaryFilter
            :default-text="t('subscriptionListing.allAgreements')"
            :options="filteredMobileAgreements"
            @selected-option="selectAgreement"
          />
        </telia-col>
      </telia-row>
      <telia-row
        v-if="stateStore.category === TabCategories.Emn && filteredEmnAgreements.length > 1"
      >
        <telia-col width="6">
          <PrimaryFilter
            :default-text="t('subscriptionListing.allAgreements')"
            :options="filteredEmnAgreements"
            @selected-option="selectAgreement"
          />
        </telia-col>
      </telia-row>
      <template v-if="loading">
        <div v-for="n in 2" :key="n">
          <telia-row spacing="md" gap class="subscription-product-listing__product-group__row">
            <telia-col v-if="true" width="12">
              <telia-skeleton
                class="subscription-product-listing__header-skeleton"
              ></telia-skeleton>
              <telia-divider />
            </telia-col>
          </telia-row>
          <telia-row spacing="md" gap class="subscription-product-listing__product-group__row">
            <telia-col v-for="n in 4" :key="n" width="12" width-md="6" width-lg="3">
              <telia-skeleton
                :key="n"
                class="subscription-product-listing__product-skeleton"
              ></telia-skeleton>
            </telia-col>
          </telia-row>
        </div>
      </template>
      <template v-if="!loading && filteredSubscriptionGroups.length < 1">
        <telia-heading
          class="subscription-product-listing__empty-agreement-header"
          tag="h2"
          variant="title-200"
        >
          {{ t("subscriptionListing.agreementEmpty") }}
        </telia-heading>
        <div class="subscription-product-listing__empty-agreement-img">
          <img src="../../../../assets/empty_basket_img.svg" alt="" />
        </div>
      </template>
      <template
        v-else
        v-for="subscriptionGroup in filteredSubscriptionGroups"
        :t-id="`b2b-subscription-product-listing__product-group-${products.productCode}`"
      >
        <telia-row spacing="md" gap class="subscription-product-listing__product-group__row">
          <telia-col width="12">
            <telia-heading tag="h3" variant="title-300">{{ subscriptionGroup.name }}</telia-heading>
            <telia-divider />
            <telia-p>{{ subscriptionGroup.description }}</telia-p>
          </telia-col>
        </telia-row>
        <telia-row spacing="md" gap class="subscription-product-listing__product-group__row">
          <subscription-product-card
            v-for="(product, index) in subscriptionGroup.products"
            :key="product.id"
            :product="product"
            :subscription-group-name="getSubscriptionGroupName(subscriptionGroup.name)"
            :disabled="subscriptionDisabled"
            @selected-subscription="
              handleSelectedSubscription(subscriptionGroup.productCode, $event, Number(index))
            "
          />
        </telia-row>
      </template>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { SubscriptionBundle, SubscriptionProduct } from "../../../types";
import SubscriptionProductCard from "./SubscriptionProductCard.vue";
import { trackSubscriptionProductList } from "../../../utils/gaTracking";
import { useGa4Helper } from "../../../utils/tracking/gaHelper";
import { watchEffect, computed, ref } from "vue";
import { useStateStore } from "../../../store/StateStore";
import { translate as t } from "@telia/b2b-i18n";
import { storeToRefs } from "pinia";
import PrimaryFilter from "../shared/sorting/PrimaryFilter.vue";
import { TabCategories } from "../../TabCategories";

interface Props {
  tscid: string;
  subscriptionGroups: SubscriptionBundle[];
  subscriptionDisabled: boolean;
  loading: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "selected-subscription", selected: { offerCode: string; productCode: string }): void;
}>();

const stateStore = useStateStore();
const { scopeId, agreement } = storeToRefs(stateStore);
const ga4Helper = useGa4Helper();

const selectedAgreement = ref<string>("");

const filteredSubscriptionGroups = computed(() => {
  if (selectedAgreement.value) {
    return props.subscriptionGroups.filter(
      (group) => group.madamAgreementNumber === selectedAgreement.value
    );
  }
  return props.subscriptionGroups;
});

const filteredMobileAgreements = computed(() => {
  const agreementNumbers = new Set(
    agreement.value?.agreements?.reduce(function (result: string[], agreement) {
      if (agreement.madamAgreementNumber) {
        stateStore.agreements.forEach((el) => {
          if (el.agreementNumber === agreement.madamAgreementNumber && el.agreementType !== "EMR") {
            result.push(agreement.madamAgreementNumber);
          }
        });
      }
      return result;
    }, [])
  );

  return Array.from(agreementNumbers);
});
const filteredEmnAgreements = computed(() => {
  const agreementNumbers = new Set(
    agreement.value?.agreements?.reduce(function (result: string[], agreement) {
      if (agreement.madamAgreementNumber) {
        stateStore.agreements.forEach((el) => {
          if (el.agreementNumber === agreement.madamAgreementNumber && el.agreementType === "EMR") {
            result.push(agreement.madamAgreementNumber);
          }
        });
      }
      return result;
    }, [])
  );

  return Array.from(agreementNumbers);
});

function getSubscriptionGroupName(subscriptionGroupName: string) {
  // TODO: Find a better solution for this...
  if (subscriptionGroupName === "Jobbmobil med bindningstid") {
    return "Jobbmobil";
  }

  if (subscriptionGroupName === "Jobbmobil Bas") {
    return "Jobbmobil";
  }

  return subscriptionGroupName;
}

function handleSelectedSubscription(
  offerCode: string,
  product: SubscriptionProduct,
  index: number
) {
  ga4Helper.trackProductSelect(product, index);
  emit("selected-subscription", {
    offerCode,
    productCode: product.productCode,
  });
}

function selectAgreement(madamAgreementNumber: string) {
  selectedAgreement.value = madamAgreementNumber;
}

watchEffect(async () => {
  if (props.subscriptionGroups && stateStore.category === TabCategories.Subscription) {
    trackSubscriptionProductList(props.subscriptionGroups);
    ga4Helper.trackSubscriptionProductListNew(props.subscriptionGroups);
  }

  if (props.subscriptionGroups && stateStore.category === TabCategories.Emn) {
    ga4Helper.trackEmnSubscriptionProductList(props.subscriptionGroups);
  }
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.subscription-product-listing {
  margin-bottom: $telia-spacing-64;
  &__grid-container {
    padding: $telia-spacing-0;
  }

  &__product-group__row {
    margin-top: $telia-spacing-2;
  }

  &__header-skeleton {
    height: 40px;
    width: 250px;
    margin-bottom: $telia-spacing-4;
  }

  &__product-skeleton {
    height: 130px;
    width: 293px;
  }
  &__legacy-link {
    display: flex;
    justify-content: flex-end;
  }
  &__empty-agreement-header {
    margin-top: $telia-spacing-24;
  }

  &__empty-agreement-img {
    text-align: center;
    img {
      width: 30%;
      padding-top: $telia-spacing-24;
    }
  }
}
</style>
