import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import {
  DatasimEmit,
  EsimWatchEmit,
} from "../components/product-configurator/subscription-configurator/types";
import { SubscriptionProductAddonBundle } from "../types";
import { AddonV2 } from "../components/product-configurator/subscription-configurator/components/addon/types";

export const useSubscriptionAddonStore = defineStore("subscription-addon-configuration", () => {
  interface AddonConfig {
    selectedAddons: Record<string, string[]>;
    datasims: DatasimEmit[];
    esimWatches: EsimWatchEmit[];
  }

  const subscriptionAddonBundles = ref<SubscriptionProductAddonBundle[]>([]);
  //Store
  const addonConfig = reactive<AddonConfig>({
    selectedAddons: {},
    datasims: [],
    esimWatches: [],
  });

  function reset() {
    addonConfig.selectedAddons = {};
    addonConfig.datasims = [];
    addonConfig.esimWatches = [];
  }

  function setAddonBundles(addonBundles: SubscriptionProductAddonBundle[]) {
    subscriptionAddonBundles.value = addonBundles;
    setDefaultSelectedAddons();
  }

  function setDefaultSelectedAddons() {
    let addonLists: Record<string, string[]> = {};
    subscriptionAddonBundles.value.forEach((addonBundle) => {
      addonLists[addonBundle.name] = addonBundle.addons
        .filter((addon) => addon.defaultSelected || addon.mandatory)
        .map((addon) => addon.id);
    });
    setSelectedAddons(addonLists);
  }

  function setSelectedAddons(addons: Record<string, string[]>) {
    addonConfig.selectedAddons = addons;
  }

  function setDatasims(datasims: DatasimEmit[]) {
    addonConfig.datasims = datasims;
  }
  function setEsimWatches(esimWatches: EsimWatchEmit[]) {
    addonConfig.esimWatches = esimWatches;
  }

  function setAddon({ bundleName, addonId }: { bundleName: string; addonId: string }) {
    let selectedAddonBundle = addonConfig.selectedAddons[bundleName];
    if (selectedAddonBundle) {
      const isAddonSelected = selectedAddonBundle.find((addon) => addon === addonId);
      let addonBundle = subscriptionAddonBundles.value.find(
        (bundle) => bundle.name === bundleName
      ) as SubscriptionProductAddonBundle;
      if (isAddonSelected) {
        if (isBundleSingleSelect(addonBundle)) {
          if (addonBundle?.groupMinQuantity !== 1) {
            addonConfig.selectedAddons[bundleName] = [];
          }
        } else {
          addonConfig.selectedAddons[bundleName] = selectedAddonBundle.filter(
            (addon) => addon !== addonId
          );
        }
      } else {
        if (isBundleSingleSelect(addonBundle)) {
          addonConfig.selectedAddons[bundleName] = [addonId];
        } else {
          addonConfig.selectedAddons[bundleName] = [...selectedAddonBundle, addonId];
        }
      }
    } else {
      addonConfig.selectedAddons[bundleName] = [addonId];
    }
  }
  function isBundleSingleSelect(addonBundle: SubscriptionProductAddonBundle) {
    return addonBundle?.groupMaxQuantity === 1;
  }

  const datasims = computed(() => {
    const addon = subscriptionAddonBundles.value
      .flatMap((addonBundle) => addonBundle.addons)
      .find((addon) => addon.subcategory === "datasim");

    if (!addon) {
      return [];
    }
    return addonConfig.datasims.map((datasim) => ({
      ...datasim,
      recurringFee: addon.recurringFee,
      productCode: addon.productCode,
      oneTimeFee: addon.oneTimeFee,
      name: addon.name,
      category: addon.category,
      subCategory: addon.subcategory ?? "",
    }));
  });

  const esimWatches = computed(() => {
    const addon = subscriptionAddonBundles.value
      .flatMap((addonBundle) => addonBundle.addons)
      .find((addon) => addon.subcategory === "mdsecondary");

    if (!addon) {
      return [];
    }

    return addonConfig.esimWatches.map((watch) => ({
      ...watch,
      recurringFee: addon.recurringFee,
      productCode: addon.productCode,
      oneTimeFee: addon.oneTimeFee,
      name: addon.name,
      category: addon.category,
      subCategory: addon.subcategory ?? "",
    }));
  });

  const addons = computed(() => {
    const addons: string[] = [];
    const addonsV2: AddonV2[] = [];

    subscriptionAddonBundles.value.forEach((addonBundle) => {
      const selectedAddons = addonConfig.selectedAddons[addonBundle.name];
      if (selectedAddons) {
        addons.push(...selectedAddons);
        selectedAddons.forEach((addonId) => {
          const addon = addonBundle.addons.find((addon) => addon.id === addonId);
          if (!addon) {
            return;
          }
          addonsV2.push({
            id: addon.id,
            productCode: addon.productCode,
            oneTimeFee: addon.oneTimeFee,
            recurringFee: addon.recurringFee,
            name: addon.name,
            category: addon.category,
            subcategory: addon.subcategory ?? "",
          });
        });
      }
    });

    return {
      addons,
      addonsV2,
    };
  });

  const addonsValid = computed(() => {
    const mandatoryBundles = subscriptionAddonBundles.value.filter(
      (bundle) => bundle.groupMinQuantity > 0
    );
    const bundlesWithSelectedAddon = mandatoryBundles.filter((bundle) =>
      bundle.addons.some((addon) =>
        addons.value.addonsV2.some((addonV2) => addonV2.productCode === addon.productCode)
      )
    );
    return mandatoryBundles.length === bundlesWithSelectedAddon.length;
  });

  return {
    addonConfig,
    addons,
    datasims,
    esimWatches,
    addonsValid,
    reset,
    setAddonBundles,
    setAddon,
    setDatasims,
    setEsimWatches,
    setSelectedAddons,
  };
});
