<template>
  <div t-id="addon-watch" class="addon-watch">
    <div class="addon-watch__container">
      <telia-p>
        <strong>{{
          `${name} ${t("mds.PRICE_PER_MONTH", { prefix: "", price: recurringFee })}`
        }}</strong>
      </telia-p>
      <telia-p>
        {{ description }}
      </telia-p>
    </div>
    <div class="addon-watch__configuration">
      <div v-for="(watch, index) in watches" :key="watch.id">
        <div class="addon-watch__watch">
          <div class="title">
            <telia-p>
              <strong>{{ t("mds.ADDON_MDS_COUNT", { count: index + 1 }) }}</strong>
            </telia-p>
            <telia-button type="button" variant="tertiary-purple" @click="removeWatch(watch.id)">
              <telia-icon name="delete" size="md" />
            </telia-button>
          </div>
          <telia-p>{{ t("mds.ADDON_MDS_ACTIVATION") }}</telia-p>
          <div class="addon-watch__instructions">
            <input
              type="checkbox"
              v-model="watch.sendInstructions"
              :id="`watch-instructions-checkbox-${index}`"
              @change="sendInstructionsChecked(watch)"
            />
            <label :for="`watch-instructions-checkbox-${index}`">
              <telia-p>{{ t("mds.ADDON_MDS_INSTRUCTIONS") }}</telia-p>
            </label>
            <telia-text-input
              v-if="watch.sendInstructions"
              @input="(event: any) => handleInput(watch, event.target.value)"
              type="email"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
              :additional="t('mds.ADDON_MDS_INSTRUCTIONS_PLACEHOLDER')"
            />
          </div>
        </div>
      </div>

      <div v-if="watches.length < maxWatchCount && !disabled" class="addon-watch__add_button">
        <telia-button
          :left-icon="leftIcon"
          :text="t('mds.ADDON_MDS_ADD')"
          t-id="addon-watch__add-button"
          :disabled="disabled"
          variant="secondary"
          size="sm"
          @click="addWatch"
        />
      </div>
    </div>

    <telia-notification
      v-if="disabled"
      t-id="addon-watch__disabled-reason"
      class="addon-watch__disabled-reason"
      heading-tag="h4"
    >
      <div slot="heading">{{ t("mds.ADDON_MDS_DISABLED_REASON_HEADING") }}</div>
      <div slot="content">
        <telia-p>{{ t("mds.ADDON_MDS_DISABLED_REASON_CONTENT", { name }) }}</telia-p>
      </div>
    </telia-notification>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { SimcardPayload } from "../../utils/SimChoicePayload";
import { EsimWatchEmit } from "../../types";

interface Props {
  id: string;
  name: string;
  productCode: string;
  offerCode: string;
  recurringFee: string;
  maxWatchCount?: number;
  description?: string;
  configurationSimcard?: SimcardPayload;
  disabled: boolean;
}
interface EsimWatch {
  id: string;
  sendInstructions: boolean;
  sendInstructionsEmail: string;
}

const props = withDefaults(defineProps<Props>(), {
  maxWatchCount: 1,
  configurationSimcard: () => ({ type: "SIMCARD" }),
  disabled: false,
});

const leftIcon = JSON.stringify({ name: "plus", size: "sm" });

const emit = defineEmits<{
  (e: "change", watches: EsimWatchEmit[]): void;
  (e: "valid", isValid: boolean): void;
}>();
const watches = ref<EsimWatch[]>([]);

const isValid = computed(() => {
  return watches.value.every((watch) => {
    if (watch.sendInstructions) {
      return (
        !!watch.sendInstructionsEmail &&
        !!watch.sendInstructionsEmail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/)
      );
    }
    return true;
  });
});

watchEffect(() => {
  emitValues(watches.value);
});

function addWatch() {
  watches.value.push({
    id: generateId(),
    sendInstructions: false,
    sendInstructionsEmail: props.configurationSimcard?.deliveryEmailAddress
      ? props.configurationSimcard.deliveryEmailAddress
      : "",
  });
}
function removeWatch(id: string) {
  watches.value = watches.value.filter((watch) => watch.id !== id);
}
function generateId() {
  return "_" + Math.random().toString(36).substring(2, 9);
}
function emitValues(watches: EsimWatch[]) {
  const esimWatches = watches.map(({ sendInstructionsEmail }) => ({
    id: props.id,
    emailAddress: sendInstructionsEmail,
    productCode: props.productCode,
    offerCode: props.offerCode,
  }));
  emit("change", esimWatches);
  emit("valid", isValid.value);
}

function sendInstructionsChecked(watch: EsimWatch) {
  if (watch.sendInstructions) {
    watch.sendInstructionsEmail = props.configurationSimcard.deliveryEmailAddress ?? "";
  }
}
function handleInput(watch: EsimWatch, email: string) {
  watch.sendInstructionsEmail = email;
}
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.addon-watch {
  background-color: $telia-white;
  // border: 1px solid $telia-gray-300;
  // border-radius: $telia-spacing-4;
  &__container {
    // align-items: flex-start;
    margin-bottom: $telia-spacing-12;
  }
  &__disabled {
    background-color: $telia-gray-200;
    color: $telia-gray-500;
    pointer-events: none;
  }
  &__add_button {
    display: flex;
    // justify-content: center;
  }
  &__watch {
    margin: $telia-spacing-12 0;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      telia-p {
        margin-bottom: 0;
      }
    }
  }
  &__icon {
    margin-right: $telia-spacing-12;
  }
  &__instructions {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: $telia-spacing-8;

    label {
      padding-left: $telia-spacing-8;
    }
  }
  &__disabled-reason {
    grid-column: 1 / span 2;
  }
  telia-p {
    margin-bottom: $telia-spacing-12;
  }
}
</style>
