<template>
  <div>
    <ConfiguratorRow>
      <telia-chip-choice
        data-testid="subscription_without"
        :text="t('hardwareSubscriptionConfigurator.SUBSCRIPTION_WITHOUT')"
        :checked="!selectedType"
        @change="() => handleOnSelect(undefined)"
      />
      <telia-chip-choice
        data-testid="subscription_new"
        :text="t('hardwareSubscriptionConfigurator.SUBSCRIPTION_NEW')"
        :checked="selectedType === 'NEW_SUBSCRIPTION'"
        :disabled="!canAddNewSubscription"
        @change="() => handleOnSelect('NEW_SUBSCRIPTION')"
      />
      <telia-chip-choice
        data-testid="subscription_extend"
        :text="t('hardwareSubscriptionConfigurator.SUBSCRIPTION_EXTEND')"
        :disabled="disableConnectSubscription"
        :checked="selectedType === 'EXTEND_SUBSCRIPTION'"
        @change="() => handleOnSelect('EXTEND_SUBSCRIPTION')"
      />
      <telia-chip-choice
        data-testid="subscription_from_basket"
        :text="t('hardwareSubscriptionConfigurator.SUBSCRIPTION_FROM_BASKET')"
        :disabled="disableConnectSubscription"
        :checked="selectedType === 'CHANGE_SUBSCRIPTION_IN_CART'"
        @change="() => handleOnSelect('CHANGE_SUBSCRIPTION_IN_CART')"
      />
    </ConfiguratorRow>
  </div>
</template>
<script setup lang="ts">
import ConfiguratorRow from "../../../../components/shared/ConfiguratorRow.vue";
import { SubscriptionChoice } from "../types";
import { translate as t } from "@telia/b2b-i18n";

defineProps<{
  selectedType: SubscriptionChoice;
  disableConnectSubscription: boolean;
  canAddNewSubscription: boolean;
}>();
const emit = defineEmits<{ (event: "change", value: SubscriptionChoice | undefined): void }>();

function handleOnSelect(type?: SubscriptionChoice) {
  emit("change", type);
}
</script>
