import { corpSimCardIcc } from "@telia/b2x-rest-client";
import { testLuhn } from "./validators";
import { ValidationResponse } from "./ValidationResponse";
import { ICC_PREFIX } from "./constants";

export function verifyIccFormat(iccNumber: string): ValidationResponse {
  if (hasWrongLength(iccNumber)) {
    return { error: "mobile.simcardSelector.validationIccNumberError", isValid: false };
  }
  if (isNaN(Number(iccNumber))) {
    return { error: "mobile.simcardSelector.simcardNumberOnlyDigits", isValid: false };
  }
  if (!iccHasCorrectPrefix(iccNumber)) {
    return { error: "mobile.simcardSelector.iccNotTelia", isValid: false };
  }
  if (!testLuhn(iccNumber)) {
    return { error: "mobile.simcardSelector.iccLuhnStringInvalidChecksum", isValid: false };
  }

  return { isValid: false };
}

export async function lookupIcc(iccNumber: string): Promise<ValidationResponse> {
  try {
    const iccResponse = await corpSimCardIcc.SimCardControllerService.validateIccUsingGet(
      iccNumber
    );
    if (iccResponse.ok) {
      return { isValid: true };
    } else {
      return {
        error: "mobile.simcardSelector.iccAlreadyInUse",
        isValid: false,
      };
    }
  } catch (error) {
    return { error: "mobile.simcardSelector.iccCommonGeneral", isValid: false };
  }
}

export async function iccIsValid(iccNumber: string): Promise<ValidationResponse> {
  const { error } = verifyIccFormat(iccNumber);
  if (error) {
    return { error: error, isValid: false };
  }
  return await lookupIcc(iccNumber);
}

export function hasWrongLength(iccNumber: string) {
  return iccNumber.length !== 20;
}

export function iccHasCorrectPrefix(iccNumber: string) {
  return iccNumber.startsWith(ICC_PREFIX);
}
