<template>
  <telia-row>
    <telia-col width="12">
      <div>
        <telia-tab
          t-id="tab-bar"
          variant="contained-on-negative"
          full-width
          @vocaTabChange="tabChange"
        >
          <telia-tab-content
            v-for="tab in tabs"
            :tab-id="tab.link"
            :key="tab.name"
            :name="tab.name"
            :icon="tab.icon"
            :active="activeTab === tab.link"
          >
            <div>
              <MessagePresenter
                :message-type="categoryError.error"
                v-for="(categoryError, index) in categoryErrors"
                :key="index"
              />
              <MessagePresenter
                v-if="showCustomerNumberNotification"
                message-type="customerNumberNotSelected"
              />
              <MessagePresenter
                :message-type="error"
                v-for="(error, index) in generalErrors"
                :key="index"
              />
            </div>
            <slot v-if="!showCustomerNumberNotification" :name="tab.link" />
          </telia-tab-content>
        </telia-tab>
      </div>
    </telia-col>
  </telia-row>
</template>
<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { computed } from "vue";
import { Permissions } from "../types";
import { TabCategories } from "./TabCategories";
import { TabCategory } from "./types";
import MessagePresenter from "./MessagePresenter.vue";

interface Props {
  permissions: Permissions;
  activeTab: TabCategory;
  generalErrors: string[];
  categoryErrors: { category: TabCategory; error: string }[];
  showCustomerNumberNotification: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (e: "tabChange", value: TabCategory): void }>();
const tabs = computed(() => {
  return [
    {
      name: t("tabs.subscription"),
      link: TabCategories.Subscription as TabCategory,
      icon: "simcard",
      show: props.permissions.hasOrderMobileVoice || props.permissions.hasOrderMobileBroadband,
    },
    {
      name: t("tabs.phones"),
      link: TabCategories.Hardware as TabCategory,
      icon: "smartphone",
      show: props.permissions.hasOrderMobileVoice,
    },
    {
      name: t("tabs.emn"),
      link: TabCategories.Emn as TabCategory,
      icon: "connected-building",
      show: props.permissions.hasEmnAgreement,
    },
    {
      name: t("tabs.accessories"),
      link: TabCategories.Accessories as TabCategory,
      icon: "accessories",
      show: props.permissions.hasOrderMobileVoice,
    },
  ].filter((option) => option.show);
});

function tabChange(event: { detail: { value: TabCategory } }) {
  emit("tabChange", event.detail.value);
}
</script>
