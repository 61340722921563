import { defineStore } from "pinia";
import { SubscriptionProductConfiguration } from "../components/product-configurator/subscription-configurator/types";
import { computed, reactive, ref } from "vue";
import { UserInformation } from "../components/product-configurator/subscription-configurator/components/user-info/types";
import { SimcardPayload } from "../components/product-configurator/subscription-configurator/utils/SimChoicePayload";
import {
  NumberMigrationConfiguration,
  PhoneNumber,
} from "../components/product-configurator/subscription-configurator/components/number/types";
import { SwitchboardConfiguration } from "../components/product-configurator/subscription-configurator/components/subscription/switchboard/switchboard-types";

import { useSwitchboardStore } from "./SwitchboardConfigurationStore";
import { SubscriptionProductAddonBundle } from "../types";
import { useSubscriptionAddonStore } from "./SubscriptionAddonStore";

export const useSubscriptionConfigurationStore = defineStore("subscription-configuration", () => {
  const switchboardStore = useSwitchboardStore();
  //Store

  interface AddonConfig {
    selectedAddons: Record<string, string[]>;
  }

  const addonStore = useSubscriptionAddonStore();

  const subscriptionConfiguration = reactive<
    Omit<SubscriptionProductConfiguration, "addons" | "addonsV2" | "datasims" | "esimWatches">
  >({
    simcard: { type: "SIMCARD" },
    user: undefined,
    numberMigration: undefined,
    phoneNumber: undefined,
    switchboardUser: undefined,
    commitment: 0,
  });

  const validity = reactive({
    phoneNumber: false,
    simcard: true,
    switchboard: true,
    esim: true,
    user: false,
  });

  const subscriptionAddonBundles = ref<SubscriptionProductAddonBundle[]>([]);

  //Actions
  function setUserInformation(userInformation: UserInformation) {
    subscriptionConfiguration.user = userInformation;
    validity.user = Object.values(userInformation).every((userAttribute) => !!userAttribute);
  }

  function setSimcard(simcardSelection: SimcardPayload) {
    subscriptionConfiguration.simcard = simcardSelection;
  }

  function setPhoneNumber(phoneNumber: PhoneNumber) {
    subscriptionConfiguration.phoneNumber = phoneNumber;
  }

  function setPhoneMigration(migrationConfig: NumberMigrationConfiguration) {
    subscriptionConfiguration.numberMigration = migrationConfig;
  }

  function setSwitchboard(switchboard: SwitchboardConfiguration | undefined) {
    subscriptionConfiguration.switchboardUser = switchboard;
  }

  function setCommitment(commitment: number) {
    subscriptionConfiguration.commitment = commitment;
  }

  function removeNumberBooking() {
    subscriptionConfiguration.phoneNumber = undefined;
  }

  function reset() {
    addonStore.reset();
    subscriptionConfiguration.simcard = { type: "SIMCARD" };
    subscriptionConfiguration.user = undefined;
    subscriptionConfiguration.switchboardUser = undefined;
    subscriptionConfiguration.numberMigration = undefined;
    subscriptionConfiguration.phoneNumber = undefined;
    subscriptionConfiguration.commitment = 0;
    switchboardStore.reset();
    validity.esim = true;
    validity.switchboard = true;
    validity.simcard = true;
    validity.phoneNumber = false;
    validity.user = false;
  }

  function setPhoneValidity(isValid: boolean) {
    validity.phoneNumber = isValid;
  }

  function setSimcardValidity(isValid: boolean) {
    validity.simcard = isValid;
  }

  function setSwitchboardValidity(isValid: boolean) {
    validity.switchboard = isValid;
  }

  function setEsimValidity(isValid: boolean) {
    validity.esim = isValid;
  }

  function setSubscriptionAddonBundles(addonBundles: SubscriptionProductAddonBundle[]) {
    subscriptionAddonBundles.value = addonBundles;
  }

  //Getters

  const getConfiguration = computed<SubscriptionProductConfiguration>(() => {
    const simcard: SimcardPayload = subscriptionConfiguration.numberMigration
      ? { type: subscriptionConfiguration.numberMigration.simFormat }
      : subscriptionConfiguration.simcard;

    return {
      ...subscriptionConfiguration,
      ...addonStore.addons,
      datasims: addonStore.datasims,
      esimWatches: addonStore.esimWatches,

      //combine subscriptionStore with switchboardstore
      switchboardUser:
        subscriptionConfiguration.switchboardUser ?? switchboardStore.switchboardConfiguration,
      simcard,
    };
  });

  const isConfigValid = computed(() => {
    return (
      Object.values(validity).every((value) => value) &&
      switchboardStore.configurationValid() &&
      addonStore.addonsValid
    );
  });
  const isMobileBroadbandConfigValid = computed(() => {
    const { phoneNumber, ...validityWithoutPhoneNumber } = validity;
    return Object.values(validityWithoutPhoneNumber).every((value) => value);
  });

  return {
    setUserInformation,
    setPhoneMigration,
    setSimcard,
    setPhoneNumber,
    setSwitchboard,
    setCommitment,
    reset,
    setPhoneValidity,
    setSimcardValidity,
    setSwitchboardValidity,
    setEsimValidity,
    setSubscriptionAddonBundles,
    removeNumberBooking,
    getConfiguration,
    isConfigValid,
    isMobileBroadbandConfigValid,
    subscriptionConfiguration,
  };
});
