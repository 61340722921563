<template>
  <div class="addons">
    <ConfiguratorCard
      v-for="addonBundle in addonBundles"
      :key="addonBundle.c2buid"
      :heading="addonBundle.name"
    >
      <SubscriptionAddonCe
        :configuration-simcard="configurationSimcard"
        :subscriptionBundle="addonBundle"
        :selected-addons="addonConfig.selectedAddons"
        :exclusions="exclusions"
        @select-addon="handleAddonSelect"
        @select-datasim="handleDatasimSelect"
        @select-esim-watches="handleEsimWatchSelect"
      />
    </ConfiguratorCard>
  </div>
</template>
<script setup lang="ts">
import { DatasimEmit, DatasimPayload, EsimWatchEmit, EsimWatchPayload } from "../../types";

import { onMounted, reactive, toRefs, watchEffect } from "vue";
import SubscriptionAddonCe from "./SubscriptionAddon.ce.vue";
import { SimcardPayload } from "../../utils/SimChoicePayload";
import { SubscriptionAddon, SubscriptionProductAddonBundle } from "../../../../../types";
import { AddonV2 } from "./types";
import ConfiguratorCard from "../../../../shared/ConfiguratorCard.vue";
import { useSubscriptionAddonStore } from "../../../../../store/SubscriptionAddonStore";
import { storeToRefs } from "pinia";

interface Props {
  addonBundles: SubscriptionProductAddonBundle[];
  exclusions: string[];
  configurationSimcard: SimcardPayload;
}

interface AddonConfig {
  selectedAddons: Record<string, string[]>;
  datasims: DatasimEmit[];
  esimWatches: EsimWatchEmit[];
}

const props = defineProps<Props>();
const { addonBundles, exclusions } = toRefs(props);

const addonStore = useSubscriptionAddonStore();
const { addonConfig } = addonStore;

function handleAddonSelect({ bundleName, addonId }: { bundleName: string; addonId: string }) {
  addonStore.setAddon({ bundleName, addonId });
}

function handleDatasimSelect(datasims: DatasimEmit[]) {
  addonStore.setDatasims(datasims);
}
function handleEsimWatchSelect(esimWatches: EsimWatchEmit[]) {
  addonStore.setEsimWatches(esimWatches);
}
</script>
<style scoped lang="scss">
@import "node_modules/@teliads/components/foundations/spacing/variables";

.addons {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-20;
}
</style>
