<template>
  <div>
    <AccessoryProductListing
      :accessories="accessories.products"
      :categories="accessories.categories"
      :hardwareOptions="hardwareOptions"
      :disabled="disabled"
      :loading="fetching.accessories"
      :alert="''"
      :categories-loading="false"
      :total-accessory-count="accessories.totalCount"
      @selected-product="onSelectProduct"
      @listing-configuration-changed="onFilterConfigurationChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { AccessoryProduct } from "../../../types";
import AccessoryProductListing from "./AccessoryProductListing.vue";
import { AccessoryListingConfiguration } from "./types";
import { useProductStore } from "../../../store/ProductStore";
import { storeToRefs } from "pinia";

interface Props {
  tscid: string;
  scopeId: string;
  disabled: boolean;
  categoriesLoading: boolean;
  alert: string;
  hardwareOptions: { name: string; value: string }[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "selected-product", product: AccessoryProduct): void;
}>();
const productStore = useProductStore();
const { accessories, fetching } = storeToRefs(productStore);

function onSelectProduct(product: AccessoryProduct) {
  emit("selected-product", product);
}

function onFilterConfigurationChanged(filterConfiguration: AccessoryListingConfiguration) {
  productStore.setAccessories(filterConfiguration);
}
</script>

<style scoped></style>
