import { Translation } from "@telia/b2b-i18n/types/types";
import { GetProductsAddonUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { currentLanguage } from "@telia/b2b-i18n";
import { SwitchboardAddon } from "../../components/subscription/switchboard/switchboard-types";

type TFunction = (
  input: string,
  data?: Record<string, string | number | Translation> | undefined
) => string;
const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);
};

const addoneOneTimeFee = (addon: SwitchboardAddon, t: TFunction) => {
  return shouldDisplayNumber(addon.oneTimeFee)
    ? t("switchboardUser.oneTimeFee", {
        price: formatCurrency(Number(addon.oneTimeFee)),
      })
    : "";
};

const addonRecurringFeeInfo = (addon: GetProductsAddonUI, t: TFunction) => {
  return shouldDisplayNumber(addon.recurringFee)
    ? t("switchboardUser.pricePerMonth", {
        price: formatCurrency(Number(addon.recurringFee)),
      })
    : "";
};

const addonTotalFeeInfo = (recurringFee: string, oneTimeFee: string, t: TFunction) => {
  return t("switchboardUser.totalPrice", {
    oneTimeFee: formatCurrency(Number(oneTimeFee)),
    recurringFee: formatCurrency(Number(recurringFee)),
  });
};

const addonFeeSummary = (addon: GetProductsAddonUI, t: TFunction): string => {
  return feeSummary(addon.recurringFee ?? "", addon.oneTimeFee ?? "", t);
};

const feeSummary = (recurringFee: string, oneTimeFee: string, t: TFunction) => {
  const displayRecurringFee = shouldDisplayNumber(recurringFee);
  const displayOneTimeFee = shouldDisplayNumber(oneTimeFee);
  if (displayOneTimeFee && displayRecurringFee) {
    return addonTotalFeeInfo(recurringFee, oneTimeFee, t);
  } else if (displayOneTimeFee) {
    return t("switchboardUser.oneTimeFee", {
      price: formatCurrency(Number(oneTimeFee)),
    });
  } else if (displayRecurringFee) {
    return t("switchboardUser.pricePerMonth", {
      price: formatCurrency(Number(recurringFee)),
    });
  } else {
    return "";
  }
};

const shouldDisplayNumber = (number?: string) => {
  const numberAmount = Number(number);
  return !isNaN(numberAmount) && numberAmount > 0;
};
export { addonFeeSummary, addoneOneTimeFee, addonRecurringFeeInfo, formatCurrency, feeSummary };
