import { defineStore } from "pinia";
import {
  BookFixedNumber,
  HuntingGroup,
  SwitchboardAddon,
  SwitchboardAddonBundle,
  SwitchboardAddonConfig,
  SwitchboardConfiguration,
} from "../components/product-configurator/subscription-configurator/components/subscription/switchboard/switchboard-types";
import { computed, ref } from "vue";
import { formatFixedNumber } from "../components/product-configurator/subscription-configurator/shared/services/number-formatter.service";
import { BookFixedNumberDTO } from "@telia/b2b-rest-client/dist/corp-number-booking";

export const useSwitchboardStore = defineStore("switchboard-configuration", () => {
  const selectedSwitchboard = ref<SwitchboardAddonBundle>();
  const selectedAddonIds = ref<string[]>([]);
  const selectedHuntingGroups = ref<HuntingGroup[]>([]);
  const selectedFixedNumber = ref<string>("");
  const fixedNumberBooking = ref<BookFixedNumber>();
  const selectedSwitchboardPackage = computed(() => selectedSwitchboard.value?.addons[0]);

  function reset() {
    selectedSwitchboard.value = undefined;
    selectedAddonIds.value = [];
    selectedHuntingGroups.value = [];
    selectedFixedNumber.value = "";
    fixedNumberBooking.value = undefined;
  }

  const hasFixedNumber = computed(() => {
    return selectedSwitchboard.value
      ? selectedSwitchboard.value.addonBundles.some((addonBundle) =>
          addonBundle.addons.some((addon) => addon.subcategory === "fixednumber")
        )
      : false;
  });

  const instance = computed<string>(() => {
    if (!selectedSwitchboard.value) {
      return "";
    }
    if (selectedSwitchboard.value?.subcategory === "scuser") {
      return selectedSwitchboard.value.ucInstanceId;
    } else {
      return selectedSwitchboard.value.inAgreementId;
    }
  });

  const selectedAddons = computed(() => {
    if (!selectedSwitchboard.value) {
      return [];
    }
    const addons = selectedSwitchboard.value.addonBundles
      .flatMap((addonBundle) => addonBundle.addons)
      .filter((addon) => selectedAddonIds.value.includes(addon.id) || addon.mandatory)
      .sort((a, b) => {
        if (a.mandatory) {
          return -1;
        }

        if (b.mandatory) {
          return 1;
        }

        return 0;
      });

    return addons;
  });

  const switchboardExclusions = computed(() => {
    if (selectedSwitchboardPackage.value) {
      const exclusions: string[] = [...selectedSwitchboardPackage.value?.excludes];

      selectedAddons.value.forEach((addon) => {
        exclusions.push(...addon.excludes);
      });
      return exclusions;
    }
    return [];
  });

  const switchboardConfiguration = computed<SwitchboardConfiguration | undefined>(() => {
    if (selectedSwitchboard.value && selectedSwitchboardPackage.value) {
      const addons = selectedAddons.value.map(
        (addon): SwitchboardAddonConfig => ({
          id: addon.id,
          productCode: addon.productCode,
          category: addon.category,
          subcategory: addon.subcategory ?? "",
          name: addon.name,
          oneTimeFee: addon.oneTimeFee,
          recurringFee: addon.recurringFee,
          attributes:
            addon.subcategory === "fixednumber"
              ? { fixedNumber: formatFixedNumber(selectedFixedNumber.value) }
              : {},
        })
      );
      return {
        offerCode: selectedSwitchboard.value.productCode, //Switchboard bundle productCode
        productId: selectedSwitchboardPackage.value.id, //Switchboard main addon id
        productCode: selectedSwitchboardPackage.value.productCode, // Switchboard main addon productCode
        name: selectedSwitchboardPackage.value.name,
        category: selectedSwitchboardPackage.value.category,
        subcategory: selectedSwitchboard.value.subcategory,
        oneTimeFee: selectedSwitchboardPackage.value.oneTimeFee,
        recurringFee: selectedSwitchboardPackage.value.recurringFee,
        switchboardId: selectedSwitchboard.value.switchboardId,
        fixedNumberBooking: fixedNumberBooking.value,
        huntingGroups: selectedHuntingGroups.value.map((huntingGroup) => ({
          groupNumber: huntingGroup.id,
        })),
        addons,
      };
    }
    return undefined;
  });

  function setSelectedSwitchboard(switchboardBundle: SwitchboardAddonBundle) {
    clearSwitchboardSpecifics();
    selectedSwitchboard.value = switchboardBundle;
  }

  function clearSwitchboardSelection() {
    selectedSwitchboard.value = undefined;
    clearSwitchboardSpecifics();
  }

  function clearSwitchboardSpecifics() {
    selectedAddonIds.value = [];
    selectedFixedNumber.value = "";
    fixedNumberBooking.value = undefined;
  }

  function setFixedNumberBooking(numberBooking: BookFixedNumberDTO) {
    fixedNumberBooking.value = numberBooking as BookFixedNumber;
    if (fixedNumberBooking.value.numbers.length) {
      setSelectedFixedNumber(fixedNumberBooking.value.numbers[0]);
    } else {
      setSelectedFixedNumber("");
    }
  }

  function setSelectedFixedNumber(fixedNumber: string) {
    selectedFixedNumber.value = fixedNumber;
  }

  function clearNumberBooking() {
    fixedNumberBooking.value = undefined;
    selectedFixedNumber.value = "";
  }

  function addAddon(addonId: string) {
    selectedAddonIds.value = [...selectedAddonIds.value, addonId];
  }

  function removeAddon(addonId: string) {
    selectedAddonIds.value = selectedAddonIds.value.filter(
      (selectedAddonId) => selectedAddonId !== addonId
    );
  }

  function removeFixedNumberAddon() {
    const fixedNumberAddon = selectedAddons.value.find(
      (addon) => addon.subcategory === "fixednumber"
    );
    if (fixedNumberAddon) {
      removeAddon(fixedNumberAddon.id);
    }
  }

  function addHuntingGroup(huntingGroup: HuntingGroup) {
    selectedHuntingGroups.value.push(huntingGroup);
  }

  function removeHuntingGroup(huntingGroupId: string) {
    selectedHuntingGroups.value = selectedHuntingGroups.value.filter(
      (huntingGroup) => huntingGroup.id !== huntingGroupId
    );
  }

  const configurationValid = () => {
    const fixedNumberSelected = selectedAddons.value.find(
      (addon) => addon.subcategory === "fixednumber"
    );
    if (fixedNumberSelected) {
      return !!selectedFixedNumber.value && !!fixedNumberBooking.value?.bookingId;
    }
    return true;
  };

  return {
    configurationValid,
    hasFixedNumber,
    selectedSwitchboard,
    selectedAddonIds,
    selectedAddons,
    selectedHuntingGroups,
    selectedFixedNumber,
    instance,
    fixedNumberBooking,
    switchboardExclusions,
    switchboardConfiguration,
    reset,
    clearSwitchboardSelection,
    setSelectedSwitchboard,
    setFixedNumberBooking,
    setSelectedFixedNumber,
    clearSwitchboardSpecifics,
    clearNumberBooking,
    addAddon,
    addHuntingGroup,
    removeAddon,
    removeHuntingGroup,
  };
});
