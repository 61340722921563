<template>
  <div>
    <ul
      class="subscription-from-basket__list"
      t-id="subscription-from-basket__list"
      v-if="!emptyList"
    >
      <li
        v-for="subscription in subscriptionList"
        :key="subscription.id"
        class="subscription-from-basket__list__basket-item"
      >
        <b2b-subscription-basket-item
          :data-testid="subscription.product.productCode"
          :show-select-button="showFullList ? true : null"
          :name="subscription.product.name"
          :user-name="subscription.user.name"
          :msisdn="subscription.msisdn"
          :recurring-fee="subscription.recurringFee"
          :one-time-fee="subscription.oneTimeFee"
          :delivery-method="getDeliveryMethodForSubscriptionLine(subscription)"
          :addons.prop="getAddonsForSubscriptionLine(subscription)"
          :operation="subscription.product.operation"
          :number-swap-details.prop="subscription.numberSwapDetails"
          @selected="selectSubscription(subscription)"
          small-layout
        />
      </li>
    </ul>
    <div v-else class="subscription-from-basket__empty-basket-message tc-padding__top--two">
      <telia-notification
        t-id="subscription-from-basket__empty-basket-message"
        heading-tag="h3"
        status="information"
      >
        <span slot="heading">{{
          t("hardwareSubscriptionConfigurator.NO_SUBSCRIPTIONS_IN_BASKET_HEADER")
        }}</span>
        <span slot="content">
          <telia-p> {{ t("hardwareSubscriptionConfigurator.EMPTY_BASKET") }} </telia-p>
        </span>
      </telia-notification>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BasketSubscriptionLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { SubscriptionLine } from "./utils";
import { translate as t } from "@telia/b2b-i18n";

interface Props {
  emptyList: boolean;
  showFullList: boolean;
  subscriptionList: SubscriptionLine[];
}

defineProps<Props>();
const emit = defineEmits<{
  (event: "select-subscription", subscription: SubscriptionLine): void;
}>();

function selectSubscription(subscription: SubscriptionLine) {
  emit("select-subscription", subscription);
}

function getDeliveryMethodForSubscriptionLine(line: BasketSubscriptionLineUI) {
  if (line.simcard?.type === "SIMCARD" && line.simcard.iccNumber) {
    return "ICC";
  }
  return line.simcard?.type;
}
function getAddonsForSubscriptionLine(line: BasketSubscriptionLineUI) {
  if (!line.addons) {
    return [];
  }
  return line.addons.map((addon) => ({
    name: addon.name,
    recurringFee: addon.recurringFee,
  }));
}
</script>

<style scoped></style>
