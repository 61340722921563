<template>
  <div class="configurator">
    <b2b-layout
      :heading="product.name"
      :organisation-selector-disabled="true"
      show-organisation-selector
      centered-heading
      :selected-organisation="tscid"
      :steps="
        JSON.stringify([
          {
            displayName: t('stepper.selectProduct'),
            revisitable: true,
          },
          {
            displayName: t('stepper.configureProduct'),
            revisitable: true,
          },
        ])
      "
      :currentStep="1"
      @onStepClicked="goToStep($event)"
    >
      <telia-grid>
        <telia-row>
          <telia-col width="12" width-lg="7" width-md="12">
            <div v-if="product && isAccessoryProduct(product)">
              <AccessoryConfigurator :product="product" @accessoryProduct="setAccessoryVariant" />
            </div>
            <div v-if="product && isHardwareProduct(product)">
              <HardwareConfigurator
                :canAddNewSubscription="canAddNewSubscription"
                :product="product"
                :devicePrices="devicePrices"
                :basket="basket"
              />
            </div>
            <div v-if="product && isSubscriptionProduct(product)">
              <EmnSubscriptionConfigurator
                v-if="product.subcategory === 'emn'"
                :scope-id="scopeId"
                :product="product"
              />
              <SubscriptionConfiguration
                v-else
                :scope-id="scopeId"
                :product-name="product.name"
                :product-code="product.productCode"
                :disabled="false"
                :offer-code="getOfferCode(product.id)"
                :tscid="tscid"
              />
            </div>
          </telia-col>
          <telia-col width="12" width-md="12" width-lg="5">
            <div class="order-summary-wrapper">
              <ConfiguratorCard :heading="t('summary.yourSelection')">
                <div class="order-summary">
                  <OrderSummary
                    v-if="currentConfig"
                    :product="product"
                    :product-configuration="currentConfig"
                    :fees="hardwareStore.deviceFees"
                  />
                  <div class="button-wrapper">
                    <b2x-loading-button
                      type="button"
                      size="md"
                      variant="primary"
                      :disabled="!configurationStore.configurationValid"
                      @click="addToBasket"
                      :is-loading="isAddingItemToBasket"
                      full-width
                      :loading-label="t('summary.addingToBasket')"
                    >
                      {{ t("summary.addToBasket") }}
                    </b2x-loading-button>
                  </div>
                  <div v-if="addToBasketErrorMessage">
                    <telia-notification heading-tag="h3" status="warning">
                      <span slot="heading">
                        <telia-visually-hidden>
                          {{ t("a11yStatus.warning") }}
                        </telia-visually-hidden>
                        {{ t(`messages.${addToBasketErrorMessage}`) }}
                      </span>
                    </telia-notification>
                  </div>
                </div>
              </ConfiguratorCard>
            </div>
          </telia-col>
        </telia-row>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script setup lang="ts">
import {
  Product,
  SubscriptionBundle,
  isSubscriptionProduct,
  isHardwareProduct,
  Basket,
  isAccessoryProduct,
  DevicePrice,
  ApiError,
} from "../../types";
import HardwareConfigurator from "./hardware-configurator/HardwareConfigurator.vue";
import AccessoryConfigurator from "./accessory-configurator/AccessoryConfigurator.vue";
import ConfiguratorCard from "../shared/ConfiguratorCard.vue";
import OrderSummary from "./shared/OrderSummary.vue";
import SubscriptionConfiguration from "./subscription-configurator/SubscriptionConfiguration.vue";
import EmnSubscriptionConfigurator from "./emn-subscription-configurator/EmnSubscriptionConfiguratorContainer.vue";
import { computed, ref, watchEffect } from "vue";
import { useConfigurationStore } from "../../store/ConfigurationStore";
import { useHardwareStore } from "../../store/HardwareConfigurationStore";
import { translate } from "@telia/b2b-i18n";
import { useGa4Helper } from "../../utils/tracking/gaHelper";
import { getParamsFromUrl, routeToCategory } from "../../utils/urlUtils";
import { Variant } from "./hardware-configurator/types";

interface Props {
  tscid: string;
  scopeId: string;
  product: Product;
  basket: Basket;
  subscriptionBundles: SubscriptionBundle[];
  isAddingItemToBasket: boolean;
  canAddNewSubscription: boolean;
  addToBasketError: ApiError | undefined;
}

interface HardwareFees {
  unitPrice: number | string;
  oneTimeFee: number | string;
  recurringFee: number | string;
  upfrontPaymentFee: number | string;
}

interface SubsriptionFees {
  oneTimeFee: number | string;
  recurringFee: number | string;
}

interface Fees {
  hardware: HardwareFees;
  subscription: SubsriptionFees;
}

const props = defineProps<Props>();
const t = translate;
const emit = defineEmits<{
  (e: "close"): void;
  (e: "addToBasket"): void;
}>();

const devicePrices = ref<DevicePrice>();
const configurationStore = useConfigurationStore();
const hardwareStore = useHardwareStore();
const ga4Helper = useGa4Helper();

const addToBasketErrorMessage = computed(() => {
  if (props.addToBasketError) {
    const errorKey = props.addToBasketError.body?.translationKey;

    switch (errorKey) {
      case "INVALID_INPUT_FIELD_SSN":
        return "INVALID_INPUT_FIELD_SSN";
      case "INVALID_INPUT_FIELD":
        return "INVALID_INPUT_FIELD";
      case "CUSTOMER_NOT_FOUND_IN_ALPHA":
        return "CUSTOMER_NOT_FOUND_IN_ALPHA";
      case "DUPLICATE_ICC_NUMBER":
        return "DUPLICATE_ICC_NUMBER";
      default:
        return "ADD_TO_BASKET_ERROR";
    }
  }

  return "";
});

const currentConfig = computed(() => {
  return configurationStore.configuration;
});

watchEffect(() => {
  if (props.product) {
    ga4Helper.trackViewProduct(props.product);
  }
});

function getOfferCode(productId: string) {
  const offer = props.subscriptionBundles.find((offer) =>
    offer.products.find((product) => product.id === productId)
  );
  return offer ? offer.productCode : "";
}

function addToBasket() {
  if (currentConfig.value) {
    emit("addToBasket");
  }
}
function goToStep(event: CustomEvent) {
  const category = getParamsFromUrl().category;
  if (event.detail === 0) {
    routeToCategory(category);
  }
}
function setAccessoryVariant(variant: Variant) {
  hardwareStore.setAccessory(variant);
}
</script>

<style lang="scss">
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";

.configurator {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-48;
  background-color: $telia-gray-100;
  padding-bottom: 10rem;
}
.header {
  background-color: $telia-purple-800;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $telia-white;
  height: 300px;

  @media screen and (max-width: $telia-breakpoint-medium) {
    padding: 0 $telia-spacing-12;
    text-align: center;
  }
}

.product-configurator {
  display: flex;
  flex-direction: column;

  height: 100%;
  &__price {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    background: #fff;
  }
}
.order-summary-wrapper {
  position: sticky;
  top: $telia-spacing-48;
  width: 100%;
  margin-top: $telia-spacing-20;
  @media screen and (min-width: $telia-breakpoint-large) {
    margin-top: 0;
  }
}
.order-summary {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-32;
}
.order-items-summary {
  display: flex;
  gap: $telia-spacing-32;
  padding-bottom: $telia-spacing-32;
  &__right-list {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__left-image {
    display: flex;
    align-items: center;
    img {
      max-width: 8rem;
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-16;
  text-align: center;
}
</style>
