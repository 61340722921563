<template>
  <div t-id="color-selector" class="color-selector">
    <telia-heading tag="h3" variant="title-200" class="title">{{
      t("configurator.colorTitle")
    }}</telia-heading>

    <telia-p class="color-selector__selected-color-name">
      {{ selectedColor }}
    </telia-p>
    <div class="color-selector__color-container">
      <div v-for="[key, value] of colors" :key="key" class="color-selector__option-wrapper">
        <input
          :data-testid="'color-option-' + key"
          type="radio"
          :id="key"
          :value="key"
          v-model="selectedColor"
          class="color-selector__option-input"
        />
        <label class="color-selector__option-label" :for="key">
          <span class="color-selector__option-dot" :style="`background:${value}`"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translate as t } from "@telia/b2b-i18n";

interface Props {
  colors: Map<string, string>;
  initColor: string;
}

const emit = defineEmits<{
  (e: "selected", color: string): void;
}>();

const props = defineProps<Props>();

const selectedColor = computed({
  // getter
  get() {
    return props.initColor;
  },
  // setter
  set(color: string) {
    emit("selected", color);
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.color-selector {
  margin-bottom: $telia-spacing-24;

  &__color-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.3rem;
    margin-top: $telia-spacing-8;
  }

  &__selected-color-name {
    margin-bottom: 0 !important;
  }

  &__option-wrapper {
    display: flex;
    margin: $telia-spacing-8;
  }

  &__option-input {
    display: none;
  }

  &__option-label {
    border-radius: 50%;
    padding: 0.6rem;
    display: flex;
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
  }

  &__option-input:checked + &__option-label {
    box-shadow: $telia-purple-500 0 0 0 1px inset;
  }

  &__option-dot {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}
</style>
