<template>
  <div class="emn-subscription-configurator">
    <ConfiguratorCard :heading="t('emnConfig.site')">
      <telia-p class="info-container" disable-hyphenation>
        {{ t("emnConfig.emnServiceInfo") }}
      </telia-p>
      <div class="input-container">
        <div class="input-wrapper">
          <telia-select
            required
            data-testid="site-selector-dropdown"
            heading-element="telia-p"
            heading-variant="paragraph-100"
            icon-size="sm"
            :label="t('emnConfig.selectEmnService')"
            :options="JSON.stringify(siteOptions)"
            :value="siteAgreementId"
            :disabled="currentStep > Step.SITE"
            @change="emit('change-site', $event.target.value)"
          />
        </div>
        <div class="edit-button-wrapper">
          <telia-button
            type="button"
            variant="text"
            :text="t('emnConfig.change')"
            :ally-label="t('emnConfig.change')"
            :left-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
            size="sm"
            :disabled="currentStep <= Step.SITE"
            @click="emit('change-step', Step.SITE)"
          />
        </div>
      </div>
      <div v-if="currentStep <= Step.SITE" class="step-button-wrapper">
        <telia-button
          variant="secondary"
          :text="t('emnConfig.nextStep')"
          :ally-label="t('emnConfig.nextStep')"
          type="button"
          size="sm"
          :disabled="!siteAgreementId"
          @click="emit('change-step', Step.CIDR)"
        />
      </div>
    </ConfiguratorCard>

    <ConfiguratorCard :heading="t('emnConfig.cidr')" :collapsed="currentStep < Step.CIDR">
      <telia-p class="info-container" disable-hyphenation>
        {{ t("emnConfig.cidrInfo") }}
      </telia-p>
      <div class="input-container">
        <div class="input-wrapper">
          <telia-text-input
            data-testid="cidr-input"
            required
            :label="t('emnConfig.cidr')"
            :additional="t('emnConfig.cidrAdditional')"
            :value="cidr"
            :required-error-message="t('general.fieldIsRequired')"
            :error-message="cidr && !isValidCidr ? t('emnConfig.cidrInvalid') : ''"
            :disabled="currentStep > Step.CIDR"
            @input="emit('change-cidr', $event.target.value)"
          />
        </div>
        <div class="edit-button-wrapper">
          <telia-button
            type="button"
            variant="text"
            :text="t('emnConfig.change')"
            :ally-label="t('emnConfig.change')"
            :left-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
            size="sm"
            :disabled="currentStep <= Step.CIDR"
            @click="emit('change-step', Step.CIDR)"
          />
        </div>
      </div>
      <div v-if="currentStep <= Step.CIDR" class="step-button-wrapper">
        <b2x-loading-button
          type="button"
          variant="secondary"
          size="sm"
          :ally-label="t('emnConfig.nextStep')"
          :loading-label="t('emnConfig.nextStep')"
          :is-loading="isValidating"
          @click="emit('change-step', Step.IP_RANGE_START)"
        >
          {{ t("emnConfig.nextStep") }}
        </b2x-loading-button>
      </div>
    </ConfiguratorCard>

    <ConfiguratorCard :heading="t('emnConfig.ipAddress')" :collapsed="currentStep < Step.IP_RANGE_START">
      <telia-p class="info-container" disable-hyphenation>
        {{ t("emnConfig.ipRangeInfo1") }}
      </telia-p>
      <telia-p class="info-container" disable-hyphenation>
        {{ t("emnConfig.ipRangeInfo2") }}
        <telia-link
          :href="`/foretag/mybusiness/${scopeId}/hantera/rapporter`"
          variant="text"
          target="_blank"
        >
          {{ t("emnConfig.ipRangeInfoReportLink") }}
        </telia-link>
      </telia-p>
      <div v-if="ipRangeInfo.ipAddressStart" class="notification-container">
        <telia-notification
          heading-tag="h3"
          status="information"
          html-aria-live="polite"
          html-role="alert"
        >
          <span slot="heading">
            <telia-visually-hidden>{{ t("a11yStatus.information") }}</telia-visually-hidden>
            {{ t("emnConfig.ipRangeInfoHeading", { start: ipRangeInfo.ipAddressStart, end: ipRangeInfo.ipAddressEnd }) }}
          </span>
          <span slot="content">
            <telia-p>
              {{ t("emnConfig.ipRangeInfoDescription") }}
            </telia-p>
          </span>
        </telia-notification>
      </div>

      <div class="input-container">
        <div class="input-wrapper">
          <telia-text-input
            data-testid="ip-range-start-input"
            required
            :label="t('emnConfig.ipRangeStart')"
            :additional="t('emnConfig.ipRangeStartAdditional')"
            :value="ipRangeStart"
            :required-error-message="t('general.fieldIsRequired')"
            :error-message="ipRangeStart && !isValidIpRangeStart ? t('emnConfig.ipRangeInvalid') : ''"
            :disabled="currentStep > Step.IP_RANGE_START || !ipRangeInfo.privateNetwork"
            @input="emit('change-ip', $event.target.value)"
          />
        </div>
        <div class="edit-button-wrapper">
          <telia-button
            type="button"
            variant="text"
            :text="t('emnConfig.change')"
            :ally-label="t('emnConfig.change')"
            :left-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
            size="sm"
            :disabled="currentStep <= Step.IP_RANGE_START"
            @click="emit('change-step', Step.IP_RANGE_START)"
          />
        </div>
      </div>
      <div v-if="currentStep <= Step.IP_RANGE_START" class="step-button-wrapper">
        <b2x-loading-button
          type="button"
          variant="secondary"
          size="sm"
          :ally-label="t('emnConfig.nextStep')"
          :loading-label="t('emnConfig.nextStep')"
          :is-loading="isValidating"
          @click="emit('change-step', Step.QUANTITY)"
        >
          {{ t("emnConfig.nextStep") }}
        </b2x-loading-button>
      </div>
    </ConfiguratorCard>

    <ConfiguratorCard :heading="t('emnConfig.subscriptions')" :collapsed="currentStep < Step.QUANTITY">
      <div v-if="ipRangeInfo.quantity" class="notification-container">
        <telia-notification
          heading-tag="h3"
          status="information"
          html-aria-live="polite"
          html-role="alert"
        >
          <span slot="heading">
            <telia-visually-hidden>{{ t("a11yStatus.information") }}</telia-visually-hidden>
            {{ t("emnConfig.quantityInfoHeading", { quantity: maxQuantity }) }}
          </span>
          <span slot="content">
            <telia-p>
              {{ t("emnConfig.quantityInfoDescription", { quantity: maxQuantity }) }}
            </telia-p>
          </span>
        </telia-notification>
      </div>

      <telia-text-input
        data-testid="quantity-input"
        required
        type="number"
        :label="t('emnConfig.quantity')"
        :additional="t('emnConfig.quantityAdditional', { quantity: maxQuantity })"
        :value="quantity"
        :error-message="!isValidQuantity ? t('emnConfig.quantityInvalid', { quantity: maxQuantity }) : ''"
        :disabled="currentStep > Step.QUANTITY || !ipRangeInfo.privateNetwork"
        @input="emit('change-quantity', $event.target.value)"
      />
    </ConfiguratorCard>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { EmnIpAddressInfoUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { Step } from "../../../store/EmnSubscriptionStore";
import ConfiguratorCard from "../../../components/shared/ConfiguratorCard.vue";

interface Props {
  scopeId: string;
  currentStep: number,
  siteOptions: { label: string; value: string }[];
  siteAgreementId: string;
  cidr: string | undefined;
  ipRangeStart: string | undefined;
  quantity: number;
  maxQuantity: number;
  ipRangeInfo: EmnIpAddressInfoUI,
  isValidating: boolean;
  isValidCidr: boolean;
  isValidIpRangeStart: boolean;
  isValidQuantity: boolean;
}

defineProps<Props>();
const emit = defineEmits<{
  (e: "change-step", step: number): void;
  (e: "change-site", siteAgreementId: string): void;
  (e: "change-cidr", cidr: string): void;
  (e: "change-ip", ipRangeStart: string): void;
  (e: "change-quantity", quantity: number): void;
}>();
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.emn-subscription-configurator {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-16;
  &__section {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    display: flex;
    padding-bottom: $telia-spacing-16;
  }

  .input-wrapper {
    flex-grow: 2;
    padding-right: 2rem;
  }

  .edit-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 10.5rem;
  }

  .step-button-wrapper {
    display: flex;
  }

  .info-container {
    padding-bottom: $telia-spacing-24;
  }

  .notification-container {
    padding-bottom: $telia-spacing-16;
  }
}
</style>
