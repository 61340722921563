import { defineStore } from "pinia";
import { useStateParams, getParamsFromUrl } from "../utils/urlUtils";
import { onBeforeMount, onMounted, ref } from "vue";
import { AgreementResponseUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { corpAgreements } from "@telia/b2b-rest-client";
import { NfAgreementOnTscidDto } from "@telia/b2b-rest-client/dist/corp-agreements";
import { trackStartofSalesFlow } from "../utils/gaTracking";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { useGa4Helper } from "../utils/tracking/gaHelper";
import { Basket } from "../types";
import { getBasket } from "../service/BasketService";

export const useStateStore = defineStore("state-store", () => {
  const scopeId = ref<string>(getParamsFromUrl().scopeId);
  const [category, setCategory] = useStateParams("", "category");
  const [tscid, setTscid] = useStateParams("", "tscid");
  const [productCode, setProductCode] = useStateParams("", "productCode");
  const ga4Helper = useGa4Helper();
  const customerNumber = ref<string>("");
  const agreement = ref<AgreementResponseUI>();
  const agreements = ref<NfAgreementOnTscidDto[]>([]);
  const basket = ref<Basket>();

  const hasValidNfAgreement = ref(false);
  const hasEmnAgreement = ref(false);
  const isReplicatedMfAgreement = ref(false);

  function setScopeId(newScopeId: string) {
    scopeId.value = newScopeId;
  }

  function setCustomerNumber(newCustomerNumber: string) {
    customerNumber.value = newCustomerNumber;
  }
  function setAgreement(newAgreement: AgreementResponseUI) {
    agreement.value = newAgreement;
  }

  function setBasket(_basket: Basket) {
    basket.value = _basket;
    if (basket.value.customerNumber && customerNumber.value === "") {
      setCustomerNumber(basket.value.customerNumber);
    }
  }

  onBeforeMount(async () => {
    setNfaAgreements();
    fetchBasket();
  });

  async function fetchBasket() {
    basket.value = await getBasket(scopeId.value, tscid.value);
  }
  async function setNfaAgreements() {
    try {
      const response = await corpAgreements.NfAgreementsControllerService.getNfAgreementsOnScope(
        scopeId.value,
        [tscid.value]
      );
      agreements.value = response.agreements ?? [];
      hasValidNfAgreement.value = agreements.value.some(
        (agreement) => !!agreement.nfAgreementNumber && agreement.agreementType !== "EMR"
      );
      isReplicatedMfAgreement.value = agreements.value.some(
        (agreement) => agreement.agreementType === "SME"
      );
      hasEmnAgreement.value = agreements.value.some(
        (agreement) => agreement.agreementType === "EMR"
      );

      trackStartofSalesFlow(isReplicatedMfAgreement.value ? AgreementType.MFA : AgreementType.NFA);
      ga4Helper.trackSalesflowInitiation(
        isReplicatedMfAgreement.value ? AgreementType.MFA : AgreementType.NFA
      );
    } catch (e) {
      agreements.value = [];
    }
  }

  return {
    category,
    tscid,
    scopeId,
    productCode,
    customerNumber,
    basket,
    agreement,
    agreements,
    hasValidNfAgreement,
    isReplicatedMfAgreement,
    hasEmnAgreement,
    setAgreement,
    setCategory,
    setTscid,
    setScopeId,
    setProductCode,
    setCustomerNumber,
    setBasket,
  };
});
