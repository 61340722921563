<template>
  <div v-if="mandatoryAddons.length" class="mandatory-addons__mandatory-addon">
    <ul
      t-id="mandatory-addons__mandatory-children-list"
      class="mandatory-addons__mandatory-addons-list"
    >
      <li
        v-for="(addon, index) in mandatoryAddons"
        class="mandatory-addons__mandatory-addons-list--item"
        t-id="mandatory-addons__mandatory-addons-list--item"
        :key="index"
      >
        <h5 class="mandatory-addons__heading">{{ addon.name }}</h5>
        <ul class="mandatory-addons__fees-list">
          <li class="mandatory-addons__fees-list-item">
            {{ t("switchboardUser.includedWithSwitchboard") }}
          </li>
          <li class="mandatory-addons__fees-list-item">
            {{ getButtonOneTimeFeeInfo(addon) }}
          </li>
          <li class="mandatory-addons__fees-list-item">
            {{ getButtonRecurringFeeInfo(addon) }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { GetProductsAddonUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { addonOneTimeFee, addonRecurringFeeInfo } from "../../../../../../utils/formatUtils";

const props = defineProps<{ mandatoryAddons: GetProductsAddonUI[] }>();

function getButtonOneTimeFeeInfo(addon: GetProductsAddonUI) {
  return addonOneTimeFee(addon.oneTimeFee);
}
function getButtonRecurringFeeInfo(addon: GetProductsAddonUI) {
  return addonRecurringFeeInfo(addon.recurringFee);
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
.mandatory-addons {
  &__heading {
    padding: 0;
    margin: 0 0 6px 0;
    font-size: 16px;
  }
  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: $telia-spacing-0;
      &:not(:last-child) {
        margin-bottom: $telia-spacing-4;
      }
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
  }
  &__mandatory-addons {
    padding: 0 $telia-spacing-12;
  }
  &__mandatory-addons-list {
    list-style-type: none;
    margin: $telia-spacing-12;
    padding: 0;
  }
  &__mandatory-addon {
    box-sizing: border-box;
    border: 1px solid $telia-gray-200;
    border-radius: $telia-border-radius-2;
  }
}
</style>
