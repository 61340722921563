<template>
  <div
    v-if="selectedSwitchboardUserAddonBundle && selectedSwitchboardUserPackage"
    class="switchboard-user__container"
  >
    <SwitchboardToggle
      t-id="switchboard-toggle"
      :isExcluded="isExcluded(selectedSwitchboardUserPackage)"
      :hasMainAddonSelected="hasMainAddonSelected"
      :selectedSwitchboardUserAddonBundleId="selectedSwitchboardUserAddonBundle.switchboardId"
      :selectedSwitchboardUserPackage="selectedSwitchboardUserPackage"
      :switchboardUserAddonBundles="switchboardUserAddonBundles"
      @on-toggle="handleMainAddonSelection"
      @selected-switchboard-bundle="setSelectedSwitchboardUserAddonBundle($event)"
    />
    <template v-if="hasMainAddonSelected">
      <div v-if="mainSwitchboardUserPackages.length > 1" t-id="main-switchboard-packages">
        <telia-heading variant="title-100" tag="h4" class="switchboard-user--margin-bottom">
          {{ t("switchboardUser.packageHeading") }}
        </telia-heading>
        <div class="switchboard-user__sub-addons">
          <b2x-radio-card
            v-bind:key="_package.id"
            v-for="_package in mainSwitchboardUserPackages"
            :label="_package.name"
            name="switchboard-addon"
            :checked="optionIsSelected(_package, _package)"
            @click="() => handlePackageChange(_package)"
          >
            <ul class="switchboard-user__fees-list">
              <li class="switchboard-user__fees-list-item">
                {{ getButtonOneTimeFeeInfo(_package) }}
              </li>
              <li class="switchboard-user__fees-list-item">
                {{ getButtonRecurringFeeInfo(_package) }}
              </li>
            </ul>
          </b2x-radio-card>
        </div>
      </div>
      <div
        v-for="(bundle, index) in filteredSwitchboardUserAddonBundles"
        :t-id="`switchboard-user__addon-group--container-${bundle.productCode}`"
        :key="index"
      >
        <telia-heading
          variant="title-100"
          tag="h4"
          class="switchboard-user--margin-bottom"
          :t-id="`switchboard-user__addon-group--header-${index}`"
        >
          {{ bundle.name }}
        </telia-heading>
        <FixedNumber
          v-if="isFixedNumberBundle(bundle) && !isFetchingAgreementId"
          t-id="switchboard-user__fixed-number"
          :heading="bundle.name"
          :fee="feeSummary(bundle.addons[0])"
          :fixed-number-error-text="fixedNumberErrorText"
          :number-booking="fixedNumberBooking"
          :selected-number="selectedFixedNumber"
          :fixed-number-addon-is-selected="fixedNumberBundleIsSelected"
          @on-selected-number="handleSelectedNumber"
          @on-search-number="handleSearchAndBookNumbers"
          @on-fixed-number-toggle="onSelectedFixedNumberBundle(bundle.addons[0])"
        />
        <div class="switchboard-user__sub-addons" v-else>
          <div
            class="switchboard-user__sub-addons-input"
            v-for="addon in getOptionalAddons(bundle.addons)"
            :key="addon.id"
            :t-id="`switchboard-user__addon-group--container-${bundle.productCode}`"
          >
            <telia-checkbox
              :t-id="`switchboard-toggle-${addon.productCode}`"
              :id="addon.productCode"
              :disabled="isExcluded(addon)"
              :checked="optionIsSelected(addon, bundle)"
              @change="() => handleAddOnChange(addon, bundle)"
            >
              <telia-p>
                {{ addon.name }}
              </telia-p>
            </telia-checkbox>
            <telia-p class="price">
              {{ feeSummary(addon) }}
            </telia-p>
          </div>
          <MandatoryAddons
            v-if="shouldShowMandatoryAddons(bundle.addons)"
            :mandatory-addons="getMandatoryAddons(bundle.addons)"
          />
        </div>
        <InfoboxAddons
          v-if="getInfoboxAddons(bundle.addons).length > 0"
          :info-box-addons="getInfoboxAddons(bundle.addons)"
        />
      </div>
      <HuntingGroups
        :huntingGroups="huntingGroups"
        :selectedHuntingGroups="selectedHuntingGroups"
        @onSelectedHuntingGroup="handleHuntingGroup"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watchEffect } from "vue";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import {
  buildSwitchboardConfiguration,
  fetchHuntingGroups,
} from "../../../shared/services/switchboard.service";
import {
  bookFixedSwitchboardNumber,
  extendBooking,
  releaseFixedSwitchboardNumbers,
  releaseAndBookFixedSwitchboardNumber,
} from "../../../shared/services/fixed-number.service";
import { SubscriptionAddonBundleUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { IFixedNumberBooking } from "../../../shared/models";
import { translate } from "@telia/b2b-i18n";
import {
  addoneOneTimeFee,
  addonRecurringFeeInfo,
  addonFeeSummary,
} from "../../../shared/services/addon-fee";
import { formatToFixedNumber } from "../../../shared/services/number-formatter.service";
import type {
  BookFixedNumber,
  HuntingGroup,
  SwitchboardAddonBundle,
  SwitchboardConfiguration,
  SwitchboardAddon,
  SwitchboardUserForm,
} from "./switchboard-types";
import FixedNumber from "./fixed-number.ce.vue";
import SwitchboardToggle from "./switchboard-toggle.ce.vue";
import MandatoryAddons from "./mandatory-addons.ce.vue";
import InfoboxAddons from "./infobox-addons.ce.vue";
import HuntingGroups from "./hunting-groups.ce.vue";

interface Props {
  switchboardUserAddonBundles: SwitchboardAddonBundle[];
  exclusions: string[];
  tscid: string;
}

const props = defineProps<Props>();
const { switchboardUserAddonBundles, exclusions, tscid } = toRefs(props);
const emit = defineEmits<{
  (e: "switchboard-user-configuration", conf?: SwitchboardConfiguration): void;
  (e: "valid", isValid: boolean): void;
}>();
const t = translate;

const scopeId = ref<string>();
const extendBookingTimeoutMinutes = ref(7);
const extendTimer = ref<number>();
const fixedNumberBooking = ref<BookFixedNumber>();
const fixedNumberErrorText = ref("");
const huntingGroups = ref<HuntingGroup[]>([]);
const instance = ref<string>();
const isFetchingAgreementId = ref(false);
const selectedFixedNumber = ref<string>("");
const selectedHuntingGroups = ref<string[]>([]);
const selectedSwitchboardUserAddonBundle = ref<SwitchboardAddonBundle>();
const selectedSwitchboardUserPackage = ref<SwitchboardAddon>();
const switchboardUserForm = ref<SwitchboardUserForm>({});

onMounted(async () => {
  scopeId.value = await getScopeIdOrThrow();
  fetchSwitchboardsAndSetInstance();
});

const mainSwitchboardUserPackages = computed((): SwitchboardAddon[] => {
  return selectedSwitchboardUserAddonBundle.value?.addons ?? [];
});

const hasMainAddonSelected = computed(() => {
  if (switchboardUserForm.value && selectedSwitchboardUserPackage.value) {
    const addons = switchboardUserForm.value[selectedSwitchboardUserPackage.value?.productCode];
    return addons.length > 0;
  }
  return false;
});
const fixedNumberStruct = computed((): IFixedNumberBooking | undefined => {
  if (!selectedSwitchboardUserAddonBundle.value) {
    return undefined;
  }
  return {
    scopeId: scopeId.value as string,
    tscid: tscid.value,
    instanceId:
      switchboardTypeOnPackage.value === "TP"
        ? selectedSwitchboardUserAddonBundle.value.inAgreementId
        : selectedSwitchboardUserAddonBundle.value.ucInstanceId,
    bookingId: fixedNumberBooking.value?.bookingId ?? "",
    switchboardType: switchboardTypeOnPackage.value,
  };
});

const filteredSwitchboardUserAddonBundles = computed((): SwitchboardAddonBundle[] => {
  if (selectedSwitchboardUserAddonBundle.value?.addonBundles) {
    return selectedSwitchboardUserAddonBundle.value.addonBundles.filter(
      (bundle) => bundle.subcategory !== "ucuser" && bundle.subcategory !== "scuser"
    ) as SwitchboardAddonBundle[];
  }
  return [];
});

const hasFixedNumberBundle = computed(() => {
  return filteredSwitchboardUserAddonBundles.value.some((bundle) => isFixedNumberBundle(bundle));
});

const switchboardTypeOnPackage = computed(() => {
  return selectedSwitchboardUserPackage.value?.subcategory === "ucuser" ? "TP" : "SC";
});

const fixedNumberBundleIsSelected = computed(() => {
  return switchboardUserForm.value["fixednumber"]?.length > 0;
});

const isFixedNumberValid = computed(() => {
  const fixedNumberIsSelected =
    switchboardUserForm.value && switchboardUserForm.value["fixednumber"]?.length > 0;

  return fixedNumberIsSelected ? !!selectedFixedNumber.value : true;
});

function emitSwitchboardUserConfiguration(switchboardUserForm: SwitchboardUserForm) {
  if (selectedSwitchboardUserAddonBundle.value && selectedSwitchboardUserPackage.value) {
    const switchboardConfig: SwitchboardConfiguration | undefined =
      isSwitchboardUserConfigurationValid()
        ? buildSwitchboardConfiguration(
            switchboardUserForm,
            selectedSwitchboardUserAddonBundle.value,
            selectedSwitchboardUserPackage.value as any,
            selectedHuntingGroups.value,
            fixedNumberBooking.value as BookFixedNumber,
            selectedSwitchboardUserAddonBundle.value.productCode,
            selectedFixedNumber.value as string
          )
        : undefined;
    emit("switchboard-user-configuration", switchboardConfig);
  }
}

async function fetchSwitchboardsAndSetInstance() {
  isFetchingAgreementId.value = true;
  await setInstance(selectedSwitchboardUserAddonBundle.value);
  isFetchingAgreementId.value = false;
}

async function setInstance(activeSwitchBoard?: SwitchboardAddonBundle) {
  if (activeSwitchBoard?.subcategory === "scuser") {
    instance.value = activeSwitchBoard.ucInstanceId;
  } else if (activeSwitchBoard?.subcategory === "ucuser") {
    instance.value = activeSwitchBoard.inAgreementId;
    huntingGroups.value = await fetchHuntingGroups(
      scopeId.value as string,
      activeSwitchBoard.c2buid as string
    );
  }
}

async function releaseFixedNumbers() {
  if (fixedNumberBooking.value && fixedNumberStruct.value?.bookingId) {
    await releaseFixedSwitchboardNumbers({
      ...fixedNumberStruct.value,
      bookingId: fixedNumberBooking.value.bookingId,
    });
    fixedNumberBooking.value = undefined;
  }
}

async function handleMainAddonSelection() {
  if (!selectedSwitchboardUserPackage.value) {
    return;
  }
  const switchboardPackageProductCode = selectedSwitchboardUserPackage.value?.productCode;
  if (switchboardUserForm.value[switchboardPackageProductCode].length === 1) {
    await releaseFixedNumbers();
    switchboardUserForm.value = getDefaultState();
    switchboardUserForm.value[switchboardPackageProductCode] = [];
  } else {
    switchboardUserForm.value[switchboardPackageProductCode] = [
      selectedSwitchboardUserPackage.value?.id,
    ];

    if (selectedSwitchboardUserAddonBundle.value) {
      await setInstance(selectedSwitchboardUserAddonBundle.value);
      await bookFixedNumberSwitchboard();
    }
  }
  if (selectedSwitchboardUserAddonBundle.value?.switchboardId) {
    switchboardUserForm.value[selectedSwitchboardUserAddonBundle.value.productCode] = [
      selectedSwitchboardUserAddonBundle.value.switchboardId,
    ];
    // clear instance as well
    instance.value = "";
  }
}

function isFixedNumberBundle(bundle: SubscriptionAddonBundleUI) {
  return (
    bundle.subcategory === "fixednumber" ||
    (bundle.addons?.some((addon) => addon.subcategory === "fixednumber") as boolean)
  );
}

function isExcluded(addon?: SwitchboardAddon) {
  return addon ? exclusions.value.includes(addon.productCode) : false;
}

function bundleIsSelected(productCode: string, addonId: string) {
  const softPhoneOptions = switchboardUserForm.value[productCode] ?? [];
  return softPhoneOptions.some((selectedOptionId) => selectedOptionId === addonId);
}

function removeOptionFromForm(productCode: string, optionId: string) {
  switchboardUserForm.value[productCode] = switchboardUserForm.value[productCode].filter(
    (selectedOptionId) => selectedOptionId != optionId
  );
}

function getOptionalAddons(addons: SwitchboardAddon[]) {
  return addons.filter((addon) => !addon.mandatory && addon.displayType !== "infobox");
}

function getMandatoryAddons(addons: SwitchboardAddon[]) {
  return addons.filter((addon) => addon.mandatory && addon.displayType !== "infobox");
}

function getInfoboxAddons(addons: SwitchboardAddon[]) {
  return addons.filter((addon) => addon.displayType === "infobox");
}
function shouldShowMandatoryAddons(addons: SwitchboardAddon[]) {
  if (selectedSwitchboardUserPackage.value) {
    return (
      switchboardUserForm.value[selectedSwitchboardUserPackage.value.productCode].length !== 0 &&
      getMandatoryAddons(addons).length > 0
    );
  }
  return false;
}

function getDefaultState(): SwitchboardUserForm {
  let form: SwitchboardUserForm = {};
  if (selectedSwitchboardUserAddonBundle.value) {
    selectedSwitchboardUserAddonBundle.value?.addonBundles?.forEach((bundle) => {
      if (bundle.addons) {
        const bundleAddon = bundle.addons[0];

        const addonIds = bundle.addons
          .filter((addon) => addon.defaultSelected || addon.mandatory)
          .map((addon) => addon.id); // id should not be optional

        const formKey =
          bundleAddon.subcategory === "fixednumber" ? "fixednumber" : bundleAddon.productCode;
        form[formKey] = addonIds;
      }
    });
    const switchboardUserPackage =
      selectedSwitchboardUserAddonBundle.value.addons.find(
        (addon) => addon.defaultSelected || addon.mandatory
      ) ?? selectedSwitchboardUserAddonBundle.value.addons[0];
    selectedSwitchboardUserPackage.value = switchboardUserPackage;

    form[switchboardUserPackage.productCode] =
      switchboardUserPackage.defaultSelected || switchboardUserPackage.mandatory
        ? [switchboardUserPackage.id]
        : [];

    if (selectedSwitchboardUserAddonBundle.value.switchboardId !== undefined) {
      form[selectedSwitchboardUserAddonBundle.value.productCode] =
        switchboardUserPackage.defaultSelected || switchboardUserPackage.mandatory
          ? [selectedSwitchboardUserAddonBundle.value.switchboardId]
          : [];
    }
    selectedHuntingGroups.value = [];
  }
  return form;
}

function getDefaultOrMandatoryAddonBundle(
  switchboardUserAddonBundles: SwitchboardAddonBundle[]
): SwitchboardAddonBundle {
  const bundle = switchboardUserAddonBundles?.find((bundle) => {
    return bundle.addons?.find((addon) => {
      return addon.defaultSelected === true || addon.mandatory === true;
    });
  });
  return bundle ?? switchboardUserAddonBundles[0];
}
async function releaseAndBookFixedNumberSwitchboard(searchString?: string) {
  if (fixedNumberStruct.value && fixedNumberStruct.value.bookingId) {
    try {
      fixedNumberErrorText.value = "";
      const response = (await releaseAndBookFixedSwitchboardNumber({
        ...fixedNumberStruct.value,
        bookingId: fixedNumberStruct.value.bookingId,
        searchString: searchString ? formatToFixedNumber(searchString) : undefined,
      })) as BookFixedNumber;
      handleBookingResponse(response);
      extendBookingOnTimeout();
    } catch (error) {
      selectedFixedNumber.value = "";
      fixedNumberBooking.value = undefined;
      fixedNumberErrorText.value = error as string;
    }
  }
}
async function bookFixedNumberSwitchboard(optionalSearchString?: string) {
  if (hasFixedNumberBundle && fixedNumberStruct.value) {
    try {
      fixedNumberErrorText.value = "";
      const response = (await bookFixedSwitchboardNumber({
        ...fixedNumberStruct.value,
        searchString: optionalSearchString,
      })) as BookFixedNumber;
      handleBookingResponse(response);
    } catch (error: unknown) {
      fixedNumberErrorText.value = error as string;
    }
  }
}

function handleBookingResponse(response: BookFixedNumber) {
  fixedNumberBooking.value = response;
}

async function handleFixedNumbersWhenSwitchingSwitchboardBundle(
  instanceId: string,
  bookingId: string
) {
  if (fixedNumberBooking.value && fixedNumberStruct.value && instanceId) {
    //release current numbers and book new for the updated instanceId.
    await releaseFixedSwitchboardNumbers({
      ...fixedNumberStruct.value,
      instanceId: instanceId,
      bookingId: bookingId,
    });
  }
  await bookFixedNumberSwitchboard();
}

async function setSelectedSwitchboardUserAddonBundle(addonBundle: SwitchboardAddonBundle) {
  selectedSwitchboardUserAddonBundle.value = addonBundle;
  selectedSwitchboardUserPackage.value = addonBundle.addons[0];
  switchboardUserForm.value = getDefaultState();
  if (selectedSwitchboardUserPackage.value) {
    switchboardUserForm.value[selectedSwitchboardUserPackage.value.productCode] = [
      selectedSwitchboardUserPackage.value.id,
    ];
  }
  switchboardUserForm.value[selectedSwitchboardUserAddonBundle.value.productCode] = [
    selectedSwitchboardUserAddonBundle.value.switchboardId,
  ];
  const cachedInstanceId = instance.value ? JSON.parse(JSON.stringify(instance.value)) : "";
  const cachedBookingId = fixedNumberBooking.value
    ? JSON.parse(JSON.stringify(fixedNumberBooking.value.bookingId))
    : "";
  await setInstance(addonBundle);
  await handleFixedNumbersWhenSwitchingSwitchboardBundle(cachedInstanceId, cachedBookingId);
}

function handlePackageChange(switchboardPackage: SwitchboardAddon) {
  if (selectedSwitchboardUserPackage.value) {
    delete switchboardUserForm.value[selectedSwitchboardUserPackage.value.productCode];
  }
  switchboardUserForm.value[switchboardPackage.productCode] = [switchboardPackage.id];
  selectedSwitchboardUserPackage.value = switchboardPackage;
}

function handleAddOnChange(addon: SwitchboardAddon, bundle: SwitchboardAddonBundle) {
  const optionIsSelected = bundleIsSelected(bundle.productCode, addon.id);
  if (optionIsSelected) {
    removeOptionFromForm(bundle.productCode, addon.id);
  } else {
    if (switchboardUserForm.value[bundle.productCode]) {
      switchboardUserForm.value[bundle.productCode].push(addon.id);
    } else {
      switchboardUserForm.value[bundle.productCode] = [addon.id];
    }
  }
}

function handleSearchAndBookNumbers(searchEvent: { isValid: boolean; searchString: string }) {
  if (searchEvent.isValid) {
    fixedNumberBooking.value?.bookingId
      ? releaseAndBookFixedNumberSwitchboard(searchEvent.searchString)
      : bookFixedNumberSwitchboard(searchEvent.searchString);
  }
}

function handleSelectedNumber(selectedNumber: string) {
  selectedFixedNumber.value = selectedNumber;
  emitSwitchboardUserConfiguration(switchboardUserForm.value);
}

function handleHuntingGroup(huntingGroupNumber: string) {
  const numberIsSelected = selectedHuntingGroups.value.includes(huntingGroupNumber);
  const indexOfNumber = selectedHuntingGroups.value.indexOf(huntingGroupNumber);
  numberIsSelected
    ? selectedHuntingGroups.value.splice(indexOfNumber, 1)
    : selectedHuntingGroups.value.push(huntingGroupNumber);
  emitSwitchboardUserConfiguration(switchboardUserForm.value);
}

function onSelectedFixedNumberBundle(addon: SwitchboardAddon) {
  if (addon.subcategory) {
    if (bundleIsSelected(addon.subcategory, addon.id)) {
      removeOptionFromForm(addon.subcategory, addon.id);
      selectedFixedNumber.value = "";
    } else {
      switchboardUserForm.value[addon.subcategory].push(addon.id);
      selectedFixedNumber.value = fixedNumberBooking.value?.numbers
        ? fixedNumberBooking.value.numbers[0]
        : "";
    }
  }
}

function optionIsSelected(
  option: SwitchboardAddon,
  bundle: SwitchboardAddon | SwitchboardAddonBundle
): boolean {
  return !!(
    switchboardUserForm.value[bundle.productCode] &&
    switchboardUserForm.value[bundle.productCode].includes(option.id)
  );
}

function isSwitchboardUserConfigurationValid() {
  if (selectedSwitchboardUserPackage.value) {
    return (
      switchboardUserForm.value[selectedSwitchboardUserPackage.value.productCode].length > 0 &&
      isFixedNumberValid.value
    );
  }
  return false;
}

function feeSummary(addon: SwitchboardAddon) {
  return addonFeeSummary(addon, t);
}

function getButtonOneTimeFeeInfo(addon: SwitchboardAddon) {
  return addoneOneTimeFee(addon, t);
}

function getButtonRecurringFeeInfo(addon: SwitchboardAddon) {
  return addonRecurringFeeInfo(addon, t);
}

async function extendBookingTime() {
  if (fixedNumberBooking.value && fixedNumberStruct.value) {
    try {
      await extendBooking({
        ...fixedNumberStruct.value,
        bookingId: fixedNumberBooking.value.bookingId,
      });
      extendBookingOnTimeout();
    } catch {
      // If we cannot extend the booking we need to start
      // over with a new booking.
      clearTimeout(extendTimer.value);
      fixedNumberBooking.value = undefined;
      selectedFixedNumber.value = "";
      bookFixedNumberSwitchboard();
    }
  }
}

function extendBookingOnTimeout() {
  const timeout = extendBookingTimeoutMinutes.value * 60 * 1000;
  extendTimer.value = setTimeout(extendBookingTime, timeout);
}

function setDefaults(switchboardBundle: SwitchboardAddonBundle) {
  selectedSwitchboardUserAddonBundle.value = switchboardBundle;
  switchboardUserForm.value = Object.keys(switchboardUserForm.value).length
    ? switchboardUserForm.value
    : getDefaultState();
}

watchEffect(() => {
  // works for reactivity tracking
  const switchboardBundle =
    selectedSwitchboardUserAddonBundle.value ??
    getDefaultOrMandatoryAddonBundle(switchboardUserAddonBundles.value);
  setDefaults(switchboardBundle);
});

watchEffect(() => {
  emitSwitchboardUserConfiguration(switchboardUserForm.value);
});

watchEffect(() => {
  emit("valid", isFixedNumberValid.value);
});
</script>
<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/typography/variables";
.switchboard-user {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }

  &__sub-addons {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;

    &-heading {
      padding: 0;
      margin: 0 0 6px 0;
      font-size: 16px;
    }
    &-input {
      display: flex;
      align-items: center;
      .price {
        margin-left: auto;
      }
    }
  }

  &__addon-group--container {
    width: 100%;
  }

  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 16px;

    &-item {
      &:not(:last-child) {
        margin-bottom: $telia-spacing-4;
      }
    }
  }

  &--margin-bottom {
    margin-bottom: $telia-spacing-12;
  }

  @media screen and (max-width: 770px) {
    padding: 1.2rem;
  }
}
</style>
