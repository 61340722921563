<template>
  <div t-id="primary-filter" class="sorting-and-filtering__primary-filter">
    <telia-chip-choice
      t-id="primary-filter-all"
      value=""
      @change="onChange($event.target.value)"
      :checked="'' === selectedFilter"
    >
      {{ defaultText ? defaultText : t("sortingAndFiltering.primaryFilter.all") }}
    </telia-chip-choice>
    <telia-chip-choice
      v-for="option of options"
      :t-id="'primary-filter-' + option"
      :key="option"
      :value="option"
      :checked="isChecked(option)"
      @change="onChange($event.target.value)"
    >
      {{ option }}
    </telia-chip-choice>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { ref } from "vue";

interface Props {
  options: string[];
  defaultText?: string;
}
const props = defineProps<Props>();
const emit = defineEmits<{ (e: "selected-option", value: string): void }>();
const selectedFilter = ref<string>("");

function isChecked(option: string) {
  return option === selectedFilter.value;
}
function onChange(selected: string) {
  selectedFilter.value = selectedFilter.value === selected ? "" : selected;
  emit("selected-option", selectedFilter.value);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.sorting-and-filtering {
  &__primary-filter {
    display: flex;
    flex-flow: row wrap;
    gap: $telia-spacing-8;
  }
}

telia-chip-choice {
  text-transform: capitalize;
}
</style>
