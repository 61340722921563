import { ga4tracking } from "@telia/b2b-ecommerce-tracking";
import {
  AccessoryProduct,
  HardwareBundle,
  HardwareProduct,
  HardwareVariant,
  Product,
  SubscriptionBundle,
  SubscriptionProduct,
  isAccessoryProduct,
  isHardwareProduct,
  isSubscriptionProduct,
} from "../../types";
import {
  ACCESSORY_LISTNAME,
  ADD_TO_CART_FAIL,
  BASKET_FETCH_FAIL,
  EMN_LISTNAME,
  HARDWARE_LISTNAME,
  PHONE_NUMBER_FETCH_FAIL,
  PRODUCT_FETCH_FAIL,
  SUBSCRIPTION_LISTNAME,
} from "../gaConstants";
import Big from "big.js";
import { useHardwareStore } from "../../store/HardwareConfigurationStore";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import {
  BaseGa4EventArgs,
  FunnelGroup,
  Ga4Product,
} from "@telia/b2b-ecommerce-tracking/dist/interfaces";
import { computed } from "vue";
import { useEmnSubscriptionStore } from "../../store/EmnSubscriptionStore";
import { useStateStore } from "../../store/StateStore";
import { useSubscriptionConfigurationStore } from "../../store/SubscriptionConfigurationStore";
import {
  buildAccessoryPayload,
  buildAddonPayload,
  buildHardwarePayload,
  buildSubscriptionPayload,
  buildVariantPayload,
} from "./gaUtils";

interface ProductSpecific {
  listName: string;
  totalPrice: number;
  product: Ga4Product;
  funnelGroup: FunnelGroup;
}

export const useGa4Helper = () => {
  const stateStore = useStateStore();
  const hardwareStore = useHardwareStore();
  const subscriptionStore = useSubscriptionConfigurationStore();
  const emnConfigurationStore = useEmnSubscriptionStore();

  const common = computed<BaseGa4EventArgs>(() => {
    return {
      agreementType: stateStore.isReplicatedMfAgreement ? AgreementType.MFA : AgreementType.NFA,
      siteVersion: "mc_online",
      isLoggedIn: true,
      pageSegment: "mybusiness",
    };
  });

  function trackSalesflowInitiation(agreementType: AgreementType = AgreementType.NFA) {
    ga4tracking.trackInitiateSalesflow({
      ...common.value,
      agreementType: agreementType,
      isLoggedIn: true,
    });
  }

  function trackSubscriptionProductListNew(products: SubscriptionBundle[]) {
    const allProducts = products.flatMap((product) => product.products);
    ga4tracking.trackProductList({
      ...common.value,
      products: allProducts.map((product) =>
        buildSubscriptionPayload(product, Number(product.minimumCommitment), "new")
      ),
      listName: SUBSCRIPTION_LISTNAME,
      funnelGroup: "mobile_subscriptions",
    });
  }

  function trackHardwareProductListNew(products: HardwareBundle[]) {
    const allProducts = products.flatMap((product) => product.products).map(buildHardwarePayload);
    ga4tracking.trackProductList({
      ...common.value,
      products: allProducts,
      listName: HARDWARE_LISTNAME,
      funnelGroup: "mobile_phones",
    });
  }

  function trackAccessoryProductList(products: AccessoryProduct[]) {
    ga4tracking.trackProductList({
      ...common.value,
      products: products.map(buildAccessoryPayload),
      listName: ACCESSORY_LISTNAME,
      funnelGroup: "accessories",
    });
  }

  function trackEmnSubscriptionProductList(products: SubscriptionBundle[]) {
    const allProducts = products.flatMap((product) => product.products);
    ga4tracking.trackProductList({
      ...common.value,
      products: allProducts.map((product) =>
        buildSubscriptionPayload(product, Number(product.minimumCommitment), "new")
      ),
      listName: EMN_LISTNAME,
      funnelGroup: "emn",
    });
  }
  function trackProductSelect(product: Product, index: number) {
    const variant = isHardwareProduct(product) ? product.variants[0] : undefined;
    const productSpecific = productSpecificTracking(product, variant);
    if (productSpecific) {
      ga4tracking.trackSelectItem({
        ...common.value,
        ...productSpecific,
        productIndex: index,
      });
    }
  }

  function trackViewProduct(product: Product, variant?: HardwareVariant) {
    const productSpecific = productSpecificTracking(product, variant);
    if (productSpecific) {
      ga4tracking.trackViewItem({
        ...common.value,
        ...productSpecific,
      });
    }
  }

  function trackAddSubscriptionToCart(product: SubscriptionProduct) {
    const { addonsV2, esimWatches, datasims, numberMigration } = subscriptionStore.getConfiguration;

    const subscriptionPayload = buildSubscriptionPayload(
      product,
      Number(product.minimumCommitment)
    );
    const subscriptionAddonProductPayloads = [...addonsV2, ...esimWatches, ...datasims].map(
      buildAddonPayload
    );
    const products = [subscriptionPayload, ...subscriptionAddonProductPayloads];

    ga4tracking.trackAddToCart({
      ...common.value,
      products: products,
      listName: SUBSCRIPTION_LISTNAME,
      totalPrice: totalPrice(products),
      funnelGroup: "mobile_subscriptions",
    });
  }

  function trackAddHardwareToCart(product: HardwareProduct) {
    if (!hardwareStore.hardwareVariant) {
      return;
    }
    const products: Ga4Product[] = [];

    const commitment =
      hardwareStore.selectedSubscription !== undefined ? hardwareStore.commitmentPeriod : 0;

    const variant = hardwareStore.hardwareVariant;
    const deviceFees = hardwareStore.deviceFees;

    const hardwareProductPayload = buildVariantPayload(
      product,
      hardwareStore.hardwareVariant,
      commitment,
      hardwareStore.deviceFees
    );

    products.push(hardwareProductPayload);

    const newOrExtend =
      hardwareStore.selectedSubscriptionOption === "NEW_SUBSCRIPTION" ||
      hardwareStore.selectedSubscriptionOption === "EXTEND_SUBSCRIPTION";

    if (hardwareStore.selectedSubscription && newOrExtend) {
      const { addonsV2, esimWatches, datasims } = subscriptionStore.getConfiguration;

      const subscriptionPayload = buildSubscriptionPayload(
        hardwareStore.selectedSubscription,
        commitment,
        hardwareStore.selectedSubscriptionOption === "NEW_SUBSCRIPTION" ? "new" : "extension"
      );
      products.push(subscriptionPayload);

      const subscriptionAddonProductPayloads = [...addonsV2, ...esimWatches, ...datasims].map(
        buildAddonPayload
      );

      products.push(...subscriptionAddonProductPayloads);
    }

    ga4tracking.trackAddToCart({
      ...common.value,
      products: products,
      listName: HARDWARE_LISTNAME,
      totalPrice: totalPrice(products),
      funnelGroup: "mobile_phones",
    });
  }
  //TODO: Should we track if this was a recommendation
  function trackAddAccessoryToCart(product: AccessoryProduct) {
    const productPayload = buildAccessoryPayload(product);
    ga4tracking.trackAddToCart({
      ...common.value,
      products: [productPayload],
      listName: ACCESSORY_LISTNAME,
      totalPrice: productPayload.price,
      funnelGroup: "accessories",
    });
  }

  function trackAddEmnToCart(product: SubscriptionProduct) {
    const subscriptionPayload = buildSubscriptionPayload(
      product,
      Number(product.minimumCommitment)
    );
    subscriptionPayload.quantity = emnConfigurationStore.emnConfiguration.quantity;

    const products = [subscriptionPayload];

    ga4tracking.trackAddToCart({
      ...common.value,
      products: products,
      listName: EMN_LISTNAME,
      totalPrice: totalPrice(products),
      funnelGroup: "emn",
    });
  }

  function trackAddToBasketFail(product: Product) {
    if (isHardwareProduct(product)) {
      const variant = hardwareStore.hardwareVariant as HardwareVariant;
      const commitment = hardwareStore.commitmentPeriod;
      const hardwarePayload = buildVariantPayload(product, variant, commitment);
      const products = [hardwarePayload];
      if (hardwareStore.selectedSubscription) {
        const subscriptionProduct = buildSubscriptionPayload(
          hardwareStore.selectedSubscription,
          Number(commitment),
          "new"
        );
        const { addonsV2, datasims, esimWatches } = subscriptionStore.getConfiguration;
        const addonProducts = [...addonsV2, ...datasims, ...esimWatches].map(buildAddonPayload);
        products.push(subscriptionProduct);
        products.push(...addonProducts);
      }
      trackFunnelFail(ADD_TO_CART_FAIL, "mobile_phones", totalPrice(products));
    } else if (isSubscriptionProduct(product)) {
      const commitment = subscriptionStore.getConfiguration.commitment ?? product.minimumCommitment;
      const subscriptionProduct = buildSubscriptionPayload(product, Number(commitment), "new");

      if (product.subcategory === "emn") {
        trackFunnelFail(
          ADD_TO_CART_FAIL,
          product.subcategory === "emn" ? "emn" : "mobile_subscriptions",
          subscriptionProduct.price
        );
      } else {
        const { addonsV2, datasims, esimWatches } = subscriptionStore.getConfiguration;
        const addonProducts = [...addonsV2, ...datasims, ...esimWatches].map(buildAddonPayload);
        trackFunnelFail(
          ADD_TO_CART_FAIL,
          "mobile_subscriptions",
          totalPrice([subscriptionProduct, ...addonProducts])
        );
      }
    } else if (isAccessoryProduct(product)) {
      const accessoryPayload = buildAccessoryPayload(product);
      trackFunnelFail(ADD_TO_CART_FAIL, "accessories", accessoryPayload.price);
    }
  }

  function trackProductFetchFail(funnelGroup: FunnelGroup) {
    trackFunnelFail(PRODUCT_FETCH_FAIL, funnelGroup);
  }

  function trackPhoneNumberFetchFail(funnelGroup: FunnelGroup) {
    trackFunnelFail(PHONE_NUMBER_FETCH_FAIL, funnelGroup);
  }

  function trackBasketFail(funnelGroup: FunnelGroup) {
    trackFunnelFail(BASKET_FETCH_FAIL, funnelGroup);
  }

  function trackFunnelFail(errorMessage: string, funnelGroup: FunnelGroup, totalPrice: number = 0) {
    ga4tracking.trackFunnelFail({ ...common.value, errorMessage, funnelGroup, totalPrice });
  }

  function trackSurveyInitiate(clickAction = "click", clickText = "") {
    ga4tracking.trackSurveyInitiate({
      ...common.value,
      clickAction: clickAction,
      clickText: clickText,
    });
  }

  return {
    trackAccessoryProductList,
    trackAddAccessoryToCart,
    trackAddHardwareToCart,
    trackAddSubscriptionToCart,
    trackAddEmnToCart,
    trackEmnSubscriptionProductList,
    trackHardwareProductListNew,
    trackSubscriptionProductListNew,
    trackAddToBasketFail,
    trackBasketFail,
    trackFunnelFail,
    trackProductFetchFail,
    trackProductSelect,
    trackSalesflowInitiation,
    trackViewProduct,
    trackPhoneNumberFetchFail,
    trackSurveyInitiate,
  };
};

function productSpecificTracking(
  product: Product,
  variant?: HardwareVariant
): ProductSpecific | undefined {
  if (isSubscriptionProduct(product)) {
    const commitment = Number(product.minimumCommitment);
    const productPayload = buildSubscriptionPayload(product, commitment);
    return {
      product: productPayload,
      listName: product.subcategory === "emn" ? EMN_LISTNAME : SUBSCRIPTION_LISTNAME,
      totalPrice: productPayload.price,
      funnelGroup: product.subcategory === "emn" ? "emn" : "mobile_subscriptions",
    };
  }

  if (isAccessoryProduct(product)) {
    const productPayload = buildAccessoryPayload(product);
    return {
      product: productPayload,
      listName: ACCESSORY_LISTNAME,
      totalPrice: productPayload.price,
      funnelGroup: "accessories",
    };
  }

  const productPayload = buildVariantPayload(product, variant ?? product.variants[0]);
  return {
    product: productPayload,
    listName: HARDWARE_LISTNAME,
    totalPrice: productPayload.price,
    funnelGroup: "accessories",
  };
}

function totalPrice(products: Ga4Product[] = []): number {
  return products
    .flatMap((product) => {
      return Big(product.price).mul(product.quantity);
    })
    .map((price) => new Big(price))
    .reduce((accumulator, current) => accumulator.add(current), new Big(0))
    .toNumber();
}
