<template>
  <div t-id="recommendation" class="recommendation__container">
    <div class="recommendation__image-container">
      <img
        t-id="recommendation__image"
        class="recommendation__image"
        v-if="image"
        :src="`https://www.telia.se/${image}`"
        :alt="product.name"
        draggable="false"
      />
      <div v-if="!image">
        <telia-icon
          t-id="recommendation__image-placeholder"
          class="recommendation__image-placeholder"
          name="accessories"
        />
      </div>
    </div>
    <div>
      <telia-heading tag="h2" variant="title-100" t-id="recommendation__name">{{
        product.name
      }}</telia-heading>
      <telia-p t-id="recommendation__color">
        {{ product.color }}
      </telia-p>
      <br />
      <ul class="recommendation__details">
        <li>
          <!--<mybd-stock-status
              t-id="recommendation__details__stock-status"
              :variant-stock-status-json="JSON.stringify(stockStatus)"
              use-voca
            />-->
        </li>
        <li t-id="recommendation__details__compatibility"></li>
      </ul>
    </div>
    <div>
      <div class="recommendation__price">
        <telia-price
          :price="formatCurrency(product.unitPrice)"
          currency="kr"
          t-id="recommendation__price"
        />
      </div>
      <div class="recommendation__button">
        <telia-button
          variant="tertiary-purple"
          t-id="recommendation__add-to-basket"
          :disabled="addToBasketDisabled"
          @click="onAddToBasket"
        >
          <telia-icon slot="left" name="plus" size="sm" />
          {{ t("productAdded.addToBasketButtonLabel") }}
        </telia-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { AccessoryProduct } from "../../types";
import { translate as t, currentLanguage } from "@telia/b2b-i18n";

interface Props {
  product: AccessoryProduct;
  compatibleWithName: string;
  addToBasketDisabled: boolean;
  stockStatus: {
    sapId: string;
    status: string;
  };
}

const emit = defineEmits<{
  (e: "add-to-basket"): void;
}>();

const props = defineProps<Props>();

const image = computed(() => {
  if (props.product?.gallery?.mainImage?.srcUrl) {
    return `${props.product.gallery.mainImage.srcUrl}?h=160`;
  } else return undefined;
});

function onAddToBasket() {
  emit("add-to-basket");
}
function formatCurrency(amount: string) {
  const lang = currentLanguage();
  const formatted = new Intl.NumberFormat(lang, {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(amount));

  return lang === "sv" ? formatted.replace("kr", "").trim() : formatted.replace("SEK", "").trim();
}
</script>

<style lang="scss">
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";

.recommendation {
  &__container {
    padding: $telia-spacing-8 $telia-spacing-32 4rem;
    border: 1px solid #c8c8cd;
    border-radius: 1.6rem;
    box-shadow: 0 0.2rem 0.8rem #dcdce1;
    margin-bottom: $telia-spacing-8;

    @media screen and (min-width: $telia-breakpoint-medium) {
      flex: 1 1 0;
      width: 0;
      max-width: 33%;
    }
    text-align: center;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
  }

  &__image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image-placeholder {
    width: 160px;
    height: 160px;
    color: $telia-gray-300;
  }
  &__price {
    justify-content: right;
    margin: 0px;
  }
  &__button {
    justify-content: right;
  }
  &__details {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    & li {
      margin-right: $telia-spacing-8;
    }

    &__compatibility {
      display: flex;
      align-items: center;
    }

    &__compatiblity-check {
      color: $telia-green-300;
      margin-right: $telia-spacing-2;
    }
  }
}
</style>
