<template>
  <div class="scope-selector" data-testid="scope-selector">
    <div>
      <telia-p v-if="showCustomerNumberDropdown">
        {{ t("scopeSelector.customerHeader") }}
        <b2x-inline-dropdown
          data-testid="customer-selector"
          class="scope-selector__dropdown"
          heading-element="telia-p"
          heading-variant="paragraph-100"
          :dark-background="darkBackground"
          icon-size="sm"
          tabIndex="0"
          :value="selectedCustomerNumber"
          :placeholder="t('scopeSelector.customerPlaceholder')"
          :options="JSON.stringify(props.customerNumberOptions)"
          @optionSelected="handleCustomerChange($event.detail)"
        />
      </telia-p>
    </div>

    <Modal
      modal-id="customer-change-modal"
      :close-button-label="t('modal.close')"
      :is-open="showCustomerChangeModal"
      @close-modal="closeModal"
    >
      <template v-slot:modal-body>
        <div>
          <telia-heading variant="title-200" tag="h3" data-testid="heading">{{
            t("scopeSelector.modal.title")
          }}</telia-heading>
          <telia-p data-testid="message">{{ t("scopeSelector.modal.information") }}</telia-p>
        </div>
      </template>
      <template v-slot:button-footer>
        <div class="customer-change-modal__footer">
          <telia-button variant="tertiary-purple" @click="confirmCustomerChange" type="button">
            <span>
              {{ t("scopeSelector.modal.emptyBasket") }}
            </span>
          </telia-button>
          <telia-button
            variant="primary"
            @click="emitCheckout"
            data-testid="remove-button"
            type="button"
          >
            <span> {{ t("scopeSelector.modal.checkout") }}</span>
          </telia-button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { Option } from "../../types";
import { computed, onBeforeMount, ref } from "vue";
import { translate } from "@telia/b2b-i18n";
import { translateSetup } from "../../locale";
import Modal from "../shared/modal.vue";

interface Props {
  selectedCustomerNumber?: string;
  customerNumberOptions: Option[];
  loading: boolean;
  hasItemsInBasket?: boolean;
  darkBackground?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selectedCustomerNumber: "",
  hasItemsInBasket: false,
  darkBackground: false,
});

const emit = defineEmits<{
  (e: "handleCustomerChange", customerNumber: string): void;
  (e: "checkout"): void;
}>();

const t = translate;
const showCustomerChangeModal = ref(false);
const customerNumberForEmit = ref("");

const showCustomerNumberDropdown = computed(
  () => !props.loading && props.customerNumberOptions.length > 1
);

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
});

function handleCustomerChange(customerNumber: string) {
  customerNumberForEmit.value = customerNumber;
  if (
    props.hasItemsInBasket &&
    props.selectedCustomerNumber &&
    props.selectedCustomerNumber !== customerNumber
  ) {
    showCustomerChangeModal.value = true;
  } else {
    emitCustomerChange();
  }
}

function closeModal() {
  showCustomerChangeModal.value = false;
  customerNumberForEmit.value = "";
}
function confirmCustomerChange() {
  emitCustomerChange();
}
function emitCustomerChange() {
  emit("handleCustomerChange", customerNumberForEmit.value);
  customerNumberForEmit.value = "";
  showCustomerChangeModal.value = false;
}

function emitCheckout() {
  emit("checkout");
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.page-alert-wrapper {
  max-width: 58rem;

  &__static {
    margin-bottom: $telia-spacing-16;
  }
}
.scope-selector {
  margin-bottom: $telia-spacing-4;

  &__dropdown {
    color: $telia-purple-500;
    // Hack to remove the left padding on b2x-dropdown
    margin-left: calc(0px - $telia-spacing-8);
  }
}
.skeleton {
  &__info-banner {
    height: 8.8rem;
    width: 117rem;
    margin-bottom: $telia-spacing-48;
  }
  &__org-selector {
    height: 4rem;
    width: 60rem;
    margin-bottom: $telia-spacing-4;
  }
  &__customer-selector {
    height: $telia-spacing-24;
    width: 40rem;
    margin-bottom: $telia-spacing-4;
  }
}
.customer-change-modal {
  &__footer {
    padding: 0 $telia-spacing-32 $telia-spacing-32 $telia-spacing-32;
  }
}
</style>
