<template>
  <div t-id="mybd-order-products-sorting-selector" class="sorting-selector">
    <telia-p>
      {{ t("sortingAndFiltering.sorting.sortBy") }}
    </telia-p>
    <telia-p>
      <b2x-inline-dropdown
        t-id="mybd-order-products-sorting-selector-dropdown"
        icon-size="md"
        heading-element="telia-p"
        heading-variant="paragraph-100"
        :options="JSON.stringify(options)"
        :value="sortingMethod"
        @optionSelected="onChange($event.detail)"
      />
    </telia-p>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { SortingOption } from "./types";

const SortingOptions = {
  NEWEST: "NEWEST",
  HIGHEST_PRICE: "HIGHEST_PRICE",
  LOWEST_PRICE: "LOWEST_PRICE",
};
const sortingOptions: SortingOption[] = ["NEWEST", "HIGHEST_PRICE", "LOWEST_PRICE"];

interface Props {
  defaultMethod: SortingOption;
}
const props = defineProps<Props>();
const emit = defineEmits<{ (e: "set-sorting-method", value: SortingOption): void }>();

const sortingMethod = ref<SortingOption>(props.defaultMethod);
const options = ref(
  sortingOptions.map((option) => ({
    label: t(`sortingAndFiltering.sorting.${SortingOptions[option]}`),
    value: option,
    disabled: false,
    selected: sortingMethod.value === option,
  }))
);

function onChange(value: SortingOption) {
  sortingMethod.value = value;
  emit("set-sorting-method", sortingMethod.value);
}
</script>

<style lang="scss" scoped>
.sorting-selector {
  display: flex;
  align-items: center;
  text-align: right;
  margin-bottom: auto;
  min-height: 45px;
}
.sorting-selector * {
  margin-left: 2px;
  text-align: initial;
}
</style>
