<template>
  <div class="subscription-selector">
    <div class="subscription-selector__bundles">
      <div>
        <telia-heading tag="h2" variant="title-200">{{
          t("hardwareSubscriptionConfigurator.SUBSCRIPTION_TYPE")
        }}</telia-heading>
        <telia-p v-if="isExtendingSubscription"
          >{{ selectedSubscriptionBundle.name }}
          <span v-if="isSameSubscription"
            >({{ t("hardwareSubscriptionConfigurator.SUBSCRIPTION_KEEP_CURRENT") }})</span
          >
        </telia-p>
      </div>
      <div v-if="bundleOptions.length === 1 && !isSameSubscription">
        <telia-p v-if="!isExtendingSubscription">{{ bundleOptions[0].name }}</telia-p>
      </div>
      <ConfiguratorRow v-else>
        <b2x-radio-card
          v-for="bundleOption in bundleOptions"
          class="option"
          :label="bundleOption.name"
          :key="bundleOption.productCode"
          :data-testid="'bundle-product-code-' + bundleOption.productCode"
          :checked="bundleOption.productCode === selectedSubscriptionBundle?.productCode"
          @change="() => emit('select-subscription-bundle', bundleOption.productCode)"
        />
      </ConfiguratorRow>
    </div>
    <div class="subscription-selector__products">
      <telia-heading tag="h2" variant="title-200" class="title">{{
        t("hardwareSubscriptionConfigurator.SUBSCRIPTION_SURF")
      }}</telia-heading>
      <ConfiguratorRow>
        <b2x-radio-card
          v-for="subscriptionOption in subscriptionOptions"
          class="option"
          :label="subscriptionOption.name"
          :data-testid="'product-code-' + subscriptionOption.productCode"
          :key="subscriptionOption.productCode"
          :checked="subscriptionOption.productCode === selectedSubscriptionProductCode"
          @change="() => emit('select-subscription', subscriptionOption.productCode)"
        >
          <telia-p>{{ addonRecurringFeeInfo(subscriptionOption.recurringFee) }}</telia-p>
        </b2x-radio-card>
      </ConfiguratorRow>
    </div>
  </div>
</template>

<script setup lang="ts">
import ConfiguratorRow from "../../../../components/shared/ConfiguratorRow.vue";
import { translate as t } from "@telia/b2b-i18n";
import { addonRecurringFeeInfo } from "../../../../utils/formatUtils";

interface Option {
  name: string;
  productCode: string;
  recurringFee?: string;
}
interface Props {
  bundleOptions: Option[];
  subscriptionOptions: Option[];
  selectedSubscriptionProductCode: string;
  selectedSubscriptionBundle: Option;
  isSameSubscription?: boolean;
  isExtendingSubscription?: boolean;
}

withDefaults(defineProps<Props>(), {
  isExtendingSubscription: false,
  isSameSubscription: false,
});
const emit = defineEmits<{
  (event: "select-subscription", subscriptionProductCode: string): void;
  (event: "select-subscription-bundle", bundleProductCode: string): void;
}>();
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.subscription-selector {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;

  .option {
    flex: 0 1 31%;
  }
  &__bundles {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }
}
.select-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .select-box {
    padding: $telia-spacing-8;
    margin-right: $telia-spacing-8;
    margin-bottom: $telia-spacing-8;
    flex-basis: 30%;
    text-align: center;
    vertical-align: baseline;
  }
}
</style>
