import { ValidationResponse } from "./ValidationResponse";
import { EsimEmailChoiceType } from "./constants";

type Predicate = (value: string) => ValidationResponse | Promise<ValidationResponse>;

export const asyncEvery = async (
  predicates: Predicate[],
  value: string
): Promise<ValidationResponse> => {
  for (const predicate of predicates) {
    const { error, isValid } = await predicate(value);
    if (error || !isValid) return { error, isValid };
  }
  return { isValid: true };
};

export function getUserEmail(
  type: EsimEmailChoiceType,
  preloadedUserEmail: string,
  userEmailInput: string
) {
  return type === "USER_EMAIL" ? preloadedUserEmail : userEmailInput;
}
