<template>
  <div class="extend-existing-subscription">
    <SearchSubscription
      v-if="!selectedSubscriptionItem"
      :tscid="stateStore.tscid"
      :scope-id="stateStore.scopeId"
      @select-subscription="selectSubscription"
    />
    <SelectedSubscription
      v-if="!!selectedSubscriptionItem"
      :selected-subscription="selectedSubscriptionItem"
      :no-matching-subscription="noMatchingSubscription"
      @clear-selected-subscription="clearSelectedSubscription"
    />

    <div v-if="selectedSubscription && selectedBundleOption">
      <SubscriptionProductSelector
        is-extending-subscription
        :is-same-subscription="isSameSubscription"
        :bundle-options="bundleOptions"
        :subscription-options="subscriptionOptions"
        :selected-subscription-product-code="selectedSubscription?.productCode"
        :selected-subscription-bundle="selectedBundleOption"
        @select-subscription="selectSubscriptionProduct"
        @select-subscription-bundle="selectSubscriptionBundle"
      />
      <CommitmentSelector
        :commitments="availableCommitmentOptions"
        :selected-commitment="selectedCommitment"
        @select-commitment-period="selectCommitment"
      />
      <PaymentOptions
        :is-upfront-payment="isUpfrontPayment"
        :increased-monthly-fee="increasedFeeForDevice"
        @select-upfront-payment="selectPaymentOption"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SubscriptionBundle, SubscriptionProduct } from "../../../../../types";
import { SubscriptionItem } from "./extendSubscriptionTypes";
import { computed, ref } from "vue";
import { UserInformation } from "../../../subscription-configurator/components/user-info/types";
import SearchSubscription from "./SearchSubscription.vue";
import SelectedSubscription from "./SelectedSubscription.vue";
import SubscriptionProductSelector from "../SubscriptionProductSelector.vue";
import { useHardwareStore } from "../../../../../store/HardwareConfigurationStore";
import CommitmentSelector from "../../../shared/CommitmentSelector.vue";
import PaymentOptions from "../PaymentOptions.vue";
import { storeToRefs } from "pinia";
import { useProductStore } from "../../../../../store/ProductStore";
import { useStateStore } from "../../../../../store/StateStore";
import { useGa4Helper } from "../../../../../utils/tracking/gaHelper";
import MessagePresenter from "src/components/MessagePresenter.vue";
import { corpProductOrder } from "@telia/b2b-rest-client";
import { formatToMsisdn } from "@telia/b2x-phone-number-format";

interface Props {
  increasedFeeForDevice: number;
  voiceSubscriptionBundles: SubscriptionBundle[];
  selectedCommitment: number;
  commitmentPeriods: number[];
}
// monthly fee for selected subscription as prop or from store.
const props = defineProps<Props>();

const hardwareStore = useHardwareStore();
const stateStore = useStateStore();
const {
  isUpfrontPayment,
  selectedSubscription,
  selectedSubscriptionBundle,
  availableCommitmentOptions,
  subscriptionsApplicableForVariant,
} = storeToRefs(useHardwareStore());
const ga4Helper = useGa4Helper();

const matchingSubscriptionProduct = ref<SubscriptionProduct>();
const matchingSubscriptionBundle = ref<SubscriptionBundle>();
const selectedSubscriptionItem = ref<SubscriptionItem | undefined>();
const noMatchingSubscription = ref(false);
const numberToBeDisconnected = ref(false);

const bundleOptions = computed(() => {
  return props.voiceSubscriptionBundles.map((bundle) => ({
    name: bundle.name,
    productCode: bundle.productCode,
  }));
});

const subscriptionOptions = computed(() => {
  const bundleName = selectedSubscriptionBundle.value?.name;
  if (!bundleName) {
    return [];
  }

  return subscriptionsApplicableForVariant.value.map((subscriptionProduct) => {
    return {
      name: subscriptionProduct.name.replace(`${bundleName} `, ""),
      productCode: subscriptionProduct.productCode,
      recurringFee: subscriptionProduct.recurringFee,
    };
  });
});

const selectedBundleOption = computed(() => {
  if (selectedSubscriptionBundle.value) {
    return {
      name: selectedSubscriptionBundle.value.name,
      productCode: selectedSubscriptionBundle.value.productCode,
    };
  }
  return undefined;
});

const isSameSubscription = computed(() => {
  if (matchingSubscriptionProduct.value && selectedSubscription.value) {
    return matchingSubscriptionProduct.value.productCode === selectedSubscription.value.productCode;
  }
  return false;
});

async function selectSubscription(subscription: SubscriptionItem) {
  numberToBeDisconnected.value = false;
  const toBeDisconnectedResponse =
    await corpProductOrder.NumberValidationControllerService.validateMsisdnForExtension(
      stateStore.scopeId,
      formatToMsisdn(subscription.phoneNumber)
    );
  if (!toBeDisconnectedResponse.valid) {
    numberToBeDisconnected.value = true;
  }
  selectedSubscriptionItem.value = subscription;
  findMatchingProduct(subscription.subscriptionType);
  if (matchingSubscriptionProduct.value && matchingSubscriptionBundle.value) {
    noMatchingSubscription.value = false;
    setSubscriptionBundle(matchingSubscriptionBundle.value);
    setSubscriptionProduct(matchingSubscriptionProduct.value);
  } else {
    noMatchingSubscription.value = true;
    const defaultBundle = props.voiceSubscriptionBundles[0];
    const defaultSubscription = defaultBundle.products[0];
    setSubscriptionBundle(defaultBundle);
    setSubscriptionProduct(defaultSubscription);
  }
  setUser(subscription.user, subscription.reference);
  setC2bUid(subscription.id);
}

function setC2bUid(c2bUid: string) {
  hardwareStore.setC2bUid(c2bUid);
}

function setUser(user: string, reference: string) {
  const userInfo = formatUser(user);
  userInfo.reference = reference;
  hardwareStore.setUserInformation(userInfo);
}

function setSubscriptionProduct(subscriptionProduct: SubscriptionProduct) {
  hardwareStore.setSubscriptionProduct(subscriptionProduct);
}

function selectSubscriptionProduct(subscriptionProductCode: string) {
  const subscriptionProduct = selectedSubscriptionBundle.value?.products.find(
    (subscription) => subscription.productCode === subscriptionProductCode
  );
  if (subscriptionProduct) {
    ga4Helper.trackViewProduct(subscriptionProduct);
    hardwareStore.setSubscriptionProduct(subscriptionProduct);
  }
}

function setSubscriptionBundle(subscriptionBundle: SubscriptionBundle) {
  hardwareStore.setSubscriptionBundle(subscriptionBundle);
}

function selectSubscriptionBundle(subscriptionBundleCode: string) {
  const voiceBundle = props.voiceSubscriptionBundles.find(
    (bundle) => bundle.productCode === subscriptionBundleCode
  );
  if (voiceBundle) {
    hardwareStore.setSubscriptionBundle(voiceBundle);
  }
}

function selectCommitment(commitmentPeriod: number) {
  hardwareStore.setCommitment(commitmentPeriod);
}

function selectPaymentOption(isUpfrontPayment: boolean) {
  hardwareStore.setUpfrontPayment(isUpfrontPayment);
}

function clearSelectedSubscription() {
  hardwareStore.clearSubscription();
  matchingSubscriptionProduct.value = undefined;
  matchingSubscriptionBundle.value = undefined;
  selectedSubscriptionItem.value = undefined;
}

function findMatchingProduct(subscriptionType: string) {
  matchingSubscriptionProduct.value = props.voiceSubscriptionBundles
    .flatMap((offer) => offer.products)
    .find((product) => product.subscriptionType === subscriptionType);
  matchingSubscriptionBundle.value = props.voiceSubscriptionBundles.find((bundle) => {
    return bundle.products.find(
      (product) => product.productCode === matchingSubscriptionProduct.value?.productCode
    );
  });
}

function formatUser(fullName: string): UserInformation {
  // some customer has faultly two commas instead of one

  let fullNameNew = fullName?.replace(",,", ",");

  let names = fullNameNew?.split(",");
  if (!names) {
    return { lastName: "", firstName: "", reference: "" };
  }

  if (names.length < 2) {
    return { lastName: fullNameNew, firstName: "-", reference: "" };
  }
  return {
    firstName: names[1].trim(),
    lastName: names[0].trim(),
    reference: "",
  };
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.extend-existing-subscription {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-12;
}
</style>
