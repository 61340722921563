import { match } from "path-to-regexp";
import { TabCategory } from "../components/types";
import { Ref, onMounted, onUnmounted, ref } from "vue";

interface Match {
  params: Params;
}
interface Params {
  tscid: string;
  scopeId: string;
  category: TabCategory;
  productCode?: string;
}

export function getParamsFromUrl(url = window.location.pathname): Params {
  const withProduct = match(
    "/foretag/mybusiness/:scopeId/bestall/:tscid/:category/produkt/:productCode?"
  )(url) as Match;
  if (withProduct) {
    return withProduct.params;
  }

  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/:category(.*)")(
    url
  ) as Match;

  return params;
}

export function routeToTscid(tscid: string) {
  const params = getParamsFromUrl();
  const splat = "";
  history.pushState(
    history.state,
    "",
    `/foretag/mybusiness/${params.scopeId}/bestall/${tscid}/${params.category ?? ""}${splat}`
  );
}

export function routeToProduct(productCode?: string) {
  const params = getParamsFromUrl();
  if (productCode) {
    history.pushState(
      history.state,
      "",
      `/foretag/mybusiness/${params.scopeId}/bestall/${params.tscid}/${params.category}/produkt/${productCode}`
    );
  } else {
    history.pushState(
      history.state,
      "",
      `/foretag/mybusiness/${params.scopeId}/bestall/${params.tscid}/${params.category}`
    );
  }
}

export function routeToCategory(category: string) {
  const params = getParamsFromUrl();

  // trackStartofSalesFlow();
  history.pushState(
    history.state,
    "",
    `/foretag/mybusiness/${params.scopeId}/bestall/${params.tscid}/${category}`
  );
}
// TODO learn how to make generic
export function useStateParams(
  initialState: string,
  paramName: "category" | "tscid" | "productCode"
): [Ref<string>, (state: string) => void] {
  const params = getParamsFromUrl();
  const state = ref<string>(params[paramName] ?? initialState);

  onMounted(() => window.addEventListener("popstate", paramListener));
  onUnmounted(() => window.removeEventListener("popstate", paramListener));

  const paramListener = () => {
    const newParams = getParamsFromUrl();
    state.value = newParams[paramName] ?? "";
  };

  const onChange = (newValue: string) => {
    state.value = newValue;
    routeTo(state.value, paramName);
  };

  function routeTo(param: string, route: "category" | "tscid" | "productCode") {
    switch (route) {
      case "category":
        routeToCategory(param);
        return;
      case "tscid":
        routeToTscid(param);
        return;
      case "productCode":
        routeToProduct(param);
        return;
    }
  }

  return [state, onChange];
}
