<template>
  <div t-id="product-configuration-user-info">
    <div class="user-info-wrapper">
      <p>{{ t("userInfo.text1") }}</p>
      <p>{{ t("userInfo.text2") }}</p>
    </div>
    <telia-form name="userInfoForm" @submit.prevent>
      <div class="row">
        <telia-text-input
          t-id="product-configuration-user-info-input-firstname"
          class="input"
          :label="t('userInfo.firstname')"
          :value="userInfo.firstName"
          @input="setFirstName($event.target.value)"
          required
          :required-error-message="t('userInfo.fieldRequired')"
          maxlength="19"
        />
        <telia-text-input
          t-id="product-configuration-user-info-input-lastname"
          class="input"
          :label="t('userInfo.lastname')"
          :value="userInfo.lastName"
          @input="setLastName($event.target.value)"
          required
          :required-error-message="t('userInfo.fieldRequired')"
          maxlength="19"
        />
      </div>
      <telia-checkbox
        t-id="product-configuration-user-info-check-reference"
        :checked="checked"
        :value="checked"
        @change="useAsReferenceChecked"
      >
        {{ t("userInfo.useAsSubscriptionReference") }}
      </telia-checkbox>
      <div class="row">
        <telia-text-input
          t-id="product-configuration-user-info-input-reference"
          class="input"
          :label="t('userInfo.subscriptionReference')"
          :additional="t('userInfo.subscriptionReferenceAssistive')"
          :value="userInfo.reference"
          @input="setReference($event.target.value)"
          required
          :required-error-message="t('userInfo.fieldRequired')"
          :disabled="checked"
          maxlength="30"
        />
      </div>
    </telia-form>
  </div>
</template>

<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { UserInformation } from "./types";

const emit = defineEmits<{
  (e: "valid", isValid: boolean): void;
  (e: "change", userInfo: UserInformation): void;
}>();

let t = translate;

const userInfo = ref<UserInformation>({
  firstName: "",
  lastName: "",
  reference: "",
});

const checked = ref(false);

const rules = computed(() => ({
  userInfo: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    reference: {
      required,
    },
  },
}));

const v$ = useVuelidate(rules, { userInfo });

onMounted(async () => {
  emitValidity(!v$.value.$invalid);
});

function clearReference() {
  userInfo.value.reference = "";
}

function emitValidity(valid: boolean) {
  emit("valid", valid);
}

function setReferenceFromFirstAndLastName() {
  let reference = `${userInfo.value.firstName} ${userInfo.value.lastName}`;
  if (reference.length > 30) {
    reference = reference.slice(0, 29) + ".";
  }
  userInfo.value.reference = reference;
}

function setFirstName(text: string) {
  userInfo.value.firstName = text.trim();
}

function setLastName(text: string) {
  userInfo.value.lastName = text.trim();
}

function setReference(text: string) {
  userInfo.value.reference = text.trim();
}

function useAsReferenceChecked() {
  checked.value = !checked.value;
  updateReference();
}

function updateReference() {
  if (checked.value) {
    setReferenceFromFirstAndLastName();
  } else {
    clearReference();
  }
}

watch(
  userInfo,
  (newUserInfo: UserInformation) => {
    if (checked.value) {
      setReferenceFromFirstAndLastName();
    }
    emit("change", newUserInfo);
  },
  { deep: true }
);

watch(v$, () => {
  emitValidity(!v$.value.$invalid);
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.input {
  flex: 1 1 auto;
}
.user-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-20;
  padding-bottom: $telia-spacing-24;
}
</style>
