<template>
  <div t-id="accessory-products">
    <SortingAndFiltering
      :primary-options="categories"
      :secondary-options="hardwareOptions"
      :selected-primary-filter="filterConfiguration.accessoryCategoryFilter"
      :selected-secondary-filter="filterConfiguration.accessoryDeviceFilter"
      :default-sorting-method="filterConfiguration.sortBy"
      secondary-filter-query-parameter-name="passar-till"
      @set-sorting-method="onSetSortingMethod"
      @set-primary-filter="onSetCategoryFilter"
      @set-secondary-filter="onSetDeviceFilter"
    />
    <section t-id="product-group-accessories" class="accessory-products__category">
      <message-presenter v-if="alert" :message-type="alert" />
      <div
        v-if="loading"
        t-id="accessory-products-loading-container"
        class="accessory-products__category-products"
      >
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
        <telia-skeleton class="accessory-products__skeleton"></telia-skeleton>
      </div>
      <TransitionGroup
        v-else
        :css="true"
        appear
        tag="div"
        name="fade-zoom"
        class="accessory-products__category-products"
      >
        <div
          t-id="product-listing"
          v-for="(product, index) in accessories"
          :key="product.id"
          :class="`accessory-products__listing ${
            disabled ? 'accessory-products__listing-disabled' : ''
          }`"
        >
          <div
            class="product-card-container"
            tabindex="1"
            @click.prevent="selectProduct(product, Number(index))"
          >
            <b2x-hardware-product-card
              :t-id="`product-listing__hardware-card-${product.productCode}`"
              :heading="product.name"
              :subheading="product.brand"
              :href="link"
              :price-heading="`${formatCurrency(product.unitPrice)}`"
              :image="getImageUrl(product)"
            >
              <div class="card-slot-content" slot="colors">
                <div class="color-container">
                  <telia-color-dot
                    slot="colors"
                    :key="product.color"
                    :color="product.color"
                    size="xs"
                  />
                </div>
                <StockStatus
                  v-if="stockStatusEnabled"
                  :status="stockStatus(product)?.status ?? 'UNKNOWN_STOCK'"
                  :availableFrom="stockStatus(product)?.availableFrom"
                  :loading="productStore.fetching.accessoryStockStatus"
                />
              </div>
            </b2x-hardware-product-card>
          </div>
        </div>
      </TransitionGroup>
      <b2x-paginator
        t-id="order-products-container-pagination"
        default-page-size="15"
        :page-sizes="'[15, 30, 60]'"
        :show-page-size-selector="shouldShowSizeSelector"
        :list-length="totalAccessoryCount"
        @paginationChange="onPaginationChange"
      >
      </b2x-paginator>
    </section>
  </div>
</template>

<script setup lang="ts">
import { currentLanguage } from "@telia/b2b-i18n";
import { computed, onMounted, reactive, watchEffect } from "vue";
import SortingAndFiltering from "../shared/sorting/SortingAndFiltering.vue";
import MessagePresenter from "./MessagePresenter.vue";
import placeholder from "../../../../assets/accessories.svg";
import { trackAccessoryProductList } from "./utils/gaTracking";
import { useGa4Helper } from "../../../utils/tracking/gaHelper";
import { AccessoryProduct } from "../../../types";
import { AccessoryListingConfiguration, Pagination } from "./types";
import { SortingOption } from "../shared/sorting/types";
import { useStateStore } from "../../../store/StateStore";
import { TabCategories } from "../../TabCategories";
import StockStatus from "../../product-configurator/shared/StockStatus.vue";
import { useProductStore } from "../../../store/ProductStore";
import { useFlag } from "@unleash/proxy-client-vue";

interface Props {
  accessories: AccessoryProduct[];
  totalAccessoryCount: number;
  disabled: boolean;
  loading: boolean;
  categories: string[];
  categoriesLoading: boolean;
  alert: string;
  hardwareOptions: { name: string; value: string }[]; // name value object
}

const emit = defineEmits<{
  (e: "selected-product", product: AccessoryProduct): void;
  (e: "listing-configuration-changed", filterConfiguration: AccessoryListingConfiguration): void;
}>();

const props = defineProps<Props>();
const stateStore = useStateStore();
const productStore = useProductStore();
const ga4Helper = useGa4Helper();
const stockStatusEnabled = useFlag("mco-listing-stock-status");

const filterConfiguration = reactive<AccessoryListingConfiguration>({
  accessoryCategoryFilter: "",
  accessoryDeviceFilter: [],
  pagination: {
    page: 1,
    pageSize: 15,
  },
  sortBy: "NEWEST",
});

const link = computed(() => {
  return window.location.href;
});

const shouldShowSizeSelector = computed(() => {
  return !!props.accessories.length;
});

onMounted(() => {
  trackProductList();
});
watchEffect(async () => {
  if (stateStore.category) {
    trackProductList();
  }
});

watchEffect(() => {
  emit("listing-configuration-changed", filterConfiguration);
});
function stockStatus(product: AccessoryProduct) {
  return productStore.hardwareStockStatus.find((stock) => stock.sapId === product.sapId);
}
function selectProduct(product: AccessoryProduct, index: number) {
  ga4Helper.trackProductSelect(product, index);
  emit("selected-product", product);
}
function formatCurrency(amount: string) {
  return new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(amount));
}
function getImageUrl(product: AccessoryProduct) {
  return product.gallery?.mainImage?.srcUrl
    ? `https://www.telia.se/${product.gallery.mainImage.srcUrl}?h=250&fit=fill`
    : placeholder;
}

function onPaginationChange(event: { detail: Pagination }) {
  filterConfiguration.pagination = event.detail;
}
function onSetSortingMethod(selected: SortingOption) {
  filterConfiguration.sortBy = selected;
}
function onSetCategoryFilter(selectedFilter: string) {
  filterConfiguration.accessoryCategoryFilter = selectedFilter;
  filterConfiguration.pagination.page = 1;
}
function onSetDeviceFilter(selectedFilter: string[]) {
  filterConfiguration.accessoryDeviceFilter = selectedFilter;
  filterConfiguration.pagination.page = 1;
}
function trackProductList() {
  if (stateStore.category === TabCategories.Accessories) {
    trackAccessoryProductList(props.accessories, filterConfiguration.accessoryCategoryFilter);
    ga4Helper.trackAccessoryProductList(props.accessories);
  }
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/breakpoints/tokens";
@import "@teliads/components/foundations/spacing/tokens";

.card-slot-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.color-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}
.accessory-products {
  &__category {
    margin: $telia-spacing-64 0;
    @media screen and (max-width: $telia-breakpoint-medium) {
      margin: 0;
    }
  }

  &__skeleton {
    height: 480px;
  }

  &__category-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: $telia-spacing-12;
    border-bottom: 1px solid #d0d0d0;
  }

  &__category-description {
    margin-top: $telia-spacing-24;
  }

  &__category-products {
    margin-top: $telia-spacing-24;
    display: grid;
    gap: $telia-spacing-24;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  &__listing {
    &-disabled {
      opacity: 0.17;
      pointer-events: none;
    }
  }

  &__loading-indicator {
    display: flex;
    justify-content: center;
  }
}

.product-card-container {
  display: inline;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-zoom-enter-active,
.fade-zoom-leave-active {
  transition:
    opacity 0.1s,
    transform 0.1s;
}

.fade-zoom-enter,
.fade-zoom-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
