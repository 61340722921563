<template>
  <div class="configurator">
    <telia-grid>
      <telia-row>
        <telia-col width="12" width-lg="12" width-md="12">
          <ConfiguratorCard
            :heading="t('productAdded.productAddedConfirmation', { name: product.name })"
          >
            <div data-testid="product-added-confirmation">
              <telia-p class="product-added-confirmation__proceed-text"
                >{{ t("productAdded.proceedToBasket") }}
              </telia-p>
              <div class="product-added-confirmation__button-container">
                <telia-button
                  data-testid="product-added-confirmation__basket-button"
                  class="product-added-confirmation__basket-button"
                  variant="primary"
                  @click="onBasketButtonClick"
                >
                  <telia-icon slot="left" name="cart" size="sm" />
                  {{ t("productAdded.toBasketButtonLabel", { count: basketCount }) }}
                </telia-button>
                <telia-button
                  data-testid="product-added-confirmation__continue-button"
                  variant="primary"
                  @click="onContinueButtonClick"
                >
                  {{ t("productAdded.continueButtonLabel") }}
                </telia-button>
              </div>
              <div data-testid="product-added-confirmation__skeleton" v-if="recommendationsLoading">
                <telia-skeleton class="product-added-confirmation__recommendation-title-skeleton" />
                <telia-skeleton class="product-added-confirmation__recommendation-skeleton" />
              </div>
              <telia-heading
                tag="h2"
                variant="title-100"
                class="product-added-confirmation__recommendation-title"
                v-if="!hasReachedMaxNumberOfItems && recommendations.length > 0"
              >
                {{ t("productAdded.recommendationsTitle", { product: product.name }) }}
              </telia-heading>
              <div
                data-testid="product-added-confirmation__recommendation-container"
                class="product-added-confirmation__recommendation-container"
                v-if="hasLoadedAccessories"
              >
                <Recommendation
                  v-for="recommendation in recommendations"
                  :key="recommendation.id"
                  :product="recommendation"
                  :compatible-with-name="product.name"
                  :add-to-basket-disabled="isAddToBasketInProgress(recommendation.id)"
                  :stock-status="getStockStatusForRecommendation(recommendation)"
                  @add-to-basket="onAddToBasket(recommendation)"
                />
              </div>
              <telia-notification
                status="error"
                heading-tag="h4"
                class="product-added-confirmation__recommendation__add-to-basket--error"
                data-testid="product-added-confirmation__recommendation__add-to-basket--error"
                v-if="addToBasketError"
              >
                <div slot="heading">{{ t("productAdded.addToBasketError") }}</div>
              </telia-notification>
              <div
                class="product-added-confirmation__recommendation__view-all"
                v-if="!hasReachedMaxNumberOfItems && recommendations.length > 0"
              >
                <telia-button
                  data-testid="product-added-confirmation__accessories-button"
                  variant="tertiary-purple"
                  @click="onAccessoriesButtonClick"
                >
                  {{ t("productAdded.toAllAccessoriesLabel") }}
                  <telia-icon slot="right" name="chevron-right" size="sm" />
                </telia-button>
              </div>
            </div>
          </ConfiguratorCard>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>
<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { AccessoryProduct, Product, ProductStockStatus, isHardwareProduct } from "../../types";
import { computed, onMounted, ref } from "vue";
import { getRecommendedAccessoriesProducts, getStockStatus } from "../../service/ProductService";
import { addProductToBasket } from "../../service/BasketService";
import Recommendation from "./recommendation.vue";
import ConfiguratorCard from "../shared/ConfiguratorCard.vue";
import { buildAccessoryRequest } from "../../utils/basketRequestUtil";
import { useGa4Helper } from "../../utils/tracking/gaHelper";

interface Props {
  product: Product;
  scopeId: string;
  tscid: string;
  basketCount: number;
  hasReachedMaxNumberOfItems: boolean;
  accessoryProductCodesInBasket: string[];
}

type StockStatus = {
  sapId: string;
  status: string;
};

const emit = defineEmits<{
  (e: "start-loader"): void;
  (e: "stop-loader"): void;
  (e: "basket-modified"): void;
  (e: "open-basket"): void;
  (e: "continue"): void;
  (e: "show-accessories", accessory: { bundleCode: string }): void;
}>();

const props = defineProps<Props>();

const recommendationsLoading = ref(false);
const recommendations = ref<AccessoryProduct[]>([]);
const stockStatus = ref<ProductStockStatus[]>([]);
const addToBasketInProgressForProduct = ref<string>();
const addToBasketError = ref(false);
const { trackAddAccessoryToCart } = useGa4Helper();

onMounted(async () => {
  if (!props.hasReachedMaxNumberOfItems && isMobileDeviceBundle.value) {
    await fetchRecommendations();
  }
});

const isMobileDeviceBundle = computed(() => {
  return (
    isHardwareProduct(props.product) &&
    props.product.category === "hardware_bundle" &&
    props.product.variants.some((v) => v.category === "goods" && v.subCategory === "mobiledevice")
  );
});

const hasLoadedAccessories = computed(() => {
  return (
    !props.hasReachedMaxNumberOfItems &&
    !recommendationsLoading.value &&
    recommendations.value.length > 0
  );
});

const subCategory = computed(() => {
  if (isHardwareProduct(props.product)) {
    return props.product.variants[0].subCategory;
  }
  return undefined;
});

async function fetchRecommendations() {
  recommendationsLoading.value = true;

  try {
    recommendations.value = (await getRecommendedAccessoriesProducts(
      props.scopeId,
      props.tscid,
      props.product.productCode
    )) as Array<AccessoryProduct>;
    // Filter away accessories that are already in the basket
    recommendations.value = recommendations.value.filter(
      (r) => !props.accessoryProductCodesInBasket.includes(r.productCode)
    );

    if (recommendations.value.length > 0) {
      await fetchStockStatus();
      // trackRecommendedAccessoryListing(recommendations.value, subCategory.value);
    }
  } catch (e) {
    recommendations.value = [];
  } finally {
    recommendationsLoading.value = false;
  }
}

async function fetchStockStatus() {
  try {
    stockStatus.value = await getStockStatus(
      recommendations.value.map((r) => ({ sapId: r.sapId, quantity: 1 }))
    );
  } catch (e) {
    stockStatus.value = [];
  }
}
function onBasketButtonClick() {
  emit("open-basket");
}
function onContinueButtonClick() {
  emit("continue");
}
function onAccessoriesButtonClick() {
  // trackBrowseAllRecommendedAccessories();
  emit("show-accessories", { bundleCode: props.product.productCode });
}
function isAddToBasketInProgress(productId: string) {
  return addToBasketInProgressForProduct.value === productId;
}
function getStockStatusForRecommendation(item: AccessoryProduct): StockStatus {
  if (stockStatus.value.length === 0) {
    return {
      sapId: item.sapId,
      status: "UNKNOWN_STOCK",
    };
  }

  return stockStatus.value.find((status) => status.sapId === item.sapId) as StockStatus;
}

async function onAddToBasket(item: AccessoryProduct) {
  // trackRecommendedAccessoryProductClick(item);
  addToBasketInProgressForProduct.value = item.id;
  addToBasketError.value = false;

  try {
    emit("start-loader");
    await addProductToBasket(props.scopeId, props.tscid, buildAccessoryRequest(item));
    trackAddAccessoryToCart(item);
    recommendations.value = recommendations.value.filter((r) => r.id !== item.id);

    // trackAddRecommendedAccessoryToBasket(item);
  } catch (e) {
    addToBasketError.value = true;
  } finally {
    emit("stop-loader");
    addToBasketInProgressForProduct.value = undefined;
  }

  emit("basket-modified");
}

function goToStep(event: CustomEvent) {
  if (event.detail === 0) {
    window.location.href = `/foretag/mybusiness/${props.scopeId}/bestall/${props.tscid}/mobilabonnemang`;
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";

.configurator {
  background-color: $telia-gray-100;
  padding-top: 5rem;
  padding-bottom: 10rem;
  height: 100%;
}

.header {
  background-color: $telia-purple-800;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $telia-white;
  height: 300px;

  @media screen and (max-width: $telia-breakpoint-medium) {
    padding: 0 $telia-spacing-12;
    text-align: center;
  }
}

.stepper-wrapper {
  margin-bottom: $telia-spacing-32;
}

.product-added-confirmation {
  &__confirmation-text {
    margin-bottom: $telia-spacing-12;
  }
  &__proceed-text {
    margin-bottom: $telia-spacing-24;
  }

  &__button-container {
    display: flex;
    margin-bottom: $telia-spacing-24;
  }

  &__recommendation-container {
    display: flex;
    gap: $telia-spacing-12;
    flex-direction: column;

    @media screen and (min-width: $telia-breakpoint-medium) {
      flex-direction: row;
    }
  }

  &__recommendation-title {
    margin-bottom: $telia-spacing-8;
  }

  &__basket-button {
    margin-right: $telia-spacing-8;
  }

  &__recommendation-title-skeleton {
    width: 75%;
    height: 2.5rem;
    margin-bottom: $telia-spacing-8;
  }

  &__recommendation-skeleton {
    width: 100%;
    height: 13rem;
    border-radius: 1.6rem;
  }

  &__recommendation__add-to-basket--error {
    margin-bottom: $telia-spacing-8;
  }

  &__recommendation__view-all {
    text-align: center;
  }
}

telia-p {
  display: block;
}
</style>
