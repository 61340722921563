<template>
  <div class="subscription-configuration" t-id="subscription-configuration">
    <div v-if="productFetchState !== 'loading'" class="subscription-configuration__items">
      <ConfiguratorCard :heading="t('configurator.userInfoTitle')">
        <UserInfo @change="handleUserInfo" />
      </ConfiguratorCard>
      <ConfiguratorCard
        v-if="subscription?.minimumCommitment && subscription.minimumCommitment > 0"
        :heading="t('configurator.commitment')"
      >
        <CommitmentSelector
          :commitments="subscriptionCommitmentPeriods ?? []"
          :selected-commitment="subscriptionConfiguration.commitment"
          :show-heading="false"
          :additional-text="t('configurator.commitmentAdditional')"
          @select-commitment-period="handleCommitmentSelection"
        />
      </ConfiguratorCard>
      <ConfiguratorCard v-if="!isMobileBroadband" :heading="t('configurator.phoneNumberTitle')">
        <Number
          :scopeId="scopeId"
          :tscid="tscid"
          :migration-products="productStore.numberMigrationProducts"
          :number-migration-error="productStore.numberMigrationError"
          @change="onSelectedNumber"
          @valid="onPhoneValid"
          @number-migration-configuration="onSelectedMigration"
        />
      </ConfiguratorCard>
      <ConfiguratorCard :heading="t('configurator.simcardTitle')">
        <SimcardSelectorCe
          identifier="simcar-select"
          @change="handleSimCardSelection"
          @valid="onSimcardValid"
        />
      </ConfiguratorCard>
      <!-- This is mainly a fix for jobbmobil bas and selection the data amount -->
      <Addons
        :configuration-simcard="subscriptionConfiguration.simcard"
        :addonBundles="mandatoryAddonBundles"
        :exclusions="exclusions"
      />
      <div class="addon-title-wrapper">
        <telia-heading tag="h2" variant="title-300">
          {{ t("configurator.addonsTitle") }}
        </telia-heading>
        <telia-p>{{ t("configurator.addonsSubtitle") }}</telia-p>
      </div>
      <MessagePresenter v-if="productFetchState === 'error'" :message-type="'addonFetchError'" />
      <ConfiguratorCard
        v-if="switchboardUserBundles?.length > 0 && newSwitchboardEnabled"
        :heading="t('configurator.switchboard')"
      >
        <SwitchboardController
          :scopeId="scopeId"
          :tscid="tscid"
          :excluded-addon-product-codes="exclusions"
          :switchboard-bundles="switchboardUserBundles"
        ></SwitchboardController>
      </ConfiguratorCard>
      <ConfiguratorCard
        v-if="switchboardUserBundles?.length > 0 && !newSwitchboardEnabled"
        :heading="t('configurator.switchboard')"
      >
        <SwitchboardUser
          :switchboard-user-addon-bundles="switchboardUserBundles"
          :exclusions="exclusions"
          :tscid="tscid"
          @switchboard-user-configuration="onSwitchboardUserChange"
          @valid="onSwitchboardValid"
        />
      </ConfiguratorCard>

      <Addons
        :configuration-simcard="subscriptionConfiguration.simcard"
        :addonBundles="optionalAddonBundles"
        :exclusions="exclusions"
      />
    </div>
    <div v-else class="skeleton-container">
      <telia-skeleton
        v-for="n in 6"
        :key="n"
        class="configuration-item___skeleton"
      ></telia-skeleton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";
import { DatasimPayload, EsimWatchPayload } from "./types";
import {
  SwitchboardAddonBundle,
  SwitchboardConfiguration,
} from "./components/subscription/switchboard/switchboard-types";
import UserInfo from "./components/user-info/user-info.ce.vue";
import { UserInformation } from "./components/user-info/types";
import SwitchboardUser from "./components/subscription/switchboard/SwitchboardUserContainer.vue";
import Addons from "./components/addon/Addons.vue";
import SimcardSelectorCe from "./components/subscription/simcard-selector/SimcardSelector.vue";
import { SimcardPayload } from "./utils/SimChoicePayload";
import { AddonEmitPayload } from "./components/addon/types";
import Number from "./components/number/Number.vue";
import { NumberMigrationConfiguration, PhoneNumber } from "./components/number/types";
import {
  SubscriptionAddon,
  SubscriptionProduct,
  SubscriptionProductAddonBundle,
} from "../../../types";
import { useSubscriptionConfigurationStore } from "../../../store/SubscriptionConfigurationStore";
import { useConfigurationStore } from "../../../store/ConfigurationStore";
import { storeToRefs } from "pinia";
import { getExtendedSubscription } from "../../../service/ProductService";
import { translate } from "@telia/b2b-i18n";
import { isMobileBroadbandSubscription } from "../../../utils/productCategory";
import { useStateStore } from "../../../store/StateStore";
import { useProductStore } from "../../../store/ProductStore";
import ConfiguratorCard from "../../shared/ConfiguratorCard.vue";
import MessagePresenter from "../../MessagePresenter.vue";
import CommitmentSelector from "../shared/CommitmentSelector.vue";
import SwitchboardController from "./components/subscription/switchboard/SwitchboardController.vue";
import { useSwitchboardStore } from "../../../store/SwitchboardConfigurationStore";
import { useFlag } from "@unleash/proxy-client-vue";
import { useSubscriptionAddonStore } from "../../../store/SubscriptionAddonStore";

interface Props {
  disabled?: boolean;
  productName: string;
  productCode: string;
  offerCode: string;
}
const newSwitchboardEnabled = useFlag("b2b-mco-new-switchboard");

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  showTitle: false,
  migrationProducts: () => [],
});

const t = translate;
const stateStore = useStateStore();
const { scopeId, tscid } = storeToRefs(stateStore);
const addonStore = useSubscriptionAddonStore();
const subscriptionStore = useSubscriptionConfigurationStore();
const configurationStore = useConfigurationStore();
const { subscriptionConfiguration } = storeToRefs(subscriptionStore);
const switchboardStore = useSwitchboardStore();
const productStore = useProductStore();
const subscription = ref<SubscriptionProduct>();
const productFetchState = ref<"loading" | "error" | "idle">("loading");

onMounted(async () => {
  await productStore.setMigrationProducts();

  if (subscription.value && subscription.value.minimumCommitment > 0) {
    handleCommitmentSelection(subscription.value.minimumCommitment);
  }
});

watchEffect(async () => {
  getSubscriptionProducts(props.offerCode, props.productCode);
});

watchEffect(() => {
  if (subscription.value && subscription.value.minimumCommitment) {
    subscriptionStore.setCommitment(subscription.value.minimumCommitment);
  }
});

const subscriptionCommitmentPeriods = computed(() => {
  if (subscription.value && subscription.value.commitmentPeriods) {
    const minimumCommitment = subscription.value.minimumCommitment;
    // TODO: Set to 24 months right now for the MVP. Remove constraint if/when we decide to allow multiple choices.
    const maximumCommitment = 24;

    return subscription.value.commitmentPeriods.filter(
      (commitmentPeriod) =>
        commitmentPeriod >= minimumCommitment && commitmentPeriod <= maximumCommitment
    );
  }
  return [];
});
const isMobileBroadband = computed(() => {
  const category = configurationStore.selectedProduct?.category ?? "";
  return isMobileBroadbandSubscription(category);
});
const switchboardUserBundles = computed(() => {
  if (subscription.value) {
    return subscription.value.addonBundles.filter((bundle) =>
      containsSwitchboard(bundle.addons)
    ) as SwitchboardAddonBundle[];
  }
  return [];
});

const mandatoryWithManualSelection = (bundle: SubscriptionProductAddonBundle) => {
  return bundle.groupMinQuantity === 1 && !bundle.addons.some((addon) => addon.defaultSelected);
};
const mandatoryAddonBundles = computed(() => {
  if (subscription.value) {
    return subscription.value.addonBundles.filter(
      (bundle) => !containsSwitchboard(bundle.addons) && mandatoryWithManualSelection(bundle)
    );
  }
  return [];
});
const optionalAddonBundles = computed(() => {
  if (subscription.value) {
    return subscription.value.addonBundles.filter(
      (bundle) => !containsSwitchboard(bundle.addons) && !mandatoryWithManualSelection(bundle)
    );
  }
  return [];
});

const exclusions = computed(() => {
  let configIds: string[] = [];

  configIds.push(
    ...addonStore.addons.addons,
    ...addonStore.datasims.map((ds) => ds.id),
    ...addonStore.esimWatches.map((mds) => mds.id)
  );

  let exclusionList: string[] = [];
  if (newSwitchboardEnabled.value) {
    exclusionList.push(...switchboardStore.switchboardExclusions);
  } else if (subscriptionConfiguration.value.switchboardUser) {
    configIds.push(
      subscriptionConfiguration.value.switchboardUser.productId,
      ...subscriptionConfiguration.value.switchboardUser.addons.map((addon) => addon.id)
    );
  }

  subscription.value?.addonBundles.forEach((bundle) => {
    bundle.addons.forEach((addon) => {
      if (configIds.includes(addon.id)) {
        if (addon.excludes) {
          exclusionList.push(...addon.excludes);
        }
      }
    });

    bundle.addonBundles?.forEach((addonBundle) => {
      addonBundle.addons.forEach((addon) => {
        if (configIds.includes(addon.id)) {
          exclusionList.push(...(addon.excludes || []));
        }
      });
    });
  });

  return exclusionList;
});

async function getSubscriptionProducts(offerCode: string, productCode: string) {
  try {
    productFetchState.value = "loading";
    subscription.value = await getExtendedSubscription(
      scopeId.value,
      tscid.value,
      offerCode,
      productCode
    );
    if (subscription.value) {
      addonStore.setAddonBundles(subscription.value?.addonBundles);
    }
    productFetchState.value = "idle";
  } catch (error) {
    console.log(error);
    productFetchState.value = "error";
  }
}

function containsSwitchboard(addons: SubscriptionAddon[]) {
  return addons
    ? addons.some(
        (addon) =>
          addon.category === "switchboardextension" &&
          (addon.subcategory === "ucuser" || addon.subcategory === "scuser")
      )
    : false;
}

function onSelectedNumber(phoneNumber: PhoneNumber) {
  subscriptionStore.setPhoneNumber(phoneNumber);
}

function onSelectedMigration(migrationConfiguration: NumberMigrationConfiguration) {
  subscriptionStore.setPhoneMigration(migrationConfiguration);
}

function handleUserInfo(userInfo: UserInformation): void {
  subscriptionStore.setUserInformation(userInfo);
}

function handleSimCardSelection(selection: SimcardPayload) {
  subscriptionStore.setSimcard(selection);
}

function handleCommitmentSelection(commitment: number) {
  subscriptionStore.setCommitment(commitment);
}

function onSwitchboardUserChange(config: SwitchboardConfiguration | undefined) {
  subscriptionStore.setSwitchboard(config);
}

function onPhoneValid(valid: boolean) {
  subscriptionStore.setPhoneValidity(valid);
}

function onSimcardValid(valid: boolean) {
  subscriptionStore.setSimcardValidity(valid);
}

function onSwitchboardValid(valid: boolean) {
  subscriptionStore.setSwitchboardValidity(valid);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.subscription-configuration__items {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
.skeleton-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.configuration-item___skeleton {
  width: 100%;
  height: 300px;
  margin-bottom: $telia-spacing-12;
}
.addon-title-wrapper {
  margin: $telia-spacing-32 0 $telia-spacing-12 0;
}
</style>
