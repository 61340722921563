import * as analytics from "@telia/b2b-web-analytics-wrapper";

export function trackAccessoryProductList(accessories, selectedAccessoryCategory) {
  let listName = `Accessories${selectedAccessoryCategory ? " - " + selectedAccessoryCategory : ""}`;
  let payload = {
    il1nm: listName, // name of product list
  };
  let index = 1;
  accessories?.forEach((product) => {
    Object.assign(
      payload,
      buildListPayload(
        product.id,
        product.name,
        product.category,
        product.subcategory || product.subCategory,
        product.brand,
        product.unitPrice,
        index
      )
    );
    index++;
  });

  // check if there are products in the payload.
  if (index > 1) {
    analytics.trackEvent(
      analytics.category.SALESFLOW,
      analytics.action.PRODUCT_LIST,
      listName,
      0,
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key],
      }))
    );
  }
}

function buildListPayload(id, name, category, subCategory, brand, unitPrice, index) {
  const payload = {};
  payload[`il1pi${index}id`] = id;
  payload[`il1pi${index}nm`] = name;
  payload[`il1pi${index}ca`] = analytics.getConcatenatedCategories(category, subCategory);
  payload[`il1pi${index}br`] = brand;
  payload[`il1pi${index}pr`] = unitPrice;
  payload[`il1pi${index}ps`] = index;
  return payload;
}
