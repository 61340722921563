<template>
  <div class="emn-subscription-configurator">
    <ConfiguratorCard v-if="product.category === 'mobilevoicesubscription'" :heading="product.name">
      <EmnSubscriptionConfiguratorView
        t-id="config-view"
        :scope-id="scopeId"
        :site-options="siteOptions"
        :site-agreement-id="emnConfiguration.siteAgreementId"
        :quantity="emnConfiguration.quantity"
        :is-valid-quantity="isValidQuantity"
        @change-site="handleSiteAgreementIdChange"
        @change-quantity="handleQuantityChange"
      />
    </ConfiguratorCard>
    <EmnDataSubscriptionConfiguratorView
      v-else
      t-id="config-view"
      :scope-id="scopeId"
      :current-step="currentStep"
      :site-options="siteOptions"
      :site-agreement-id="emnConfiguration.siteAgreementId"
      :cidr="cidr"
      :quantity="emnConfiguration.quantity"
      :max-quantity="maxQuantity"
      :ip-range-info="ipRangeInfo"
      :ip-range-start="emnConfiguration.ipRangeStart"
      :is-validating="isValidating"
      :is-valid-cidr="isValidCidr"
      :is-valid-ip-range-start="isValidIpRangeStart"
      :is-valid-quantity="isValidQuantity"
      @change-step="handleStepChange"
      @change-site="handleSiteAgreementIdChange"
      @change-cidr="handleCidrChange"
      @change-ip="handleIpRangeStartChange"
      @change-quantity="handleQuantityChange"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { corpProductOrder } from "@telia/b2b-rest-client";
import { SubscriptionProduct } from "../../../types";
import { useEmnSubscriptionStore, Step } from "../../../store/EmnSubscriptionStore";
import EmnSubscriptionConfiguratorView from "./EmnSubscriptionConfiguratorView.vue";
import EmnDataSubscriptionConfiguratorView from "./EmnDataSubscriptionConfiguratorView.vue";
import ConfiguratorCard from "../../../components/shared/ConfiguratorCard.vue";

interface Props {
  scopeId: string;
  product: Pick<SubscriptionProduct, "name" | "category">;
}

const props = defineProps<Props>();
const emnConfigurationStore = useEmnSubscriptionStore();
const {
  currentStep,
  emnConfiguration,
  emnSites,
  cidr,
  ipRangeInfo,
  maxQuantity,
  isValidCidr,
  isValidIpRangeStart,
  isValidQuantity
} = storeToRefs(emnConfigurationStore);
const isValidating = ref(false);

emnConfigurationStore.setSelectedProductCategory(props.product.category);
onUnmounted(() => {
  emnConfigurationStore.reset();
})

const siteOptions = computed(() => {
  return emnSites.value.map((site) => ({ label: site.name, value: site.siteAgreementId }));
});

async function handleStepChange(nextStep: Step) {
  // Should always be able to go back to previous step without validating data
  if (nextStep < currentStep.value) {
    emnConfigurationStore.setCurrentStep(nextStep);
  }

  switch (currentStep.value) {
    case Step.SITE:
      // No extra validation needed
      emnConfigurationStore.setCurrentStep(nextStep);
      break;
    case Step.CIDR:
      await validateCidr();
      if (isValidCidr.value) {
        emnConfigurationStore.setCurrentStep(nextStep);
      }
      break;
    case Step.IP_RANGE_START:
      await validateIpRangeStartChange();
      if (isValidIpRangeStart.value) {
        emnConfigurationStore.setCurrentStep(nextStep);
      }
      break;
    case Step.QUANTITY:
      // No extra validation needed
      emnConfigurationStore.setCurrentStep(nextStep);
      break;
  }
}

function handleSiteAgreementIdChange(siteAgreementId: string) {
  emnConfigurationStore.setSiteAgreementId(siteAgreementId);
}

function handleCidrChange(cidr: string) {
  emnConfigurationStore.setIsValidCidr(true);
  emnConfigurationStore.setCidr(cidr);
}

function handleIpRangeStartChange(ipRangeStart: string) {
  emnConfigurationStore.setIsValidIpRangeStart(true);
  emnConfigurationStore.setIpRangeStart(ipRangeStart);
}

function handleQuantityChange(quantity: number) {
  emnConfigurationStore.setQuantity(quantity);
}

async function validateCidr() {
  if (!emnConfigurationStore.cidr) {
    emnConfigurationStore.setIsValidCidr(false);
    return;
  }

  try {
    isValidating.value = true;
    const response = await corpProductOrder.EmnControllerService.validateIpAddressesFromCidr(props.scopeId, {
      cidr: emnConfigurationStore.cidr,
    });
    emnConfigurationStore.setIsValidCidr(true);
    emnConfigurationStore.setMaxQuantity(response.quantity);
    emnConfigurationStore.setIpRangeInfo(response);
    isValidating.value = false;
  } catch (e) {
    emnConfigurationStore.setIsValidCidr(false);
    isValidating.value = false;
  }
}

async function validateIpRangeStartChange() {
  if (!emnConfigurationStore.emnConfiguration.ipRangeStart) {
    emnConfigurationStore.setIsValidIpRangeStart(false);
    return;
  }

  try {
    isValidating.value = true;
    const response = await corpProductOrder.EmnControllerService.validateIpAddressesFromCidr(props.scopeId, {
      cidr: cidr.value,
      ipAddressStart: emnConfigurationStore.emnConfiguration.ipRangeStart,
    });
    emnConfigurationStore.setIsValidIpRangeStart(response.validipAddressStart);
    emnConfigurationStore.setMaxQuantity(response.quantity);
    isValidating.value = false;
  } catch (e) {
    emnConfigurationStore.setIsValidIpRangeStart(false);
    isValidating.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
