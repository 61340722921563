<template>
  <div>
    <div>
      <telia-link
        variant="standalone"
        href="#"
        @click.prevent="clearSelection"
        t-id="subscription-from-basket__back-link"
        class="subscription-from-basket__back-link"
        v-if="!showFullItemList"
      >
        <telia-icon name="chevron-left" size="md"></telia-icon>
        {{ t("SELECT_ANOTHER") }}
      </telia-link>
    </div>

    <SubscriptionFromBasketList
      :empty-list="showListIsEmptyMessage"
      :show-full-list="showFullItemList"
      :subscription-list="displaySubscriptionList"
      @select-subscription="selectSubscription"
    />

    <CommitmentSelector
      v-if="hardwareConfigurationStore.selectedSubscription"
      no-commitment-allowed
      :commitments="hardwareConfigurationStore.availableCommitmentOptions ?? []"
      :selected-commitment="hardwareConfigurationStore.commitmentPeriod"
      @select-commitment-period="handleCommitmentPeriod"
    />
    <PaymentOptions
      class="tc-padding__top--five"
      v-if="showUpfrontPayment"
      :is-upfront-payment="hardwareConfigurationStore.isUpfrontPayment"
      :increased-monthly-fee="increasedFeeForMobileDevice"
      t-id="subscription-from-basket__payment-options"
      @select-upfront-payment="handleSelectUpfrontPayment"
    />
    <p t-id="subscription-from-basket__increased-fee" v-if="hasCommitted">
      {{ t("INCREASED_FEE", { fee: increasedFeeForMobileDevice }) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { Basket, SubscriptionBundle } from "../../../../types";
import { BasketSubscriptionLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { SubscriptionLine, getStandaloneSubscriptionsFromBasket } from "../utils";
import { useHardwareStore } from "../../../../store/HardwareConfigurationStore";
import PaymentOptions from "./PaymentOptions.vue";
import CommitmentSelector from "../../shared/CommitmentSelector.vue";
import SubscriptionFromBasketList from "../SubscriptionFromBasketList.vue";

interface Props {
  subscriptions: SubscriptionBundle[];
  basket: Basket;
  increasedFeeForMobileDevice: number;
}

interface Configuration {
  subscriptionProductId?: string;
  subscriptionLine?: Required<BasketSubscriptionLineUI>;
  relationCount?: number;
}

const props = withDefaults(defineProps<Props>(), {
  increasedFeeForMobileDevice: 0,
});

const configuration = ref<Configuration>({});
const hardwareConfigurationStore = useHardwareStore();

const showListIsEmptyMessage = computed(() => {
  return !displaySubscriptionList.value || !displaySubscriptionList.value.length;
});
const displaySubscriptionList = computed((): SubscriptionLine[] => {
  return showFullItemList.value
    ? standaloneSubscriptions.value
    : standaloneSubscriptions.value.filter(
        (line) => line.id === configuration.value.subscriptionLine?.id
      );
});

const standaloneSubscriptions = computed(() => {
  const subscriptionsInBasket = getStandaloneSubscriptionsFromBasket(props.basket);
  return subscriptionsInBasket.filter((subscriptionProduct) =>
    hardwareConfigurationStore.isSubscriptionApplicableForVariant(subscriptionProduct.productCode)
  );
});
const showUpfrontPayment = computed(() => {
  return !showFullItemList.value && hardwareConfigurationStore.commitmentPeriod;
});
const showFullItemList = computed(() => {
  return configuration.value.subscriptionLine === undefined;
});
const hasCommitted = computed(() => {
  return !!hardwareConfigurationStore.commitmentPeriod;
});

function createSubscriptionToBasketConfiguration(
  line: Required<BasketSubscriptionLineUI>,
  relationCount: number
) {
  return {
    relationId: line.relationId,
    relationCount: relationCount + 1, // plus 1 for added hardware.
    existingBasketId: line.id,
  };
}

function selectSubscription(subscription: Required<BasketSubscriptionLineUI>) {
  const config = {
    subscriptionLine: subscription,
    subscriptionProductId: findSubscriptionInOffersByProductId(subscription.productCode)?.id,
    relationCount: getLinkedItemCount(subscription.relationId),
  };

  configuration.value = { ...configuration.value, ...config };
  const bundle = findOfferByProductId(subscription.productCode);
  if (bundle) {
    hardwareConfigurationStore.setSubscriptionBundle(bundle);
  }
  const subscriptionProduct = findSubscriptionInOffersByProductId(subscription.productCode);

  if (subscriptionProduct) {
    hardwareConfigurationStore.setSubscriptionProduct(subscriptionProduct);
  }

  if (configuration.value.subscriptionLine && configuration.value.relationCount) {
    const basketSubscriptionConfig = createSubscriptionToBasketConfiguration(
      configuration.value.subscriptionLine,
      configuration.value.relationCount
    );
    hardwareConfigurationStore.setRelationCount(basketSubscriptionConfig.relationCount);
    hardwareConfigurationStore.setRelationId(basketSubscriptionConfig.relationId ?? "");
    hardwareConfigurationStore.setExistingBasket(basketSubscriptionConfig.existingBasketId ?? "");
  }
}

function handleCommitmentPeriod(commitment: number) {
  hardwareConfigurationStore.setCommitment(commitment);
}

function handleSelectUpfrontPayment(isUpfrontPayment: boolean) {
  hardwareConfigurationStore.setUpfrontPayment(isUpfrontPayment);
}

function findOfferByProductId(productId: string): SubscriptionBundle | undefined {
  return props.subscriptions.find((subscriptionBundle) =>
    subscriptionBundle.products.find((product) => product.productCode === productId)
  );
}

function findSubscriptionInOffersByProductId(productId: string) {
  return props.subscriptions
    .flatMap((offer) => offer.products)
    .find((product) => product.productCode === productId);
}
function getLinkedItemCount(relationId: string) {
  let linkedItemCount = 0;
  let items = [
    ...props.basket.lines,
    ...props.basket.hardwareLines,
    ...props.basket.switchboardUserLines,
    ...props.basket.processLines,
  ];
  linkedItemCount += items.filter((line) => line.relationId === relationId).length;
  // Object.keys(props.basket).forEach((key) => {
  //   if (Array.isArray(props.basket[key])) {
  //     linkedItemCount += items.filter((line) => line.relationId === relationId).length;
  //   }
  // });
  return linkedItemCount;
}
function clearSelection() {
  hardwareConfigurationStore.clearSubscription();
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.subscription-from-basket {
  &__list {
    list-style-type: none;
    padding-left: 1em;
    border-top: 0.1rem solid #dbdbdb;

    &__basket-item {
      border-bottom: 0.1rem solid #dbdbdb;
      padding-top: $telia-spacing-8;
    }
  }
  &__back-link {
    padding: $telia-spacing-8 0;
    display: flex;
  }
}
</style>
