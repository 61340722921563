<template>
  <div class="agreement-selector">
    <telia-p>
      {{ t("agreementDownloadSelector.prices_shown_excl_vat") }}
      {{ documentOptions.length > 0 ? " " + t("agreementDownloadSelector.from") : "" }}
    </telia-p>
    <div
      v-if="!fetchingAgreementDocuments"
      ref="button"
      class="agreement-selector__label"
      :class="{
        'agreement-selector__label--has-dropown': hasDropdown,
      }"
      aria-haspopup="listbox"
      @click="selectorLabelClicked"
      @keydown.enter.prevent="selectorLabelClicked"
      tabindex="0"
    >
      <telia-p v-if="documentOptions.length" class="agreement-selector__label-text">
        {{ " " + documentOptions.length }}
        {{ t("agreementDownloadSelector.document") }}
      </telia-p>
      <telia-p class="agreement-selector__chevron-container" v-show="hasDropdown">
        <telia-icon
          class="agreement-selector__chevron"
          :name="showMenu ? 'chevron-up' : 'chevron-down'"
          :size="'sm'"
        />
      </telia-p>
    </div>

    <transition name="slide-fade">
      <div
        v-if="hasDropdown"
        class="agreement-selector__dropdown-wrapper"
        v-show="showMenu"
        :style="{ width: `calc(${buttonWidth}px + ${iconSize} + $telia-spacing-16)` }"
      >
        <div class="agreement-selector__dropdown-title">
          <telia-p>
            {{ t("agreementDownloadSelector.agreement_title") }}
          </telia-p>
        </div>
        <ul
          ref="options"
          role="listbox"
          tabindex="-1"
          class="agreement-selector__dropdown"
          @keyup.esc="toggleMenu"
          @keydown.enter.prevent="selectItem"
        >
          <li
            v-for="(option, index) in documentOptions"
            :key="index"
            class="agreement-selector__dropdown-item tc-body"
            @click="downloadAgreement(option.agreementDoc, option.documentTitle)"
          >
            <telia-p>{{ option.documentTitle }}</telia-p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { AgreementAsDocResponseUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { computed, onMounted, ref, watchEffect } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { useStateStore } from "../store/StateStore";
import { useAgreementDocuments } from "../service/ProductService";

interface Props {
  iconSize?: string;
}

const props = withDefaults(defineProps<Props>(), {
  iconSize: "2rem",
});

const buttonWidth = ref(0);
const showMenu = ref(false);
const button = ref<HTMLElement | null>();
const options = ref<HTMLElement | null>();
const stateStore = useStateStore();

const { getAgreementDocument, agreementDocumentResponse, fetchingAgreementDocuments } =
  useAgreementDocuments(stateStore.scopeId, stateStore.tscid);

const hasDropdown = computed(() => {
  return documentOptions.value.length > 1;
});

const documentOptions = computed((): AgreementAsDocResponseUI[] => {
  if (agreementDocumentResponse.value) {
    if (Array.isArray(agreementDocumentResponse.value)) {
      return agreementDocumentResponse.value;
    } else {
      return [agreementDocumentResponse.value];
    }
  }
  return [];
});

onMounted(() => {
  getAgreementDocument();
  buttonWidth.value = getButtonWidth();
});

function getButtonWidth() {
  return button.value?.offsetWidth || 100;
}

function toggleMenu() {
  if (!showMenu.value) {
    showMenu.value = true;
    if (options.value) {
      options.value.focus();
    }
  } else {
    showMenu.value = false;
    if (button.value) {
      button.value.focus();
    }
  }
}

watchEffect(() => {
  if (showMenu.value) {
    buttonWidth.value = getButtonWidth();
  }
});

function selectItem() {
  toggleMenu();
}

function downloadAgreement(base64?: string, title?: string) {
  if (base64 && title) {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64}`;
    link.setAttribute("download", title);
    link.click();
  }
}

function selectorLabelClicked() {
  if (hasDropdown.value) {
    toggleMenu();
  } else {
    const document = documentOptions.value[0] as Required<AgreementAsDocResponseUI>;
    downloadAgreement(document.agreementDoc, document.documentTitle);
  }
}
</script>

<style lang="scss">
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
.agreement-selector {
  display: flex;
  position: relative;

  &__label {
    cursor: pointer;
    display: flex;

    &:focus {
      outline-style: dotted;
      outline-width: 1px;
    }
    &-text {
      color: $telia-purple-500;
      white-space-collapse: preserve;
    }
  }

  &__chevron {
    transition:
      fill 0.3s ease,
      transform 0.3s ease;
    color: $telia-purple-500;

    &-container {
      display: flex;
      align-items: center;
      margin-left: $telia-spacing-4;
    }
  }

  &__dropdown-wrapper {
    position: absolute;
    top: 40px;
    min-width: 220px;
    max-width: calc(100vw - $telia-spacing-8);
    border: 2px solid $telia-purple-500;
    border-radius: 3px;
    background-color: $telia-white;
    transform: translateX(-1.4rem) translateY(-1.4rem);
    z-index: 10;

    @media screen and (max-width: 420px) {
      min-width: auto;
    }
  }

  &__dropdown-title {
    padding: $telia-spacing-8 $telia-spacing-8 0 1.7rem;
  }

  &__dropdown {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    outline: none;
    max-height: 24rem;
  }

  &__dropdown-item {
    color: var(--black);
    list-style: none;
    border: 0;
    padding: $telia-spacing-4 $telia-spacing-8 $telia-spacing-4 1.7rem;
    height: 100%;
    outline: none;
    font-weight: normal;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:focus,
    &:hover,
    &.is-active {
      background-color: $telia-gray-500;
    }

    &:first-child {
      margin-top: $telia-spacing-8;
    }

    &:last-child {
      margin-bottom: $telia-spacing-8;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s ease;
}

.slide-fade-enter-from {
  opacity: 0;
}

.slide-fade-leave-to {
  opacity: 0;
}
</style>
