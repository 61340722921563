import { defineStore, storeToRefs } from "pinia";
import { AccessoryListingConfiguration } from "../components/product-category/accessory/types";
import {
  StockStatusRequest,
  getAccessoriesProducts,
  getAccessoryCategories,
  getEmnSubscriptionProducts,
  getHardwareProducts,
  getMigrationProducts,
  getStockStatus,
  getSubscriptionProducts,
} from "../service/ProductService";
import {
  AccessoryProduct,
  HardwareBundle,
  HardwareProduct,
  NumberMigrationProduct,
  Product,
  ProductStockStatus,
  SubscriptionBundle,
} from "../types";
import { computed, onMounted, reactive, ref } from "vue";
import { TabCategory } from "../components/types";
import { useStateStore } from "./StateStore";
import { useGa4Helper } from "../utils/tracking/gaHelper";

interface Accessories {
  products: AccessoryProduct[];
  categories: string[];
  totalCount: number;
}

export const useProductStore = defineStore("product-store", () => {
  const stateStore = useStateStore();
  const { category, tscid, scopeId, productCode } = storeToRefs(stateStore);
  const { trackProductFetchFail } = useGa4Helper();

  const hardwareBundles = ref<HardwareBundle[]>([]);
  const subscriptionBundles = ref<SubscriptionBundle[]>([]);
  const emnBundles = ref<SubscriptionBundle[]>([]);
  const accessories = reactive<Accessories>({
    products: [],
    categories: [],
    totalCount: 0,
  });
  const hardwareStockStatus = ref<ProductStockStatus[]>([]);
  const numberMigrationProducts = ref<NumberMigrationProduct[]>([]);

  const fetching = reactive({
    hardware: false,
    subscriptions: false,
    accessories: false,
    migrationProducts: false,
    emn: false,
    hardwareStockStatus: false,
    accessoryStockStatus: false,
  });

  const categoryErrors = ref<{ category: TabCategory; error: string }[]>([]);
  const numberMigrationError = ref(false);

  onMounted(() => {
    fetchProductsForTscid();
  });

  async function fetchProductsForTscid() {
    await Promise.all([
      setAccessories(),
      setHardwareProducts(),
      setSuscriptionProducts(),
      setEmnSubscriptions(),
    ]);
  }

  const selectedProduct = computed((): Product | undefined => {
    return getProductByProductCode.value(productCode.value);
  });

  //Getters
  const getProductByProductCode = computed<(productCode: string) => Product | undefined>(() => {
    const activeCategory = category.value;
    return (productCode: string) => {
      switch (activeCategory as TabCategory) {
        case "mobilabonnemang":
          return subscriptionBundles.value
            .flatMap((bundle) => bundle.products)
            .find((product) => product.productCode === productCode);

        case "mobiltelefoner":
          return hardwareBundles.value
            .flatMap((bundle) => bundle.products)
            .find((product) => product.productCode === productCode);
        case "tillbehor":
          return accessories.products.find((product) => product.productCode === productCode);
        case "emn":
          return emnBundles.value
            .flatMap((bundle) => bundle.products)
            .find((product) => product.productCode === productCode);
        default:
          return undefined;
      }
    };
  });

  const getSubscriptionBundleForSubscription = computed<
    (productCode: string) => SubscriptionBundle | undefined
  >(() => {
    return (productCode: string) =>
      subscriptionBundles.value.find((bundle) =>
        bundle.products.some(
          (subscriptionProduct) => subscriptionProduct.productCode === productCode
        )
      );
  });

  const getOfferCodeForEMNSubscription = computed<(subscriptionProductCode: string) => string>(
    () => {
      return (subscriptionProductCode: string) => {
        const bundle = emnBundles.value.find((bundle) =>
          bundle.products.some(
            (subscriptionProduct) => subscriptionProduct.productCode === subscriptionProductCode
          )
        );
        return bundle?.productCode ?? "";
      };
    }
  );

  const voiceSubscriptionBundles = computed(() => {
    return subscriptionBundles.value.filter((bundle) =>
      bundle.products.some((product) => product.category === "mobilevoicesubscription")
    );
  });

  //Actions
  async function setHardwareProducts() {
    fetching.hardware = true;
    try {
      hardwareBundles.value = await getHardwareProducts(scopeId.value, tscid.value);
      getStockStatusBundles(hardwareBundles.value);
    } catch (error: any) {
      categoryErrors.value.push({ category: "mobiltelefoner", error: "hardwareFetch" });
      if (error.errorId) {
        trackProductFetchFail("mobile_phones");
      }
    }
    fetching.hardware = false;
  }

  async function getStockStatusBundles(hardwareBundles: HardwareBundle[]) {
    const products = hardwareBundles.flatMap((bundle) => bundle.products);
    const sapIds = products.flatMap((product) => product.variants).map((variant) => variant.sapId);
    fetching.hardwareStockStatus = true;
    getStockStatusForProducts("hardware", sapIds);
  }

  async function getStockStatusForProducts(type: "hardware" | "accessory", sapIds: string[]) {
    const stockRequests: StockStatusRequest[] = sapIds.map((sapId) => ({
      sapId,
      quantity: 1,
    }));

    getStockStatus(stockRequests)
      .then((response) => {
        hardwareStockStatus.value.push(...response);
      })
      .catch((e) => {
        console.log("Stock fetch error");
      })
      .finally(() => {
        if (type === "accessory") {
          fetching.accessoryStockStatus = false;
        } else {
          fetching.hardwareStockStatus = false;
        }
      });
  }

  async function setSuscriptionProducts() {
    fetching.subscriptions = true;
    try {
      subscriptionBundles.value = await getSubscriptionProducts(scopeId.value, tscid.value);
    } catch (error: any) {
      categoryErrors.value.push({ category: "mobilabonnemang", error: "subscriptionFetch" });
      if (error.errorId) {
        trackProductFetchFail("mobile_subscriptions");
      }
    }
    fetching.subscriptions = false;
  }

  async function setMigrationProducts() {
    fetching.migrationProducts = true;
    try {
      numberMigrationProducts.value = await getMigrationProducts(scopeId.value, tscid.value);
    } catch (error) {
      // TODO funnel fail, keep phone number QUESTION
      numberMigrationError.value = true;
    }
    fetching.migrationProducts = false;
  }

  async function setEmnSubscriptions() {
    fetching.emn = true;
    try {
      emnBundles.value = await getEmnSubscriptionProducts(scopeId.value, tscid.value);
    } catch (error) {
      trackProductFetchFail("emn");
    }
    fetching.emn = false;
  }

  async function setAccessories(
    filter: AccessoryListingConfiguration = {
      accessoryCategoryFilter: "",
      accessoryDeviceFilter: [],
      pagination: {
        page: 1,
        pageSize: 15,
      },
      sortBy: "NEWEST",
    }
  ) {
    fetching.accessories = true;
    try {
      await Promise.all([
        setAccessoryProducts(filter),
        setAccessoryCategories(scopeId.value, tscid.value),
      ]);
    } catch (error: any) {
      categoryErrors.value.push({ category: "tillbehor", error: "accessoriesFetch" });
      if (error.errorId) {
        trackProductFetchFail("accessories");
      }
    }
    fetching.accessories = false;
  }

  async function setAccessoryProducts(filter: AccessoryListingConfiguration) {
    const accessoryRepsonse = await getAccessoriesProducts(scopeId.value, tscid.value, filter);
    accessories.products = accessoryRepsonse.accessories;
    accessories.totalCount = accessoryRepsonse.totalItemCount;
    getStockStatusForProducts(
      "accessory",
      accessories.products.map((product) => product.sapId)
    );
  }
  async function setAccessoryCategories(scopeId: string, tscid: string) {
    accessories.categories = await getAccessoryCategories(scopeId, tscid);
  }

  return {
    fetchProductsForTscid,
    setHardwareProducts,
    setAccessories,
    setSuscriptionProducts,
    setMigrationProducts,
    selectedProduct,
    getProductByProductCode,
    getSubscriptionBundleForSubscription,
    getOfferCodeForEMNSubscription,
    voiceSubscriptionBundles,
    hardwareBundles,
    subscriptionBundles,
    emnBundles,
    numberMigrationProducts,
    accessories,
    fetching,
    categoryErrors,
    numberMigrationError,
    hardwareStockStatus,
  };
});
