export const SUBSCRIPTION_BRAND = "Telia";
export const MYBUSINESS_SALESFLOW = "MyBusiness-Salesflow";
export const SUBSCRIPTION_LISTNAME = "Beställ produkter och tjänster - Abonnemang ";
export const HARDWARE_LISTNAME = "Beställ produkter och tjänster - Mobiltelefoner";
export const ACCESSORY_LISTNAME = "Beställ produkter och tjänster - Tillbehör";
export const EMN_LISTNAME = "Beställ produkter och tjänster - EMN";
export const ADD_TO_CART_FAIL = "Kunde inte lägga produkt i varukorg";
export const PHONE_NUMBER_FETCH_FAIL = "Något gick fel vid hämtningen av telefonnummer";
export const PRODUCT_FETCH_FAIL = "Något gick fel vid hämtningen av produkter";
export const BASKET_FETCH_FAIL = "Något gick fel vid hämtningen av varukorgen";
