import { corpNumberBooking } from "@telia/b2b-rest-client";
import { BookFixedNumberDTO } from "@telia/b2b-rest-client/dist/corp-number-booking";
import { IFixedNumberBooking, SwitchboardType } from "../models";
import { computed, ref } from "vue";
import { useSwitchboardStore } from "../../../../../store/SwitchboardConfigurationStore";
interface BookFixedNumberRequest {
  scopeId: string;
  tscid: string;
  instanceId: string;
  switchboardType: SwitchboardType;
  searchString?: string;
}

export function useFixedNumberHelper() {
  const isFetchingNumbers = ref(false);
  const fetchError = ref(false);
  const noNumbersAvailable = ref(false);
  const noNumbersMatchingSearch = ref(false);
  const booking = ref<BookFixedNumberDTO>();

  const errorMessage = computed<string | undefined>(() => {
    if (isFetchingNumbers.value) {
      return undefined;
    }
    if (fetchError.value) {
      return "switchboardFixedNumber.numberFetchError";
    }
    if (noNumbersAvailable.value) {
      return "switchboardFixedNumber.noNumbersAvailable";
    }
    if (noNumbersMatchingSearch.value) {
      return "switchboardFixedNumber.noNumbersMatchingSearch";
    }
    return undefined;
  });

  function searchReset() {
    fetchError.value = false;
    isFetchingNumbers.value = true;
    noNumbersAvailable.value = false;
    noNumbersMatchingSearch.value = false;
  }

  async function bookFixedNumber(fixedNumberBooking: BookFixedNumberRequest): Promise<void> {
    try {
      searchReset();
      const result =
        await corpNumberBooking.PublicFixedNumberBookingControllerService.bookFixedNumber(
          fixedNumberBooking.scopeId,
          fixedNumberBooking.tscid,
          fixedNumberBooking.instanceId,
          fixedNumberBooking.switchboardType,
          fixedNumberBooking.searchString
        );
      booking.value = result;
      if (result.numbers && result.numbers.length === 0) {
        if (fixedNumberBooking.searchString) {
          noNumbersMatchingSearch.value = true;
        } else {
          noNumbersAvailable.value = true;
        }
      }
    } catch (e) {
      fetchError.value = true;
    } finally {
      isFetchingNumbers.value = false;
    }
  }

  const releaseAndBookfixedNumber = async (
    fixedNumberBooking: ReleaseAndBookFixedNumberRequest
  ): Promise<void> => {
    try {
      searchReset();
      const result =
        await corpNumberBooking.PublicFixedNumberBookingControllerService.releaseAndBookFixedNumber(
          fixedNumberBooking.scopeId,
          fixedNumberBooking.tscid,
          fixedNumberBooking.instanceId,
          fixedNumberBooking.bookingId,
          fixedNumberBooking.switchboardType,
          fixedNumberBooking.searchString
        );
      booking.value = result;

      if (result.numbers && result.numbers.length === 0) {
        if (fixedNumberBooking.searchString) {
          noNumbersMatchingSearch.value = true;
        } else {
          noNumbersAvailable.value = true;
        }
      }
    } catch (error) {
      fetchError.value = true;
    } finally {
      isFetchingNumbers.value = false;
    }
  };

  const releaseFixedNumbers = async (
    fixedNumberBooking: ReleaseAndBookFixedNumberRequest
  ): Promise<void> => {
    try {
      corpNumberBooking.PublicFixedNumberBookingControllerService.releaseFixedNumber(
        fixedNumberBooking.scopeId,
        fixedNumberBooking.tscid,
        fixedNumberBooking.instanceId,
        fixedNumberBooking.bookingId,
        fixedNumberBooking.switchboardType
      );
      booking.value = undefined;
    } catch {
      throw new Error("Could not release booking");
    }
  };

  const extendBooking = async (
    fixedNumberBooking: ReleaseAndBookFixedNumberRequest
  ): Promise<void> => {
    try {
      return await corpNumberBooking.PublicFixedNumberBookingControllerService.extendFixedNumbers(
        fixedNumberBooking.scopeId,
        fixedNumberBooking.tscid,
        fixedNumberBooking.instanceId,
        fixedNumberBooking.bookingId,
        fixedNumberBooking.switchboardType
      );
    } catch (e) {
      new Error("Could not extend booking");
    }
  };

  return {
    booking,
    errorMessage,
    isFetchingNumbers,
    noNumbersAvailable,
    extendBooking,
    bookFixedNumber,
    releaseAndBookfixedNumber,
    releaseFixedNumbers,
  };
}

const bookFixedSwitchboardNumber = async (
  fixedNumberBooking: BookFixedNumberRequest
): Promise<BookFixedNumberDTO> => {
  try {
    return await corpNumberBooking.PublicFixedNumberBookingControllerService.bookFixedNumber(
      fixedNumberBooking.scopeId,
      fixedNumberBooking.tscid,
      fixedNumberBooking.instanceId,
      fixedNumberBooking.switchboardType,
      fixedNumberBooking.searchString
    );
  } catch (e) {
    throw new Error("switchboardFixedNumber.numberFetchError");
  }
};
interface ReleaseAndBookFixedNumberRequest extends IFixedNumberBooking {
  bookingId: string;
}
const releaseAndBookFixedSwitchboardNumber = async (
  fixedNumberBooking: ReleaseAndBookFixedNumberRequest
): Promise<BookFixedNumberDTO> => {
  try {
    const result =
      await corpNumberBooking.PublicFixedNumberBookingControllerService.releaseAndBookFixedNumber(
        fixedNumberBooking.scopeId,
        fixedNumberBooking.tscid,
        fixedNumberBooking.instanceId,
        fixedNumberBooking.bookingId,
        fixedNumberBooking.switchboardType,
        fixedNumberBooking.searchString
      );
    return result;
  } catch (e) {
    throw new Error("switchboardFixedNumber.numberFetchError");
  }
};
const releaseFixedSwitchboardNumbers = async (
  fixedNumberBooking: ReleaseAndBookFixedNumberRequest
): Promise<void> => {
  try {
    corpNumberBooking.PublicFixedNumberBookingControllerService.releaseFixedNumber(
      fixedNumberBooking.scopeId,
      fixedNumberBooking.tscid,
      fixedNumberBooking.instanceId,
      fixedNumberBooking.bookingId,
      fixedNumberBooking.switchboardType
    );
  } catch {
    throw new Error("Could not release booking");
  }
};

const extendBooking = async (
  fixedNumberBooking: ReleaseAndBookFixedNumberRequest
): Promise<void> => {
  try {
    return await corpNumberBooking.PublicFixedNumberBookingControllerService.extendFixedNumbers(
      fixedNumberBooking.scopeId,
      fixedNumberBooking.tscid,
      fixedNumberBooking.instanceId,
      fixedNumberBooking.bookingId,
      fixedNumberBooking.switchboardType
    );
  } catch (e) {
    new Error("Could not extend booking");
  }
};

export {
  bookFixedSwitchboardNumber,
  releaseAndBookFixedSwitchboardNumber,
  releaseFixedSwitchboardNumbers,
  extendBooking,
};
