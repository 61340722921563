<template>
  <div t-id="mybd-new-number-from-series">
    <b2x-input-suggestions
      :suggestions="JSON.stringify(suggestions)"
      :value="inputValue"
      @inputValue="handleInput"
      @focus="setSuggestions"
      @blur="clearSuggestions"
      @suggestionSelected="handleSelectSuggestion"
    />
    <telia-p v-if="selectedMsisdn">
      {{ t("newNumber.phoneNumberChosenNumber") }}
      <strong>
        {{ formatPhoneNumber(selectedMsisdn) }}
      </strong>
    </telia-p>

    <telia-notification
      heading-tag="h3"
      v-if="showShortNoticeOnReservation"
      t-id="short-reservation-time-remaining-notice"
      status="information"
    >
      <span slot="heading">{{
        t("newNumberFromSeries.phoneNumberFromSeriesShortReservationHeader")
      }}</span>
      <span slot="content">
        <telia-p>
          {{ shortNoticeOnReservationText }}
        </telia-p>
      </span>
    </telia-notification>
  </div>
</template>
<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";
import { computed, onMounted, onBeforeUnmount, ref } from "vue";
import { corpNumberBooking } from "@telia/b2b-rest-client";
import { ReservedNumber, BookedReservedNumber } from "../../types";
import { formatToMsisdn, formatPhoneNumber } from "@telia/b2x-phone-number-format";
import moment from "moment";
import { useStateStore } from "../../../../../store/StateStore";

interface Props {
  scopeId: string;
  tscid: string;
  extendBookingTimeoutMinutes?: number;
}

const props = withDefaults(defineProps<Props>(), {
  extendBookingTimeoutMinutes: 10,
});
const emit = defineEmits<{
  (e: "change", msisdn: string): void;
  (e: "number-series-error"): void;
  (e: "empty-number-series"): void;
  (e: "new-booking-id", bookingId: string): void;
}>();

const t = translate;

const stateStore = useStateStore();
const selectedMsisdn = ref<string>();
const inputValue = ref<string>("");
const reservedNumbers = ref<ReservedNumber[]>([]);
const bookingId = ref<string>();
const extendTimer = ref<number>();
const suggestions = ref<string[]>([]);

const formattedReservedNumbers = computed(() => {
  return reservedNumbers.value.map((number) => formatPhoneNumber(number.msisdn));
});

const filteredReservedNumbers = computed(() => {
  return formattedReservedNumbers.value.filter((number) =>
    number.replace(/\s+/g, "").includes(inputValue.value)
  );
});

const remainingDaysOfSelectedNumber = computed(() => {
  const number = reservedNumbers.value.find((number) => number.msisdn === selectedMsisdn.value);

  if (!number || number.reservedTo === undefined) {
    return null;
  }

  return moment(number.reservedTo).diff(moment(), "days");
});

const showShortNoticeOnReservation = computed(() => {
  return remainingDaysOfSelectedNumber.value !== null && remainingDaysOfSelectedNumber.value < 7;
});
const shortNoticeOnReservationText = computed(() => {
  let days;
  if (remainingDaysOfSelectedNumber.value) {
    days = remainingDaysOfSelectedNumber.value + 1; // + 1 due to expires end of day
  } else {
    days = 1;
  }
  return t(
    days !== 1
      ? "newNumberFromSeries.phoneNumberReservationNoticePlural"
      : "newNumberFromSeries.phoneNumberReservationNotice",
    { days }
  );
});

onMounted(async () => {
  window.addEventListener("beforeunload", onUnload);
  await getReservedNumbers();
});
onBeforeUnmount(async () => {
  removeExtendBookingTimer();

  //TODO: Remove this when number booking solution is decided
  // if (bookingId.value) {
  //   await corpNumberBooking.PublicNumberBookingControllerService.releaseBooking(
  //     props.scopeId,
  //     props.tscid,
  //     bookingId.value
  //   );
  // }
  window.removeEventListener("beforeunload", onUnload);
});

async function getReservedNumbers() {
  try {
    await corpNumberBooking.PublicNumberBookingControllerService.getReservedNumbersForTscid(
      props.scopeId,
      props.tscid,
      stateStore.customerNumber ? stateStore.customerNumber : ""
    ).then((res) => {
      reservedNumbers.value = res.filter(
        (number) => number.reservedStatus === "FREE"
      ) as ReservedNumber[];
    });
  } catch (error) {
    emit("number-series-error");
  } finally {
    if (reservedNumbers.value.length === 0) {
      emit("empty-number-series");
    }
  }
}

async function selectNumber(number?: string) {
  if (number && selectedMsisdn.value !== number) {
    try {
      await corpNumberBooking.PublicNumberBookingControllerService.bookReservedNumber(
        props.scopeId,
        props.tscid,
        number,
        bookingId.value
      ).then((res) => {
        bookingId.value = res.bookingId;
        if (bookingId.value) {
          emitNumber(number);
          extendBookingOnTimeout();
        } else {
          emit("number-series-error");
        }
      });
      selectedMsisdn.value = number;
    } catch (error) {
      emit("number-series-error");
    }
  }
}
function emitNumber(number: string) {
  if (bookingId.value) {
    emit("change", number);
    emitBookingId(bookingId.value);
  }
}
function emitBookingId(bookingIdParam: string) {
  emit("new-booking-id", bookingIdParam);
}
function extendBookingOnTimeout() {
  const timeout = props.extendBookingTimeoutMinutes * 60 * 1000; // minutes to ms
  extendTimer.value = setTimeout(extendBooking, timeout, bookingId.value);
}
function removeExtendBookingTimer() {
  clearTimeout(extendTimer.value);
  extendTimer.value = undefined;
}

async function extendBooking(bookingIdParam: string) {
  try {
    await corpNumberBooking.PublicNumberBookingControllerService.extendBooking(
      props.scopeId,
      props.tscid,
      bookingIdParam
    );
  } catch (e) {
    return;
  }
  extendBookingOnTimeout();
}

function setSuggestions() {
  suggestions.value = filteredReservedNumbers.value;
}

function clearSuggestions() {
  suggestions.value = [];
}

function handleInput(event: { detail: string }) {
  inputValue.value = event.detail;
  setSuggestions();
  const matchingNumber = filteredReservedNumbers.value.find(
    (number) => number === inputValue.value
  );
  selectNumber(formatToMsisdn(matchingNumber));
}

function handleSelectSuggestion(event: { detail: string }) {
  setSuggestions();
  inputValue.value = event.detail;
  selectNumber(formatToMsisdn(event.detail));
}

function onUnload() {
  // if (bookingId.value) {
  //   corpNumberBooking.PublicNumberBookingControllerService.releaseBooking(
  //     props.scopeId,
  //     props.tscid,
  //     bookingId.value
  //   );
  // }
  removeExtendBookingTimer();
}
</script>
