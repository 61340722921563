<template>
  <div t-id="product-listing" class="mybd-product-listing" tab-index="1">
    <b2x-hardware-product-card
      :t-id="`product-listing__hardware-card-${product.productCode}`"
      :heading="product.name"
      :subheading="product.brand"
      :href="link"
      :price-heading="`Fr. ${hardwareMinPrice}`"
      :image="getImageUrl"
      :key="product.productCode"
      @click.prevent="$emit('click')"
    >
      <div class="card-slot-content" slot="colors">
        <div class="color-container">
          <telia-color-dot
            v-for="color in colorOptions"
            :key="color.name"
            :color="color.hex"
            size="xs"
          />
        </div>
        <StockStatus
          v-if="stockStatusEnabled"
          :status="stockStatus?.status ?? 'UNKNOWN_STOCK'"
          :availableFrom="stockStatus?.availableFrom"
          :loading="loadingStockStatus"
        />
      </div>
    </b2x-hardware-product-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { currentLanguage } from "@telia/b2b-i18n";
import { GetHardwareProductsHardwareProductUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import phone from "../../../../assets/TC_Mobile.svg";
import { ProductStockStatus } from "../../../types";
import StockStatus from "../../product-configurator/shared/StockStatus.vue";
import { useFlag } from "@unleash/proxy-client-vue";

interface Props {
  product: Required<GetHardwareProductsHardwareProductUI>;
  stockStatus?:
    | ProductStockStatus
    | { status: "PRE_SALES" | "UNKNOWN_STOCK"; availableFrom: string | undefined };
  loadingStockStatus: boolean;
}
const props = defineProps<Props>();
const stockStatusEnabled = useFlag("mco-listing-stock-status");

const minPrice = computed(() => {
  return Math.min(...props.product.variants.map((variant) => Number(variant.unitPrice)));
});
const link = computed(() => {
  return window.location.href;
});
const hardwareMinPrice = computed(() => {
  return formatCurrency(minPrice.value);
});
const colorOptions = computed(() => {
  return [...new Set(props.product.variants.map((variant) => variant.colorHex))].map(
    (option, index) => ({ name: index, hex: option })
  );
});
const getImageUrl = computed(() => {
  return props.product.imageUrl
    ? `https://www.telia.se/${props.product.imageUrl}?h=250&fit=fill`
    : phone;
});

function formatCurrency(amount: number) {
  return new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);
}
</script>
<style lang="scss" scoped>
.card-slot-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.color-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}
</style>
