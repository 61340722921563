<template>
  <div class="configurator-row">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";

.configurator-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $telia-spacing-16;
  margin-bottom: $telia-spacing-32;

  & .radio-card {
    height: 100%;
  }

  @media screen and (max-width: $telia-fluid-breakpoint-medium) {
    flex-direction: column;
  }
}

.title {
  margin-bottom: $telia-spacing-24;
}
</style>
