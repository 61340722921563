<template>
  <div v-if="images.length" data-testid="image-gallery" class="image-gallery">
    <div class="image-gallery__display image-gallery__flex-center">
      <img
        data-testid="image-gallery-display"
        class="image-gallery__image"
        :src="`https://www.telia.se/${images[currentDisplayIndex].imageUrl}`"
        :alt="images[currentDisplayIndex].color"
        draggable="false"
      />
    </div>

    <div class="image-gallery__choices-wrapper image-gallery__choice-selected">
      <div class="image-gallery__navigation">
        <input
          class="image-gallery__choice-input"
          data-testid="image-gallery-left-chevron"
          id="left-chevron"
          type="button"
          @click="setCurrentDisplayIndex(--currentDisplayIndex)"
          :disabled="shouldDisableNavigation"
        />
        <label class="image-gallery__flex-center" for="left-chevron">
          <telia-icon name="chevron-left" size="lg" />
        </label>
      </div>
      <div class="image-gallery__choices-carousel">
        <ul
          class="image-gallery__choices-slider"
          :style="{ transform: 'translateX(' + getSliderOffset() + '%)' }"
        >
          <li
            v-for="(image, index) of images"
            :key="index"
            class="image-gallery__choice image-gallery__flex-center"
          >
            <input
              class="image-gallery__choice-input"
              :id="String(index)"
              type="button"
              name="image-choice"
              @click="setCurrentDisplayIndex(Number(index))"
            />
            <label :class="getStyle(Number(index))" :for="String(index)">
              <img
                class="image-gallery__image"
                :data-testid="`image-gallery-choice-${String(index)}`"
                :src="`https://www.telia.se/${image.thumbnailUrl}`"
                :alt="image.color"
                draggable="false"
              />
            </label>
          </li>
        </ul>
      </div>
      <div class="image-gallery__navigation image-gallery__flex-center">
        <input
          class="image-gallery__choice-input"
          data-testid="image-gallery-right-chevron"
          id="right-chevron"
          type="button"
          @click="setCurrentDisplayIndex(++currentDisplayIndex)"
          :disabled="shouldDisableNavigation"
        />
        <label class="image-gallery__flex-center" for="right-chevron">
          <telia-icon name="chevron-right" size="lg" />
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Image {
  thumbnailUrl: string;
  imageUrl: string;
  color?: string;
}
interface Props {
  images: Image[];
  selectedColor?: string;
}
const props = defineProps<Props>();

const currentDisplayIndex = ref<number>(0);
const shouldDisableNavigation = computed(() => {
  return props.images.length === 1;
});

function setCurrentDisplayIndex(index: number) {
  currentDisplayIndex.value = modulo(index);
}
function getSliderOffset(): number {
  return props.images.length === 3 ? 0 : currentDisplayIndex.value * -33 + 33;
}
function getStyle(index: number): string {
  return `image-gallery__choice-label image-gallery__flex-center ${
    index === modulo(currentDisplayIndex.value) ? "image-gallery__choice-selected" : ""
  }`;
}
function modulo(value: number): number {
  const limit = props.images.length;
  return ((value % limit) + limit) % limit;
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.image-gallery {
  border: 1px solid #dcdce1;
  border-radius: 1.6rem;
  padding: $telia-spacing-12;
  margin-bottom: 20px;
}

.image-gallery__display {
  height: 25rem;
  margin-bottom: 2rem;
}

.image-gallery__image {
  max-width: 100%;
  max-height: 100%;
}

.image-gallery__choices-wrapper {
  width: 275px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 32px 210px 32px;
  margin-bottom: 2rem;
}

.image-gallery__choices-carousel {
  height: fit-content;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.image-gallery__choices-slider {
  transition: 500ms;
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 70px;
  margin: 0px;
}

.image-gallery__choice {
  height: 70px;
  width: 70px;
}

.image-gallery__choice-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
}

.image-gallery__choice-label {
  transition: 500ms;
  transform: scale(0.75, 0.75);
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.image-gallery__choice-selected {
  transform: scale(1.25, 1.25);
}

.image-gallery__flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery__navigation label {
  cursor: pointer;
  height: 100%;
}

.image-gallery__choice-input:disabled + label {
  color: var(--dark-grey);
  cursor: not-allowed;
}
</style>
