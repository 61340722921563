<template>
  <div>
    <div class="multiselect-dropdown-container" t-id="multiselect-dropdown">
      <div class="multiselect-dropdown-inputContainer">
        <telia-text-input
          id="multiselect-dropdown-input"
          t-id="multiselect-dropdown-input"
          @click="onFocus()"
          @focus="onFocus()"
          @focusout="onBlur"
          @keydown="onKeyDown"
          @input="onInput($event.target.value)"
        />
        <telia-icon :class="getClassesForArrow()" name="chevron-down" />
      </div>
      <ul
        t-id="multiselect-dropdown-suggestions"
        class="multiselect-dropdown-suggestions"
        @mouseover="onMouseOver()"
        v-if="showSuggestions"
      >
        <li
          v-for="(option, index) in filteredOptions"
          :t-id="`multiselect-dropdown-option-${option.name}`"
          :key="option.value"
          :class="getClassesForSuggestion(Number(index))"
          @mousedown.prevent="clickOption(option)"
        >
          <div>
            <telia-icon v-show="option.checked" size="sm" name="check-bold" />
          </div>
          <telia-p v-html="formattedDisplayNameWithMatchInBold(option)"></telia-p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Option {
  name: string;
  value: string;
  checked: boolean;
}
interface Props {
  options: Option[];
}
const props = defineProps<Props>();
const emit = defineEmits<{ (e: "selected-option", value: string): void }>();

const highlightedSuggestion = ref<number | undefined>(undefined);
const inputText = ref<string>("");
const showSuggestions = ref(false);

const filteredOptions = computed(() => {
  return inputText.value.length === 0
    ? props.options.slice(0, 100)
    : props.options
        .filter((option) => option.name.toLowerCase().includes(inputText.value.toLowerCase()))
        .slice(0, 100);
});

function onBlur() {
  showSuggestions.value = false;
  clearHighlightedSuggestion();
}
function onFocus() {
  showSuggestions.value = true;
}
function onInput(value: string) {
  showSuggestions.value = true;
  inputText.value = value;
}
function onKeyDown(e: any) {
  switch (e.keyCode) {
    case 27: // escape
      showSuggestions.value = false;
      clearHighlightedSuggestion();
      break;
    case 40: // down
      showSuggestions.value = true;
      incrementHighlightedSuggestion();
      break;
    case 38:
      decrementHighlightedSuggestion();
      break;
    case 13: // enter
      selectHighlightedSuggestion();
      break;
  }
}
function onMouseOver() {
  clearHighlightedSuggestion();
}
function escapeRegExpChars(text: string) {
  return text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
}
function formattedDisplayNameWithMatchInBold(option: Option) {
  const displayname = option.name;
  return inputText.value
    ? displayname.replace(
        new RegExp(escapeRegExpChars(inputText.value), "ig"),
        (m) => `<strong>${m}</strong>`
      )
    : displayname;
}
function getClassesForArrow() {
  let classes = ["multiselect-dropdown-arrowDown"];

  if (showSuggestions.value) {
    classes.push("multiselect-dropdown-arrowDown--rotate");
  }

  return classes;
}
function getClassesForSuggestion(index: number) {
  let classes = ["multiselect-dropdown-suggestions-suggestion"];

  if (index === highlightedSuggestion.value) {
    classes.push("multiselect-dropdown-suggestions-suggestion--highlighted");
  }

  return classes;
}
function incrementHighlightedSuggestion() {
  if (highlightedSuggestion.value === undefined) {
    highlightedSuggestion.value = 0;
  } else {
    highlightedSuggestion.value = (highlightedSuggestion.value + 1) % filteredOptions.value.length;
  }
}
function decrementHighlightedSuggestion() {
  if (highlightedSuggestion.value === undefined || highlightedSuggestion.value === 0) {
    highlightedSuggestion.value = filteredOptions.value.length - 1;
  } else {
    highlightedSuggestion.value--;
  }
}
function selectHighlightedSuggestion() {
  if (highlightedSuggestion.value !== undefined) {
    const option = filteredOptions.value[highlightedSuggestion.value];
    clickOption(option);
  }
}
function clearHighlightedSuggestion() {
  highlightedSuggestion.value = undefined;
}
function clickOption(option: Option) {
  emit("selected-option", option.value);
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.multiselect-dropdown-container {
  position: relative;
}

.multiselect-dropdownButton-container {
  display: flex;
  align-items: center;
  margin-bottom: $telia-spacing-8;
}

.multiselect-dropdown-inputContainer {
  position: relative;
  margin-bottom: $telia-spacing-8;
}

.multiselect-dropdown-arrowDown {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 4px;
  line-height: 16px;
  fill: $telia-black;
  margin-right: 12px;
  transition: transform 0.3s ease;
  cursor: pointer;
  pointer-events: none;

  position: absolute;
  top: 12px;
  right: 0;
}

.multiselect-dropdown-arrowDown--rotate {
  transform: rotateX(180deg);
}

.multiselect-dropdown-suggestions {
  position: absolute;
  z-index: 98;
  top: 100%;
  overflow: auto;

  list-style: none;
  margin: 0;
  width: calc(100% - 0.2rem);
  padding: 0;
  color: $telia-black;
  background-color: $telia-white;

  border: 0.1rem solid $telia-purple-500;
  border-top: 0;
  max-height: 33vh;
  border-radius: 0.2rem;
}

.multiselect-dropdown-suggestions-suggestion {
  padding: 1.5rem $telia-spacing-12;
  cursor: pointer;
  display: grid;
  grid-template-columns: 3rem auto;
}

.multiselect-dropdown-suggestions-suggestion:hover {
  background-color: $telia-gray-500;
}

.multiselect-dropdown-suggestions-suggestion--highlighted {
  background-color: $telia-gray-500;
}
</style>
