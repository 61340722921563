import { SimChoiceType } from "./constants";
import { required, validEmail, validIcc } from "./validators";
import { asyncEvery } from "./utils";
import { ValidationResponse } from "./ValidationResponse";

const ValidatorMap = {
  ["existing-sim"]: [required, validIcc],
  ["new-esim"]: [required, validEmail],
};

export async function isValid(
  type: SimChoiceType,
  payload?: { iccNumber: string; deliveryEmailAddress: string }
): Promise<ValidationResponse> {
  if (type === "new-sim") {
    return { isValid: true };
  }
  if (!payload) {
    throw new Error("No payload");
  }
  if (type === "existing-sim") {
    return await asyncEvery([required, validIcc], payload.iccNumber);
  }
  if (type === "new-esim") {
    return await asyncEvery(ValidatorMap[type], payload.deliveryEmailAddress);
  }
  throw new Error("Invalid sim type");
}
