export function isMobileBroadbandSubscription(category: string) {
  return equalsIgnoreCase(category, "mobilebroadbandsubscription");
}

export function isMobileVoiceSubscription(category: string) {
  return equalsIgnoreCase(category, "mobilevoicesubscription");
}

export function isHardwareBundle(category: string) {
  return equalsIgnoreCase(category, "hardware_bundle");
}

export function isAccessory(subCategory?: string) {
  return equalsIgnoreCase(subCategory, "accessories");
}

export function getGAProductListName(category: string, subCategory?: string) {
  if (isHardwareBundle(category)) return "Mobile Devices";
  else if (isAccessory(subCategory)) return "Accessories";
  else return "Mobile Subscriptions"; // product list where click occured
}

function equalsIgnoreCase(string1?: string, string2?: string) {
  return !!string1 && !!string2 && string1.toLowerCase() === string2.toLowerCase();
}
