<template>
  <div
    t-id="addon-datasims"
    class="addon-datasims"
    :class="{ 'addon-datasims--disabled': disabled }"
  >
    <div class="addon-datasims__container">
      <telia-p>
        <strong>{{
          `${name} ${t("mds.PRICE_PER_MONTH", { prefix: "", price: recurringFee })}`
        }}</strong>
      </telia-p>
      <telia-p>{{
        `${description} ${t("datasim.maxQuantity", {
          count: maxDatasims,
        })}`
      }}</telia-p>
    </div>

    <div class="addon-datasims__configuration">
      <div v-for="(datasim, index) in datasims" :key="datasim.id">
        <div class="addon-datasims__datasim">
          <div class="title">
            <telia-p>
              <strong>
                {{ t("datasim.count", { count: index + 1 }) }}
              </strong>
            </telia-p>
            <telia-button
              type="button"
              variant="tertiary-purple"
              @click="removeDatasim(datasim.id)"
            >
              <telia-icon name="delete" size="md" />
            </telia-button>
          </div>
          <SimCardSelector
            :identifier="`datasim-${index}-${datasim.id}`"
            :simcard-types="simcardTypes"
            @change="updateConfiguration(datasim.id, $event)"
          />
        </div>
      </div>
      <div v-if="datasims.length < maxDatasims && !disabled" class="addon-datasims__add_button">
        <telia-button
          :left-icon="leftIcon"
          :disabled="disabled"
          :text="t('datasim.add')"
          variant="secondary"
          size="sm"
          @click="addDatasim"
          data-testid="addon-datasims__add-button"
        />
      </div>
    </div>
    <telia-notification
      t-id="addon-datasims__disabled-reason"
      class="addon-datasims__disabled-reason"
      heading-tag="h4"
      v-if="disabled"
    >
      <div slot="heading">
        {{ t("datasim.disabledReasonHeading") }}
      </div>
      <div slot="content">
        <telia-p>{{ t("datasim.disabledReasonContent", { name }) }}</telia-p>
      </div>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, watchEffect } from "vue";
import { translate } from "@telia/b2b-i18n";
import { DatasimEmit, SelectedDatasim } from "../../types";
import SimCardSelector from "../subscription/simcard-selector/SimcardSelector.vue";
import { SimcardPayload } from "../../utils/SimChoicePayload";

const leftIcon = JSON.stringify({ name: "plus", size: "sm" });

interface Props {
  description?: string;
  productCode: string;
  offerCode: string;
  disabled?: boolean;
  addonId: string;
  name: string;
  maxDatasims?: number;
  recurringFee: string;
}
const props = withDefaults(defineProps<Props>(), {
  description: "",
  maxDatasims: 2,
  disabled: false,
});
const { addonId, productCode, offerCode } = toRefs(props);

const t = translate;
const simcardTypes = ["new-sim", "existing-sim", "new-esim"];
const datasims = ref<SelectedDatasim[]>([]);
const emit = defineEmits<{ (e: "change", datasims: DatasimEmit[]): void }>();

watchEffect(() => {
  emitValues(datasims.value);
});

function addDatasim() {
  datasims.value.push({
    id: generateId(),
    type: "SIMCARD",
  });
}
function updateConfiguration(id: string, configuration: SimcardPayload) {
  const datasimIndex = datasims.value.findIndex((datasim) => datasim.id === id);
  if (datasimIndex >= 0) {
    datasims.value[datasimIndex] = {
      id,
      ...configuration,
    };
  }
}
function removeDatasim(id: string): void {
  datasims.value = datasims.value.filter((datasim) => datasim.id !== id);
}
function generateId(): string {
  return "_" + Math.random().toString(36).substr(2, 9);
}
function emitValues(datasims: SelectedDatasim[]) {
  const filteredDatasims = datasims
    .filter((datasim) => datasim.type)
    .map(({ type, iccNumber, deliveryEmailAddress }) => ({
      id: addonId.value,
      productCode: productCode.value,
      offerCode: offerCode.value,
      type,
      iccNumber,
      deliveryEmailAddress,
    }));

  emit("change", filteredDatasims);
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.addon-datasims {
  background-color: $telia-white;
  padding-bottom: $telia-spacing-24;
  &__container {
    margin-bottom: $telia-spacing-12;
  }
  &--disabled {
    background-color: $telia-gray-200;
    color: $telia-gray-500;
    pointer-events: none;
  }
  &__add_button {
    display: flex;
  }
  &__datasim {
    margin: $telia-spacing-12 0;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      telia-p {
        margin-bottom: 0;
      }
    }
  }
  &__icon {
    margin-right: $telia-spacing-12;
  }
  &__disabled-reason {
    grid-column: 1 / span 2;
  }
}
</style>
