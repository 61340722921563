<template>
  <div
    class="overlay"
    :class="isOpen ? 'visible' : 'hidden'"
    :aria-hidden="isOpen ? 'false' : 'true'"
  >
    <div :id="modalId" :class="`modal modal__${width}`" role="dialog" aria-modal="true">
      <telia-focus-trap>
        <div v-if="showCloseButton" class="icon-tag">
          <button
            data-testid="close-button"
            class="close-button"
            type="button"
            @click="handleClose"
            :aria-label="closeButtonLabel"
            :aria-expanded="isOpen ? 'true' : 'false'"
            :aria-controls="modalId"
          >
            <telia-icon name="close" size="lg" />
          </button>
        </div>
        <div class="modal-content">
          <div class="modal-body">
            <slot name="modal-body" />
          </div>
          <slot name="button-footer" />
        </div>
      </telia-focus-trap>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  modalId: string;
  width?: "s" | "m" | "l";
  isOpen?: boolean;
  showCloseButton?: boolean;
  closeButtonLabel?: string;
}

withDefaults(defineProps<Props>(), {
  width: "s",
  isOpen: false,
  showCloseButton: true,
  closeButtonLabel: "Stäng",
});

const emit = defineEmits<{ (e: "closeModal"): void }>();

function handleClose() {
  emit("closeModal");
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/breakpoints/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

* {
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  background: rgba($telia-gray-900, 0.7);
  inset: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &.visible {
    visibility: visible;
    opacity: 1;

    & .modal {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &.hidden {
    visibility: hidden;
  }
}

.modal {
  overflow-y: auto;
  background-color: $telia-white;
  top: calc(50% + #{$telia-spacing-24});
  left: calc(50% + #{$telia-spacing-24});
  transform: translateY(8px);
  max-height: calc(100% - #{$telia-spacing-24});
  width: calc(100% - #{$telia-spacing-24});
  opacity: 0;

  @media (min-width: $telia-breakpoint-medium) {
    width: 75%;
  }

  &__s {
    @media (min-width: $telia-breakpoint-large) {
      width: 66rem;
    }
  }

  &__m {
    @media (min-width: $telia-breakpoint-large) {
      width: 86rem;
    }
  }

  &__l {
    @media (min-width: $telia-breakpoint-large) {
      width: 102rem;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  background-color: $telia-white;
}

.modal-body {
  padding: $telia-spacing-16;

  @media (min-width: $telia-breakpoint-medium) {
    padding: $telia-spacing-32;
  }
}

.icon-tag {
  position: sticky;
  height: 0;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 100001;
  color: $telia-black;
}

.close-button {
  position: absolute;
  top: $telia-spacing-12;
  right: $telia-spacing-12;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
  color: inherit;
}

.description-container {
  padding-top: $telia-spacing-12;
}
</style>
