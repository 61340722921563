import { corpSwitchboards } from "@telia/b2b-rest-client";
import {
  BookFixedNumber,
  HuntingGroup,
  SwitchboardAddonConfig,
  SwitchboardAddonBundle,
  SwitchboardConfiguration,
  SwitchboardAddon,
  SwitchboardUserForm,
} from "../../components/subscription/switchboard/switchboard-types";
import { formatFixedNumber } from "./number-formatter.service";
import { SwitchboardDTO } from "@telia/b2b-rest-client/dist/corp-switchboards";

export type SwitchboardType = "smartconnect" | "touchpoint" | "all";
const getSwitchboardType = (
  switchboardUserAddon: SwitchboardAddonConfig
): "smartconnect" | "touchpoint" | "all" => {
  switch (switchboardUserAddon.subcategory) {
    case "scuser":
      return "smartconnect";
    case "ucuser":
      return "touchpoint";
    default:
      return "all";
  }
};

const fetchHuntingGroups = async (scopeId: string, c2bUid: string): Promise<HuntingGroup[]> => {
  try {
    const result = await corpSwitchboards.PublicSwitchboardsControllerV2Service.getHuntingGroups(
      scopeId,
      c2bUid
    );
    return (result.huntingGroups as HuntingGroup[]) ?? [];
  } catch {
    return [];
  }
};

const fetchActiveSwitchboards = async (
  switchboardUserAddon: SwitchboardAddonConfig,
  scopeId: string,
  tscid: string
): Promise<SwitchboardDTO[]> => {
  const switchboardType = getSwitchboardType(switchboardUserAddon);
  try {
    const activeSwitchboardDTOs =
      await corpSwitchboards.PublicSwitchboardsControllerV2Service.getActiveSwitchboards(
        scopeId,
        tscid,
        switchboardType
      );
    const switchboards = activeSwitchboardDTOs.map((activeSwitchboard) => {
      return activeSwitchboard.switchboard as SwitchboardDTO;
    });
    return switchboards;
  } catch {
    // error
    return [];
  }
};

const mapAddonsToAddonsList = (
  switchboardUserForm: SwitchboardUserForm,
  selectedSwitchboardUserAddonBundle: SwitchboardAddonBundle,
  selectedSwitchboardUserPackage: SwitchboardAddonConfig,
  selectedFixedNumber: string
): SwitchboardAddonConfig[] => {
  const addons: SwitchboardAddonConfig[] = [];

  const keys = Object.keys(switchboardUserForm).filter(
    (key) =>
      key !== selectedSwitchboardUserAddonBundle.productCode &&
      key !== selectedSwitchboardUserPackage.productCode
  );

  function addAddon(key: string): void {
    addons.push(
      ...switchboardUserForm[key].map((addon): SwitchboardAddonConfig => {
        const addonProduct = selectedSwitchboardUserAddonBundle.addonBundles
          .flatMap((b) => b.addons)
          .find((a) => a.id === addon) as any;
        const { id, productCode, recurringFee, oneTimeFee, name, category, subcategory } =
          addonProduct;
        return {
          id,
          productCode,
          recurringFee,
          oneTimeFee,
          name,
          category,
          subcategory,
          attributes:
            addonProduct.subcategory === "fixednumber"
              ? {
                  fixedNumber: formatFixedNumber(selectedFixedNumber),
                }
              : {},
        };
      })
    );
  }

  keys.filter((key) => switchboardUserForm[key].length > 0).forEach((key) => addAddon(key));
  return addons;

  // const addons: any[] = [];
  // Object.keys(switchboardUserForm)
  //   .filter(
  //     (key) =>
  //       key !== selectedSwitchboardUserAddonBundle.productCode &&
  //       key !== selectedSwitchboardUserPackage.productCode
  //   )
  //   ?.forEach((key) => {
  //     if (switchboardUserForm[key].length > 0) {
  //       addons.push(
  //         ...switchboardUserForm[key].map((addon) => {
  //           const addonProduct = selectedSwitchboardUserAddonBundle.addonBundles
  //             .flatMap((b) => b.addons)
  //             ?.find((a) => a.id === addon);
  //           return {
  //             id: addonProduct?.id as string,
  //             productCode: addonProduct?.productCode as string,
  //             recurringFee: addonProduct?.recurringFee as string,
  //             oneTimeFee: addonProduct?.oneTimeFee as string,
  //             name: addonProduct?.name as string,
  //             category: addonProduct?.category as string,
  //             subcategory: addonProduct?.subcategory as string,
  //             attributes:
  //               addonProduct?.subcategory === "fixednumber"
  //                 ? {
  //                     fixedNumber: formatFixedNumber(selectedFixedNumber),
  //                   }
  //                 : {},
  //           };
  //         })
  //       );
  //     }
  //   });
  return addons;
};

const buildSwitchboardConfiguration = (
  switchboardUserForm: SwitchboardUserForm,
  selectedSwitchboardUserAddonBundle: SwitchboardAddonBundle,
  selectedSwitchboardUserPackage: SwitchboardAddonConfig,
  selectedHuntingGroups: string[],
  fixedNumberBooking: BookFixedNumber,
  offerCode: string,
  selectedFixedNumber: string
): SwitchboardConfiguration => {
  const switchboardConf = {
    offerCode: offerCode,
    productId: switchboardUserForm[selectedSwitchboardUserPackage.productCode][0],
    productCode: selectedSwitchboardUserPackage.productCode,
    name: selectedSwitchboardUserPackage.name,
    category: selectedSwitchboardUserPackage.category,
    subcategory: selectedSwitchboardUserPackage.subcategory,
    oneTimeFee: selectedSwitchboardUserPackage.oneTimeFee,
    recurringFee: selectedSwitchboardUserPackage.recurringFee,
    switchboardId: selectedSwitchboardUserAddonBundle.switchboardId,
    addons: mapAddonsToAddonsList(
      switchboardUserForm,
      selectedSwitchboardUserAddonBundle,
      selectedSwitchboardUserPackage,
      selectedFixedNumber
    ),
    huntingGroups: selectedHuntingGroups.map((hg) => ({
      groupNumber: hg,
    })),
    fixedNumberBooking: fixedNumberBooking,
    ...(selectedSwitchboardUserAddonBundle.switchboardId !== undefined && {
      switchboardId: selectedSwitchboardUserAddonBundle.switchboardId,
    }),
  };
  return switchboardConf;
};

export {
  fetchActiveSwitchboards,
  fetchHuntingGroups,
  mapAddonsToAddonsList,
  buildSwitchboardConfiguration,
};
