import { currentLanguage } from "@telia/b2b-i18n";
import { corpProductOrder } from "@telia/b2b-rest-client";
import { Basket } from "../types";
import {
  AddEmnRequestUI,
  AddToBasketRequestUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";

export async function getBasket(scopeId: string, tscid: string) {
  const language = currentLanguage().toUpperCase() as "SV" | "EN";
  const res = await corpProductOrder.BasketControllerService.getBasket(scopeId, tscid, language);
  return res as Basket;
}

const updateAmandaBasketEvent = new CustomEvent("update:amandabasket", {
  composed: true,
  bubbles: true,
  cancelable: true,
});

export function updateAmandaBasket(element: HTMLElement | null) {
  if (element) {
    element.dispatchEvent(updateAmandaBasketEvent);
  }
}

export async function emptyBasket(scopeId: string, tscid: string) {
  await corpProductOrder.BasketControllerService.emptyBasket(scopeId, tscid);
}

export async function addProductToBasket(
  scopeId: string,
  tscid: string,
  request: AddToBasketRequestUI
) {
  await corpProductOrder.BasketControllerService.addToBasket(scopeId, tscid, request);
}

export async function addEmnToBasket(scopeId: string, tscid: string, request: AddEmnRequestUI) {
  await corpProductOrder.BasketControllerService.addEmnToBasket(scopeId, tscid, request);
}

export const BasketRequestBuilder = {
  updateAmandaBasket,
  emptyBasket,
};
