import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import {
  BasketHardwareSubscription,
  ExtendExistingHardwareSubscription,
  Fees,
  HardwareConfiguration,
  HardwareSubscriptionConfiguration,
  NewHardwareSubscription,
  SubscriptionChoice,
  Variant,
} from "../components/product-configurator/hardware-configurator/types";
import {
  HardwareProductVariantUI,
  MobileDevicePricesUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import {
  HardwareVariant,
  ProductStockStatus,
  SubscriptionBundle,
  SubscriptionProduct,
} from "../types";
import { useSubscriptionConfigurationStore } from "./SubscriptionConfigurationStore";
import { UserInformation } from "../components/product-configurator/subscription-configurator/components/user-info/types";
import { useFlag } from "@unleash/proxy-client-vue";

interface HardwareConfigState {
  operation: "NEW_SUBSCRIPTION" | "EXTEND_SUBSCRIPTION" | "CHANGE_SUBSCRIPTION_IN_CART" | undefined;
  c2bUid?: string;
  relationId?: string;
  relationCount?: number;
  existingBasketId?: string;
}

const defaultHardwareSubscriptionConfig = {
  operation: undefined,
  commitmentPeriod: 0,
  c2bUid: undefined,
  relationId: undefined,
  relationCount: undefined,
  existingBasketId: undefined,
  isUpfrontPayment: false,
};

export const useHardwareStore = defineStore("hardware-store", () => {
  const preSales = useFlag("b2b-mco-presales");

  const subscriptionConfiguationStore = useSubscriptionConfigurationStore();
  const accessory = ref<Variant | undefined>();
  const hardwareVariant = ref<HardwareVariant | undefined>();
  const selectedSubscription = ref<SubscriptionProduct>();
  const selectedSubscriptionBundle = ref<SubscriptionBundle>();
  const hardwareSubscription = reactive<HardwareConfigState>({
    ...defaultHardwareSubscriptionConfig,
  });

  const devicePrices = ref<Required<MobileDevicePricesUI>>();
  const stockStatuses = ref<ProductStockStatus[]>([]);

  const isUpfrontPayment = ref(false);

  function setOperation(
    operation:
      | "NEW_SUBSCRIPTION"
      | "EXTEND_SUBSCRIPTION"
      | "CHANGE_SUBSCRIPTION_IN_CART"
      | undefined
  ) {
    selectedSubscription.value = undefined;
    selectedSubscriptionBundle.value = undefined;
    hardwareSubscription.operation = operation;
  }

  function setVariant(variant: Required<HardwareProductVariantUI>) {
    hardwareVariant.value = variant;
  }

  function setAccessory(accessoryVariant: Variant) {
    accessory.value = accessoryVariant;
  }

  function setSubscriptionBundle(subscriptionBundle: SubscriptionBundle) {
    selectedSubscriptionBundle.value = subscriptionBundle;
  }

  function setSubscriptionProduct(subscriptionProduct: SubscriptionProduct) {
    selectedSubscription.value = subscriptionProduct;
  }

  function setCommitment(commitmentPeriod: number) {
    subscriptionConfiguationStore.setCommitment(commitmentPeriod);
  }

  function setUpfrontPayment(upfrontPayment: boolean) {
    isUpfrontPayment.value = upfrontPayment;
  }

  function setRelationCount(relationCount: number) {
    hardwareSubscription.relationCount = relationCount;
  }

  function setRelationId(relationId: string) {
    hardwareSubscription.relationId = relationId;
  }

  function setExistingBasket(basketId: string) {
    hardwareSubscription.existingBasketId = basketId;
  }

  function setC2bUid(c2bUid: string) {
    hardwareSubscription.c2bUid = c2bUid;
  }

  function setDevicePrices(prices: Required<MobileDevicePricesUI>) {
    devicePrices.value = prices;
  }
  function setStockStatuses(prices: Required<ProductStockStatus[]>) {
    stockStatuses.value = prices;
  }

  function setUserInformation(user: UserInformation) {
    subscriptionConfiguationStore.setUserInformation(user);
  }

  function clearSubscription() {
    selectedSubscription.value = undefined;
    selectedSubscriptionBundle.value = undefined;
    Object.assign(hardwareSubscription, defaultHardwareSubscriptionConfig);
    isUpfrontPayment.value = false;
  }

  function reset() {
    Object.assign(hardwareSubscription, defaultHardwareSubscriptionConfig);
    hardwareVariant.value = undefined;
    accessory.value = undefined;
    selectedSubscription.value = undefined;
    selectedSubscriptionBundle.value = undefined;
    isUpfrontPayment.value = false;
  }

  //Getters
  const selectedSubscriptionOption = computed<SubscriptionChoice>(() => {
    return hardwareSubscription.operation;
  });

  const commitmentPeriod = computed(
    () => subscriptionConfiguationStore.subscriptionConfiguration.commitment
  );

  const subscriptionPricesForVariant = computed(() => {
    const variantPrices = devicePrices.value?.variantPrices.find(
      (variantPrice) => variantPrice.variantProductCode === hardwareVariant.value?.productCode
    );
    return variantPrices?.subscriptions ?? [];
  });

  const subscriptionsApplicableForVariant = computed(() => {
    return selectedSubscriptionBundle.value
      ? selectedSubscriptionBundle.value?.products.filter((subscriptionProduct) =>
          isSubscriptionApplicable(subscriptionProduct.productCode)
        )
      : [];
  });

  const deviceFees = computed((): Fees => {
    if (
      hardwareSubscription.operation === "NEW_SUBSCRIPTION" ||
      hardwareSubscription.operation === "EXTEND_SUBSCRIPTION" ||
      hardwareSubscription.operation === "CHANGE_SUBSCRIPTION_IN_CART"
    ) {
      if (selectedSubscription.value && hardwareVariant.value && devicePrices.value) {
        const subscriptionPrice = subscriptionPricesForVariant.value.find(
          (subscription) =>
            subscription.subscriptionProductCode === selectedSubscription.value?.productCode
        );
        const commitment = subscriptionPrice?.commitments?.find(
          (commitment) => commitment.commitment === commitmentPeriod.value
        );
        return {
          hardware: {
            unitPrice: hardwareVariant.value?.unitPrice
              ? Number(hardwareVariant.value.unitPrice)
              : 0,
            oneTimeFee: commitment?.oneTimeFee || 0,
            recurringFee: commitment?.recurringFee || 0,
            upfrontPaymentFee: commitment?.upfrontPaymentFee || 0,
          },
          subscription: {
            oneTimeFee: selectedSubscription.value.oneTimeFee,
            recurringFee: selectedSubscription.value.recurringFee,
          },
        };
      }
    }
    return {
      hardware: {
        unitPrice: hardwareVariant?.value?.unitPrice ? Number(hardwareVariant.value.unitPrice) : 0,
        oneTimeFee: 0,
        recurringFee: 0,
        upfrontPaymentFee: 0,
      },
      subscription: {
        oneTimeFee: 0,
        recurringFee: 0,
      },
    };
  });

  const getConfiguration = computed<HardwareConfiguration>(() => {
    if (hardwareVariant.value) {
      const configuration: HardwareConfiguration = {
        productId: hardwareVariant.value.id,
        productCode: hardwareVariant.value.productCode,
        isUpfrontPayment: isUpfrontPayment.value,
        subscription: getHardwareSubscriptionConfig(hardwareSubscription.operation),
      };
      return configuration;
    }

    if (accessory.value) {
      return {
        productId: accessory.value.variantId,
        productCode: accessory.value.variantProductId,
        isUpfrontPayment: isUpfrontPayment.value,
      };
    }

    return {
      productId: "",
      productCode: "",
      isUpfrontPayment: isUpfrontPayment.value,
    };
  });

  const configurationValid = computed(() => {
    if (accessory.value) {
      if (!stockStatusForSelectedVariant(accessory.value.sapId)) {
        return false;
      }
      return true;
    }

    if (!hardwareVariant.value) {
      return false;
    }

    if (!stockStatusForSelectedVariant(hardwareVariant.value.sapId)) {
      return false;
    }

    const config = getConfiguration.value;
    if (!config.subscription?.operation) {
      return true;
    }

    if (!config.subscription.subscriptionProduct) {
      return false;
    }

    if (config.subscription.operation === "EXTEND_SUBSCRIPTION") {
      return !!config.subscription.c2bUid && !!config.subscription.commitmentPeriod;
    }

    if (config.subscription.operation === "CHANGE_SUBSCRIPTION_IN_CART") {
      return (
        !!config.subscription.relationCount &&
        !!config.subscription.relationId &&
        !!config.subscription.existingBasketId
      );
    } else {
      return subscriptionConfiguationStore.isConfigValid;
    }
  });

  //Helpers

  function stockStatusForSelectedVariant(sapId: string) {
    const stockStatus = stockStatuses.value.find((stock) => stock.sapId === sapId);
    if (!stockStatus) {
      return false;
    } else {
      return (
        stockStatus.status === "IN_STOCK" ||
        stockStatus.status === "FEW_REMAIN" ||
        (preSales.value && stockStatus.status === "UPCOMING_STOCK")
      );
    }
  }
  const isSubscriptionApplicableForVariant = computed<(subscriptionProductCode: string) => boolean>(
    () => {
      return (subscriptionProductCode) => {
        return isSubscriptionApplicable(subscriptionProductCode);
      };
    }
  );

  function isSubscriptionApplicable(subscriptionProductCode: string) {
    return subscriptionPricesForVariant.value.some(
      (price) => price.subscriptionProductCode === subscriptionProductCode
    );
  }

  function getHardwareSubscriptionConfig(
    operation: SubscriptionChoice
  ): HardwareSubscriptionConfiguration | undefined {
    switch (operation) {
      case "NEW_SUBSCRIPTION":
        return getNewSubscriptionConfiguration();
      case "CHANGE_SUBSCRIPTION_IN_CART":
        return getBasketSubscriptionConfiguration();
      case "EXTEND_SUBSCRIPTION":
        return getExtendSubscriptionConfiguration();
      default:
        return;
    }
  }

  function getNewSubscriptionConfiguration(): NewHardwareSubscription | undefined {
    if (!selectedSubscription.value || !selectedSubscriptionBundle.value) {
      return;
    }
    return {
      subscriptionConfiguration: subscriptionConfiguationStore.getConfiguration,
      subscriptionProduct: selectedSubscription.value,
      subscriptionBundle: selectedSubscriptionBundle.value,
      commitmentPeriod: commitmentPeriod.value,
      operation: "NEW_SUBSCRIPTION",
    };
  }
  function getBasketSubscriptionConfiguration(): BasketHardwareSubscription | undefined {
    if (
      !selectedSubscription.value ||
      !selectedSubscriptionBundle.value ||
      !hardwareSubscription.relationCount ||
      !hardwareSubscription.relationId ||
      !hardwareSubscription.existingBasketId
    ) {
      return;
    }
    return {
      subscriptionProduct: selectedSubscription.value,
      subscriptionBundle: selectedSubscriptionBundle.value,
      relationCount: hardwareSubscription.relationCount,
      relationId: hardwareSubscription.relationId,
      existingBasketId: hardwareSubscription.existingBasketId,
      commitmentPeriod: commitmentPeriod.value,
      operation: "CHANGE_SUBSCRIPTION_IN_CART",
    };
  }

  function getExtendSubscriptionConfiguration(): ExtendExistingHardwareSubscription | undefined {
    if (
      !selectedSubscription.value ||
      !selectedSubscriptionBundle.value ||
      !hardwareSubscription.c2bUid ||
      !subscriptionConfiguationStore.getConfiguration.user
    ) {
      return;
    }
    return {
      c2bUid: hardwareSubscription.c2bUid,
      commitmentPeriod: commitmentPeriod.value,
      operation: "EXTEND_SUBSCRIPTION",
      subscriptionProduct: selectedSubscription.value,
      subscriptionBundle: selectedSubscriptionBundle.value,
      subscriptionUser: subscriptionConfiguationStore.getConfiguration.user,
    };
  }

  const availableCommitmentOptions = computed(() => {
    const subscriptionPrice = subscriptionPricesForVariant.value.find(
      (subscription) =>
        subscription.subscriptionProductCode === selectedSubscription.value?.productCode
    );
    if (subscriptionPrice && subscriptionPrice.commitments) {
      const commitments: number[] = [];
      subscriptionPrice.commitments.forEach((commitment) => {
        if (commitment) {
          commitments.push(commitment.commitment as number);
        }
      });
      return commitments;
    }

    return selectedSubscription.value?.commitmentPeriods ?? [];
  });

  const availableFromDate = computed(() => {
    const stockStatus = stockStatuses.value.find(
      (stock) => stock.sapId === hardwareVariant.value?.sapId
    );
    if (stockStatus) {
      return stockStatus.availableFrom;
    }
    return undefined;
  });

  return {
    getConfiguration,
    hardwareVariant,
    hardwareSubscription,
    selectedSubscriptionOption,
    selectedSubscription,
    selectedSubscriptionBundle,
    commitmentPeriod,
    deviceFees,
    isUpfrontPayment,
    subscriptionPricesForVariant,
    subscriptionsApplicableForVariant,
    availableCommitmentOptions,
    isSubscriptionApplicableForVariant,
    availableFromDate,
    setVariant,
    reset,
    clearSubscription,
    setAccessory,
    setSubscriptionProduct,
    setSubscriptionBundle,
    setCommitment,
    setUpfrontPayment,
    setOperation,
    setRelationCount,
    setRelationId,
    setExistingBasket,
    setC2bUid,
    setUserInformation,
    setDevicePrices,
    setStockStatuses,
    configurationValid,
  };
});
