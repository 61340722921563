<template>
  <div class="phone-selector_memory-wrapper" t-id="PhoneSelector-memoryWrapper">
    <telia-fieldset :legend="t('configurator.memoryTitle')">
      <ConfiguratorRow>
        <telia-chip-choice
          :data-testid="'memory-option-' + memory"
          v-for="(memory, index) of memoryOptions"
          :key="index"
          :checked="selectedMemory === memory"
          :disabled="isDisabled(memory)"
          :text="formatMemory(memory)"
          @change="selectedMemory = memory"
        />
      </ConfiguratorRow>
    </telia-fieldset>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import ConfiguratorRow from "../../../components/shared/ConfiguratorRow.vue";

interface Props {
  memoryOptions: string[];
  initMemory: string;
  availableOptions?: string[];
}

const emit = defineEmits<{
  (e: "selected", memory: string): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  memoryOptions: () => [],
  availableOptions: () => [],
});

const selectedMemory = computed({
  get() {
    return props.initMemory;
  },
  set(memory: string) {
    emit("selected", memory);
  },
});

function formatMemory(memory: string) {
  let memArray = memory.split(/(\d+)/);
  return `${memArray[1]} ${memArray[2]}`;
}
function isDisabled(memory: string) {
  return !props.availableOptions.find((option) => option === memory);
}
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.phone-selector_memory-wrapper {
  margin-top: $telia-spacing-4;
}
</style>
