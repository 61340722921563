import { iccIsValid } from "./validate-icc";
import { ValidationResponse } from "./ValidationResponse";

export async function validIcc(iccNumber: string): Promise<ValidationResponse> {
  return await iccIsValid(iccNumber);
}

export function required(value: string): ValidationResponse {
  return value !== "" ? { isValid: true } : { isValid: false };
}

export function validEmail(email: string): ValidationResponse {
  const emailRegex = /[\w\-._]+@[\w\-._]+\.\w{2,10}/;
  const isValid = emailRegex.test(email);
  return isValid ? { isValid: true } : { error: "general.invalidFormatEmail", isValid: false };
}

export function testLuhn(number: string) {
  const numbers = number.split("").map((x) => parseInt(x));

  let sum = numbers.slice(-1)[0];
  const numDigits = numbers.length;
  const parity = numDigits % 2;

  numbers.forEach((digit, index) => {
    let currentDigit = digit;
    if (index < numDigits - 1) {
      if (index % 2 === parity) {
        currentDigit = digit * 2;
      }
      if (currentDigit > 9) {
        currentDigit -= 9;
      }
      sum += currentDigit;
    }
  });
  return sum % 10 === 0;
}
