<template>
  <telia-col width="12" width-md="6" width-lg="3">
    <div
      :t-id="`subscription-product__product-${props.product.productCode}`"
      class="subscription-product__card"
      role="button"
      :class="{ 'subscription-product__card--disabled': props.disabled }"
      @click="handleSelected"
    >
      <div
        v-if="showDiscountBadge"
        class="subscription-product__discount-badge"
        t-id="subscription-product__discount"
      >
        <telia-badge variant="special">
          {{ t("pricing.discount", { price: 50 }) }}
        </telia-badge>
      </div>
      <div
        class="subscription-product__amount-wrapper"
        :class="{ 'subscription-product__amount-wrapper--disabled': props.disabled }"
      >
        <telia-icon
          v-if="isVariable"
          class="subscription-product__variable-icon"
          t-id="subscription-product__filter-icon"
          name="filter"
          size="lg"
        />
        <span
          :class="{
            'subscription-product__amount-suffix': isVariable,
            'subscription-product__data-name': !isVariable,
          }"
          t-id="subscription-product__name"
        >
          {{
            product.subcategory !== "emn"
              ? product.name.replace(`${subscriptionGroupName} `, "")
              : product.name
          }}
          <!--TODO this is borked-->
        </span>
      </div>
      <telia-divider />
      <div
        class="subscription-product__price"
        :class="{ 'subscription-product__price--disabled': props.disabled }"
      >
        <telia-p class="subscription-product__price-text" t-id="subscription-product__price-text">
          {{ price }}
        </telia-p>
      </div>
    </div>
  </telia-col>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { currentLanguage, translate as t } from "@telia/b2b-i18n";
import { SubscriptionProduct } from "../../../types";

interface Props {
  product: SubscriptionProduct;
  subscriptionGroupName: string;
  disabled: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits<{ (e: "selected-subscription", product: SubscriptionProduct): void }>();

const isVariable = computed(() => {
  return props.product.name.toUpperCase().includes("BAS");
});
const showDiscountBadge = computed(() => {
  return !isVariable.value && props.product.minimumCommitment > 0;
});
const price = computed(() => {
  const price = formatCurrency(props.product.recurringFee);
  return `${price}${recurringPriceSuffix()}`;
});

function recurringPriceSuffix() {
  return props.product.recurringFee ? t("subscriptionListing.subscriptionPrice.suffix") : "";
}
function formatCurrency(amount: string) {
  return new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(amount));
}
function handleSelected() {
  emit("selected-subscription", props.product);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/borders/tokens";

.subscription-product {
  &__card {
    position: relative;
    display: block;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.1rem $telia-gray-300;
    background-color: $telia-white;
    cursor: pointer;
    outline: none;
    transition: 300ms;

    &:not(&--disabled) {
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.2rem $telia-purple-500;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      border-width: $telia-border-width-2;
      background-color: $telia-gray-50;
    }
  }

  &__discount-badge {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
  }

  &__amount-wrapper {
    padding: $telia-spacing-24 $telia-spacing-24 $telia-spacing-12;
    display: flex;
    align-items: baseline;
    color: $telia-purple-600;

    &--disabled {
      color: $telia-gray-200;
    }
  }

  &__data-name {
    font-size: 2rem;
    font-weight: bold;
    min-height: 5rem;
  }
  &__amount-suffix {
    font-size: 2rem;
    font-weight: bold;
    margin-left: $telia-spacing-8;
  }

  &__infinite-icon {
    width: 5.6rem;
    height: 100%;
  }

  &__variable-icon {
    width: 5.2rem;
    min-height: 3.7rem;
    margin-top: -0.4rem;
  }
  &__price {
    background-color: $telia-gray-50;
    padding: $telia-spacing-12 $telia-spacing-24;

    &--disabled {
      background-color: $telia-gray-100;
      color: $telia-gray-400;
    }
  }
  &__price-text {
    margin-bottom: 0;
  }
}
</style>
