<template>
  <div
    v-if="huntingGroups && huntingGroups.length"
    t-id="switchboard-user__hunting-group-list"
    class="switchboard-user__hunting-groups-list"
  >
    <telia-heading class="switchboard-user--margin-bottom" variant="title-100" tag="h4">
      {{ t("switchboardUser.huntingGroupsHeader") }}
    </telia-heading>
    <telia-p class="switchboard-user--margin-bottom">
      {{ t("switchboardUser.huntingGroupsSubheader") }}
    </telia-p>
    <telia-checkbox
      v-for="group in huntingGroups"
      class="switchboard-user__hunting-group-option"
      :t-id="`switchboard-user__hunting-group-option__${group.id}`"
      :key="group.name"
      :value="group.id"
      :disabled="shouldDisableHuntingGroup(group.id)"
      @click="emitSelectedHuntingGroup"
    >
      <span>
        {{ group.name || group.id }}
      </span>
    </telia-checkbox>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { toRefs } from "vue";
import { HuntingGroup } from "./switchboard-types";

const props = defineProps<{
  huntingGroups: HuntingGroup[];
  selectedHuntingGroups: string[];
}>();
const { huntingGroups, selectedHuntingGroups } = toRefs(props);
const emit = defineEmits<{
  (e: "onSelectedHuntingGroup", selectedHuntingGroupNumber: string): void;
}>();

function emitSelectedHuntingGroup(event: { target: { value: string } }) {
  emit("onSelectedHuntingGroup", event.target.value);
}
function shouldDisableHuntingGroup(groupId: string) {
  return !selectedHuntingGroups.value.includes(groupId) && selectedHuntingGroups.value.length >= 10;
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/typography/variables";
.switchboard-user {
  height: 100%;

  &__hunting-groups-list {
    margin-bottom: 1.4rem;
  }

  &__hunting-group-option {
    margin-bottom: 1.4rem;
    min-height: 28px;
  }

  &--margin-bottom {
    margin-bottom: $telia-spacing-12;
  }

  @media screen and (max-width: 770px) {
    padding: 1.2rem;
  }
}
</style>
