<template>
  <div
    id="switchboard-toggle__container"
    class="switchboard-toggle__container"
    data-testid="switchboard-toggle__container"
    :class="{
      'switchboard-toggle__container--disabled': isExcluded,
    }"
  >
    <div
      class="switchboard-toggle__toggle-container"
      :class="{
        'switchboard-toggle__toggle-container--disabled': isExcluded,
      }"
    >
      <div class="switchboard-toggle__text-container">
        <ConfiguratorRow>
          <b2x-radio-card
            data-testid="switchboard-toggle__off"
            class="switchboard-toggle__option"
            name="switchboard"
            value="no-switchboard"
            :label="t('switchboardUser.noConnection')"
            :checked="!isSwitchboardSelected"
            @change="handleUnsetSwitchboardOption"
          >
            <telia-p>{{ t("switchboardUser.continueWithoutSwitchboard") }}</telia-p>
          </b2x-radio-card>
          <b2x-radio-card
            data-testid="switchboard-toggle__on"
            class="switchboard-toggle__option"
            name="switchboard"
            :disabled="isExcluded"
            :label="switchboardDisplayName"
            :checked="isSwitchboardSelected"
            @change="handleSelectSwitchboardOption"
          >
            <telia-p disable-hyphenation>{{ t("switchboardUser.connectToSwitchboard") }}</telia-p>
            <ul class="switchboard-toggle__fees-list">
              <li>
                <telia-p>{{ switchboardPrice.oneTimeFee }}</telia-p>
              </li>
              <li>
                <telia-p>{{ switchboardPrice.recurringFee }}</telia-p>
              </li>
            </ul>
          </b2x-radio-card>
        </ConfiguratorRow>
        <div
          class="switchboard-toggle__select-container"
          v-if="isSwitchboardSelected && !isExcluded && switchboardBundles.length > 1"
        >
          <telia-select @change="selectboxChange" :options="JSON.stringify(selectBoxBundle)" />
        </div>
      </div>
    </div>
    <telia-notification
      v-if="isExcluded"
      data-testid="switchboard-toggle-disabled"
      heading-tag="h4"
    >
      <span slot="heading">
        {{ t("switchboardUser.addonSwitchboardDisabledReasonContentHeading") }}
      </span>
      <span slot="content">
        <telia-p>{{ t("switchboardUser.addonSwitchboardDisabledReasonContent") }} </telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { SwitchboardAddonBundle, SwitchboardAddon } from "./switchboard-types";
import { addoneOneTimeFee, addonRecurringFeeInfo } from "../../../shared/services/addon-fee";
import { computed, toRefs } from "vue";
import { translate } from "@telia/b2b-i18n";
import ConfiguratorRow from "../../../../../shared/ConfiguratorRow.vue";

interface Props {
  isExcluded: boolean;
  isSwitchboardSelected: boolean;
  selectedSwitchboardId?: string;
  switchboardDisplayName: string;
  switchboardPrice: {
    recurringFee: string;
    oneTimeFee: string;
  };
  switchboardBundles: SwitchboardAddonBundle[];
}
const emit = defineEmits<{
  (e: "unset-switchboard"): void;
  (e: "selected-switchboard-bundle", switchboardId: string): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  switchboardBundles: () => [],
});
const { isExcluded, selectedSwitchboardId, switchboardBundles } = toRefs(props);

const t = translate;

const selectBoxBundle = computed(() => {
  const bundle = switchboardBundles.value.map((bundle) => {
    return {
      value: bundle.switchboardId,
      label: bundle.switchboardName,
      selected: bundle.switchboardId === props.selectedSwitchboardId,
    };
  });

  return [
    {
      selected: !selectedSwitchboardId,
      label: t("switchboardUser.chooseConnection"),
      disabled: true,
    },
    ...bundle,
  ];
});
function handleUnsetSwitchboardOption() {
  emit("unset-switchboard");
}
function handleSelectSwitchboardOption() {
  emit(
    "selected-switchboard-bundle",
    props.selectedSwitchboardId ?? props.switchboardBundles[0].switchboardId
  );
}

function selectboxChange(event: { target: { value: string } }) {
  const selectedSwitchboardBundle = switchboardBundles.value.find(
    (bundle) => bundle.switchboardId === event.target.value
  ) as SwitchboardAddonBundle;

  emit("selected-switchboard-bundle", selectedSwitchboardBundle.switchboardId);
}

function getOneTimeFeeInfo(addon: SwitchboardAddon) {
  return addoneOneTimeFee(addon, t);
}
function getRecurringFeeInfo(addon: SwitchboardAddon) {
  return addonRecurringFeeInfo(addon, t);
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.switchboard-toggle {
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 5fr;
  }

  &__select-container {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
  }

  &__container {
    &--disabled {
      background-color: #f2f2f2;
    }
  }

  &__toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.4rem;

    &--disabled {
      color: #808080;
    }
  }

  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__text-container {
    flex: 1;
  }

  &__option {
    flex: 1 1 45%;
  }
}
</style>
