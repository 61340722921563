<template>
  <div
    t-id="b2b-order-products"
    data-test-id="b2b-order-products"
    class="b2b-order-products"
    v-if="scopeId && tscid && category"
  >
    <OrderProducts
      :tscid="tscid"
      :productCode="productCode"
      :scopeId="scopeId"
      :permissions="permissions"
      :organisations="organisations"
      :loading-organisations="loadingOrganisations === 'loading'"
      :prefetchErrors="errors"
      :nf-agreements="agreements"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed } from "vue";
import { getScopeDetails, getOrganizations } from "@telia/b2b-logged-in-service";
import { translateSetup } from "./locale";
import { Organisation, Permissions } from "./types";
import OrderProducts from "./components/OrderProducts.vue";
import { useStateStore } from "./store/StateStore";
import { storeToRefs } from "pinia";

import { useFlag } from "@unleash/proxy-client-vue";

// TODO: Feature flags. Cleanup when appropriate.
const orderEmnFeatureEnabled = useFlag("b2b-order-emn");

type LoadingState = "idle" | "loading" | "error";
const stateStore = useStateStore();
const { scopeId, tscid, category, productCode, agreements, hasValidNfAgreement, hasEmnAgreement } =
  storeToRefs(stateStore);

const organisations = ref<Organisation[]>([]);
const loadingOrganisations = ref<LoadingState>("loading");
const errors = ref<string[]>([]);
const actions = ref<string[]>([]);

const permissions = computed<Permissions>(() => {
  return {
    hasEmnAgreement: hasEmnPermission.value,
    hasReadAgreement: getPermission("READ_AGREEMENT", actions.value),
    hasOrderMobileVoice: getPermission("ORDER_MOBILE_VOICE", actions.value, true),
    hasOrderMobileBroadband: getPermission("ORDER_MOBILE_BROADBAND", actions.value, true),
  };
});

const hasEmnPermission = computed<boolean>(() => {
  if (!orderEmnFeatureEnabled.value) {
    return false;
  }
  return hasEmnAgreement.value;
});

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  Promise.all([setupUser(), setupOrganisations()]);
});

async function setupUser() {
  try {
    const res = await getScopeDetails();
    stateStore.setScopeId(res.scope?.id ?? "");
    actions.value = res.scope?.actions?.available ?? [];
  } catch (error) {
    errors.value.push("loggedInUserFailed");
  }
}

function getPermission(action: string, actions: string[], requiresNfa: boolean = false): boolean {
  return requiresNfa
    ? actions.includes(action) && hasValidNfAgreement.value
    : actions.includes(action);
}

async function setupOrganisations() {
  try {
    loadingOrganisations.value = "loading";
    const organisationResponse = (await getOrganizations()) as Organisation[];
    organisations.value = organisationResponse ?? [];
    loadingOrganisations.value = "idle";
  } catch (error) {
    errors.value.push("organisationsFailed");
    loadingOrganisations.value = "error";
  }
}
</script>

<style lang="scss" scoped>
.b2b-order-products {
  height: 100%;
}
</style>
