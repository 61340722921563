<template>
  <div class="extend-existing-subscription__selected-subscription">
    <telia-button
      variant="tertiary-purple"
      type="button"
      :left-icon="buttonIcon"
      :text="t('hardwareSubscriptionConfigurator.SELECT_ANOTHER')"
      @click="() => emit('clear-selected-subscription')"
    >
      <!-- <telia-p>{{ t("hardwareSubscriptionConfigurator.SELECT_ANOTHER") }}</telia-p> -->
    </telia-button>
    <div class="extend-existing-subscription__selected-subscription__container">
      <img
        src="https://www.telia.se/dam/jcr:0139a980-77e5-4fa6-a708-a70f73846c90/%20single-sim-2021.png"
        class="extend-existing-subscription__selected-subscription__result-image"
        draggable="false"
      />
      <div class="extend-existing-subscription__selected-subscription__details">
        <telia-p
          class="extend-existing-subscription__selected-subscription__user-info tc-margin__top--half"
          t-id="extend-existing-subscription__selected-subscription__user-info"
        >
          {{ userInfo }}
        </telia-p>
        <telia-p
          class="extend-existing-subscription__selected-subscription__subscription-info tc-margin__top--half"
          t-id="extend-existing-subscription__selected-subscription__subscription-info"
        >
          {{ subscriptionInfo }}
        </telia-p>
        <telia-p
          class="tc-margin__top--half"
          t-id="extend-existing-subscription__selected-subscription__commitment-end-date"
          v-if="hasCommitment"
        >
          {{ t("COMMITMENT_UNTIL", { endDate: selectedSubscription.commitment.endDate }) }}
        </telia-p>
      </div>
    </div>
    <telia-notification status="information" v-if="noMatchingSubscription">
      <telia-p slot="heading">{{
        t("hardwareSubscriptionConfigurator.EXTEND_SUBSCRIPTION_NOT_POSSIBLE_TITLE")
      }}</telia-p>
      <telia-p slot="content">{{
        t("hardwareSubscriptionConfigurator.EXTEND_SUBSCRIPTION_NOT_POSSIBLE")
      }}</telia-p>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { SubscriptionItem } from "./extendSubscriptionTypes";
import { computed } from "vue";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";

interface Props {
  selectedSubscription: SubscriptionItem;
  noMatchingSubscription: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (event: "clear-selected-subscription"): void }>();

const buttonIcon = JSON.stringify({ name: "chevron-left", size: "md" });

const hasCommitment = computed(() => {
  return !!props.selectedSubscription.commitment.endDate;
});

const userInfo = computed(() => {
  return `${formattedPhoneNumber.value}${
    props.selectedSubscription.user ? " / " + props.selectedSubscription.user : ""
  }`;
});

const formattedPhoneNumber = computed(() => {
  return formatPhoneNumber(props.selectedSubscription.phoneNumber);
});

const subscriptionInfo = computed(() => {
  return ` ${props.selectedSubscription.description}${
    props.selectedSubscription.components?.length > 0 &&
    props.selectedSubscription.components[0].description
      ? ", " + props.selectedSubscription.components[0].description
      : ""
  }`;
});
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.extend-existing-subscription__selected-subscription {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-8;

  &__back-link {
    padding: $telia-spacing-8 0;
    display: flex;
  }

  &__container {
    background: $telia-gray-50;
    padding: $telia-spacing-16 0 $telia-spacing-24 0;
    display: flex;
    width: 100%;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__result-image {
    max-height: 3.4rem;
    max-width: 3.7rem;
    margin-left: $telia-spacing-8;
    margin-right: $telia-spacing-8;
  }
}
</style>
