import { Product } from "@telia/b2b-ecommerce-tracking";
import {
  AddEmnRequestUI,
  AddHardwareRequestUI,
  AddNumberMigrationRequestUI,
  AddSubscriptionRequestUI,
  AddSwitchboardUserRequestUI,
  AddToBasketRequestUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { UserInformation } from "../components/product-configurator/subscription-configurator/components/user-info/types";
import { useEmnSubscriptionStore } from "../store/EmnSubscriptionStore";
import { useSubscriptionConfigurationStore } from "../store/SubscriptionConfigurationStore";
import { AccessoryProduct, HardwareProduct, HardwareVariant, SubscriptionProduct } from "../types";
import { useHardwareStore } from "../store/HardwareConfigurationStore";
import { NumberMigrationConfiguration } from "../components/product-configurator/subscription-configurator/components/number/types";
import { SwitchboardConfiguration } from "../components/product-configurator/subscription-configurator/components/subscription/switchboard/switchboard-types";
import { useSwitchboardStore } from "../store/SwitchboardConfigurationStore";

function useBasketRequestCreator(product: Product, offerCode: string) {}

function buildSubscriptionOrderRequest(
  subscriptionProduct: SubscriptionProduct,
  offerCode: string,
  newSwitchboardEnabled: boolean = false // TODO remove when feature toggle is removed
): Pick<AddToBasketRequestUI, "subscription" | "numberMigration" | "switchboardUser"> {
  const subscriptionConfiguationStore = useSubscriptionConfigurationStore();
  const configuration = subscriptionConfiguationStore.getConfiguration;
  const switchboardConfigurationStore = useSwitchboardStore();
  const subscriptionConfig: AddSubscriptionRequestUI = {
    productId: subscriptionProduct.id,
    productCode: subscriptionProduct.productCode,
    offerCode,
    mds: configuration.esimWatches[0],
    msisdn: configuration.phoneNumber?.msisdn,
    reservedMSISDN: configuration.phoneNumber?.component === "new-phone-number-from-series",
    operation: "NEW_SUBSCRIPTION",
    ...configuration,
    user: configuration.user as UserInformation,
  };

  let numberMigrationOrder: AddNumberMigrationRequestUI | undefined = undefined;
  if (configuration.numberMigration) {
    numberMigrationOrder = buildNumberMigrationOrder(
      configuration.numberMigration,
      configuration.user as UserInformation
    );
  }

  let switchboardUserOrder: AddSwitchboardUserRequestUI | undefined = undefined;
  if (newSwitchboardEnabled && switchboardConfigurationStore.switchboardConfiguration) {
    switchboardUserOrder = buildSwitchboardOrder(
      switchboardConfigurationStore.switchboardConfiguration,
      configuration.user
    );
  }
  if (configuration.switchboardUser && !newSwitchboardEnabled) {
    switchboardUserOrder = buildSwitchboardOrder(configuration.switchboardUser, configuration.user);
    if (configuration.switchboardUser.fixedNumberBooking) {
      //TODO await fixed number booking
    }
  }
  return {
    subscription: subscriptionConfig,
    numberMigration: numberMigrationOrder,
    switchboardUser: switchboardUserOrder,
  };
}

function buildNumberMigrationOrder(
  numberMigration: NumberMigrationConfiguration,
  user: UserInformation
): AddNumberMigrationRequestUI {
  return {
    type: numberMigration.type,
    productId: numberMigration.migrationProduct.id,
    productCode: numberMigration.migrationProduct.productCode,
    offerCode: numberMigration.migrationProduct.offerCode,
    email: numberMigration.email,
    ssn: numberMigration.ssn,
    user,
    msisdn: numberMigration.msisdn,
    orgNumber: numberMigration.organizationNumber,
    c2bUid: numberMigration.c2bUid,
    desiredDeliveryDate: numberMigration.desiredDeliveryDate,
  };
}

function buildSwitchboardOrder(
  switchboardUser: SwitchboardConfiguration,
  user: UserInformation | undefined
): AddSwitchboardUserRequestUI {
  return {
    productCode: switchboardUser.productCode,
    offerCode: switchboardUser.offerCode,
    addons: switchboardUser.addons.map((addon) => ({
      productCode: addon.productCode,
      attributes: addon.attributes,
    })),
    huntingGroups: switchboardUser.huntingGroups,
    user,
    switchboardId: switchboardUser.switchboardId,
    name: switchboardUser.name,
    subcategory: switchboardUser.subcategory,
  };
}

function buildEmnOrderRequest(
  subscriptionProduct: SubscriptionProduct,
  offerCode: string,
  customerNumber: string
): AddEmnRequestUI {
  const emnConfigurationStore = useEmnSubscriptionStore();
  return {
    customerNumber,
    productCode: subscriptionProduct.productCode,
    offerCode,
    ...emnConfigurationStore.emnConfiguration,
  };
}

function buildHardwareOrderRequest(hardwareProduct: HardwareProduct) {
  const hardwareConfigurationStore = useHardwareStore();

  const hardwareConfig = hardwareConfigurationStore.getConfiguration;
  const hardwareSubscriptionConfig = hardwareConfig.subscription;

  const hardwareRequest: AddHardwareRequestUI = {
    offerCode: hardwareProduct.offerCode,
    productId: hardwareConfig.productId,
    productCode: hardwareConfig.productCode,
    upfrontPayment: hardwareConfig.isUpfrontPayment,
    user: hardwareConfig.user,
    availableFrom: hardwareConfigurationStore.availableFromDate,
  };

  let subscriptionRequest: Pick<
    AddToBasketRequestUI,
    "subscription" | "numberMigration" | "switchboardUser"
  > = {};

  if (hardwareSubscriptionConfig) {
    if (hardwareSubscriptionConfig.operation === "NEW_SUBSCRIPTION") {
      subscriptionRequest = buildSubscriptionOrderRequest(
        hardwareSubscriptionConfig.subscriptionProduct,
        hardwareSubscriptionConfig.subscriptionBundle.productCode
      );
    } else if (hardwareSubscriptionConfig.operation === "CHANGE_SUBSCRIPTION_IN_CART") {
      subscriptionRequest = {
        subscription: {
          existingBasketId: hardwareSubscriptionConfig.existingBasketId,
          operation: hardwareSubscriptionConfig.operation,
          commitment: hardwareSubscriptionConfig.commitmentPeriod,
          productCode: hardwareSubscriptionConfig.subscriptionProduct.productCode,
          offerCode: hardwareSubscriptionConfig.subscriptionBundle.productCode,
          // we do not care about these fields when using subscriptions in cart
          user: { firstName: "ignore_this", lastName: "ignore_this", reference: "ignore_this" },
          simcard: { type: "SIMCARD" },
        },
      };
    } else if (hardwareSubscriptionConfig.operation === "EXTEND_SUBSCRIPTION") {
      subscriptionRequest = {
        subscription: {
          commitment: hardwareSubscriptionConfig.commitmentPeriod,
          existingSubscriptionC2bUid: hardwareSubscriptionConfig.c2bUid,
          productCode: hardwareSubscriptionConfig.subscriptionProduct.productCode,
          productId: hardwareSubscriptionConfig.subscriptionProduct.id,
          offerCode: hardwareSubscriptionConfig.subscriptionBundle.productCode,
          user: hardwareSubscriptionConfig.subscriptionUser,
          operation: hardwareSubscriptionConfig.operation,
        },
      };
    }
  }
  return {
    hardware: hardwareRequest,
    ...subscriptionRequest,
  };
}

function buildAccessoryRequest(accessoryProduct: AccessoryProduct) {
  return {
    accessory: {
      productCode: accessoryProduct.productCode,
      offerCode: accessoryProduct.offerCode,
    },
  };
}

export {
  buildAccessoryRequest,
  buildSubscriptionOrderRequest,
  buildHardwareOrderRequest,
  buildEmnOrderRequest,
};
