import { defineStore } from "pinia";
import {
  Product,
  ProductConfiguration,
  isAccessoryProduct,
  isHardwareProduct,
  isSubscriptionProduct,
} from "../types";
import { computed, watchEffect } from "vue";
import { useSubscriptionConfigurationStore } from "./SubscriptionConfigurationStore";
import { useHardwareStore } from "./HardwareConfigurationStore";
import { useProductStore } from "./ProductStore";
import { useStateStore } from "./StateStore";
import { SubscriptionProductConfiguration } from "../components/product-configurator/subscription-configurator/types";
import { HardwareConfiguration } from "../components/product-configurator/hardware-configurator/types";
import { trackProductConfiguration } from "../utils/gaTracking";
import { isMobileBroadbandSubscription } from "../utils/productCategory";
import { useEmnSubscriptionStore } from "./EmnSubscriptionStore";

export const useConfigurationStore = defineStore("configuration-store", () => {
  const stateStore = useStateStore();
  const subscriptionStore = useSubscriptionConfigurationStore();
  const emnSubscriptionStore = useEmnSubscriptionStore();
  const hardwareStore = useHardwareStore();
  const productStore = useProductStore();

  const selectedProduct = computed<Product | undefined>(() => {
    return productStore.selectedProduct;
  });

  watchEffect(() => {
    if (selectedProduct.value) {
      trackProductConfiguration(selectedProduct.value);
    } else {
      unsetConfiguration();
    }
  });
  const getSelectedBundle = computed(() => productStore.getSubscriptionBundleForSubscription);

  const configuration = computed<ProductConfiguration | undefined>(() => {
    if (selectedProduct.value && isSubscriptionProduct(selectedProduct.value)) {
      return subscriptionStore.getConfiguration;
    }
    if (selectedProduct.value) {
      //Hardware and accessory are sameish
      return hardwareStore.getConfiguration;
    }
    return undefined;
  });

  const subscriptionConfiguration = computed<SubscriptionProductConfiguration>(() => {
    return subscriptionStore.getConfiguration;
  });

  const hardwareConfiguration = computed<HardwareConfiguration>(() => {
    return hardwareStore.getConfiguration;
  });

  const configurationValid = computed(() => {
    if (!selectedProduct.value) {
      return false;
    }
    const product = selectedProduct.value;
    if (isSubscriptionProduct(product)) {
      if (product.subcategory === "emn") {
        return emnSubscriptionStore.isConfigValid;
      }
      if (isMobileBroadbandSubscription(selectedProduct.value.category)) {
        return subscriptionStore.isMobileBroadbandConfigValid;
      }
      return subscriptionStore.isConfigValid;
    }
    return hardwareStore.configurationValid;
  });

  function selectProduct(product: Product) {
    stateStore.setProductCode(product.productCode);
    if (isHardwareProduct(product)) {
      hardwareStore.setVariant(product.variants[0]);
    } else if (isAccessoryProduct(product)) {
      hardwareStore.setAccessory({
        variantId: product.id,
        variantProductId: product.productCode,
        sapId: product.sapId,
      });
    }
  }

  function unsetProduct() {
    stateStore.setProductCode("");
    unsetConfiguration();
  }

  function unsetConfiguration() {
    hardwareStore.reset();
    subscriptionStore.reset();
    emnSubscriptionStore.reset();
  }

  return {
    selectedProduct,
    configuration,
    subscriptionConfiguration,
    hardwareConfiguration,
    getSelectedBundle,
    configurationValid,
    selectProduct,
    unsetProduct,
    unsetConfiguration,
  };
});
