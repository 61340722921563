import { Fees } from "../../components/product-configurator/hardware-configurator/types";
import { AddonV2 } from "../../components/product-configurator/subscription-configurator/components/addon/types";
import {
  DatasimPayload,
  EsimWatchPayload,
} from "../../components/product-configurator/subscription-configurator/types";
import {
  HardwareVariant,
  isHardwareProduct,
  SubscriptionProduct,
  HardwareProduct,
  AccessoryProduct,
} from "../../types";
import { Ga4Product, Product as ProductPayload } from "@telia/b2b-ecommerce-tracking";

import { SUBSCRIPTION_BRAND } from "../gaConstants";
import Big from "big.js";

export interface CommonPayload {
  id: string;
  name: string;
  category: string;
  quantity: number;
  variant?: string;
  discount: number;
}
interface TrackProduct {
  id: string;
  name: string;
  category: string;
}
export function buildCommonPayload(product: TrackProduct): CommonPayload {
  return {
    id: product.id,
    name: product.name,
    category: product.category,
    quantity: 1,
    discount: 0,
  };
}

export function buildSubscriptionPayload(
  product: SubscriptionProduct,
  commitment: number,
  operation: "new" | "extension" = "new"
): Ga4Product {
  const totalFee = Big(product.recurringFee)
    .mul(Big(commitment ? commitment : 1))
    .toFixed();
  return {
    ...buildCommonPayload(product),
    price: Number(totalFee),
    subCategory: product.subcategory,
    brand: SUBSCRIPTION_BRAND,
    recurringPrice: Number(product.recurringFee),
    onetimePrice: Number(product.oneTimeFee),
    newOrExtend: operation,
    commitment,
  };
}

export function buildHardwarePayload(product: HardwareProduct): Ga4Product {
  return buildVariantPayload(product, product.variants[0]);
  // product.variants.map((variant) => buildVariantPayload(product, variant));
}

export function buildVariantPayload(
  product: HardwareProduct,
  variant: HardwareVariant,
  commitment: number = 0,
  deviceFees?: Fees
): Ga4Product {
  let phoneUpfrontFee = Big(variant.unitPrice);
  if (deviceFees?.hardware.upfrontPaymentFee) {
    phoneUpfrontFee = Big(deviceFees.hardware.upfrontPaymentFee).add(
      Big(deviceFees.hardware.oneTimeFee)
    );
  }

  const recurringFee = deviceFees ? deviceFees.hardware.recurringFee : "0";
  const totalPrice = Big(phoneUpfrontFee).add(
    Big(recurringFee).mul(commitment === 0 ? 1 : commitment)
  );
  const discount = Big(variant.unitPrice).minus(totalPrice);

  return {
    ...buildCommonPayload({ ...product, id: variant.productCode }),
    variant: `${variant.memory} ${variant.color} `,
    price: Number(totalPrice.toFixed(2)),
    category: variant.category,
    subCategory: variant.subCategory,
    brand: product.brand,
    recurringPrice: Number(recurringFee),
    onetimePrice: Number(phoneUpfrontFee.toFixed(2)),
    discount: discount.lte(Big(0)) ? 0 : Number(discount),
    commitment,
  };
}

export function buildAccessoryPayload(product: AccessoryProduct): Ga4Product {
  return {
    ...buildCommonPayload(product),
    price: Number(product.unitPrice),
    subCategory: product.subCategory,
    brand: product.brand,
    recurringPrice: 0,
    onetimePrice: Number(product.unitPrice),
  };
}

export function buildAddonPayload(
  product: AddonV2 | EsimWatchPayload | DatasimPayload
): Ga4Product {
  const subCategory =
    (product as AddonV2).subcategory ?? (product as EsimWatchPayload | DatasimPayload).subCategory;
  return {
    ...buildCommonPayload(product),
    price: Number(Big(product.oneTimeFee).add(Big(product.recurringFee)).toFixed(2)),
    subCategory,
    brand: "Telia",
    recurringPrice: Number(product.recurringFee),
    onetimePrice: Number(product.oneTimeFee),
  };
}
