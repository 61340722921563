<template>
  <div
    :class="`accordion-item ${open ? 'accordion-item__active' : 'accordion-item__closed'}`"
    :aria-expanded="open"
  >
    <div :class="`body ${open ? 'body__active' : 'body__closed'}`">
      <slot />
    </div>
    <div @click="toggle" @keyup.enter="toggle" class="show-more-wrapper" tabindex="0">
      <telia-p>{{ open ? t("accordion.hide") : t("accordion.details") }}</telia-p>
      <div :class="`${open ? 'chevron' : 'chevron__open'}`">
        <telia-icon size="sm" name="chevron-up" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translate as t } from "@telia/b2b-i18n";

const open = ref<boolean>(false);

function toggle() {
  open.value = !open.value;
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.accordion-item {
  transition: all 0.2s ease-in;
  overflow: hidden;
}
.accordion-item:hover {
  cursor: pointer;
}
.label-wrapper {
  padding-top: $telia-spacing-12;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subtitle {
  transition: transform 0.2s;
  transition-timing-function: ease-in;
  transform: translateZ(0);
  &__active {
    display: none;
    transform: translateY($telia-spacing-8) translateZ(0);
  }
  &__closed {
    display: block;
  }
}
.chevron {
  transition: transform 0.3s ease;
  &__open {
    transform: rotateX(180deg);
  }
}
.body {
  overflow: hidden;
  transition: all 0.25s ease-in-out;

  &__active {
    overflow: visible;
    display: block;
    max-height: 100vh;
    padding-bottom: $telia-spacing-12;
    cursor: default;
  }
  &__closed {
    max-height: 0;
    visibility: hidden;
  }
}
.show-overflow {
  overflow: visible;
}
.show-more-wrapper {
  display: flex;
  gap: $telia-spacing-8;
  align-items: center;
  color: $telia-purple-700;
}
</style>
