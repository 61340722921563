<template>
  <div class="switchboard__container">
    <SwitchboardToggleNew
      :is-switchboard-selected="isSwitchboardSelected"
      :switchboard-display-name="switchboardOptionName"
      :switchboard-price="switchboardPrice"
      :is-excluded="isExcluded"
      :selected-switchboard-id="selectedSwitchboard?.switchboardId"
      :switchboard-bundles="switchboardBundles"
      @selected-switchboard-bundle="handleSelectBundle"
      @unset-switchboard="handleUnsetBundle"
    />
    <b2b-switchboard
      data-testid="switchboard-addon-selector"
      v-if="isSwitchboardSelected"
      :addonBundles="bundleOptions"
      :fixedNumberBundle="fixedNumberOption"
      :huntingGroups="huntingGroupOptions"
      @select-addon="handleSelectAddon"
      @select-fixed-number-addon="handleSelectFixedNumberAddon"
      @select-fixed-phonenumber="handleSelectFixedPhoneNumber"
      @search-fixed-phonenumber="handleFixedNumberSearch"
      @select-hunting-group="handleSelectHuntingGroup"
    ></b2b-switchboard>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import {
  SwitchboardAddonBundle,
  FixedNumberBundleType,
  AddonType,
  AddonBundleType,
  HuntingGroup,
  HuntingGroupType,
} from "./switchboard-types";
import SwitchboardToggleNew from "./SwitchboardToggleNew.vue";
import { addonOneTimeFee, addonRecurringFeeInfo } from "../../../../../../utils/formatUtils";
import { SubscriptionAddon, SubscriptionProductAddonBundle } from "../../../../../../types";
import { SubscriptionAddonBundleUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { addonFeeSummary } from "../../../shared/services/addon-fee";
import { translate as t } from "@telia/b2b-i18n";
import { watchEffect } from "vue";
import { fetchHuntingGroups } from "../../../shared/services/switchboard.service";
import { storeToRefs } from "pinia";
import { useSwitchboardStore } from "../../../../../../store/SwitchboardConfigurationStore";
import { useFixedNumberHelper } from "../../../shared/services/fixed-number.service";
import { formatToFixedNumber } from "../../../shared/services/number-formatter.service";

type Props = {
  tscid: string;
  scopeId: string;
  excludedAddonProductCodes: string[];
  switchboardBundles: SwitchboardAddonBundle[];
};

const props = defineProps<Props>();
const switchboardStore = useSwitchboardStore();
const {
  selectedSwitchboard,
  selectedAddonIds,
  selectedAddons,
  selectedHuntingGroups,
  selectedFixedNumber,
  fixedNumberBooking,
  instance,
} = storeToRefs(switchboardStore);

const {
  booking,
  errorMessage,
  isFetchingNumbers,
  noNumbersAvailable,
  bookFixedNumber,
  releaseAndBookfixedNumber,
  releaseFixedNumbers,
  extendBooking: doExtendBooking,
} = useFixedNumberHelper();

const extendTimer = ref<number>();
const extendBookingTimeoutMinutes = ref(7);
const availableHuntingGroups = ref<HuntingGroup[]>([]);
const isSwitchboardSelected = ref(false);

const fixedNumberErrorMessage = computed(() => {
  return errorMessage.value ? t(errorMessage.value) : undefined;
});

const selectedSwitchboardPackage = computed(() => selectedSwitchboard.value?.addons[0]);

const bundleOptions = computed(() => {
  if (selectedSwitchboard.value) {
    return selectedSwitchboard.value.addonBundles
      .filter((bundle) => !isFixedNumberBundle(bundle))
      .map(bundleConverter);
  }
  return [];
});

const fixedNumberOption = computed<FixedNumberBundleType | undefined>(() => {
  if (selectedSwitchboard.value) {
    const fixedNumberBundle = selectedSwitchboard.value.addonBundles.find(isFixedNumberBundle);
    return fixedNumberBundle
      ? fixedNumberBundleConverter(
          fixedNumberBundle,
          isFetchingNumbers.value,
          fixedNumberBooking.value?.numbers,
          fixedNumberErrorMessage.value,
          selectedFixedNumber.value
        )
      : undefined;
  }
  return undefined;
});

const huntingGroupOptions = computed(() => {
  return availableHuntingGroups.value.map(huntingGroupConverter);
});

const switchBoardType = computed(() => {
  if (selectedSwitchboardPackage.value) {
    return selectedSwitchboardPackage.value?.subcategory === "ucuser" ? "TP" : "SC";
  }
  return undefined;
});

const switchboardOptionName = computed(() => {
  if (props.switchboardBundles.length) {
    return props.switchboardBundles[0].addons[0].name;
  }
  return "";
});

const switchboardPrice = computed(() => {
  const switchboardMainAddon = props.switchboardBundles[0].addons[0];
  return {
    oneTimeFee: addonOneTimeFee(switchboardMainAddon.oneTimeFee),
    recurringFee: addonRecurringFeeInfo(switchboardMainAddon.recurringFee),
  };
});

const isExcluded = computed(() => {
  return selectedSwitchboardPackage.value
    ? isAddonExcluded(selectedSwitchboardPackage.value.productCode)
    : false;
});

function isAddonExcluded(addonId: string) {
  return props.excludedAddonProductCodes.includes(addonId);
}

function handleSelectBundle(switchboardId: string) {
  if (selectedSwitchboard.value && switchboardId !== selectedSwitchboard.value.switchboardId) {
    releaseNumbers();
    switchboardStore.clearSwitchboardSpecifics();
  }
  isSwitchboardSelected.value = true;
  switchboardStore.setSelectedSwitchboard(
    props.switchboardBundles.find(
      (switchboardBundle) => switchboardBundle.switchboardId === switchboardId
    ) as SwitchboardAddonBundle
  );
}

function handleUnsetBundle() {
  isSwitchboardSelected.value = false;
  releaseNumbers();
  switchboardStore.clearSwitchboardSelection();
}

function handleSelectAddon(addonSelectEvent: CustomEvent<string[]>) {
  const addonId = addonSelectEvent.detail[0];
  if (selectedAddonIds.value.includes(addonId)) {
    switchboardStore.removeAddon(addonId);
  } else {
    switchboardStore.addAddon(addonId);
  }
}

function handleSelectFixedNumberAddon(fixedNumberAddonId: CustomEvent<string[]>) {
  const addonId = fixedNumberAddonId.detail[0];
  if (selectedAddonIds.value.includes(addonId)) {
    switchboardStore.removeAddon(addonId);
    releaseNumbers();
  } else {
    switchboardStore.addAddon(addonId);
    if (!fixedNumberBooking.value) {
      bookFixedNumberSwitchboard();
    }
  }
}

function handleSelectHuntingGroup(huntingGroupSelectEvent: CustomEvent<string[]>) {
  const huntingGroupId = huntingGroupSelectEvent.detail[0];
  const huntingGroup = availableHuntingGroups.value.find(
    (huntingGroup) => huntingGroup.id === huntingGroupId
  );
  if (huntingGroup) {
    if (selectedHuntingGroups.value.some((huntingGroup) => huntingGroup.id === huntingGroupId)) {
      switchboardStore.removeHuntingGroup(huntingGroupId);
    } else {
      switchboardStore.addHuntingGroup(huntingGroup);
    }
  }
}

function handleSelectFixedPhoneNumber(fixedNumberEvent: CustomEvent<string[]>) {
  const fixedNumber = fixedNumberEvent.detail[0];
  switchboardStore.setSelectedFixedNumber(fixedNumber);
}

function handleFixedNumberSearch(numberSearchEvent: CustomEvent<string[]>) {
  const fixedNumberSearch = numberSearchEvent.detail[0];
  releaseAndBookFixedNumberSwitchboard(fixedNumberSearch);
}

function fixedNumberBundleConverter(
  addonBundle: SubscriptionAddonBundleUI,
  fetchingNumbers: boolean,
  fixedNumbers: string[] | undefined,
  fixedNumberError: string | undefined,
  selectedNumber: string
): FixedNumberBundleType {
  const baseBundle = bundleConverter(addonBundle);
  return {
    ...baseBundle,
    searchErrorMessage: fixedNumberError,
    fetchingPhoneNumbers: fetchingNumbers,
    fixedNumbers,
    selectedNumber,
  };
}

function bundleConverter(addonBundle: SubscriptionProductAddonBundle): AddonBundleType {
  return {
    id: addonBundle.productCode,
    name: addonBundle.name,
    addons: addonBundle.addons.map(addonConverter),
  };
}

function huntingGroupConverter(huntingGroup: HuntingGroup): HuntingGroupType {
  return {
    ...huntingGroup,
    isSelected: selectedHuntingGroups.value.some((hg) => hg.id === huntingGroup.id),
  };
}
function addonConverter(addon: SubscriptionAddon): AddonType {
  const isInfobox = addon.displayType === "infobox";
  const isMandatory = !isInfobox && addon.mandatory;
  const isSelected = selectedAddonIds.value.includes(addon.id);
  const disabled =
    addon.subcategory === "fixednumber"
      ? noNumbersAvailable.value
      : isAddonExcluded(addon.productCode);

  return {
    id: addon.id,
    productCode: addon.productCode,
    name: addon.name,
    price: addonFeeSummary(addon, t),
    isSelected,
    isMandatory,
    isInfobox,
    disabled,
  };
}

// Fixed number stuff
function isFixedNumberBundle(bundle: SubscriptionProductAddonBundle) {
  return (
    bundle.subcategory === "fixednumber" ||
    (bundle.addons?.some((addon) => addon.subcategory === "fixednumber") as boolean)
  );
}

async function releaseAndBookFixedNumberSwitchboard(searchString?: string) {
  if (fixedNumberBooking.value) {
    await releaseAndBookfixedNumber({
      instanceId: instance.value,
      scopeId: props.scopeId,
      tscid: props.tscid,
      switchboardType: switchBoardType.value,
      bookingId: fixedNumberBooking.value.bookingId,
      searchString: searchString ? formatToFixedNumber(searchString) : undefined,
    });
    if (booking.value) {
      switchboardStore.setFixedNumberBooking(booking.value);
    }
  } else {
    bookFixedNumberSwitchboard(searchString);
  }
}

async function bookFixedNumberSwitchboard(searchString?: string) {
  await bookFixedNumber({
    searchString: searchString,
    instanceId: instance.value,
    scopeId: props.scopeId,
    tscid: props.tscid,
    switchboardType: switchBoardType.value,
  });
  if (booking.value) {
    switchboardStore.setFixedNumberBooking(booking.value);
  }
}

async function releaseNumbers(): Promise<void> {
  if (fixedNumberBooking.value) {
    try {
      await releaseFixedNumbers({
        bookingId: fixedNumberBooking.value.bookingId,
        instanceId: instance.value,
        switchboardType: switchBoardType.value,
        scopeId: props.scopeId,
        tscid: props.tscid,
      });
      switchboardStore.clearNumberBooking();
    } catch (error) {
      console.log(error);
    }
  }
}

async function extendBookingTime() {
  if (fixedNumberBooking.value) {
    try {
      await doExtendBooking({
        instanceId: instance.value,
        scopeId: props.scopeId,
        tscid: props.tscid,
        switchboardType: switchBoardType.value,
        bookingId: fixedNumberBooking.value.bookingId,
      });
      extendBookingOnTimeout();
    } catch {
      // If we cannot extend the booking we need to start
      // over with a new booking.
      clearTimeout(extendTimer.value);
      switchboardStore.clearNumberBooking();
      bookFixedNumberSwitchboard();
    }
  }
}

function extendBookingOnTimeout() {
  const timeout = extendBookingTimeoutMinutes.value * 60 * 1000;
  extendTimer.value = setTimeout(extendBookingTime, timeout);
}

watchEffect(async () => {
  if (selectedSwitchboard.value?.subcategory === "ucuser") {
    availableHuntingGroups.value = await fetchHuntingGroups(
      props.scopeId,
      selectedSwitchboard.value.c2buid
    );
  }
});

watch(
  () => instance.value,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await releaseAndBookFixedNumberSwitchboard();
    }
  }
);
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.switchboard {
  &__container {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }
}
</style>
