<template>
  <section class="sorting-and-filtering-container">
    <div class="sorting-and-filtering-primary">
      <div
        class="sorting-and-filtering-filter-wrapper"
        v-if="primaryOptions && primaryOptions.length"
      >
        <PrimaryFilter
          :options="primaryOptions"
          @selected-option="emit('set-primary-filter', $event)"
        />
      </div>
      <SortingSelector
        t-id="order-product-container-sorting-selector"
        class="sorting-and-filtering__sorting-selector"
        @set-sorting-method="onSetSortingMethod"
        :default-method="defaultSortingMethod"
      />
    </div>
    <div
      v-if="secondaryOptions && secondaryOptions.length"
      class="sorting-and-filtering__right-side"
    >
      <SecondaryFilter
        :options="secondaryOptions"
        :query-parameter-name="secondaryFilterQueryParameterName"
        @selected-options="emit('set-secondary-filter', $event)"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import PrimaryFilter from "./PrimaryFilter.vue";
import SecondaryFilter from "./SecondaryFilter.vue";
import SortingSelector from "./SortingSelector.vue";
import { SortingOption } from "./types";

interface Props {
  primaryOptions: string[];
  secondaryOptions?: Option[];
  secondaryFilterQueryParameterName: string;
  defaultSortingMethod: SortingOption;
}
interface Option {
  name: string;
  value: string;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "set-sorting-method", value: SortingOption): void;
  (e: "set-primary-filter", value: string): void;
  (e: "set-secondary-filter", value: string[]): void;
}>();

function onSetSortingMethod(sortingMethod: SortingOption) {
  trackSortingMethod(sortingMethod);
  emit("set-sorting-method", sortingMethod);
}
function trackSortingMethod(sortingMethod: SortingOption) {
  let payload: Record<string, string> = {
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
  };

  analytics.trackEvent(
    analytics.category.SALESFLOW,
    analytics.action.CLICK,
    sortingMethod,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}
</script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

@media screen and (min-width: 768px) {
  .sorting-and-filtering-primary {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-bottom: $telia-spacing-24;
  }
}

@media not screen and (min-width: 768px) {
  .sorting-and-filtering-primary {
    margin-bottom: $telia-spacing-8;
  }

  .sorting-and-filtering-filter-wrapper {
    margin-bottom: $telia-spacing-8;
  }
}

.primary-filter-button {
  margin: 0 $telia-spacing-8 0 0;

  @media screen and (min-width: 768px) {
    &--all {
      margin-right: $telia-spacing-24;
    }
  }
}

.sorting-and-filtering__sorting-selector {
  margin-bottom: $telia-spacing-12;
}

.sorting-and-filtering__right-side {
  margin-bottom: auto;
}
</style>

<style lang="scss">
.primary-filter-button .tc-selection-button__content {
  justify-content: center;
}

@media not screen and (min-width: 768px) {
  .primary-filter-button .tc-selection-button__content {
    min-width: 10rem;
  }
}
</style>
