import { currentLanguage } from "@telia/b2b-i18n";
import { corpProductOrder, corpStockStatus } from "@telia/b2b-rest-client";
import {
  AccessoryProduct,
  DevicePrice,
  HardwareBundle,
  NumberMigrationProduct,
  ProductStockStatus,
  SubscriptionBundle,
  SubscriptionProduct,
} from "../types";
import { AccessoryListingConfiguration } from "../components/product-category/accessory/types";
import {
  AgreementAsDocResponseUI,
  AgreementResponseUI,
  GetEmnSiteUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { useStateStore } from "../store/StateStore";
import { onMounted, ref } from "vue";

export async function getSubscriptionProducts(
  scopeId: string,
  tscid: string
): Promise<SubscriptionBundle[]> {
  return corpProductOrder.ProductControllerService.getSubscriptionProducts(
    scopeId,
    tscid,
    currentLanguage().toUpperCase()
  ) as Promise<SubscriptionBundle[]>;
}

export async function getEmnSubscriptionProducts(
  scopeId: string,
  tscid: string
): Promise<SubscriptionBundle[]> {
  return corpProductOrder.ProductControllerService.getEmnSubscriptionProducts(
    scopeId,
    tscid,
    currentLanguage().toUpperCase()
  ) as Promise<SubscriptionBundle[]>;
}

export async function getEmnSites(scopeId: string, tscid: string): Promise<GetEmnSiteUI[]> {
  return corpProductOrder.EmnControllerService.getEmnSites(scopeId, tscid);
}

export async function getExtendedSubscription(
  scopeId: string,
  tscid: string,
  offerCode: string,
  productCode: string
) {
  try {
    const body = {
      subscriptionOfferProductCode: [
        {
          offer: offerCode,
          productCode: productCode,
        },
      ],
    };
    const res = await corpProductOrder.ProductControllerService.getExtendedSubscriptionProducts(
      scopeId,
      tscid,
      body,
      currentLanguage().toLocaleUpperCase()
    );
    const product = res[0].products?.find(
      (subscriptionWithAddons) => subscriptionWithAddons.productCode === productCode
    );
    if (product) {
      return product as SubscriptionProduct;
    }
    return undefined;
  } catch (e) {
    throw new Error();
  }
}

export async function getHardwareProducts(
  scopeId: string,
  tscid: string
): Promise<HardwareBundle[]> {
  return corpProductOrder.ProductControllerService.getHardwareProducts(
    scopeId,
    tscid,
    currentLanguage()
  ) as Promise<HardwareBundle[]>;
}
interface AccessoryResponse {
  accessories: AccessoryProduct[];
  totalItemCount: number;
}
export async function getAccessoriesProducts(
  scopeId: string,
  tscid: string,
  filter: AccessoryListingConfiguration
): Promise<AccessoryResponse> {
  try {
    const { content, totalItemCount } =
      await corpProductOrder.ProductControllerService.getAccessoriesProducts(
        scopeId,
        tscid,
        currentLanguage(),
        filter.pagination.page,
        filter.pagination.pageSize,
        filter.accessoryDeviceFilter,
        filter.accessoryCategoryFilter !== "" ? filter.accessoryCategoryFilter : undefined,
        filter.sortBy
      );
    const acc = content as AccessoryProduct[];
    return { accessories: acc, totalItemCount: totalItemCount as number };
  } catch (e) {
    throw new Error();
  }
}

export async function getAccessoryCategories(scopeId: string, tscid: string) {
  try {
    const res = await corpProductOrder.ProductControllerService.getAccessoryCategories(
      scopeId,
      tscid,
      currentLanguage()
    );

    return res.map((category) => category.accessoryCategory) as string[];
  } catch (e) {
    throw new Error();
  }
}

type Language = "SV" | "EN";

export async function getRecommendedAccessoriesProducts(
  scopeId: string,
  tscid: string,
  productCode: string
): Promise<AccessoryProduct[]> {
  return corpProductOrder.ProductControllerService.getRecommendedAccessoriesProducts(
    scopeId,
    tscid,
    productCode,
    currentLanguage() as Language
  ) as Promise<AccessoryProduct[]>;
}

export async function getMigrationProducts(scopeId: string, tscid: string) {
  try {
    const { products } = await corpProductOrder.ProductControllerService.getProcessProducts(
      scopeId,
      tscid
    );
    return (products as NumberMigrationProduct[]) ?? [];
  } catch (e) {
    // TODO maybe funnel fail
    throw new Error();
  }
}

export interface StockStatusRequest {
  quantity: number;
  sapId: string;
}
export async function getStockStatus(request: StockStatusRequest[]): Promise<ProductStockStatus[]> {
  return corpStockStatus.StockStatusControllerService.getMultiUsingPost(request) as Promise<
    ProductStockStatus[]
  >;
}

export async function getPriceForDevice(
  scopeId: string,
  tscid: string,
  offerCode: string,
  productCode: string,
  periods: string[]
): Promise<DevicePrice> {
  return corpProductOrder.ProductControllerService.getPricesForMobileDevice(
    scopeId,
    tscid,
    offerCode,
    productCode,
    periods
  ) as Promise<DevicePrice>;
}

export async function isBlacklistedForFraud(scopeId: string, tscid: string) {
  return corpProductOrder.OrganizationInformationControllerService.isBlackListed(scopeId, tscid);
}

export async function getCreditCheck(scopeId: string, tscid: string) {
  return corpProductOrder.OrganizationInformationControllerService.getCreditCheck(scopeId, tscid);
}

export async function getAgreements(scopeId: string, tscid: string) {
  const stateStore = useStateStore();
  const response = await corpProductOrder.AgreementControllerService.getAgreement(scopeId, tscid);
  if (response.agreement?.status !== "Active") {
    throw new Error("AGREEMENT_NOT_ACTIVE");
  }
  stateStore.setAgreement(response);
  return response;
}

export async function getAgreementDocuments(
  scopeId: string,
  tscid: string,
  contractNumber: string
) {
  return corpProductOrder.AgreementControllerService.getAgreementAsDoc(
    scopeId,
    tscid,
    contractNumber
  );
}

export const useAgreementDocuments = (scopeId: string, tscid: string) => {
  const agreementResponse = ref<AgreementResponseUI>();
  const agreementDocumentResponse = ref<AgreementAsDocResponseUI>();
  const fetchingAgreementDocuments = ref(true);

  onMounted(async () => {
    try {
      agreementResponse.value = await getAgreements(scopeId, tscid);
      getAgreementDocument();
    } catch (e) {
      agreementResponse.value = undefined;
    }
  });

  async function getAgreementDocument() {
    const contractNumber = agreementResponse.value?.agreement?.contractNumber;
    if (contractNumber) {
      try {
        fetchingAgreementDocuments.value = true;
        agreementDocumentResponse.value = await getAgreementDocuments(
          scopeId,
          tscid,
          contractNumber
        );
      } catch (e) {
        throw new Error("Failed to get agreements");
      } finally {
        fetchingAgreementDocuments.value = false;
      }
    }
  }

  return { getAgreementDocument, agreementDocumentResponse, fetchingAgreementDocuments };
};
