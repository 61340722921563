import { storeToRefs } from "pinia";
import { ApiError } from "../types";
import { SubscriptionProductConfiguration } from "../components/product-configurator/subscription-configurator/types";
import { addEmnToBasket, addProductToBasket, updateAmandaBasket } from "../service/BasketService";
import { useConfigurationStore } from "../store/ConfigurationStore";
import { useStateStore } from "../store/StateStore";
import {
  AccessoryProduct,
  HardwareProduct,
  Product,
  SubscriptionBundle,
  SubscriptionProduct,
  isHardwareProduct,
  isSubscriptionProduct,
} from "../types";
import { ref } from "vue";
import {
  trackAddAccessoryToBasket,
  trackAddHardware,
  trackAddSubscriptionToBasket,
} from "./gaTracking";
import { useGa4Helper } from "./tracking/gaHelper";
import {
  buildAccessoryRequest,
  buildEmnOrderRequest,
  buildHardwareOrderRequest,
  buildSubscriptionOrderRequest,
} from "./basketRequestUtil";
import { useProductStore } from "../store/ProductStore";
import { useFlag } from "@unleash/proxy-client-vue";

export function useBasketHelper(newSwitchboardEnabled: boolean = false) {
  const stateStore = useStateStore();
  const { tscid, scopeId, customerNumber } = storeToRefs(stateStore);
  const productStore = useProductStore();
  const configStore = useConfigurationStore();
  const { subscriptionConfiguration, selectedProduct } = storeToRefs(configStore);
  const isAddingItemToBasket = ref(false);
  const showConfirmation = ref(false);
  const addToBasketError = ref<ApiError | undefined>();
  const ga4Helper = useGa4Helper();

  async function addToBasket(element: HTMLElement | null) {
    if (selectedProduct.value) {
      try {
        isAddingItemToBasket.value = true;
        addToBasketError.value = undefined;
        await productSpecificAddToBasket(selectedProduct.value);
        trackAddToBasket(selectedProduct.value, subscriptionConfiguration.value);
        showConfirmation.value = true;
        updateAmandaBasket(element);
      } catch (error: any) {
        ga4Helper.trackAddToBasketFail(selectedProduct.value);
        addToBasketError.value = error;
      }
      isAddingItemToBasket.value = false;
    }
  }

  async function productSpecificAddToBasket(product: Product) {
    if (isHardwareProduct(product)) {
      return addHardwareToBasket(product);
    } else if (isSubscriptionProduct(product)) {
      return addSubscriptionToBasket(product);
    } else {
      return addAccessoryToBasket(product);
    }
  }

  async function addSubscriptionToBasket(product: SubscriptionProduct) {
    if (product.subcategory === "emn") {
      const offerCode = productStore.getOfferCodeForEMNSubscription(product.productCode);
      const emnRequest = buildEmnOrderRequest(product, offerCode, customerNumber.value);
      return addEmnToBasket(scopeId.value, tscid.value, emnRequest);
    }
    const selectedBundle = configStore.getSelectedBundle(product.productCode) as SubscriptionBundle;
    const basketRequest = {
      customerNumber: customerNumber.value,
      ...buildSubscriptionOrderRequest(product, selectedBundle?.productCode, newSwitchboardEnabled),
    };
    return addProductToBasket(scopeId.value, tscid.value, basketRequest);
  }

  async function addHardwareToBasket(product: HardwareProduct) {
    const hardwareBasketRequest = {
      customerNumber: customerNumber.value,
      ...buildHardwareOrderRequest(product),
    };
    return addProductToBasket(scopeId.value, tscid.value, hardwareBasketRequest);
  }

  async function addAccessoryToBasket(product: AccessoryProduct) {
    const accessoryBasketRequest = {
      customerNumber: customerNumber.value,
      ...buildAccessoryRequest(product),
    };
    return addProductToBasket(scopeId.value, tscid.value, accessoryBasketRequest);
  }

  function trackAddToBasket(
    selectedProduct: Product,
    subscriptionConfiguration: SubscriptionProductConfiguration
  ) {
    const {
      trackAddHardwareToCart,
      trackAddAccessoryToCart,
      trackAddSubscriptionToCart,
      trackAddEmnToCart,
    } = ga4Helper;
    if (isSubscriptionProduct(selectedProduct)) {
      if (selectedProduct.subcategory === "emn") {
        trackAddEmnToCart(selectedProduct);
      } else {
        trackAddSubscriptionToCart(selectedProduct);
        trackAddSubscriptionToBasket(
          selectedProduct,
          subscriptionConfiguration.addonsV2,
          [...subscriptionConfiguration.datasims, ...subscriptionConfiguration.esimWatches],
          subscriptionConfiguration.numberMigration?.migrationProduct,
          subscriptionConfiguration.switchboardUser
        );
      }
    } else if (isHardwareProduct(selectedProduct)) {
      trackAddHardwareToCart(selectedProduct);
      trackAddHardware(selectedProduct);
    } else {
      trackAddAccessoryToCart(selectedProduct);
      trackAddAccessoryToBasket(selectedProduct);
    }
  }

  return { addToBasket, isAddingItemToBasket, showConfirmation, addToBasketError };
}
