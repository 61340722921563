<template>
  <div>
    <telia-heading v-if="showHeading" tag="h2" variant="title-200" class="title">
      {{ t("configurator.commitment") }}
    </telia-heading>
    <telia-p v-if="additionalText" variant="paragraph-100" class="sub-title">
      {{ additionalText }}
    </telia-p>
    <ConfiguratorRow>
      <telia-chip-choice
        v-if="noCommitmentAllowed"
        data-testid="no-commitment"
        :text="t('configurator.subscriptionNoCommitment')"
        :checked="selectedCommitment === 0"
        @change="() => emit('select-commitment-period', 0)"
      />
      <telia-chip-choice
        v-for="commitment in validCommitments"
        :key="commitment"
        :data-testid="'commitment-' + commitment"
        :text="t('configurator.subscriptionMonths', { period: commitment })"
        :checked="commitment === selectedCommitment"
        @change="() => emit('select-commitment-period', commitment)"
      />
    </ConfiguratorRow>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { computed } from "vue";
import ConfiguratorRow from "../../shared/ConfiguratorRow.vue";

interface Props {
  selectedCommitment: number;
  commitments: number[];
  noCommitmentAllowed?: boolean;
  showHeading?: boolean;
  additionalText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  noCommitmentAllowed: false,
  showHeading: true,
  additionalText: undefined,
});
const emit = defineEmits<{ (event: "select-commitment-period", value: number): void }>();

const validCommitments = computed(() => {
  return props.commitments.filter((commitment) => commitment !== 0);
});
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.title {
  margin-bottom: $telia-spacing-24;
}

.sub-title {
  margin-bottom: $telia-spacing-24;
}
</style>
