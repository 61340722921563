<template>
  <div class="simcard-selector">
    <ConfiguratorRow>
      <b2x-radio-card
        t-id="choice__new-sim"
        class="simcard-option"
        value="new-sim"
        data-testid="new-sim"
        :label="t('simcardSelector.newSim.heading')"
        :name="`sim-option-${identifier}`"
        :checked="isChecked('new-sim')"
        @click="() => updateChoice('new-sim')"
      >
        <div>
          <telia-p>{{ t("simcardSelector.newSim.subtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
      <b2x-radio-card
        t-id="choice__existing-simcard"
        class="simcard-option"
        value="existing-sim"
        data-testid="existing-sim"
        :label="t('simcardSelector.existingSim.heading')"
        :name="`sim-option-${identifier}`"
        :checked="isChecked('existing-sim')"
        @click="() => updateChoice('existing-sim')"
      >
        <div>
          <telia-p>{{ t("simcardSelector.existingSim.subtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
      <b2x-radio-card
        t-id="choice__new-esim"
        class="simcard-option"
        value="new-esim"
        data-testid="new-esim"
        :name="`sim-option-${identifier}`"
        :checked="isChecked('new-esim')"
        :subtitle="t('simcardSelector.newESim.subtitle')"
        :label="t('simcardSelector.newESim.heading')"
        @click="() => updateChoice('new-esim')"
      >
        <div>
          <telia-p>{{ t("simcardSelector.newESim.subtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
    </ConfiguratorRow>
    <div v-if="simChoice === 'new-esim'">
      <div t-id="e-sim-body" class="card__content" v-if="simChoice === 'new-esim'">
        <div class="card__text">
          <telia-p class="card__title" variant="paragraph-100"
            ><span>{{ t("mobile.simcardSelector.esimInfoHeader") }}</span>
          </telia-p>
          <telia-p variant="paragraph-100">{{ t("mobile.simcardSelector.esimInfo") }}</telia-p>
          <telia-p variant="paragraph-100">{{
            t("mobile.simcardSelector.esimQRCodeCaption")
          }}</telia-p>
        </div>
        <div class="esim-mail">
          <telia-radio-button
            t-id="esim__user-email-radiobutton"
            :disabled="!preloadedUserEmail"
            :name="identifier"
            value="USER_EMAIL"
            :checked="esimEmailChoiceIsChecked('USER_EMAIL')"
            :label="
              !preloadedUserEmail
                ? t('mobile.simcardSelector.existingEmailError')
                : `${t('mobile.simcardSelector.existingEmailRadioButton')} (${preloadedUserEmail})`
            "
            @input="updateEsimEmailChoice"
          />
          <telia-radio-button
            t-id="esim__new-email-radiobutton"
            :name="identifier"
            value="NEW_EMAIL"
            :checked="esimEmailChoiceIsChecked('NEW_EMAIL')"
            :label="t('mobile.simcardSelector.otherEmailRadioButton')"
            @input="updateEsimEmailChoice"
          />
          <div class="card__new-email-input-container" v-if="esimEmailChoice === 'NEW_EMAIL'">
            <telia-text-input
              t-id="esim__new-email-input"
              :label="t('mobile.simcardSelector.otherEmailInputLabel')"
              :required-error-message="t('general.fieldIsRequired')"
              required
              additional="Ex. epost@exempel.se"
              type="email"
              inputmode="email"
              show-valid-icon
              :error-message="translatedErrorMessage"
              @input="emailInput"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="simChoice === 'existing-sim'">
      <div t-id="existing-simcard-body" class="card__content" v-if="simChoice === 'existing-sim'">
        <div class="card__text">
          <telia-p variant="paragraph-100"
            >{{ t("mobile.simcardSelector.existingSimcardInfo") }}
          </telia-p>
          <telia-p variant="paragraph-100"
            >{{ t("mobile.simcardSelector.existingSimcardIccInfo") }}
          </telia-p>
        </div>
        <telia-text-input
          t-id="existing-simcard__iccInput"
          :value="iccNumber"
          label="894601xxxxxxxxxxxxxx"
          maxlength="20"
          inputmode="numeric"
          required
          :required-error-message="t('general.fieldIsRequired')"
          :error-message="translatedErrorMessage"
          :additional="verifyIccMessage"
          @input="iccInput"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { translate } from "@telia/b2b-i18n";
import { computed, onBeforeMount, ref } from "vue";

import { GetSimChoicePayload } from "../../../utils/GetSimChoicePayload";
import { isValid as choiceIsValid } from "../../../utils/sim-choice-validator";
import { SimcardPayload } from "../../../utils/SimChoicePayload";
import { EsimEmailChoiceType, SimChoiceType } from "../../../utils/constants";
import { getUserEmail } from "../../../utils/utils";

import ConfiguratorRow from "../../../../../shared/ConfiguratorRow.vue";

const emit = defineEmits<{
  (e: "valid", isValid: boolean): void;
  (e: "change", payload: SimcardPayload): void;
}>();
defineProps<{ identifier: string }>();
let t = translate;

const simChoice = ref<SimChoiceType>("new-sim");
const esimEmailChoice = ref<EsimEmailChoiceType>("USER_EMAIL");
const errorMessage = ref("");
const iccNumber = ref("");
const preloadedUserEmail = ref("");
const userEmailInput = ref("");
const iccLoading = ref(false);
const isSimValid = ref(false);

const translatedErrorMessage = computed(() => {
  return errorMessage.value.length > 0 ? t(errorMessage.value) : "";
});

const verifyIccMessage = computed(() => {
  if (iccLoading.value) {
    return t("mobile.simcardSelector.iccVerifying");
  } else if (isSimValid.value) {
    return t("mobile.simcardSelector.iccVerified");
  }
  return "";
});

onBeforeMount(async () => {
  await preloadUserEmail();
  await updateChoice(simChoice.value);
});

async function preloadUserEmail() {
  try {
    preloadedUserEmail.value = (await getLoggedInUserInfo()).mail;
  } catch (_) {
    esimEmailChoice.value = "NEW_EMAIL";
  }
}
async function updateChoice(updatedSimChoice: SimChoiceType) {
  simChoice.value = updatedSimChoice;
  await emitValidity(simChoice.value);
  emitChoice(simChoice.value);
}
function emitChoice(simChoice: SimChoiceType) {
  const emitPayload = GetSimChoicePayload(simChoice, {
    iccNumber: iccNumber.value,
    deliveryEmailAddress: getUserEmail(
      esimEmailChoice.value,
      preloadedUserEmail.value,
      userEmailInput.value
    ),
  });
  emit("change", emitPayload);
}
async function emitValidity(simChoice: SimChoiceType) {
  const { error, isValid } = await choiceIsValid(simChoice, {
    deliveryEmailAddress: getUserEmail(
      esimEmailChoice.value,
      preloadedUserEmail.value,
      userEmailInput.value
    ),
    iccNumber: iccNumber.value,
  });
  isSimValid.value = isValid;

  if (error) {
    errorMessage.value = error;
  }
  emit("valid", !!isValid);
}
function iccInput(event: { target: { value: string } }) {
  errorMessage.value = "";
  iccNumber.value = event.target.value;
  updateChoiceExistingSim();
}
function emailInput(event: { target: { value: string } }) {
  errorMessage.value = "";
  userEmailInput.value = event.target.value;
  updateChoice("new-esim");
}
async function updateChoiceExistingSim() {
  iccLoading.value = true;
  await updateChoice("existing-sim");
  iccLoading.value = false;
}
function updateEsimEmailChoice(event: { target: { value: EsimEmailChoiceType } }) {
  esimEmailChoice.value = event.target.value;
}
function isChecked(simChoiceParam: string) {
  return simChoiceParam === simChoice.value;
}
function esimEmailChoiceIsChecked(esimEmailChoiceParam: string) {
  return esimEmailChoiceParam === esimEmailChoice.value;
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/typography/variables";

.recipient {
  margin-top: $telia-spacing-24;

  &__heading span {
    font-weight: $telia-typography-weight-bold;
  }
}

.card {
  &__content {
    padding: $telia-spacing-12 $telia-spacing-12 $telia-spacing-24;
  }

  &__title {
    span {
      font-weight: $telia-typography-weight-bold;
    }
  }

  &__text {
    margin-bottom: $telia-spacing-12;

    telia-p + telia-p {
      margin-top: $telia-spacing-16;
    }
  }

  &__new-email-input-container {
    margin-top: $telia-spacing-8;
  }
}

.simcard-option {
  flex: 1 1 30%;
  & .radio-card {
    height: 100%;
  }
}

.simcard-selector {
  margin-bottom: $telia-spacing-24;
}

.esim-mail {
  display: grid;
  grid-gap: $telia-spacing-8;
}
</style>
<style lang="scss">
.simcard-option {
  & .radio-card {
    height: 100%;
  }
}
</style>
