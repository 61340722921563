import { MobileUser } from "@telia/b2b-rest-client/dist/corp-mobile-customer-service";
import {
  AgreementResponseUI,
  AgreementUI,
  BasketSubscriptionLineUI,
  BasketUI,
  GalleryUI,
  GetAccessoriesAccessoryProductUI,
  GetHardwareProductsHardwareProductUI,
  GetProcessProductsProcessProductUI,
  GetProductsAddonUI,
  GetProductsSubscriptionProductUI,
  HardwareProductBundleUI,
  HardwareProductVariantUI,
  MobileDevicePricesUI,
  SubscriptionAddonBundleUI,
  SubscriptionProductBundleUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { BasketDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { GetOrganizationResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { DeliveryTime, ProductAvailability } from "@telia/b2b-rest-client/dist/corp-stock-status";
import { HardwareConfiguration } from "./components/product-configurator/hardware-configurator/types";
import { SubscriptionProductConfiguration } from "./components/product-configurator/subscription-configurator/types";
import { AccessoryConfiguration } from "./components/product-configurator/accessory-configurator/types";

export type Organisation = Required<GetOrganizationResponse>;

export type Customer = Required<MobileUser>;

//Subscription
export interface SubscriptionBundle extends SubscriptionProductBundleUI {
  products: SubscriptionProduct[];
}
export interface SubscriptionProduct extends GetProductsSubscriptionProductUI {
  addonBundles: SubscriptionProductAddonBundle[];
}

export interface SubscriptionProductAddonBundle extends SubscriptionAddonBundleUI {
  addons: SubscriptionAddon[];
  addonBundles?: SubscriptionProductAddonBundle[];
}

export type SubscriptionAddon = GetProductsAddonUI;

//Hardware(phones)
export interface HardwareBundle extends Required<HardwareProductBundleUI> {
  products: HardwareProduct[];
}
export interface HardwareProduct extends Required<GetHardwareProductsHardwareProductUI> {
  variants: HardwareVariant[];
}

export type HardwareVariant = Required<HardwareProductVariantUI>;

export type DevicePrice = Required<MobileDevicePricesUI>;

//Accessories
export interface AccessoryProduct extends Required<GetAccessoriesAccessoryProductUI> {
  gallery: Required<GalleryUI>;
}

export interface AgreementResoponse extends Required<AgreementResponseUI> {
  agreement: Agreement;
}

//Number migration
export type NumberMigrationProduct = Required<GetProcessProductsProcessProductUI>;

//Basket
export interface Basket extends Required<BasketUI> {
  customerNumber: string;
  lines: Required<BasketSubscriptionLineUI>[];
}

export type ApiError = {
  url: string;
  status: number;
  statusText: string;
  body: any;
};

export type SpockBasket = Required<BasketDTO>;

export interface Permissions {
  hasReadAgreement: boolean;
  hasOrderMobileVoice: boolean;
  hasOrderMobileBroadband: boolean;
  hasEmnAgreement: boolean;
}

export type Agreement = Required<AgreementUI>;

export interface ProductStockStatus extends Required<ProductAvailability> {
  deliveryTime: Delivery;
}
export type Delivery = Required<DeliveryTime>;

export type Option = {
  label: string;
  value: string;
};

export type Product = SubscriptionProduct | HardwareProduct | AccessoryProduct;
export type ProductGroup = SubscriptionBundle | HardwareBundle | undefined;
export type ProductConfiguration =
  | HardwareConfiguration
  | SubscriptionProductConfiguration
  | AccessoryConfiguration;

//Typeguards
export function isHardwareProduct(product: any): product is HardwareProduct {
  return (product as HardwareProduct).category === "hardware_bundle";
}

export function isSubscriptionProduct(product: any): product is SubscriptionProduct {
  return (
    (product as SubscriptionProduct).category === "mobilevoicesubscription" ||
    (product as SubscriptionProduct).category === "mobilebroadbandsubscription"
  );
}

export function isAccessoryProduct(product: any): product is AccessoryProduct {
  return (product as AccessoryProduct).accessoryCategory !== undefined;
}

export function isSubscriptionProductConfiguration(
  productConfiguration: ProductConfiguration
): productConfiguration is SubscriptionProductConfiguration {
  return (productConfiguration as SubscriptionProductConfiguration).addons !== undefined;
}

export function isHardwareConfiguration(
  productConfiguration: ProductConfiguration
): productConfiguration is HardwareConfiguration {
  return (productConfiguration as HardwareConfiguration).isUpfrontPayment !== undefined;
}

export function isAccessoryConfiguration(
  productConfiguration: ProductConfiguration
): productConfiguration is AccessoryConfiguration {
  return (productConfiguration as AccessoryConfiguration).productId !== undefined;
}

// type ProductBundle = SubscriptionBundle | HardwareBundle | { products: { productCode: string } };
// export function isHardwareBundle(bundle: ProductBundle) {
//   return (bundle as)
// }
