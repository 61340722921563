<template>
  <div class="product-configuration__container">
    <div
      :key="addon.id"
      :disabled="false"
      class="product-configuration__addon"
      v-for="addon in optionalAddons"
    >
      <DatasimAddonCe
        v-if="addon.subcategory === 'datasim'"
        :data-testid="`datasim-${addon.id}`"
        :description="addon.description"
        :productCode="addon.productCode"
        :offerCode="subscriptionBundle.productCode"
        :addonId="addon.id"
        :name="addon.name"
        :maxDatasims="addon.maxQuantity"
        :recurringFee="addon.recurringFee"
        :disabled="isExcluded(addon)"
        @change="handleDatasim"
      />
      <EsimWatchAddonCe
        v-if="addon.subcategory === 'mdsecondary'"
        :id="addon.id"
        :data-testid="`esim-watch-${addon.id}`"
        :name="addon.name"
        :productCode="addon.productCode"
        :offerCode="subscriptionBundle.productCode"
        :recurringFee="addon.recurringFee"
        :maxWatchCount="addon.maxQuantity"
        :description="addon.description"
        :configurationSimcard="configurationSimcard"
        :disabled="isExcluded(addon)"
        @change="EsimWatchOnChange"
        @valid="esimValid"
      />
      <div
        v-if="addon.subcategory !== 'datasim' && addon.subcategory !== 'mdsecondary'"
        class="product-configuration__addon-checkbox-container"
      >
        <div class="product-configuration__addon-checkbox">
          <telia-checkbox
            v-if="bundleSelectionType === 'checkbox'"
            :checked="isAddonSelected(addon)"
            @change="() => handleAddonSelect(addon.id)"
            :disabled="isExcluded(addon)"
            :data-testid="`other-addon-checkbox-${addon.id}`"
          >
            <div>
              {{ addon.name }}
              <b2x-tooltip
                v-if="addon.description"
                :content="addon.description"
                class="select-box__tooltip"
              />
            </div>
          </telia-checkbox>

          <telia-radio-button
            v-else
            :checked="isAddonSelected(addon)"
            @change="() => handleAddonSelect(addon.id)"
            :data-testid="`other-addon-radio-${addon.id}`"
          >
            <div>
              {{ addon.name }}
              <b2x-tooltip
                v-if="addon.description"
                :content="addon.description"
                class="select-box__tooltip"
              />
            </div>
          </telia-radio-button>
        </div>
        <telia-p>
          <span>
            <ul class="product-configuration__fees-list">
              <li class="product-configuration__fees-list-item">
                {{ addonOneTimeFee(addon.oneTimeFee) }}
              </li>
              <li class="product-configuration__fees-list-item">
                {{ addonRecurringFeeInfo(addon.recurringFee) }}
              </li>
            </ul>
          </span>
        </telia-p>
      </div>
    </div>
    <MandatoryAddonsCe v-if="mandatoryAddons.length" :mandatory-addons="mandatoryAddons" />
  </div>
</template>
<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";

import { computed } from "vue";
import EsimWatchAddonCe from "./EsimWatchAddon.ce.vue";
import { SimcardPayload } from "../../utils/SimChoicePayload";
import { DatasimEmit, EsimWatchEmit } from "../../types";
import MandatoryAddonsCe from "../subscription/switchboard/mandatory-addons.ce.vue";
import DatasimAddonCe from "./DatasimAddon.ce.vue";
import { SubscriptionAddon, SubscriptionProductAddonBundle } from "../../../../../types";
import { useSubscriptionConfigurationStore } from "../../../../../store/SubscriptionConfigurationStore";
import { addonOneTimeFee, addonRecurringFeeInfo } from "../../../../../utils/formatUtils";

interface Props {
  selectedAddons: Record<string, string[]>;
  subscriptionBundle: SubscriptionProductAddonBundle;
  configurationSimcard: SimcardPayload;
  exclusions: string[];
}

interface AddonEmit {
  bundleName: string;
  addonId: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "select-addon", payload: AddonEmit): void;
  (e: "select-datasim", payload: DatasimEmit[]): void;
  (e: "select-esim-watches", payload: EsimWatchEmit[]): void;
  (e: "valid-esim", isValid: boolean): void;
}>();

const t = translate;
const { setEsimValidity } = useSubscriptionConfigurationStore();

const optionalAddons = computed(() => {
  return props.subscriptionBundle.addons.filter((addon) => !addon.mandatory);
});
const mandatoryAddons = computed(() => {
  return props.subscriptionBundle.addons.filter((addon) => addon.mandatory);
});
const bundleSelectionType = computed(() =>
  props.subscriptionBundle.groupMinQuantity === 1 ? "radio" : "checkbox"
);
function isExcluded(addon: SubscriptionAddon) {
  return props.exclusions.includes(addon.productCode);
}

function isAddonSelected(addon: SubscriptionAddon) {
  return !!props.selectedAddons[props.subscriptionBundle.name]?.includes(addon.id);
}

function handleAddonSelect(addonId: string) {
  emit("select-addon", { bundleName: props.subscriptionBundle.name, addonId });
}

function handleDatasim(datasims: DatasimEmit[]) {
  emit("select-datasim", datasims);
}
function EsimWatchOnChange(esimWatches: EsimWatchEmit[]) {
  emit("select-esim-watches", esimWatches);
}

function esimValid(valid: boolean) {
  setEsimValidity(valid);
}
</script>
<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.product-configuration {
  &__container {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
  }

  &__addon {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;

    &-heading {
      padding: 0;
      margin: 0 0 6px 0;
      font-size: 16px;
    }
  }

  &__addon-checkbox-container {
    display: flex;
    justify-content: space-between;
  }

  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 16px;

    &-item {
      &:not(:last-child) {
        margin-bottom: $telia-spacing-4;
      }
    }
  }

  &__mandatory-addons {
    padding: 0 $telia-spacing-8;

    .tc-paragraph {
      margin-bottom: $telia-spacing-8;
    }
  }

  &__mandatory-addons-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__mandatory-addon-price {
    color: $telia-gray-300;
  }
}
</style>
