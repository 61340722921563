<template>
  <div class="summary">
    <div
      v-if="isHardwareProduct(product) || isAccessoryProduct(product)"
      class="order-items-summary__hardware"
    >
      <div class="order-items-summary__left-image-hardware">
        <img :src="getProductImage" />
      </div>
      <div class="order-items-summary__right-list">
        <telia-heading class="order-items-summary__heading" tag="h3" variant="title-100">
          {{ product.name }}
        </telia-heading>
      </div>
    </div>
    <div
      v-if="isSubscriptionProduct(product) || hardwareWithSubscription"
      class="order-items-summary"
    >
      <div class="order-items-summary__left-image">
        <img src="https://www.telia.se/.resources/mybusiness/resources/simcard.png" />
      </div>
      <div class="order-items-summary__right-list">
        <div>
          <telia-heading class="order-items-summary__heading" tag="h3" variant="title-100">
            {{ subscriptionName }}
          </telia-heading>
          <telia-p v-if="isMobileBroadband && !isEmnSubscription">{{ t("summary.numberGeneratedInBasket") }}</telia-p>
        </div>
        <div class="addon-list" v-if="isEmnSubscription">
          <telia-p v-if="product.category === 'mobilebroadbandsubscription'">
            {{
              t("emnConfig.ipRangeStartSummary", {
                ipRangeStart: emnConfigurationStore.emnConfiguration.ipRangeStart || "-",
              })
            }}
          </telia-p>
          <telia-p>
            {{
              t("emnConfig.quantitySummary", {
                quantity: emnConfigurationStore.emnConfiguration.quantity,
              })
            }}
          </telia-p>
        </div>
        <div class="addon-list">
          <telia-p v-for="addon in visibleAddonList" :key="addon">
            {{ addon }}
          </telia-p>
          <telia-button
            v-if="addonList.length > 4"
            :text="showMore ? t('summary.showLessAddons') : t('summary.showAllAddons')"
            variant="text"
            :right-icon="showMore ? showLessIcon : ShowMoreIcon"
            @click="toggleShowMore"
          />
        </div>
      </div>
    </div>
    <telia-divider />
    <div class="price-summary">
      <telia-heading class="price-summary__heading" tag="h3" variant="title-100">
        {{ t("price.total") }}
      </telia-heading>
      <span>
        <telia-p>{{ t("price.oneTimeFee") }}</telia-p>
        <telia-p>{{ formatCurrency(totalOneTimeFee) }}</telia-p>
      </span>
      <span>
        <telia-p>{{ t("price.monthlyFee") }}</telia-p>
        <telia-p>{{ formatCurrency(totalRecurringFee) }}</telia-p>
      </span>
      <telia-p variant="additional-100">{{ t("summary.allPricesExclVat") }}</telia-p>
      <AccordionItem v-if="commitmentPeriod && commitmentPeriod > 0">
        <div class="price-summary__details">
          <span>
            <telia-p disable-hyphenation>
              {{ t("price.totalRecurringFeeForSubscription") }}
            </telia-p>
            <telia-p>
              {{ reccuringFeePriceSummary(totalRecurringFeeForSubscription) }}
            </telia-p>
          </span>
          <span>
            <telia-p disable-hyphenation>
              {{ t("price.increasedMonthlyFeeForPhone") }}
            </telia-p>
            <telia-p>{{ formatCurrency(increasedMonthlyFee) }}</telia-p>
          </span>
          <span>
            <telia-p disable-hyphenation>
              {{ t("price.minimumCostExcludingAddons") }}
            </telia-p>
            <telia-p>
              {{ formatCurrency(totalMinimumFeeExclAddons) }}
            </telia-p>
          </span>
          <telia-p disable-hyphenation>
            {{ t("price.variableFeesByAgreement") }}
          </telia-p>
        </div>
      </AccordionItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, ref } from "vue";
import {
  Product,
  isSubscriptionProduct,
  isHardwareProduct,
  isAccessoryProduct,
  ProductConfiguration,
  isHardwareConfiguration,
  isAccessoryConfiguration,
  isSubscriptionProductConfiguration,
} from "../../../types";
import { AddonPrice } from "../price-summary/types";
import { useHardwareStore } from "../../../store/HardwareConfigurationStore";
import { useConfigurationStore } from "../../../store/ConfigurationStore";
import { storeToRefs } from "pinia";
import Big from "big.js";
import { translate } from "@telia/b2b-i18n";
import { calculateFeeFor, switchboardPrices } from "../price-summary/price-util";
import { Fees, HardwareConfiguration } from "../hardware-configurator/types";
import { formatCurrency, reccuringFeePriceSummary } from "../../../utils/formatUtils";
import { SubscriptionProductConfiguration } from "../subscription-configurator/types";
import AccordionItem from "./AccordionItem.vue";
import { isMobileBroadbandSubscription } from "../../../utils/productCategory";
import { useEmnSubscriptionStore } from "../../../store/EmnSubscriptionStore";
interface Props {
  product: Product;
  productConfiguration: ProductConfiguration;
  fees: Fees;
}
const hardwareConfigurationStore = useHardwareStore();
const configurationStore = useConfigurationStore();
const emnConfigurationStore = useEmnSubscriptionStore();
const { commitmentPeriod, deviceFees } = storeToRefs(hardwareConfigurationStore);

const props = defineProps<Props>();
const t = translate;
const ShowMoreIcon = JSON.stringify({ name: "chevron-down", size: "sm" });
const showLessIcon = JSON.stringify({ name: "chevron-up", size: "sm" });
const showMore = ref(false);

const addonList = computed(() => {
  const subscriptionAddons: string[] = [];
  if (isSubscriptionProductConfiguration(props.productConfiguration)) {
    if (props.productConfiguration.switchboardUser) {
      subscriptionAddons.push(props.productConfiguration.switchboardUser.name);
      subscriptionAddons.push(
        ...props.productConfiguration.switchboardUser.addons.map((addon) => addon.name)
      );
    }
    subscriptionAddons.push(...props.productConfiguration.addonsV2.map((addon) => addon.name));
  }
  if (isHardwareConfiguration(props.productConfiguration)) {
    if (props.productConfiguration.subscription?.operation === "NEW_SUBSCRIPTION") {
      subscriptionAddons.push(
        ...props.productConfiguration.subscription.subscriptionConfiguration.addonsV2.map(
          (addon) => addon.name
        )
      );
    }
  }
  return subscriptionAddons;
});
const isMobileBroadband = computed(() => {
  const category = configurationStore.selectedProduct?.category ?? "";
  return isMobileBroadbandSubscription(category);
});

const isEmnSubscription = computed(() => {
  if (isSubscriptionProduct(props.product)) {
    return props.product.subcategory === "emn";
  }
  return false;
});

const increasedMonthlyFee = computed(() => {
  return hardwareConfigurationStore.isUpfrontPayment ? 0 : deviceFees.value.hardware.recurringFee;
});
const totalMinimumFeeExclAddons = computed(() => {
  return totalRecurringFee.value * commitmentPeriod.value + totalOneTimeFee.value;
});

const visibleAddonList = computed(() => {
  return showMore.value ? addonList.value : addonList.value.slice(0, 4);
});

const hardwareWithSubscription = computed(() => {
  if (isHardwareConfiguration(props.productConfiguration)) {
    if (props.productConfiguration.subscription?.operation === "NEW_SUBSCRIPTION") {
      return true;
    }
  }
  return false;
});

const subscriptionName = computed(() => {
  if (isHardwareConfiguration(props.productConfiguration)) {
    if (props.productConfiguration.subscription?.operation === "NEW_SUBSCRIPTION") {
      return props.productConfiguration.subscription.subscriptionProduct.name;
    }
  }
  return props.product.name;
});

const hasCommitement = computed(() => {
  return isHardwareProduct(props.product)
    ? !!(props.productConfiguration as HardwareConfiguration).subscription?.commitmentPeriod
    : false;
});

const getProductImage = computed(() => {
  let imageUrl = "";
  if (isSubscriptionProduct(props.product)) {
    imageUrl = "https://www.telia.se/.resources/mybusiness/resources/simcard.png";
  } else if (isHardwareProduct(props.product)) {
    imageUrl = `https://www.telia.se${props.product.imageUrl}`;
  } else if (isAccessoryProduct(props.product) && props.product?.gallery.mainImage?.srcUrl) {
    imageUrl = `https://www.telia.se${props.product.gallery.mainImage.srcUrl}`;
  }
  return imageUrl;
});

const subscriptionAddonPrices: ComputedRef<AddonPrice[]> = computed(() => {
  let subscriptionConfig: ProductConfiguration = props.productConfiguration;
  if (isHardwareConfiguration(subscriptionConfig)) {
    if (subscriptionConfig.subscription?.operation === "NEW_SUBSCRIPTION") {
      subscriptionConfig = subscriptionConfig.subscription.subscriptionConfiguration;
    } else {
      return [];
    }
  } else if (isAccessoryConfiguration(subscriptionConfig)) {
    return [];
  }

  subscriptionConfig = subscriptionConfig as SubscriptionProductConfiguration;

  return subscriptionConfig
    ? [
        ...subscriptionConfig.addonsV2,
        ...subscriptionConfig.datasims,
        ...subscriptionConfig.esimWatches,
        ...switchboardPrices(subscriptionConfig.switchboardUser),
      ]
    : [];
});

const totalOneTimeFee = computed(() => {
  return Big(totalOneTimeFeeForSubscription.value)
    .plus(totalOneTimeFeeForHardware.value)
    .plus(calculateFeeFor("oneTimeFee", subscriptionAddonPrices.value))
    .toNumber();
});

const totalRecurringFee = computed(() => {
  return Big(totalRecurringFeeForSubscription.value)
    .plus(totalRecurringFeeForHardware.value)
    .plus(calculateFeeFor("recurringFee", subscriptionAddonPrices.value))
    .toNumber();
});

const totalOneTimeFeeForSubscription = computed(() => {
  if (isSubscriptionProduct(props.product) && props.product.oneTimeFee) {
    return Number(props.product.oneTimeFee);
  } else if (isHardwareProduct(props.product)) {
    return props.fees.subscription.oneTimeFee;
  }
  return 0;
});

const totalRecurringFeeForSubscription = computed(() => {
  if (isSubscriptionProduct(props.product) && props.product.recurringFee) {
    return Big(props.product.recurringFee)
      .mul(isEmnSubscription.value ? emnConfigurationStore.emnConfiguration.quantity : 1)
      .toNumber();
  } else if (isHardwareProduct(props.product)) {
    return Big(props.fees.subscription.recurringFee).toNumber();
  }
  return 0;
});

const totalOneTimeFeeForHardware = computed(() => {
  if (isHardwareConfiguration(props.productConfiguration)) {
    const hardwareConfiguration = props.productConfiguration;
    if (isHardwareProduct(props.product)) {
      if (!hasCommitement.value) {
        const variant = props.product.variants.find(
          (variant) => variant.productCode === hardwareConfiguration.productCode
        );
        return variant?.unitPrice ?? 0;
      }

      if (hardwareConfiguration.isUpfrontPayment) {
        return props.fees.hardware.upfrontPaymentFee;
      }
      return props.fees.hardware.oneTimeFee;
    }
    if (isAccessoryProduct(props.product)) {
      return props.product.unitPrice;
    }
  }
  return 0;
});

const totalRecurringFeeForHardware = computed(() => {
  return isHardwareConfiguration(props.productConfiguration) &&
    hasCommitement.value &&
    !props.productConfiguration.isUpfrontPayment
    ? props.fees.hardware.recurringFee
    : 0;
});

const toggleShowMore = () => {
  showMore.value = !showMore.value;
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.summary {
  padding-top: $telia-spacing-8;
}
.order-items-summary {
  display: flex;
  gap: $telia-spacing-24;
  padding-bottom: $telia-spacing-32;
  &__hardware {
    display: flex;
    gap: $telia-spacing-24;
    padding-bottom: $telia-spacing-48;
  }
  &__heading {
    padding-bottom: $telia-spacing-8;
  }
  &__right-list {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__left-image-hardware {
    img {
      max-width: 8rem;
      max-height: 6rem;
      padding-left: $telia-spacing-8;
    }
  }
  &__left-image {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: $telia-spacing-32;
    img {
      max-width: 8rem;
      max-height: 6rem;
    }
  }
}
.price-summary {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-8;
  > span {
    display: flex;
    justify-content: space-between;
  }
  &__heading {
    padding: $telia-spacing-32 0 $telia-spacing-8 0;
  }
  &__details {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
    padding: $telia-spacing-24 0 0;
    > span {
      display: flex;
      gap: $telia-spacing-48;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
</style>
