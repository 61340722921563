<template>
  <div>
    <telia-notification heading-tag="h3" :status="alertMessage.status" :t-id="alertMessage.tId">
      <span slot="heading" v-if="alertTitle">{{ t("messages." + alertTitle) }}</span>
      <span slot="content">
        <telia-p>
          {{ t("messages." + alertMessage.message) }}
        </telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { computed, ref } from "vue";

interface Props {
  messageType: string;
}

type Variants = "alert" | "error" | "info";

type Statuses = "warning" | "error" | "information";

interface Message {
  tId: string;
  variant: Variants;
  status: Statuses;
  message: string;
  title?: string;
}

const props = defineProps<Props>();

const messages = ref<Map<string, Message>>(
  new Map<string, Message>([
    [
      "agreementNotActive",
      {
        tId: "agreement-not-active-alert",
        variant: "alert",
        status: "warning",
        message: "AGREEMENT_NOT_ACTIVE",
        title: "AGREEMENT_NOT_ACTIVE_HEADER",
      },
    ],
    [
      "agreementFetch",
      {
        tId: "agreement-fetch-alert",
        variant: "alert",
        status: "warning",
        message: "FETCH_AGREEMENT_ERROR",
      },
    ],
    [
      "loggedInUserFailed",
      {
        tId: "logged-in-user-failed-alert",
        variant: "alert",
        status: "warning",
        message: "LOGGED_IN_USER_FAILED_ERROR",
      },
    ],
    [
      "organisationsFailed",
      {
        tId: "organisation-failed-alert",
        variant: "alert",
        status: "warning",
        message: "ORGANISATIONS_FAILED_ERROR",
      },
    ],
    [
      "agreementDocument",
      {
        tId: "agreement-document-alert",
        variant: "alert",
        status: "warning",
        message: "FETCH_AGREEMENT_DOCUMENT_ERROR",
      },
    ],
    [
      "basketFetch",
      {
        tId: "basket-fetch-alert",
        variant: "alert",
        status: "warning",
        message: "BASKET_FETCH_ERROR",
      },
    ],
    [
      "creditCheck",
      {
        tId: "credit-check-alert",
        variant: "error",
        status: "error",
        message: "CREDIT_CHECK_ALERT_BODY",
        title: "CREDIT_CHECK_ALERT_HEAD",
      },
    ],
    [
      "customerNumberFetch",
      {
        tId: "customer-number-fetch-alert",
        variant: "error",
        status: "error",
        message: "CUSTOMER_NUMBER_ALERT_BODY",
      },
    ],
    [
      "fraudCheck",
      {
        tId: "fraud-check-alert",
        variant: "error",
        status: "error",
        message: "FRAUD_CHECK_ALERT_BODY",
        title: "FRAUD_CHECK_ALERT_HEAD",
      },
    ],
    [
      "hardwareFetch",
      {
        tId: "hardware-fetch-alert",
        variant: "alert",
        status: "warning",
        message: "PRODUCT_FETCH_ERROR",
      },
    ],
    [
      "manualCreditCheck",
      {
        tId: "manual-credit-check-info",
        variant: "info",
        status: "information",
        message: "CREDIT_CHECK_MANUAL_ALERT_BODY",
        title: "CREDIT_CHECK_MANUAL_ALERT_HEAD",
      },
    ],
    [
      "subscriptionFetch",
      {
        tId: "subscription-fetch-alert",
        variant: "alert",
        status: "warning",
        message: "PRODUCT_FETCH_ERROR",
      },
    ],
    [
      "numberMigration",
      {
        tId: "number-migration-fetch-error",
        variant: "alert",
        status: "warning",
        message: "PRODUCT_FETCH_ERROR",
      },
    ],
    [
      "accessoriesFetch",
      {
        tId: "accessories-fetch-error",
        variant: "alert",
        status: "warning",
        message: "PRODUCT_FETCH_ERROR",
      },
    ],
    [
      "accessoriesFilterNoResult",
      {
        tId: "accessories-filterNoResult-alert",
        variant: "info",
        status: "information",
        message: "PRODUCT_FILTER_NO_RESULT",
        title: "PRODUCT_FILTER_NO_RESULT_HEADER",
      },
    ],
    [
      "customerNumberNotSelected",
      {
        tId: "no-customer-number",
        variant: "info",
        status: "information",
        message: "NO_CUSTOMER_SELECTED",
        title: "NO_CUSTOMER_SELECTED_HEADER",
      },
    ],
    [
      "addonFetchError",
      {
        tId: "no-customer-number",
        variant: "alert",
        status: "warning",
        message: "SUBSCRIPTION_ADDON_FETCH_ERROR",
      },
    ],
  ])
);

const alertMessage = computed<Message>(() => {
  const message = messages.value.get(props.messageType);

  if (!message) {
    return {
      tId: "no-alert-message-available",
      variant: "alert",
      status: "warning",
      message: "NO_ALERT_MESSAGE",
    };
  }

  return message;
});

const alertTitle = computed(() => {
  return alertMessage.value?.title ? alertMessage.value?.title : undefined;
});
</script>

<style scoped></style>
