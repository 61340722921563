<template>
  <div>
    <telia-heading tag="h2" variant="title-200" class="title">{{
      t("hardwareSubscriptionConfigurator.PAYMENT_OPTIONS_TITLE")
    }}</telia-heading>
    <ConfiguratorRow>
      <telia-chip-choice
        :text="`${t('hardwareSubscriptionConfigurator.PAYMENT_OPTIONS_MONTHLY')} +${formatCurrency(
          increasedMonthlyFee
        )}`"
        :checked="!isUpfrontPayment"
        data-testid="select-payment-monthly"
        @change="() => emit('select-upfront-payment', false)"
      />
      <telia-chip-choice
        :text="t('hardwareSubscriptionConfigurator.PAYMENT_OPTIONS_UPFRONT')"
        :checked="isUpfrontPayment"
        data-testid="select-payment-upfront"
        @change="() => emit('select-upfront-payment', true)"
      />
    </ConfiguratorRow>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import ConfiguratorRow from "../../../../components/shared/ConfiguratorRow.vue";
import { formatCurrency } from "../../../../utils/formatUtils";
interface Props {
  isUpfrontPayment: boolean;
  increasedMonthlyFee: number;
}
defineProps<Props>();
const emit = defineEmits<{ (event: "select-upfront-payment", value: boolean): void }>();
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.heading {
  margin-bottom: $telia-spacing-24;
}
</style>
