<template>
  <div t-id="phone-selector" class="hardware-configurator">
    <div>
      <ConfiguratorCard heading="Mobiltelefon">
        <ImageGallery :images="images" :selected-color="color" />
        <ColorSelector :init-color="color" :colors="colors" @selected="color = $event" />
        <MemorySelector
          :memory-options="memoryArray"
          :available-options="memoryOptionsAvailableForColor"
          :init-memory="memory"
          @selected="memory = $event"
        />
        <StockStatus
          :status="stockStatusForSelectedVariant.status"
          :availableFrom="stockStatusForSelectedVariant.availableFrom"
          :loading="fetchingStockStatus"
        />
      </ConfiguratorCard>
    </div>
    <SubscriptionSelectorContainer
      :canAddNewSubscription="canAddNewSubscription"
      :basket="basket"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";
import { Basket, HardwareProduct, ProductStockStatus } from "../../../types";
import { getPriceForDevice, getStockStatus } from "../../../service/ProductService";
import ColorSelector from "./ColorSelector.vue";
import MemorySelector from "./MemorySelector.vue";
import ImageGallery from "../shared/ImageGallery.vue";
import StockStatus from "../shared/StockStatus.vue";
import { useHardwareStore } from "../../../store/HardwareConfigurationStore";
import { useProductStore } from "../../../store/ProductStore";
import { useStateStore } from "../../../store/StateStore";
import SubscriptionSelectorContainer from "./subscription-selector/SubscriptionSelectorContainer.vue";
import ConfiguratorCard from "../../../components/shared/ConfiguratorCard.vue";
import { MobileDevicePricesUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { useFlag } from "@unleash/proxy-client-vue";

interface Image {
  thumbnailUrl: string;
  imageUrl: string;
  color: string;
}
interface Props {
  basket: Basket;
  product: HardwareProduct;
  canAddNewSubscription: boolean;
  devicePrices?: Required<MobileDevicePricesUI>;
}

const props = defineProps<Props>();
const fetchingStockStatus = ref(true);
const fetchingStockStatusError = ref(false);
const color = ref<string>(props.product.variants[0].color);
const memory = ref<string>(props.product.variants[0].memory);
const productStockStatuses = ref<ProductStockStatus[]>([]);
const stateStore = useStateStore();
const hardwareConfigurationStore = useHardwareStore();
const productStore = useProductStore();

const preSales = useFlag("b2b-mco-presales");

onMounted(async () => {
  Promise.all([fetchStockStatus(), getDevicePrice(props.product, uniqueCommitmentPeriods.value)]);
});

const selectedVariant = computed(() => {
  return props.product.variants.find(
    (variant) => variant.color === color.value && variant.memory === memory.value
  );
});

const colors = computed(() => {
  const colors = new Map<string, string>();
  props.product.variants.forEach((variant) => {
    colors.set(variant.color, variant.colorHex);
  });

  return colors;
});

const images = computed(() => {
  const imgs: Image[] = [];
  props.product.variants
    .filter((variant) => variant.color?.toLowerCase() === color.value.toLowerCase())
    .forEach((variant) => {
      if (variant.images) {
        variant.images.forEach((image) => {
          imgs.push({
            thumbnailUrl: `${image.url}?h=70`,
            imageUrl: `${image.url}?h=250`,
            color: variant.color,
          });
        });
      }
    });
  return imgs;
});

const memoryArray = computed(() => {
  let options: string[] = Array.from(
    new Set(props.product.variants.map((variant) => variant.memory))
  );
  return options.sort((a, b) => (parseMemoryValue(a) < parseMemoryValue(b) ? -1 : 1)) as string[];
});

const memoryOptionsAvailableForColor = computed(() => {
  return props.product.variants
    .filter((variant) => variant.color === color.value)
    .map((variant) => variant.memory);
});

const stockStatusForSelectedVariant = computed(
  ():
    | ProductStockStatus
    | { status: "UNKNOWN_STOCK" | "PRE_SALES"; availableFrom: undefined | string } => {
    if (fetchingStockStatusError.value) {
      return { status: "UNKNOWN_STOCK", availableFrom: undefined };
    }
    const selectedVariant = props.product.variants.find(
      (v) => v.color === color.value && v.memory === memory.value
    );
    const selectedVariantSapId = selectedVariant?.sapId as string;
    const stockStatus = productStockStatuses.value.find(
      (stock) => stock.sapId === selectedVariantSapId
    );
    if (preSales.value && stockStatus?.status === "UPCOMING_STOCK" && props.product.presales) {
      return {
        status: "PRE_SALES",
        availableFrom: stockStatus.availableFrom,
      };
    }
    return (
      productStockStatuses.value.find((stock) => stock.sapId === selectedVariantSapId) ?? {
        status: "UNKNOWN_STOCK",
        availableFrom: undefined,
      }
    );
  }
);

const uniqueCommitmentPeriods = computed(() => {
  return [
    ...new Set(
      productStore.voiceSubscriptionBundles
        .flatMap((subscription) => subscription.products)
        .flatMap((product) => product.commitmentPeriods)
        .map((commitment) => commitment.toString())
    ),
  ];
});

function parseMemoryValue(memory: string): number {
  let memArray = memory.split(/(\d+)/);
  let memValue = parseFloat(memArray[1]);
  let memUnit = memArray[2];

  if (memUnit === "TB") {
    memValue *= 1024;
  }

  return memValue;
}

async function fetchStockStatus() {
  fetchingStockStatus.value = true;
  fetchingStockStatusError.value = false;
  try {
    const variantSapIds = props.product.variants.map((v) => ({ quantity: 1, sapId: v.sapId }));
    productStockStatuses.value = await getStockStatus(variantSapIds);
    hardwareConfigurationStore.setStockStatuses(productStockStatuses.value);
  } catch (error) {
    fetchingStockStatusError.value = true;
  } finally {
    fetchingStockStatus.value = false;
  }
}

async function getDevicePrice(hardwareProduct: HardwareProduct, commitmentPeriods: string[]) {
  try {
    const devicePrices = await getPriceForDevice(
      stateStore.scopeId,
      stateStore.tscid,
      hardwareProduct.offerCode,
      hardwareProduct.productCode,
      commitmentPeriods
    );
    hardwareConfigurationStore.setDevicePrices(devicePrices);
  } catch (error) {
    console.log("deviceprice-error", error);
  }
}

watchEffect(() => {
  if (selectedVariant.value) {
    hardwareConfigurationStore.setVariant(selectedVariant.value);
  }
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.hardware-configurator {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
</style>
