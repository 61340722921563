<template>
  <div t-id="PhoneSelector-stockStatus" class="stock-status">
    <span class="stock-status__text" :class="`stock-status__text--${status.toLowerCase()}`" />
    <telia-p v-if="loading" data-testid="loading-status-text" class="stock-status__status-text">{{
      t(`stockStatus.fetching`)
    }}</telia-p>
    <telia-p v-else data-testid="status-text" class="stock-status__status-text">{{
      t(`stockStatus.${status}`)
    }}</telia-p>
    <telia-p v-if="!loading" data-testid="status-out-of-stock">{{ outOfStockText }}</telia-p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ProductStockStatus } from "../../../types";
import { translate as t } from "@telia/b2b-i18n";

interface Props {
  status: ProductStockStatus["status"] | "UNKNOWN_STOCK" | "PRE_SALES";
  availableFrom?: ProductStockStatus["availableFrom"];
  loading: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  status: "UNKNOWN_STOCK",
});
const outOfStockText = computed(() => {
  if (["OUT_OF_STOCK", "UPCOMING_STOCK", "PRE_SALES"].includes(props.status)) {
    return props.availableFrom ? props.availableFrom : t("stockStatus.DATE_MISSING");
  }
  return "";
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.stock-status {
  display: flex;
  align-items: center;
  &__status-text {
    margin-right: $telia-spacing-4;
  }
  &__text {
    height: $telia-spacing-12;
    width: $telia-spacing-12;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    &--pre_sales {
      background-color: $telia-orange-500;
    }
    &--in_stock {
      background-color: $telia-green-500;
    }

    &--few_remain {
      background-color: $telia-orange-500;
    }

    &--out_of_stock {
      background-color: $telia-red-500;
    }

    &--upcoming_stock {
      background-color: $telia-orange-500;
    }

    &--missing_article {
      background-color: #fff;
      border: 1px solid $telia-black;
    }
    &--unknown_stock {
      background-color: $telia-gray-300;
    }
  }
}
</style>
