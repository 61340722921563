<template>
  <div class="emn-subscription-configurator">
    <div>
      <telia-select
        required
        data-testid="site-selector-dropdown"
        heading-element="telia-p"
        heading-variant="paragraph-100"
        icon-size="sm"
        :label="t('emnConfig.site')"
        :additional="t('emnConfig.siteAdditional')"
        :options="JSON.stringify(siteOptions)"
        :value="siteAgreementId"
        @change="emit('change-site', $event.target.value)"
      />
    </div>
    <telia-text-input
      data-testid="quantity-input"
      required
      type="number"
      :label="t('emnConfig.quantity')"
      :value="quantity"
      :additional="isValidQuantity ? t('emnConfig.quantityAdditional', { quantity: 250 }) : ''"
      :error-message="!isValidQuantity ? t('emnConfig.quantityAdditional', { quantity: 250 }) : ''"
      @input="emit('change-quantity', $event.target.value)"
    />
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";

interface Props {
  scopeId: string;
  quantity: number;
  isValidQuantity: boolean;
  siteAgreementId: string;
  siteOptions: { label: string; value: string }[];
}

defineProps<Props>();
const emit = defineEmits<{
  (e: "change-quantity", quantity: number): void;
  (e: "change-site", siteAgreementId: string): void;
}>();
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.emn-subscription-configurator {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-16;
  &__section {
    display: flex;
    flex-direction: column;
  }
}
</style>
