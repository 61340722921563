<template>
  <div class="infobox-addons">
    <telia-notification v-for="(addon, index) in props.infoBoxAddons" :key="index" heading-tag="h3">
      <span slot="heading">
        <telia-visually-hidden>Information</telia-visually-hidden>
        <strong>{{ addon.name }}</strong>
      </span>
      <telia-text-spacing slot="content">
        <telia-p>
          {{ t("infoboxAddons.notificationContent", { addonName: addon.name }) }}
        </telia-p>
      </telia-text-spacing>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { SwitchboardAddon } from "./switchboard-types";
const props = defineProps<{ infoBoxAddons: SwitchboardAddon[] }>();
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.infobox-addons {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
  margin: $telia-spacing-24 0;
}
</style>
