<template>
  <div>
    <div
      v-if="disableFixedNumberAddon"
      t-id="switchboard-user__disabled-text"
      class="switchboard-user__disabled-text"
    >
      <telia-p>
        {{ t("switchboardFixedNumber.phoneNumberSearchNotFound") }}
      </telia-p>
    </div>
    <div class="switchboard-user__sub-addons-input" :t-id="'switchboard-user__fixed-number-select'">
      <telia-checkbox
        :t-id="'fixed-number-checkbox'"
        :checked="fixedNumberAddonIsSelected"
        :disabled="disableFixedNumberAddon"
        @change="handleSelection"
      >
        <telia-p>{{ heading }}</telia-p>
      </telia-checkbox>
      <telia-p class="price">{{ fee }}</telia-p>
    </div>
    <div v-if="fixedNumberAddonIsSelected" class="switchboard-user__addon">
      <div
        v-if="fixedNumberAddonIsSelected"
        t-id="switchboard-user__fixed-number-options"
        class="switchboard-user__fixed-number-options"
        :key="numbersFieldsetKey"
      >
        <form
          @change="onValidationChange"
          @submit.prevent
          t-id="switchboard-user__fixed-number-search-form"
        >
          <telia-text-input
            t-id="switchboard-user__fixed-number-search"
            type="tel"
            pattern="^(0|\+46|46)[\d|\s|-]*$"
            maxlength="20"
            :value="searchNumber"
            :label="t('switchboardFixedNumber.phoneNumberSearchBeginningNumber')"
            :pattern-error-message="
              t('switchboardFixedNumber.phoneNumberSearchValidationInvalidCharacter')
            "
            @input="setSearchNumber"
            @keyup.enter.prevent="searchAndBookNumbers"
          />
        </form>
        <div v-if="showNumberOptions" class="switchboard-user__numbers">
          <telia-radio-button
            v-for="option in numberOptions"
            :t-id="`switchboard-user__fixed-number-option__${option}`"
            :value="option"
            :key="option"
            :checked="numberOptionIsSelected(option)"
            @change="() => selectNumberChange(option)"
          >
            {{ formatNumberForView(option) }}
          </telia-radio-button>
        </div>
        <telia-p v-if="!showNoNumbersFoundMessage">
          {{ t("switchboardFixedNumber.phoneNumberReservationNotice") }}
        </telia-p>

        <telia-notification
          heading-tag="h3"
          variant="inline"
          status="information"
          t-id="switchboard-user__fixed-number-noNumbersFoundText"
          v-if="showNoNumbersFoundMessage"
        >
          <span slot="heading">
            <telia-visually-hidden>Information</telia-visually-hidden>
            {{ t("switchboardFixedNumber.noNumbersFoundInformationHeader") }}
          </span>
          <span slot="content">
            <telia-p>
              {{ t("switchboardFixedNumber.noNumbersFoundInformation") }}
            </telia-p>
          </span>
        </telia-notification>
      </div>
    </div>
  </div>
</template>
<script lang="ts"></script>
<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { ref, toRefs, computed } from "vue";
import { BookFixedNumber } from "./switchboard-types";
import {
  formatNumberForView,
  numbersAndWildcardValidator,
} from "../../../shared/services/number-formatter.service";
import { useVuelidate } from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";

interface FixedNumberProps {
  heading: string;
  fee: string;
  selectedNumber: string;
  numberBooking?: BookFixedNumber;
  fixedNumberAddonIsSelected: boolean;
}

const props = defineProps<FixedNumberProps>();
const { heading, fee, selectedNumber, numberBooking, fixedNumberAddonIsSelected } = toRefs(props);
const emit = defineEmits<{
  (e: "on-selected-number", selectedNumber: string): void;
  (e: "on-search-number", searchEvent: { isValid: boolean; searchString: string }): void;
  (e: "on-fixed-number-toggle"): void;
}>();

const fixedNumberBooking = ref<BookFixedNumber>();
const searchNumberIsValid = ref<boolean>(false);
const searchNumber = ref<string>("");
const disableFixedNumberAddon = computed(() => {
  return !fixedNumberAddonIsSelected.value && !numberOptions.value?.length;
});
const numberOptions = computed(() => {
  return numberBooking?.value?.numbers ?? [];
});
const numbersFieldsetKey = computed(() => {
  return fixedNumberBooking.value?.bookingId || "numbersFieldsetKey";
});
const showNoNumbersFoundMessage = computed(() => {
  return numberOptions.value?.length === 0;
});
const showNumberOptions = computed(() => {
  return numberBooking?.value ? true : false;
});

const rules = computed(() => ({
  searchNumber: {
    maxLength: maxLength(20),
    numbersAndWildcardValidator,
  },
}));

useVuelidate(rules, { searchNumber });

function numberOptionIsSelected(numberOption: string) {
  return numberOption === selectedNumber.value;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onValidationChange(event: any) {
  searchNumberIsValid.value = event.target?.validity?.valid ?? false;
}
function selectNumberChange(phoneNumber: string) {
  emit("on-selected-number", phoneNumber);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function searchAndBookNumbers(event: any) {
  emit("on-search-number", {
    isValid: searchNumberIsValid.value,
    searchString: event.target.value,
  });
}
function handleSelection() {
  emit("on-fixed-number-toggle");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setSearchNumber(ev: any) {
  searchNumber.value = ev.target.value;
}
</script>
<style lang="scss" scoped>
.switchboard-user {
  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &__disabled-text {
    margin-bottom: 1.6rem;
  }
  &__fixed-number-options {
    margin-top: 1rem;
  }

  &__fixed-number-option {
    margin-bottom: 1rem;
  }

  &__fixed-number-search {
    margin-bottom: 1.6rem;
  }
}
</style>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.switchboard-user {
  &__fixed-number:hover {
    cursor: pointer;
  }

  &__numbers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin: 1.4rem 0;
  }

  &__fixed-number-option {
    &--centered {
      width: 100%;
      text-align: center;
    }
  }

  &__fixed-number-search > input {
    opacity: 100 !important;
    position: relative !important;
  }
  &__addon-heading {
    padding: 0;
    margin: 0 0 6px 0;
    font-size: 16px;
  }
  &__sub-addons {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;

    &-heading {
      padding: 0;
      margin: 0 0 6px 0;
      font-size: 16px;
    }
    &-input {
      display: flex;
      align-items: center;
      .price {
        margin-left: auto;
      }
    }
  }
}
</style>
