<template>
  <div
    id="switchboard-toggle__container"
    class="switchboard-toggle__container"
    t-id="switchboard-toggle__container"
    :class="{
      'switchboard-toggle__container--disabled': isExcluded,
    }"
  >
    <div
      class="switchboard-toggle__toggle-container"
      :class="{
        'switchboard-toggle__toggle-container--disabled': isExcluded,
      }"
    >
      <div class="switchboard-toggle__text-container">
        <ConfiguratorRow>
          <b2x-radio-card
            t-id="switchboard-toggle__off"
            class="switchboard-toggle__option"
            name="switchboard"
            value="no-switchboard"
            :label="t('switchboardUser.noConnection')"
            :checked="!hasMainAddonSelected"
            @change="() => handleMainAddonSelection()"
          >
            <telia-p>{{ t("switchboardUser.continueWithoutSwitchboard") }}</telia-p>
          </b2x-radio-card>
          <b2x-radio-card
            t-id="switchboard-toggle__on"
            class="switchboard-toggle__option"
            name="switchboard"
            :disabled="isExcluded"
            :label="selectedSwitchboardUserPackage.name"
            :checked="hasMainAddonSelected"
            @change="() => handleMainAddonSelection()"
          >
            <telia-p disable-hyphenation>{{ t("switchboardUser.connectToSwitchboard") }}</telia-p>
            <ul class="switchboard-toggle__fees-list">
              <li>
                <telia-p>{{ getOneTimeFeeInfo(selectedSwitchboardUserPackage) }}</telia-p>
              </li>
              <li>
                <telia-p>{{ getRecurringFeeInfo(selectedSwitchboardUserPackage) }}</telia-p>
              </li>
            </ul>
          </b2x-radio-card>
        </ConfiguratorRow>
        <div
          class="switchboard-toggle__select-container"
          v-if="!isExcluded && hasMainAddonSelected && switchboardUserAddonBundles.length > 1"
        >
          <telia-select @change="selectboxChange" :options="JSON.stringify(selectBoxBundle)" />
        </div>
      </div>
    </div>
    <telia-notification v-if="isExcluded" t-id="switchboard-toggle-disabled" heading-tag="h4">
      <span slot="heading">
        {{ t("switchboardUser.addonSwitchboardDisabledReasonContentHeading") }}
      </span>
      <span slot="content">
        <telia-p>{{ t("switchboardUser.addonSwitchboardDisabledReasonContent") }} </telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { SwitchboardAddonBundle, SwitchboardAddon } from "./switchboard-types";
import { addoneOneTimeFee, addonRecurringFeeInfo } from "../../../shared/services/addon-fee";
import { computed, toRefs } from "vue";
import { translate } from "@telia/b2b-i18n";
import ConfiguratorRow from "../../../../../shared/ConfiguratorRow.vue";

interface Props {
  isExcluded: boolean;
  selectedSwitchboardUserPackage: SwitchboardAddon;
  hasMainAddonSelected: boolean;
  switchboardUserAddonBundles: SwitchboardAddonBundle[];
  selectedSwitchboardUserAddonBundleId: string;
}
const emit = defineEmits<{
  (e: "on-toggle", value: SwitchboardAddon): void;
  (e: "selected-switchboard-bundle", value: SwitchboardAddonBundle): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  switchboardUserAddonBundles: () => [],
});
const {
  isExcluded,
  selectedSwitchboardUserAddonBundleId,
  selectedSwitchboardUserPackage,
  switchboardUserAddonBundles,
  hasMainAddonSelected,
} = toRefs(props);

const t = translate;

const selectBoxBundle = computed(() => {
  const bundle = switchboardUserAddonBundles.value.map((bundle) => {
    return {
      value: bundle.switchboardId,
      label: bundle.switchboardName,
      selected:
        bundle.switchboardId === selectedSwitchboardUserAddonBundleId.value &&
        hasMainAddonSelected.value,
    };
  });

  return [
    {
      selected: !hasMainAddonSelected.value,
      label: t("switchboardUser.chooseConnection"),
      disabled: true,
    },
    ...bundle,
  ];
});

function handleMainAddonSelection() {
  if (selectedSwitchboardUserPackage.value) {
    emit("on-toggle", selectedSwitchboardUserPackage.value);
  } else {
    emit("on-toggle", props.switchboardUserAddonBundles[0].addons[0]);
  }
}
function getOneTimeFeeInfo(addon: SwitchboardAddon) {
  return addoneOneTimeFee(addon, t);
}
function getRecurringFeeInfo(addon: SwitchboardAddon) {
  return addonRecurringFeeInfo(addon, t);
}
function selectboxChange(event: { target: { value: string } }) {
  const selectedSwitchboardBundle = switchboardUserAddonBundles.value.find(
    (bundle) => bundle.switchboardId === event.target.value
  ) as SwitchboardAddonBundle;

  emit("selected-switchboard-bundle", selectedSwitchboardBundle);
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.switchboard-toggle {
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 5fr;
  }

  &__select-container {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-12;
  }

  &__container {
    &--disabled {
      background-color: #f2f2f2;
    }
  }

  &__toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.4rem;

    &--disabled {
      color: #808080;
    }
  }

  &__fees-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__text-container {
    flex: 1;
  }

  &__option {
    flex: 1 1 45%;
  }
}
</style>
