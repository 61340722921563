import { SimChoiceType } from "./constants";
import {
  ExistingSimPayload,
  NewEsimPayload,
  NewSimPayload,
  SimcardPayload,
} from "./SimChoicePayload";

export function GetSimChoicePayload(
  simType: SimChoiceType,
  payload: { iccNumber: string; deliveryEmailAddress: string }
): SimcardPayload {
  if (simType === "new-sim") {
    return {
      type: "SIMCARD",
    } as NewSimPayload;
  }
  if (simType === "existing-sim") {
    return {
      type: "SIMCARD",
      iccNumber: payload.iccNumber,
    } as ExistingSimPayload;
  }
  if (simType === "new-esim") {
    return {
      type: "ESIM",
      deliveryEmailAddress: payload.deliveryEmailAddress,
    } as NewEsimPayload;
  }
  throw new Error("Wrong sim type");
}
