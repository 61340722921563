<template>
  <div>
    <SubscriptionProductSelector
      v-if="selectedSubscription && selectedBundleOption"
      :bundle-options="bundleOptions"
      :subscription-options="subscriptionOptions"
      :selected-subscription-product-code="selectedSubscription?.productCode"
      :selected-subscription-bundle="selectedBundleOption"
      :subscription-bundles="voiceSubscriptionBundles"
      @select-subscription="handleSelectSubscriptionProduct"
      @select-subscription-bundle="handleSelectSubscriptionBundle"
    />

    <CommitmentSelector
      no-commitment-allowed
      :commitments="availableCommitmentOptions"
      :selected-commitment="comittmentPeriod"
      @select-commitment-period="handleCommitmentPeriod"
    />

    <PaymentOptions
      v-if="commitmentPeriod > 0"
      :is-upfront-payment="isUpfrontPayment"
      :increased-monthly-fee="increasedMonthlyFee"
      @select-upfront-payment="handleUpfrontPayment"
    />

    <div>
      <telia-p>{{ t("INCREASED_FEE", { fee: recurringFee }) }}</telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NumberMigrationProduct, SubscriptionBundle } from "../../../../types";
import { useHardwareStore } from "../../../../store/HardwareConfigurationStore";
import { storeToRefs } from "pinia";
import CommitmentSelector from "../../shared/CommitmentSelector.vue";
import PaymentOptions from "./PaymentOptions.vue";
import SubscriptionProductSelector from "./SubscriptionProductSelector.vue";
import { computed } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { useGa4Helper } from "../../../../utils/tracking/gaHelper";

interface Props {
  commitments: number[];
  migrationProducts: NumberMigrationProduct[];
  comittmentPeriod: number;
  isUpfrontPayment: boolean;
  recurringFee?: number;
  voiceSubscriptionBundles: SubscriptionBundle[];
}

const props = withDefaults(defineProps<Props>(), {
  recurringFee: 0,
});
const hardwareConfigurationStore = useHardwareStore();
const {
  selectedSubscription,
  selectedSubscriptionBundle,
  commitmentPeriod,
  isUpfrontPayment,
  deviceFees,
  availableCommitmentOptions,
  subscriptionsApplicableForVariant,
} = storeToRefs(hardwareConfigurationStore);

const ga4Helper = useGa4Helper();

const bundleOptions = computed(() => {
  return props.voiceSubscriptionBundles.map((bundle) => ({
    name: bundle.name,
    productCode: bundle.productCode,
  }));
});

const subscriptionOptions = computed(() => {
  const bundleName = selectedSubscriptionBundle.value?.name;
  return subscriptionsApplicableForVariant.value.map((subscriptionProduct) => {
    return {
      name: subscriptionProduct.name.replace(`${bundleName} `, ""),
      productCode: subscriptionProduct.productCode,
      recurringFee: subscriptionProduct.recurringFee,
    };
  });
});

const selectedBundleOption = computed(() => {
  if (selectedSubscriptionBundle.value) {
    return {
      name: selectedSubscriptionBundle.value.name,
      productCode: selectedSubscriptionBundle.value.productCode,
    };
  }
  return undefined;
});

const increasedMonthlyFee = computed(() => {
  return deviceFees.value.hardware.recurringFee;
});

function handleSelectSubscriptionBundle(productCode: string) {
  const selectedBundle = props.voiceSubscriptionBundles.find(
    (subscriptionBundle) => subscriptionBundle.productCode === productCode
  );
  if (selectedBundle) {
    hardwareConfigurationStore.setSubscriptionBundle(selectedBundle);
    hardwareConfigurationStore.setSubscriptionProduct(selectedBundle.products[0]);
  }
}
function handleSelectSubscriptionProduct(productCode: string) {
  const subscriptionProduct = selectedSubscriptionBundle.value?.products.find(
    (product) => product.productCode === productCode
  );
  if (subscriptionProduct) {
    ga4Helper.trackViewProduct(subscriptionProduct);
    hardwareConfigurationStore.setSubscriptionProduct(subscriptionProduct);
  }
}

function handleCommitmentPeriod(commitmentPeriod: number) {
  hardwareConfigurationStore.setCommitment(commitmentPeriod);
}

function handleUpfrontPayment(isUpfrontPayment: boolean) {
  hardwareConfigurationStore.setUpfrontPayment(isUpfrontPayment);
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
.select-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .select-box {
    padding: $telia-spacing-8;
    margin-right: $telia-spacing-8;
    margin-bottom: $telia-spacing-8;
    flex-basis: 30%;
    text-align: center;
    vertical-align: baseline;
  }
}
</style>
