import * as analytics from "@telia/b2b-web-analytics-wrapper";

export function trackProductList(productGroups, selectedHwBrandFilter) {
  let listName = `Mobile Devices${selectedHwBrandFilter ? " - " + selectedHwBrandFilter : ""}`;
  let payload = {
    il1nm: listName, // name of product list
  };
  let index = 1;
  productGroups.forEach((productGroup) => {
    productGroup?.products?.forEach((product) => {
      let cheapestVariant = getVariantWithLowestPrice(product.variants);

      payload[`il1pi${index}id`] = cheapestVariant.id;
      payload[`il1pi${index}nm`] = product.name;
      payload[`il1pi${index}ca`] = analytics.getConcatenatedCategories(
        product.category,
        product.subCategory
      );
      payload[`il1pi${index}br`] = product.brand;
      payload[`il1pi${index}pr`] = cheapestVariant.unitPrice;
      payload[`il1pi${index}ps`] = index;

      index++;
    });
  });

  // check if there are products in the payload.
  if (index > 1) {
    analytics.trackEvent(
      analytics.category.SALESFLOW,
      analytics.action.PRODUCT_LIST,
      listName,
      0,
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key],
      }))
    );
  }
}

function getVariantWithLowestPrice(variants) {
  return variants.reduce((previous, current) =>
    previous.unitPrice < current.unitPrice ? previous : current
  );
}
