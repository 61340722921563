<template>
  <div t-id="b2b-mobile-phone-product-listing">
    <SortingAndFiltering
      v-if="hardwareGroups.length > 0"
      :primary-options="hardwareBrands"
      :selected-primary-filter="selectedHwBrandFilter"
      :default-sorting-method="hardwareSortingMethod"
      secondary-filter-query-parameter-name="passar-till"
      @set-sorting-method="onSetSortingMethod"
      @set-primary-filter="onSetBrandFilter"
    />
    <section v-if="loading" :t-id="'product-group-skeleton'" class="hardware-products__category">
      <div class="hardware-products__category-products">
        <telia-skeleton v-for="n in 6" class="hardware-products__skeleton" :key="n" />
      </div>
    </section>
    <section
      v-else
      v-for="(productGroup, index) in sortedAndFilteredHwGroups"
      :t-id="'product-group-' + productGroup.name"
      :key="`${productGroup.name}-${String(index)}`"
      class="hardware-products__category"
    >
      <transition-group
        :css="!hwBrandFilterActivated"
        appear
        tag="div"
        name="fade-zoom"
        class="hardware-products__category-products"
      >
        <HardwareProductWrapper
          v-for="(product, i) in productGroup.products"
          t-id="b2b-mobile-phone-product-listing__product-wrapper"
          :key="product.productCode"
          :class="`hardware-products__listing ${
            hardwareDisabled ? 'hardware-products__listing-disabled' : ''
          }`"
          :style="`transition-delay: ${Number(i) * 50}ms;`"
          :product="product"
          :stock-status="stockStatus(product)"
          :loadingStockStatus="productStore.fetching.hardwareStockStatus"
          @click="selectProduct(product, productGroup, Number(i))"
          @keydown.enter="selectProduct(product, productGroup, Number(i))"
        />
      </transition-group>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";
import { HardwareBundle, HardwareProduct, ProductStockStatus } from "../../../types";
import { sortBy } from "./sortingUtils";
import SortingAndFiltering from "../shared/sorting/SortingAndFiltering.vue";
import HardwareProductWrapper from "./HardwareProductWrapper.vue";
import { trackProductList as track } from "./utils/gaTracking";
import { SortingOption } from "../shared/sorting/types";
import { useGa4Helper } from "../../../utils/tracking/gaHelper";
import { useStateStore } from "../../../store/StateStore";
import { TabCategories } from "../../TabCategories";
import { useProductStore } from "../../../store/ProductStore";
import { useFlag } from "@unleash/proxy-client-vue";

interface Props {
  hardwareGroups: HardwareBundle[];
  hardwareDisabled: boolean;
  loading: boolean;
}
const props = defineProps<Props>();
const state = useStateStore();
const productStore = useProductStore();
const preSales = useFlag("b2b-mco-presales");

const emit = defineEmits<{
  (e: "selected-product", selected: HardwareProduct): void;
}>();

const hwBrandFilterActivated = ref<boolean>(false);
const hardwareSortingMethod = ref<SortingOption>("NEWEST");
const selectedHwBrandFilter = ref<string>("");
const ga4Helper = useGa4Helper();

const hardwareBrands = computed(() => {
  return [
    ...new Set(props.hardwareGroups.flatMap((group) => group.products).map((p) => p.brand)),
  ] as string[];
});
const sortedAndFilteredHwGroups = computed((): HardwareBundle[] => {
  const clone = deepCopy(brandFilteredHwGroups.value);
  return clone.map((group) => ({
    ...group,
    products: sortBy(group.products, hardwareSortingMethod.value),
  }));
});
const brandFilteredHwGroups = computed(() => {
  if (!selectedHwBrandFilter.value) {
    return props.hardwareGroups ?? [];
  }
  return props.hardwareGroups
    .map((group) => ({
      ...group,
      products: group.products.filter((p) => p.brand === selectedHwBrandFilter.value),
    }))
    .filter((group) => group.products.length > 0);
});

onMounted(() => {
  trackProductList();
});

watchEffect(() => {
  if (state.category) {
    trackProductList();
  }
});
function stockStatus(
  product: HardwareProduct
):
  | ProductStockStatus
  | { status: "UNKNOWN_STOCK" | "PRE_SALES"; availableFrom: undefined | string } {
  const stockStatusForAllVariants = productStore.hardwareStockStatus.filter((stockStatus) =>
    product.variants.map((variant) => variant.sapId).includes(stockStatus.sapId)
  );

  const inStock = stockStatusForAllVariants.find(
    (stockStatus) => stockStatus.status === "IN_STOCK"
  );
  if (inStock) {
    return inStock;
  }
  const fewRemain = stockStatusForAllVariants.find(
    (stockStatus) => stockStatus.status === "FEW_REMAIN"
  );
  if (fewRemain) {
    return fewRemain;
  }
  const upcomingStock = stockStatusForAllVariants.find(
    (stockStatus) => stockStatus.status === "UPCOMING_STOCK"
  );
  if (upcomingStock) {
    if (preSales.value && product.presales) {
      return {
        status: "PRE_SALES",
        availableFrom: upcomingStock.availableFrom,
      };
    }
    return upcomingStock;
  }
  const outOfStock = stockStatusForAllVariants.find(
    (stockStatus) => stockStatus.status === "OUT_OF_STOCK"
  );
  if (outOfStock) {
    return outOfStock;
  }
  return { status: "UNKNOWN_STOCK", availableFrom: undefined };
}
function onSetSortingMethod(sortingMethod: SortingOption) {
  hardwareSortingMethod.value = sortingMethod;
  trackProductList();
}
function onSetBrandFilter(brandFilter: string) {
  hwBrandFilterActivated.value = true;
  selectedHwBrandFilter.value = brandFilter;
  trackProductList();
}
function selectProduct(product: HardwareProduct, productGroup: HardwareBundle, index: number) {
  ga4Helper.trackProductSelect(product, index);
  emit("selected-product", product);
}
function trackProductList() {
  if (state.category === TabCategories.Hardware) {
    track(props.hardwareGroups, selectedHwBrandFilter.value);
    ga4Helper.trackHardwareProductListNew(sortedAndFilteredHwGroups.value);
  }
}
function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/breakpoints/tokens";
.hardware-products {
  &__category {
    margin: $telia-spacing-64 0;
    @media screen and (max-width: $telia-breakpoint-medium) {
      margin: 0;
    }
  }

  &__skeleton {
    height: 460px;
  }

  &__category-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: $telia-spacing-12;
    border-bottom: 1px solid #d0d0d0;
  }

  &__category-description {
    margin-top: $telia-spacing-24;
  }

  &__category-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: $telia-spacing-24;
  }

  &__listing {
    &-disabled {
      opacity: 0.17;
      pointer-events: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-zoom-enter-active,
.fade-zoom-leave-active {
  transition:
    opacity 0.1s,
    transform 0.1s;
}

.fade-zoom-enter,
.fade-zoom-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.hardware-brand-filter-buttons {
  margin: 0 0.8rem 1.8rem 0;
}
</style>

<style lang="scss">
.hardware-brand-filter-buttons .tc-selection-button__content {
  justify-content: center;
  min-width: 10rem;
}
</style>
