<template>
  <div t-id="accessory-configurator" class="accessory-configurator">
    <div>
      <ConfiguratorCard :heading="product.name">
        <ImageGallery :images="images" />
        <telia-p>{{ product.description }}</telia-p>
        <div class="accessory-configurator__description">
          <telia-p>{{ detailedInformation }}</telia-p>
          <telia-p>&nbsp;|&nbsp;</telia-p>
          <StockStatus
            :status="stockStatusForSelectedAccessory.status"
            :available-from="stockStatusForSelectedAccessory.availableFrom"
            :loading="fetchingStockStatus"
          />
        </div>
      </ConfiguratorCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { getStockStatus } from "../../../service/ProductService";
import { AccessoryProduct, ProductStockStatus } from "../../../types";
import ImageGallery from "../shared/ImageGallery.vue";
import StockStatus from "../shared/StockStatus.vue";
import { useHardwareStore } from "../../../store/HardwareConfigurationStore";

import { Variant } from "../hardware-configurator/types";
import ConfiguratorCard from "../../../components/shared/ConfiguratorCard.vue";

interface Props {
  product: AccessoryProduct;
}
const props = defineProps<Props>();
const emit = defineEmits<{ (e: "accessoryProduct", product: Variant): void }>();

const fetchingStockStatus = ref(false);
const fetchingStockStatusError = ref(false);
const productStockStatuses = ref<ProductStockStatus[]>([]);
const hardwareConfigurationStore = useHardwareStore();

onMounted(async () => {
  await fetchStockStatus();
  emit("accessoryProduct", {
    variantId: props.product.id,
    variantProductId: props.product.productCode,
    sapId: props.product.sapId,
  });
});

const images = computed(() => {
  const imgs = props.product.gallery.moreImages ?? [];
  const initialImage = props.product.gallery?.mainImage?.srcUrl;
  if (initialImage && !imgs.find((image) => image?.srcUrl === initialImage)) {
    imgs.push(props.product.gallery.mainImage);
  }
  return imgs.map((i) => ({
    thumbnailUrl: `${i.srcUrl}?h=70`,
    imageUrl: `${i.srcUrl}?h=250`,
  }));
});
const detailedInformation = computed(() => {
  return props.product.color ? props.product.color : "";
});
const stockStatusForSelectedAccessory = computed<{
  status: ProductStockStatus["status"] | "UNKNOWN_STOCK";
  availableFrom?: string;
}>(() => {
  if (fetchingStockStatusError.value) {
    return { status: "UNKNOWN_STOCK" };
  }
  const stockStatus = productStockStatuses.value.find(
    (stockStatus) => stockStatus.sapId === props.product.sapId
  );
  if (stockStatus) {
    return stockStatus;
  }
  return { status: "UNKNOWN_STOCK" };
});

async function fetchStockStatus() {
  fetchingStockStatus.value = true;
  fetchingStockStatusError.value = false;
  try {
    const request = [{ quantity: 1, sapId: props.product.sapId }];
    productStockStatuses.value = await getStockStatus(request);
    hardwareConfigurationStore.setStockStatuses(productStockStatuses.value);
  } catch (error) {
    fetchingStockStatusError.value = true;
  } finally {
    fetchingStockStatus.value = false;
  }
}
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.accessory-configurator {
  &__divider {
    margin: $telia-spacing-12 0;
  }
  &__description {
    margin-top: $telia-spacing-12;
    line-height: 26px;
    display: flex;
    align-items: center;
  }
}
</style>
