<template>
  <div data-testid="new-number" class="new-number">
    <div v-if="!fetchingMsisdns">
      <form data-testid="new-number__search-number-form" @submit.prevent="onSubmit">
        <telia-text-input
          data-testid="new-number__number-input"
          type="tel"
          :label="t('newNumber.phoneNumberSearchBeginningNumber')"
          :value="searchNumber"
          maxLength="10"
          :error-message="validationMessage"
          @input="handleNumberSearchInput"
          @keyup.enter="searchBeginNumber()"
        />
        <telia-notification
          class="new-number__notification-spacing"
          heading-tag="h3"
          status="information"
          data-testid="new-number__no-numbers-found-notification"
          v-if="!hasSearchResults && !errorText"
        >
          <span slot="heading">{{ t("newNumber.noNumbersFoundInformationHeader") }}</span>
          <span slot="content">
            <telia-p>
              {{ t("newNumber.noNumbersFoundInformation") }}
            </telia-p>
          </span>
        </telia-notification>
        <telia-notification
          class="new-number__notification-spacing"
          heading-tag="h3"
          status="warning"
          data-testid="new-number__error-message-notification"
          v-if="errorText"
        >
          <span slot="heading">
            <telia-p>{{ t("newNumber.defaultAlertHeader") }}</telia-p>
          </span>
          <span slot="content">
            <telia-p>{{ t(errorText) }}</telia-p>
          </span>
        </telia-notification>

        <div
          v-if="msisdns && msisdns.length > 0"
          class="new-number__phone-numbers-container"
          data-testid="new-number__search-results-container"
        >
          <telia-radio-button
            v-for="msisdn in msisdns"
            :data-testid="'msisdn-option-' + msisdn"
            :key="msisdn"
            :value="msisdn"
            :checked="msisdn === selectedMsisdn"
            :label="formatPhoneNumber(msisdn)"
            @change="onChange"
          />
        </div>
      </form>

      <div>
        <telia-p class="new-number__chosen-number" v-if="selectedMsisdn && hasSearchResults">
          {{ t("newNumber.phoneNumberChosenNumber") }}
          <strong>{{ formatPhoneNumber(selectedMsisdn) }}</strong>
        </telia-p>

        <telia-p class="new-number__reserved-number" v-if="hasSearchResults">
          <telia-icon name="checkmark" size="sm" />
          {{ t("newNumber.phoneNumberReservationNotice") }}
        </telia-p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount, onDeactivated } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { corpNumberBooking } from "@telia/b2b-rest-client";
import { formatPhoneNumber, formatToMsisdn } from "@telia/b2x-phone-number-format";
import { useGa4Helper } from "../../../../../utils/tracking/gaHelper";

interface Props {
  scopeId: string;
  tscid: string;
  extendBookingTimeoutMinutes?: number;
  selectedMsisdn: string;
}
const props = withDefaults(defineProps<Props>(), {
  extendBookingTimeoutMinutes: 10,
  selectedMsisdn: undefined,
});
const emit = defineEmits<{
  (e: "change", msisdn: string): void;
  (e: "new-booking-id", bookingId: string): void;
}>();

const numbersAndWildcardValidator = (value: string) => value.match(/^[\d%*]*$/i);

const bookingId = ref<string>("");
const msisdns = ref<string[]>([]);
const searchNumber = ref<string>("");
const fetchingMsisdns = ref<boolean>(true);
const extendTimer = ref<any>(null);
const errorText = ref<string>("");
const ga4Helper = useGa4Helper();

const hasSearchResults = computed(() => {
  return msisdns.value.length !== 0;
});

const validationMessage = computed(() => {
  if (!(searchNumber.value.length <= 10)) {
    return t("newNumber.phoneNumberSearchValidationLength");
  }
  if (!numbersAndWildcardValidator(searchNumber.value)) {
    return t("newNumber.phoneNumberSearchValidationInvalidCharacter");
  }
  return "";
});

onMounted(async () => {
  window.addEventListener("beforeunload", onUnload);

  await getMsisdns();
});
onBeforeUnmount(async () => {
  removeExtendBookingTimer();
  window.removeEventListener("beforeunload", onUnload);
});
onDeactivated(async () => {
  // Called when vue-web-component-wrapper is removing the element from the DOM
  // https://github.com/vuejs/vue-web-component-wrapper
  removeExtendBookingTimer();
});

async function getMsisdns() {
  fetchingMsisdns.value = true;
  errorText.value = "";

  try {
    if (bookingId.value) {
      const res = await corpNumberBooking.PublicNumberBookingControllerService.releaseAndBook(
        props.scopeId,
        props.tscid,
        bookingId.value,
        formatToMsisdn(searchNumber.value)
      );
      msisdns.value = res.msisdns ?? [];
      bookingId.value = res.bookingId ?? "";
    } else {
      const res = await corpNumberBooking.PublicNumberBookingControllerService.bookNumber1(
        props.scopeId,
        props.tscid,
        "VOICE",
        formatToMsisdn(searchNumber.value)
      );

      msisdns.value = res.msisdns ?? [];
      bookingId.value = res.bookingId ?? "";
      emit("change", msisdns.value[0]);
      emit("new-booking-id", bookingId.value);
      extendBookingOnTimeout();
    }
  } catch (error: any) {
    errorText.value = "newNumber.numberFetchError";
    ga4Helper.trackPhoneNumberFetchFail("mobile_subscriptions");
    msisdns.value = [];
  } finally {
    fetchingMsisdns.value = false;
  }
}
async function extendBooking() {
  try {
    await corpNumberBooking.PublicNumberBookingControllerService.extendBooking(
      props.scopeId,
      props.tscid,
      bookingId.value
    );
  } catch (e) {
    // If we cannot extend the booking we need to start over with a new booking.
    bookingId.value = "";
    removeExtendBookingTimer();
    getMsisdns();
    return;
  }

  extendBookingOnTimeout();
}

function handleNumberSearchInput(event: any) {
  searchNumber.value = event.target.value;
}

function searchBeginNumber() {
  if (!validationMessage.value) {
    getMsisdns();
  }
}

function extendBookingOnTimeout() {
  const timeout = props.extendBookingTimeoutMinutes * 60 * 1000; // minutes to ms
  extendTimer.value = setTimeout(extendBooking, timeout);
}
function removeExtendBookingTimer() {
  clearTimeout(extendTimer.value);
  extendTimer.value = null;
}

function onChange(event: any) {
  emit("change", event.target.value);
  emitBookingId();
}
function emitBookingId() {
  emit("new-booking-id", bookingId.value);
}
function onSubmit(event: any) {
  event.preventDefault();
}

function onUnload() {
  removeExtendBookingTimer();
}
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.new-number {
  $self: &;
  &__phone-numbers-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-column-gap: $telia-spacing-20;
    grid-row-gap: $telia-spacing-12;
    margin-top: $telia-spacing-24;
    cursor: default;
  }

  &__phone-number {
    padding: $telia-spacing-12;
    border: 1px solid #8c8c90;
    border-radius: 2px;
    text-align: center;

    &:hover {
      border: 1px solid $telia-purple-500;
      cursor: pointer;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__label {
    position: relative;
  }
  &__chosen-number {
    margin: $telia-spacing-12 0;
  }
  &__reserved-number {
    margin-bottom: $telia-spacing-12;
  }
  &__notification-spacing {
    margin-top: $telia-spacing-12;
    display: block;
  }
}
</style>
