import {
  BasketProcessLineUI,
  BasketSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { Basket, SubscriptionProduct } from "../../../types";

interface NumberSwapDetails {
  msisdn?: string;
  isTransfer: boolean;
  id?: string;
  oneTimeFee?: string;
  recurringFee?: string;
  relationId?: string;
  temporaryMsisdn?: string;
  currentOwnerSsn?: string;
  currentOwnerOrganization?: string;
  currentOwnerEmail?: string;
  currentOwnerType?: "ORGANIZATION" | "PRIVATE";
  billingAccountId?: string;
}

export interface SubscriptionLine extends Required<BasketSubscriptionLineUI> {
  numberSwapDetails?: NumberSwapDetails;
}

export function getStandaloneSubscriptionsFromBasket(basket: Basket): SubscriptionLine[] {
  if (!isBasketEmpty(basket)) {
    const relationIds = getHardwareRelationIds(basket);
    return basket.lines
      .filter((line) => line.product.category === "mobilevoicesubscription")
      .filter((product) => !relationIds.includes(product.relationId))
      .map((line) => ({
        ...line,
        numberSwapDetails: appendNumberSwapDetails(basket, line.relationId),
      }));
  }
  return [];
}

function getHardwareRelationIds(basket: Basket) {
  return basket.hardwareLines
    .map((line) => line.relationId)
    .filter((value, index, result) => result.indexOf(value) === index);
}

function isBasketEmpty(basket: Basket) {
  return Object.keys(basket).length === 0;
}

function appendNumberSwapDetails(
  basket: Basket,
  relationId: string
): NumberSwapDetails | undefined {
  const numberSwapItem = findNumberSwapDetails(basket, relationId);
  if (numberSwapItem) {
    return {
      msisdn: numberSwapItem.currentMsisdn,
      temporaryMsisdn: numberSwapItem.temporaryMsisdn,
      currentOwnerSsn: numberSwapItem.currentOwnerSsn,
      currentOwnerEmail: numberSwapItem.currentOwnerEmail,
      currentOwnerOrganization: numberSwapItem.currentOwnerOrganization,
      oneTimeFee: numberSwapItem.oneTimeFee,
      currentOwnerType: numberSwapItem.currentOwnerType,
      isTransfer: numberSwapItem.product?.productCode === "TRANSFER_PROCESS_DATA",
    };
  }
  return;
}

function findNumberSwapDetails(
  basket: Basket,
  relationId?: string
): BasketProcessLineUI | undefined {
  const processLine = basket.processLines.find(
    (processLine) => processLine.relationId === relationId
  );
  return processLine;
}

export function findAddonProductIdFromCode(
  subscriptionProducts: SubscriptionProduct[],
  productCode: string
) {
  const addon = subscriptionProducts
    .flatMap((bundle) => allAddonsInOffer(bundle))
    .find((addon) => addon.productCode === productCode);

  return addon ? addon.id : "";
}

function allAddonsInOffer(subscriptionProduct: SubscriptionProduct) {
  return subscriptionProduct.addonBundles.flatMap((bundle) => bundle.addons);
}
