import { HardwareBundle, HardwareProduct, Product } from "../../../types";
import { SortingOption } from "../shared/sorting/types";

const SortingOptions = {
  sortNewest: "NEWEST",
  sortLowestPrice: "LOWEST_PRICE",
  sortHighestPrice: "HIGHEST_PRICE",
};

function sortBy(items: HardwareProduct[], key: SortingOption): HardwareProduct[] {
  switch (key) {
    case SortingOptions.sortHighestPrice:
      return items.sort((a, b) => (getMinimumPrice(a) > getMinimumPrice(b) ? -1 : 1));
    case SortingOptions.sortLowestPrice:
      return items.sort((a, b) => (getMinimumPrice(a) < getMinimumPrice(b) ? -1 : 1));
    case SortingOptions.sortNewest:
      if (items.some((i) => i.mostRecentVariantSellingStartDate)) {
        return items.sort((a, b) => {
          const aSellingStartDate = a.mostRecentVariantSellingStartDate;
          const bSellingStartDate = b.mostRecentVariantSellingStartDate;
          if (aSellingStartDate === bSellingStartDate) {
            return 0;
          } else if (aSellingStartDate === null) {
            return 1;
          } else if (bSellingStartDate === null) {
            return -1;
          } else {
            return bSellingStartDate > aSellingStartDate ? 1 : -1;
          }
        });
      } else {
        return items;
      }
  }
  return items;
}

function getMinimumPrice(productGroup: HardwareProduct) {
  return Math.min(...productGroup.variants?.map((v) => Number(v.unitPrice)));
}

export { SortingOptions, sortBy };
