<template>
  <div class="subscription-selector">
    <ConfiguratorCard :heading="t('hardwareSubscriptionConfigurator.heading.subscription')">
      <SubscriptionChoiceSelector
        :selected-type="selectedSubscriptionOption"
        :disable-connect-subscription="disableConnectSubscription"
        :can-add-new-subscription="!disableNewSubscription"
        @change="handleSubscriptionOperationChange"
      />

      <NewSubscriptionConfigurator
        v-if="selectedSubscriptionOption === 'NEW_SUBSCRIPTION'"
        :commitments="comittmentPeriods"
        :comittment-period="commitmentPeriod"
        :is-upfront-payment="isUpfrontPayment"
        :recurring-fee="recurringFeeForMobileDevice"
        :voice-subscription-bundles="bundlesWithApplicableSubscriptions"
        :migration-products="productStore.numberMigrationProducts"
      />
    </ConfiguratorCard>
    <SubscriptionConfiguration
      v-if="
        selectedSubscription &&
        selectedSubscriptionBundle &&
        selectedSubscriptionOption === 'NEW_SUBSCRIPTION'
      "
      :disabled="false"
      :scope-id="stateStore.scopeId"
      :tscid="stateStore.tscid"
      :migration-products="productStore.numberMigrationProducts"
      :product-name="selectedSubscription?.name"
      :product-code="selectedSubscription?.productCode"
      :offer-code="selectedSubscriptionBundle?.productCode"
    />
    <ConfiguratorCard
      :heading="t('hardwareSubscriptionConfigurator.heading.extend')"
      v-if="selectedSubscriptionOption === 'EXTEND_SUBSCRIPTION'"
    >
      <ExtendExistingSubscription
        :voice-subscription-bundles="bundlesWithApplicableSubscriptions"
        :selected-commitment="commitmentPeriod"
        :commitment-periods="comittmentPeriods"
        :increased-fee-for-device="recurringFeeForMobileDevice"
        :scope-id="stateStore.scopeId"
      />
    </ConfiguratorCard>
    <ConfiguratorCard
      :heading="t('hardwareSubscriptionConfigurator.heading.fromBasket')"
      v-if="selectedSubscriptionOption === 'CHANGE_SUBSCRIPTION_IN_CART'"
    >
      <SubscriptionFromBasket
        :subscriptions="bundlesWithApplicableSubscriptions"
        :basket="basket"
        :increased-fee-for-mobile-device="recurringFeeForMobileDevice"
      ></SubscriptionFromBasket>
    </ConfiguratorCard>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useHardwareStore } from "../../../../store/HardwareConfigurationStore";
import { useProductStore } from "../../../../store/ProductStore";
import { SubscriptionBundle, SubscriptionProduct } from "../../../../types";
import NewSubscriptionConfigurator from "./NewSubscriptionConfigurator.vue";
import { SubscriptionChoice } from "../types";
import { computed, watchEffect } from "vue";
import SubscriptionChoiceSelector from "./SubscriptionChoiceSelector.vue";
import SubscriptionFromBasket from "./SubscriptionFromBasket.vue";
import ExtendExistingSubscription from "./extend-existing-subscription/ExtendExistingSubscription.vue";
import { Basket } from "../../../../types";
import SubscriptionConfiguration from "../../subscription-configurator/SubscriptionConfiguration.vue";
import { useStateStore } from "../../../../store/StateStore";
import ConfiguratorCard from "../../../../components/shared/ConfiguratorCard.vue";
import { translate as t } from "@telia/b2b-i18n";
import { useGa4Helper } from "../../../../utils/tracking/gaHelper";
import { SubscriptionPricesUI } from "@telia/b2b-rest-client/dist/corp-product-order";

interface Props {
  basket: Basket;
  canAddNewSubscription: boolean;
}
const props = defineProps<Props>();
const stateStore = useStateStore();
const hardwareConfigurationStore = useHardwareStore();
const {
  selectedSubscriptionOption,
  selectedSubscription,
  selectedSubscriptionBundle,
  commitmentPeriod,
  isUpfrontPayment,
  deviceFees,
  subscriptionPricesForVariant,
} = storeToRefs(hardwareConfigurationStore);
const productStore = useProductStore();
const ga4Helper = useGa4Helper();

const bundlesWithApplicableSubscriptions = computed(() => {
  return productStore.voiceSubscriptionBundles.filter((bundle) =>
    bundle.products.some((subscriptionProduct) =>
      isOfferApplicable(subscriptionProduct, subscriptionPricesForVariant.value)
    )
  );
});

const comittmentPeriods = computed(() => {
  return commitments.value
    .filter((commitment) => commitment.commitment)
    .map((commitment) => commitment.commitment as number);
});

const recurringFeeForMobileDevice = computed(() => {
  return deviceFees.value.hardware.recurringFee;
});

const commitments = computed(() => {
  const subscriptionPrice = subscriptionPricesForVariant.value?.find(
    (subscriptionPrice) =>
      subscriptionPrice.subscriptionProductCode === selectedSubscription.value?.productCode
  );
  return subscriptionPrice?.commitments ?? [];
});

const disableConnectSubscription = computed(() => {
  const onlyZeroCommitments = !subscriptionPricesForVariant.value.some(
    (subscription) => subscription.commitments?.some((commitment) => commitment.commitment !== 0)
  );
  return onlyZeroCommitments;
});

const disableNewSubscription = computed(() => {
  return disableConnectSubscription.value || !props.canAddNewSubscription;
});

watchEffect(() => {
  const actualCommitments = comittmentPeriods.value.filter(
    (commitmentPeriod) => commitmentPeriod !== 0
  );
  if (actualCommitments.length) {
    handleCommitmentPeriod(actualCommitments[0]);
  }
});

function handleSubscriptionOperationChange(value: SubscriptionChoice) {
  hardwareConfigurationStore.setOperation(value);
  if (value === "NEW_SUBSCRIPTION") {
    const selectedBundle = productStore.voiceSubscriptionBundles[0] as SubscriptionBundle;
    handleSelectSubcriptionBundle(selectedBundle);
    const firstSubscription = selectedBundle.products[0];
    if (firstSubscription) {
      handleSelectSubscription(firstSubscription);
      ga4Helper.trackViewProduct(selectedBundle.products[0]);
    }
  }
}

function handleSelectSubcriptionBundle(bundle: SubscriptionBundle) {
  hardwareConfigurationStore.setSubscriptionBundle(bundle);
}

function handleCommitmentPeriod(commitment: number) {
  hardwareConfigurationStore.setCommitment(commitment);
}
function handleSelectSubscription(subscription: SubscriptionProduct) {
  hardwareConfigurationStore.setSubscriptionProduct(subscription);
}

function isOfferApplicable(
  subscription: SubscriptionProduct,
  subscriptionPrices: SubscriptionPricesUI[]
) {
  return subscriptionPrices.some(
    (price) => price.subscriptionProductCode === subscription.productCode
  );
}
</script>

<style scoped lang="scss">
@import "node_modules/@teliads/components/foundations/spacing/variables";
.subscription-selector {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
</style>
