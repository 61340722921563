import { corpMobileCustomerService, corpProductOrder } from "@telia/b2b-rest-client";
import { AgreementResoponse, Customer } from "../types";

export async function getCustomers(scopeId: string, tscId: string) {
  const {
    results,
  } = await corpMobileCustomerService.MobileCustomerControllerService.getMobileCustomer(scopeId, {
    tscId,
  });
  return results as Customer[];
}

export async function getAgreement(scopeId: string, tscId: string) {
  const res = await corpProductOrder.AgreementControllerService.getAgreement(scopeId, tscId);
  return res as AgreementResoponse;
}
