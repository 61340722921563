import { EmnIpAddressInfoUI, GetEmnSiteUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { defineStore } from "pinia";
import { computed, reactive, ref, onMounted } from "vue";
import { getEmnSites } from "../service/ProductService";
import { useStateStore } from "./StateStore";

interface EmnConfiguration {
  siteAgreementId: string;
  quantity: number;
  ipRangeStart?: string;
}

export enum Step {
  SITE = 1,
  CIDR = 2,
  IP_RANGE_START = 3,
  QUANTITY = 4,
}

export const useEmnSubscriptionStore = defineStore("emn-subscription-store", () => {
  const stateStore = useStateStore();
  const emnSites = ref<GetEmnSiteUI[]>([]);

  onMounted(async () => {
    fetchEmnSites();
  });

  const selectedProductCategory = ref<string>("");
  const currentStep = ref<number>(Step.SITE);

  const emnConfiguration = reactive<EmnConfiguration>({
    siteAgreementId: "",
    quantity: 1,
    ipRangeStart: "",
  });

  const maxQuantity = ref(250);
  const cidr = ref("");
  const isValidCidr = ref(true);
  const isValidIpRangeStart = ref(true);
  const ipRangeInfo = ref<EmnIpAddressInfoUI>({
    ipAddressStart: "",
    ipAddressEnd: "",
    quantity: 0,
    privateNetwork: true,
    validipAddressStart: true,
    validipAddressEnd: true,
  });

  const isConfigValid = computed(() => {
    if (selectedProductCategory.value === "mobilevoicesubscription") {
      return (
        emnConfiguration.siteAgreementId.length &&
        isValidQuantity.value
      );
    } else {
      return (
        currentStep.value === Step.QUANTITY &&
        emnConfiguration.siteAgreementId.length &&
        isValidQuantity.value &&
        ipRangeInfo.value.ipAddressStart.length &&
        isValidIpRangeStart.value
      );
    }
  });

  const isValidQuantity = computed(() => {
    return emnConfiguration.quantity > 0 && emnConfiguration.quantity <= maxQuantity.value;
  });

  async function fetchEmnSites() {
    try {
      const emnSites = await getEmnSites(stateStore.scopeId, stateStore.tscid);
      setEmnSites(emnSites);
      setSiteAgreementId(emnSites[0].siteAgreementId);
    } catch (error) {
      // TODO: categoryErrors.value.push({ category: "emn", error: "emnSitesFetch" });
    }
  }

  function setSelectedProductCategory(category: string) {
    selectedProductCategory.value = category;
  }

  function setCurrentStep(step: number) {
    currentStep.value = step;
  }

  function setEmnSites(sites: GetEmnSiteUI[]) {
    emnSites.value = sites;
  }

  function setSiteAgreementId(siteAgreementId: string) {
    emnConfiguration.siteAgreementId = siteAgreementId;
  }

  function setMaxQuantity(quantity: number) {
    maxQuantity.value = quantity;
  }

  function setQuantity(quantity: number) {
    emnConfiguration.quantity = quantity;
  }

  function setIpRangeStart(ipRangeStart: string) {
    emnConfiguration.ipRangeStart = ipRangeStart;
  }

  function setIsValidIpRangeStart(valid: boolean) {
    isValidIpRangeStart.value = valid;
  }

  function setCidr(ipRange: string) {
    cidr.value = ipRange;
  }

  function setIsValidCidr(valid: boolean) {
    isValidCidr.value = valid;
  }

  function setIpRangeInfo(emnIpAddressInfo: EmnIpAddressInfoUI) {
    ipRangeInfo.value = emnIpAddressInfo;
  }

  function reset() {
    if (emnSites.value.length) {
      emnConfiguration.siteAgreementId = emnSites.value.length
        ? emnSites.value[0].siteAgreementId
        : "";
    }
    emnConfiguration.ipRangeStart = "";
    emnConfiguration.quantity = 1;
    maxQuantity.value = 250;
    cidr.value = "";
    isValidIpRangeStart.value = false;
    currentStep.value = Step.SITE;
  }

  return {
    currentStep,
    emnConfiguration,
    emnSites,
    cidr,
    ipRangeInfo,
    maxQuantity,
    isValidCidr,
    isValidIpRangeStart,
    isValidQuantity,
    isConfigValid,
    setSelectedProductCategory,
    setCurrentStep,
    setEmnSites,
    setSiteAgreementId,
    setQuantity,
    setMaxQuantity,
    setIpRangeStart,
    setIsValidIpRangeStart,
    setCidr,
    setIsValidCidr,
    setIpRangeInfo,
    reset,
  };
});
