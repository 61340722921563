<template>
  <Modal modal-id="max-items-modal" :is-open="isOpen" @close-modal="() => emit('close')">
    <template v-slot:modal-body>
      <div>
        <telia-heading variant="title-200" tag="h3" data-testid="heading">{{
          t("maxItems.CONTINUE_SHOPPING")
        }}</telia-heading>

        <telia-p data-testid="message">{{
          t("maxItems.MAXIMUM_NUMBER_OF_ITEMS_TEXT", { amount: maxItemsAllowed })
        }}</telia-p>
      </div>
    </template>
    <template v-slot:button-footer>
      <div class="max-items-modal__footer">
        <telia-button variant="tertiary-purple" @click="emit('close')" type="button">
          <span>
            {{ t("maxItems.CHECK_OUT_LATER") }}
          </span>
        </telia-button>
        <telia-button
          variant="primary"
          @click="() => emit('proceed-to-basket')"
          data-testid="remove-button"
          type="button"
        >
          <span> {{ t("maxItems.PROCEED_TO_BASKET") }}</span>
        </telia-button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "./shared/modal.vue";
import { translate as t } from "@telia/b2b-i18n";

interface Props {
  isOpen: boolean;
  maxItemsAllowed: number;
}

defineProps<Props>();
const emit = defineEmits<{
  (e: "close"): void;
  (e: "proceed-to-basket"): void;
}>();
</script>

<style scoped lang="scss">
.max-items-modal {
  @import "~@teliads/components/foundations/spacing/variables.scss";
  &__footer {
    padding: 0 $telia-spacing-32 $telia-spacing-32 $telia-spacing-32;
  }
}
</style>
